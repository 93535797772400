@use '../../../foundation/config' as *;

.p-companyBlock12 {
  margin: 15rem 0 0;
}
.p-companyBlock12__image1 {
  display: block;
  margin: 0 -4rem;
  @include mq(md, min) {
    text-align: center;
    margin: 0;
  }
}
.p-companyBlock12__main1 {
  margin: 8.8rem 0 0;
  @include mq(md, min) {
    margin: 6.8rem 17.5rem 0;
  }
}

.p-companyBlock12__text1 {
  font-size: 3rem;
  line-height: (60/30);
  margin: 2rem 0 0;
  @include mq(md, min) {
    font-size: 1.6rem;
    line-height: (36/16);
    margin: 0;
  }
}
