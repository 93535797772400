@use '../../../foundation/config' as *;

/*---------------
共通スタイル
---------------*/

.p-communicationCulture {
  padding-bottom: 25.2rem;

  @include mq(md) {
    padding-bottom: 24rem;
  }
}

.p-communicationCulture__text {
  font-size: 1.6rem;
  line-height: (36 / 16);

  @include mq(md) {
    font-size: 3rem;
    line-height: (60 / 30);
  }

  &.-bold {
    font-weight: bold;
  }
}

.p-communicationCulture__textNotes {
  margin-top: 2.2rem;
  font-size: 1.4rem;
  line-height: (26 / 14);

  @include mq(md) {
    font-size: 2.4rem;
    line-height: (40 / 24);
  }
}

.p-communicationCulture__images {
  margin-top: 7.4rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  &.-mt2 {
    margin-top: 2rem;

    @include mq(md) {
      margin-top: 3.5rem;
    }
  }

  &.-mt7 {
    margin-top: 7rem;

    @include mq(md) {
      margin-top: 6.4rem;
    }
  }

  &.-mt93 {
    margin-top: 9.3rem;

    @include mq(md) {
      margin-top: 3.7rem;
    }
  }
}

.p-communicationCulture__image {
  img {
    width: 100%;
  }

  &.-column2 {
    width: 50%;
    padding-right: 3.5rem;

    @include mq(md) {
      width: 100%;
      padding-right: 0;

      & + & {
        margin-top: 5.2rem;
      }
    }

    &.-right {
      padding-left: 3.5rem;
      padding-right: 0;

      @include mq(md) {
        padding-left: 0;
      }
    }
  }

  &.-column3 {
    width: calc(33.333% - 4.8rem);
    margin-left: 4.8rem;

    &:nth-child(1) {
      margin-left: 0;
    }

    @include mq(md) {
      width: 100%;
      padding: 0 7.2rem;
      margin-left: 0;
      & + & {
        margin-top: 4.9rem;
      }
    }
  }
}

.p-communicationCulture__video {
  width: 90rem;
  margin-top: 7.4rem;
  margin-right: auto;
  margin-left: auto;

  @include mq(md) {
    width: 100%;
  }
}

.p-communicationCulture__figure {
  position: relative;
  padding-top: percentage(9 / 16);
  overflow: hidden;

  > iframe {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
  }
}

/*---------------
各セクションのスタイル
---------------*/
.p-communicationCultureGovernment__heading {
  margin-top: 14.2rem;

  @include mq(md) {
    margin-top: 13.7rem;
  }
}

.p-communicationCultureGovernment__description {
  margin-top: 6.2rem;
  @include mq(md) {
    margin-top: 5.1rem;
  }
}

.p-communicationCultureRegion {
  margin-top: 19.1rem;

  @include mq(md) {
    margin-top: 18.6rem;
  }
}

.p-communicationCultureRegion__description {
  margin-top: 6.2rem;
  @include mq(md) {
    margin-top: 5.1rem;
  }
}

.p-communicationCultureSchool {
  margin-top: 19.2rem;

  @include mq(md) {
    margin-top: 18.5rem;
  }
}

.p-communicationCultureSchool__heading3 {
  margin-top: 6.1rem;

  @include mq(md) {
    margin-top: 5.4rem;
  }
}

.p-communicationCultureSchool__description {
  margin-top: 1.8rem;

  @include mq(md) {
    margin-top: 2.3rem;
  }
}

.p-communicationCultureStudent {
  margin-top: 19.2rem;

  @include mq(md) {
    margin-top: 18.7rem;
  }
}

.p-communicationCultureStudent__heading2 {
  margin-top: 6.9rem;

  @include mq(md) {
    margin-top: 5.3rem;
  }
}

.p-communicationCultureStudent__heading3 {
  margin-top: 8.4rem;

  @include mq(md) {
    margin-top: 7.3rem;
  }
}

.p-communicationCultureStudent__heading4 {
  margin-top: 3.8rem;
}

.p-communicationCultureStudent__heading5 {
  margin-top: 6.7rem;
}

.p-communicationCultureStudent__description {
  margin-top: 3.9rem;

  @include mq(md) {
    margin-top: 3.4rem;
  }
}

.p-communicationCultureStudent__description2 {
  margin-top: 5.8rem;

  @include mq(md) {
    margin-top: 5rem;
  }
}

.p-communicationCultureStudent__description3 {
  margin-top: 6.8rem;
  @include mq(md) {
    margin-top: 5.8rem;
  }
}

.p-communicationCultureStudent__description4 {
  margin-top: 3.4rem;

  @include mq(md) {
    margin-top: 3.3rem;
  }
}

.p-communicationCultureStudent__description5 {
  margin-top: 1.5rem;
  @include mq(md) {
    margin-top: 2.3rem;
  }
}

.p-communicationCultureStudent__description6 {
  margin-top: 3.8rem;

  @include mq(md) {
    margin-top: 3.3rem;
  }
}
.p-communicationCultureStudent__thought {
  margin-top: 8rem;
  border: 1px solid color(gray-300);
  padding: 2.7rem 0 3.3rem 4rem;
  display: flex;
  flex-wrap: wrap;

  @include mq(md) {
    margin-top: 7.5rem;
    border: 2px solid color(gray-300);
    padding: 3.5rem 1rem 3.3rem 4rem;
  }
}

.p-communicationCultureStudent__thoughtTitle {
  margin-right: 4rem;

  @include mq(md) {
    width: 100%;
    margin-right: 0;
  }
}

.p-communicationCultureStudent__thoughtContent {
  margin-top: 0.3rem;

  @include mq(md) {
    width: 100%;
    margin-top: 0.5rem;
  }
}

.p-communicationCulture__externalLink {
  font-size: 1.6rem;
  line-height: (36 / 16);
  color: color(gray-600);

  @include mq(md) {
    font-size: 3rem;
    line-height: (60 / 30);
  }

  a {
    transition-property: color, text-decoration-color;
    transition-duration: 400ms;
    transition-timing-function: easing(easeOutCubic);
    text-decoration: underline;
    text-underline-offset: 0.4rem;

    &:hover {
      color: color(green-600);
      text-decoration-color: rgba(color(gray-600), 0);
    }
  }

  svg {
    position: relative;
    width: 1.4rem;
    height: 1.4rem;
    top: -1.1rem;
    margin-left: 0.5rem;

    @include mq(md) {
      width: 2.1rem;
      height: 2.1rem;
      top: -2rem;
      margin-left: 0.8rem;
    }
  }
}

.p-communicationCultureBeyond__programImage {
  margin-top: 1rem;
  width: 31%;

  @include mq(md) {
    margin-top: 3.2rem;
    width: 100%;
  }
}

.p-communicationCultureWorkshop {
  margin-top: 8.3rem;

  @include mq(md) {
    margin-top: 8.6rem;
  }
}

.p-communicationCultureWorkshop__description {
  margin-top: 3.9rem;

  @include mq(md) {
    margin-top: 4.3rem;
  }
}

.p-communicationCultureEncyclopedia {
  margin-top: 8.4rem;

  @include mq(md) {
    margin-top: 7.4rem;
  }
}

.p-communicationCultureEncyclopedia__description {
  margin-top: 3.8rem;
}
