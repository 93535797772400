@use '../../../foundation/config' as *;

.p-companyLocalNav3 {
  border-top: 1px solid color(gray-300);
  border-bottom: 1px solid color(gray-300);
  margin: 14.8rem 0 0;
  padding: 3.2rem 7.7rem 3.5rem 7.7rem;
  @include mq(md, min) {
    margin: 15rem 0 0;
    padding: 2.9rem 0 2.9rem 15.8rem;
  }
}
.p-companyLocalNav3__list1 {
  display: flex;
  flex-wrap: wrap;
  & > li {
    margin: 0.1rem 0 0 4.3rem;
    @include mq(md, min) {
      margin: 0 0 0 7.9rem;
    }
    &:nth-of-type(4n + 1) {
      margin-left: 0;
      @include mq(md, min) {
        margin-left: 7.9rem;
      }
    }
    &:first-child {
      @include mq(md, min) {
        margin-left: 0;
      }
    }
    & > a {
      font-size: 2.6rem;
      font-weight: bold;
      line-height: (60/26);
      transition: color 400ms easing(easeOutCubic);
      @include mq(md, min) {
        font-size: 2rem;
        line-height: (40/20);
      }
      &.-active,
      &:hover {
        color: color(green-600);
      }
    }
  }
}
