@use '../../foundation/config' as *;

.c-list {
  margin: 0 0 2.5rem 0;

  li {
    line-height: 1.6;

    + li {
      margin-top: .4em;
    }

    li {
      margin-left: 1.3em;
    }

    .c-list {
      margin-top: .4em;
      margin-bottom: .4em;

      li {
        margin-left: 2em;
        @include mq(md) {
          margin-left: 1em;
        }
        + li {
          margin-top: .4em;
        }
      }
    }
    &.-none {
      list-style: none;
    }
  }

  &.-disc {
    li {
      list-style: disc;
    }
  }
  &.-number {
    li {
      list-style: decimal;
    }
  }
  &.-none {
    li {
      list-style: none;
      margin-left: 0;
    }
  }
  &.-custom {
    li {
      display: flex;

      span:nth-of-type(1) {
        margin-right: .5rem;
        @include mq(md) {
          margin-right: 1rem;
        }
      }
    }
  }
  &.-custom_nest {
    li {
      display: flex;

      > span:nth-of-type(1) {
        flex-shrink: 0;
        margin-right: .5rem;
        @include mq(md) {
          margin-right: 1rem;
        }
      }
      + li {
        margin-top: 1em;
      }
    }
  }
  &.-hasIndent {
    margin-left: 1em;
  }
  &.-custom_nest2 {
    li {
      div {
        display: flex;

        > span:nth-of-type(1) {
          flex-shrink: 0;
          margin-right: .5rem;
          @include mq(md) {
            margin-right: 1rem;
          }
        }
      }
    }
  }

  dt {
    font-weight: bold;
  }
}
