@use '../../../foundation/config' as *;

.p-companyBlock3 {
  margin: 17.2rem 0 0;
  @include mq(md, min) {
    margin: 19.3rem 0 0;
  }
}
.p-companyBlock3__wrap1 {
  margin: 5.2rem 0 0;
  @include mq(md, min) {
    margin: 6.1rem 0 0;
    display: flex;
  }
}
.p-companyBlock3__wrap1Subtitle1 {
  @include mq(md, min) {
    min-width: 50%;
    max-width: 50%;
  }
}
.p-companyBlock3__title2MainLabel1 {
  font-size: 6rem;
  line-height: (80/60);
  @include font-serif;
  display: block;
  @include mq(md, min) {
    position: relative;
    left: -2.7rem;
    width: 40rem;
    font-size: 4.2rem;
    line-height: (62/42);
    margin: auto;
  }
}
.p-companyBlock3__title2SubLabel1 {
  font-size: 3.4rem;
  line-height: (64/34);
  font-weight: bold;
  display: block;
  margin: 3.2rem 0 0;
  @include mq(md, min) {
    position: relative;
    left: -2.7rem;
    width: 40rem;
    font-size: 2rem;
    line-height: (40/20);
    margin: 2.7rem auto 0;
    text-align: left;
  }
}
.p-companyBlock3__wrap1Body1 {
  margin: 4.8rem 0 0;
  @include mq(md, min) {
    margin: 0 0 0;
    min-width: 50%;
    max-width: 50%;
  }
}
.p-companyBlock3__text1 {
  font-size: 3rem;
  line-height: (60/30);
  text-align: justify;
  @include mq(md, min) {
    font-size: 1.6rem;
    line-height: (36/16);
  }
}
.p-companyBlock3__list1 {
  margin: 3.3rem 0 0;
  padding: 1.4rem 0 1rem 3.8rem;
  border: 1px solid color(gray-300);
  @include mq(md, min) {
    margin: 4.1rem 0 0;
    padding: 2.7rem 0 2.9rem 4rem;
    display: flex;
    align-items: center;
  }
}
.p-companyBlock3__list1Title1 {
  @include mq(md, min) {
    white-space: nowrap;
  }
}
.p-companyBlock3__listGroup1 {
  @include mq(md, min) {
    display: flex;
    width: 100%;
  }
}
.p-companyBlock3__list1List1 {
  display: flex;
  flex-wrap: wrap;
  margin: 2.1rem 0 1rem 0;
  @include mq(md, min) {
    margin: 0;
    width: 100%;
    display: block;
  }
  &:nth-of-type(3),
  &:nth-of-type(2) {
    margin-top: 1.9rem;
    @include mq(md, min) {
      margin-top: 0;
    }
  }
  &:nth-of-type(2) {
    @include mq(md, min) {
      margin: 0 0 0 -1.3rem;
    }
  }
  &:nth-of-type(1) {
    @include mq(md, min) {
      margin: 0 0 0 4.3rem;
    }
  }
  & > li {
    font-size: 3rem;
    line-height: (40/30);
    min-width: 65%;
    max-width: 65%;
    padding: 0 2.8rem 0 3rem;
    position: relative;
    @include mq(md, min) {
      font-size: 1.6rem;
      line-height: (36/16);
      padding: 0 0 0 1.6rem;
      min-width: inherit;
      max-width: inherit;
    }
    &:nth-of-type(2n + 1) {
      min-width: 35%;
      max-width: 35%;
      @include mq(md, min) {
        min-width: inherit;
        max-width: inherit;
      }
    }
    &:before {
      content: '・';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
    }
  }
}
