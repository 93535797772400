@use '../../../foundation/config' as *;

.p-companyCompliance {
  margin-top: 13.8rem;
  padding-bottom: 1rem;

  @include mq(md) {
    margin-top: 13.6rem;
    padding-bottom: 0.9rem;
  }
}

.p-companyCompliance__section {
  & + & {
    margin-top: 19.4rem;

    @include mq(md) {
      margin-top: 18.4rem;
    }
  }
}

.p-companyCompliance__body {
  .c-heading.-level4 + & {
    margin-top: 2.5rem;

    @include mq(md) {
      margin-top: 3.6rem;
    }
  }

  .c-heading.-level2 + & {
    margin-top: 7.1rem;
  }
}

.p-companyCompliance__text {
  @include lhCrop(2.25);
  font-size: 1.6rem;
  line-height: 2.25;

  @include mq(md) {
    @include lhCrop(2);
    font-size: 3rem;
    line-height: 2;
  }

  &.-margin1row {
    margin-top: 5.6rem;

    @include mq(md) {
      margin-top: 9rem;
    }
  }
}

.p-companyCompliance__image {
  margin-top: 7.9rem;

  @include mq(md, min) {
    padding-right: 2.6rem;
    padding-left: 2.6rem;
  }

  @include mq(md) {
    margin-top: 8.1rem;
  }

  img {
    width: 100%;
  }
}

.p-companyCompliance__button {
  margin-top: 5rem;

  @include mq(md) {
    text-align: center;
  }

  &.-large {
    margin-top: 8rem;
    text-align: center;
  }
}
