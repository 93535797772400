@use '../../../foundation/config' as *;

.p-terms {
  margin: 13rem 0 0;
  padding-bottom: 24rem;
  @include mq(md, min) {
    max-width: 1050px;
    margin: 14.5rem auto 0;
    padding: 0 0 25.2rem 6.1rem;
  }
}

.p-terms__layout {
  @include mq(md, min) {
    display: flex;
  }

  .p-terms__text1 {
    @include mq(md, min) {
      flex-grow: 1;
      margin-right: 9rem;
    }
  }

  .p-terms__image1 {
    @include mq(md, min) {
      flex-shrink: 0;
      max-width: 50%;
    }

    @include mq(md) {
      margin-top: 5rem;
    }

    img {
      height: auto;

      @include mq(md) {
        width: 45rem;
      }

      @include mq(md, min) {
        width: 30.4rem;
      }
    }
  }
}

.p-terms__text1 {
  &:first-child {
    margin-top: 0;
  }

  & > p {
    font-size: 3rem;
    line-height: (60/30);
    text-align: justify;
    margin: 1em 0 0;
    @include mq(md, min) {
      font-size: 1.6rem;
      line-height: (36/16);
    }

    &:first-child {
      margin-top: 0;
    }
  }

  & > ul > li {
    position: relative;
    font-size: 1.6rem;
    line-height: (36/16);
    padding-left: 2rem;

    @include mq(md) {
      padding-left: 4rem;
    }

    &::before {
      content: '';
      display: inline-block;
      position: absolute;
      top: 1.8rem;
      left: 0;
      width: 0.8rem;
      height: 0.8rem;
      margin-right: 2rem - 0.8rem;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='8px' height='8px'%3E%3Cpath fill-rule='evenodd' fill='rgb(0, 160, 64)' d='M4.000,-0.000 C6.209,-0.000 8.000,1.791 8.000,4.000 C8.000,6.209 6.209,8.000 4.000,8.000 C1.791,8.000 -0.000,6.209 -0.000,4.000 C-0.000,1.791 1.791,-0.000 4.000,-0.000 Z'/%3E%3C/svg%3E");
      background-repeat: no-repeat;
      background-size: 100%;
      transform: translateY(-50%);

      @include mq(md) {
        top: 3rem;
        width: 1.6rem;
        height: 1.6rem;
        margin-right: 4rem - 1.6rem;
      }
    }

    @include mq(md) {
      font-size: 3rem;
      line-height: (60/30);
    }
  }

  a {
    color: color(gray-600);
    transition-property: color, text-decoration-color;
    transition-duration: 400ms;
    transition-timing-function: easing(easeOutCubic);
    text-decoration: underline;
    text-underline-offset: 0.4rem;

    &:hover {
      color: color(green-600);
      text-decoration-color: rgba(color(gray-600), 0);
    }
  }

  .p-terms__rightText {
    text-align: right;
  }

  &.-mt64 {
    margin-top: 6.4rem;

    @include mq(md) {
      margin-top: 5rem;
    }
  }
}
.p-terms__title1 {
  margin: 17.5rem 0 5rem;
  @include mq(md, min) {
    margin: 18.2rem 0 6.4rem;
  }
  &:nth-of-type(1) {
    margin: 17.3rem 0 5rem;
    @include mq(md, min) {
      margin: 13.2rem 0 6.4rem;
    }
  }
}
.p-terms__title2 {
  margin: 5rem 0 3rem;
  @include mq(md, min) {
    margin: 6.4rem 0 4rem;
  }
}

.p-terms__margin1row {
  margin-top: (36 / 16) * 1em;

  @include mq(md) {
    margin-top: 2em;
  }
}

.p-terms__table {
  margin-top: 5rem;
}

.p-terms__tableCol {
  @include mq(md) {
    width: 30rem;
  }
}

.p-terms__notes {
  display: block;
  font-size: 1.4rem;
  line-height: (26 / 14);

  @include mq(md) {
    font-size: 2.4rem;
    line-height: (40 / 24);
  }
}
