@use '../../../foundation/config' as *;

.p-newsroom {
  overflow: hidden;
  .p-news__inner * {
    @include font-default;
  }

  //level2見出し
  .c-heading {
    &.-level2 {
      margin: 4rem 0;
      font-size: 3rem;
      @include mq(md) {
        margin-top: 10rem;
        font-size: 4.5rem;
      }
    }
  }
}

/* 最新情報 */
.p-newsroomLatest__area {
  @include mq(md,min) {
    margin-bottom: 2.2rem;
  }
  @include mq(md) {
    margin-bottom: 6rem;
  }
}
.p-newsroomLatest {
  display: flex;
  justify-content: space-between;
  @include mq(md) {
    flex-direction: column;
  }
}
.p-newsroomLatestCol {
  .p-newsListItemHaveThumb__info {
    margin-top: 1.4rem;
  }
  &.--primary {
    @include mq(md,min) {
      flex: 0 0 ((757+2) / 12) * 1rem;//画像幅+左右borderを考慮
      margin-right: 3.6rem;
    }
    @include mq(md) {
      flex: 0 0 100%;
    }
    @include mq(md) {
      margin-bottom: 5rem;
    }
  }
  &.--secondary {
    flex-direction: column;
    justify-content: space-between;
    height: auto;
    flex: 1 0;
  }
}

/* ピックアップ */
.p-newsroomPickup__area {
  margin-left: calc(50% - 50vw);
  margin-right: calc(50% - 50vw);
  background-color: #fff;
  padding: 4rem 0 2.2rem;
  @include mq(md) {
    padding: 10rem 0;
  }
}

.p-newsroomPickup__area__inner {
  .c-heading.-level2{
    &:first-child{
      margin-top: 0;
    }
  }
}

.p-newsroomPickup {
  display: flex;
  flex-wrap: wrap;
  @include mq(md,min) {
    justify-content: flex-start;
  }
  @include mq(md) {
    flex-direction: column;
  }
  .p-newsListItemHaveThumb{
    @include mq(md,min) {
      margin-left: 2%;
      &:first-child{
        margin-left: 0;
      }
    }
  }
}

/* ニュースリリース */
.p-newsroomRelease__area {
  margin-bottom: 10rem;
  .p-news__headerUpper {
    padding-top: 0;
    margin-bottom: 4rem;
  }
  .p-newsroomRelease__link {
    display: flex;
    align-items: center;
    @include mq(md) {
      padding-right: 4rem;
      padding-left: 4rem;
    }
    li:nth-child( n + 2 ) {
      .c-linkButton {
        margin-left: 4rem;
      }
    }
    .c-linkButton {
      min-width: auto;
      @include mq(md,min) {
        margin: 0 0 2.5rem;
      }
      @include mq(md) {
        margin: 0 0 4rem;
      }
    }
  }
  //検索ボックス
  .p-news__search{
    .label{
      @include mq(md, min) {
        padding-left: 0;
        width: unset;
        min-width: unset;
      }
    }
    &.-keyword{
      margin-bottom: 0;
      @include mq(md, min) {
        padding: 2rem 8rem;
      }
    }
    .p-newsChildMenu__searchGroup {
      display: flex;
      width: 100%;
      @include mq(md) {
        width: 100%;
      }
    }
  }
  .p-newsListItemHaveThumb__wrap {
    margin-top: 4rem;
    @include mq(md) {
      margin-top: 0;
    }
  }
}

/* 活動・取り組み */
.p-newsroomBanner__area {
  margin-bottom: 10rem;
}
.p-newsroomBanner__list {
  @include mq(md, min) {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
}
.p-newsroomBanner__listItem {
  flex: 0 0 48%;
  background-color: color(gray-100);
  @include mq(md, min) {
    &:nth-child(n+1):nth-child(-n+6) {
      margin-bottom: 5rem;
    }
  }
  @include mq(md) {
    flex: 1;
    & +.p-newsroomBanner__listItem {
      margin-top: 3rem;
    }
  }
}
.p-newsroomBanner__listItemLink {
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  height: 100%;
  &::before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transition: background-color 400ms cubic-bezier(0.215, 0.61, 0.355, 1);
    z-index: 1;
  }
  &:hover {
    .p-newsroomBanner__listItemImage {
      img {
        transform: scale(1.06);
      }
    }
  }
}
.p-newsroomBanner__listItemBody {
  flex: 1;
  padding: 0rem 1.6rem 0 0;
  color: initial;
  .c-heading {
    &.-level3 {
      font-size: 2rem;
      margin-bottom: 1.2rem;
      padding-bottom: .6rem;
      @include mq(md) {
        font-size: 2.4rem;
      }
    }
  }
  p {
    font-size: 1.6rem;
    font-weight: normal;
    @include mq(md) {
      font-size: 1.8rem;
    }
  }
}
.p-newsroomBanner__listItemImage {
  flex: 0 0 46%;
  overflow: hidden;
  @include mq(md) {
    flex: 0 0 44%;
  }
  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
    transition: transform 400ms cubic-bezier(0.215, 0.61, 0.355, 1);
    backface-visibility: hidden;
  }
}
.p-newsroomBanner__icon {
  position: absolute;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  right: 1.8rem;
  bottom: 1.8rem;
  width: 4rem;
  height: 4rem;
  border: 1px solid;
  color: rgba(color(white), 0.5);
  svg {
    width: 1.6rem;
    height: 1.1rem;
    color: color(white);
  }
}

/* メディア・報道関係者の方へ */
.p-newsroomMedia__area {
  margin-bottom: 10rem;
}
.p-newsroomMedia {
  @include mq(md, min) {
    display: flex;
    justify-content: space-between;
  }
}
.p-newsroomMediaCol {
  flex: 0 0 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1.5rem 4rem;
  @include mq(md, min) {
    &:nth-child(2) {
      border-left: 1px solid color(gray-300);
    }
  }
}
.p-newsroomMedia .c-linkButton {
  justify-content: center;
  padding: 0;
  min-width: 40rem;
}
.p-newsroomMedia .c-linkButton::after {
  position: initial;
  margin-left: 1rem;
}
@media (max-width: 750px) {
  .p-newsroomMedia .c-linkButton {
    padding: 0 5rem;
    font-size: 3rem;
  }
  .p-newsroomMedia .c-linkButton::after {
    width: 2.4rem;
    height: 2.4rem;
    margin-left: auto;
  }
}

/* ソーシャルメディア */
.p-newsroomSocial__area {
  padding-top: 10rem;
  border-top: 1px solid color(gray-300);
  @include mq(md){
    padding-top: 6rem;
    padding-bottom: 4rem;
  }
}
.p-newsroomSocial__list {
  display: flex;
  align-items: center;
  justify-content: center;
  @include mq(md){
    justify-content: space-around;
  }
}
.p-newsroomSocial__listItem {
  @include mq(md, min) {
    & + .p-newsroomSocial__listItem {
      margin-left: 8rem;
    }
  }
  @include mq(md){
    min-width: 19rem;
  }
  .c-socialButton {
    font-size: 2rem;
    @include mq(md){
      flex-direction: column;
      justify-content: center;
      font-size: 3.2rem;
      line-height: 1.5;
      .c-socialButton_icon {
        margin-right: 0;
      }
    }
  }
  .-x, .-facebook {
    width: 4rem;
    height: 4rem;
    @include mq(md){
      width: 5.2rem;
      height: 5.2rem;
    }
  }
  .-line {
    width: 4.5rem;
    height: 4.5rem;
    @include mq(md){
      width: 5.7rem;
      height: 5.7rem;
    }
  }
}
.p-newsroomSocial__text {
  @include mq(md){
    margin-top: 6rem;
  }
  .c-linkButton {
    min-width: unset;
    min-height: unset;
    margin-left: auto;
  }
}
