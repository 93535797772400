@use '../../../foundation/config' as *;

.p-irGrowthPerformance__section + .p-irGrowthPerformance__section {
  margin-top: 19rem;
}

.p-irGrowthPerformance__sectionHeading + .p-irGrowthPerformance__body {
  margin-top: 7.2rem;
}

.p-irGrowthPerformance__box {
  background-color: color(gray-200);

  &.-box1 {
    display: flex;
    width: 100%;
    height: 28.3rem;
    @include mq(md, min) {
      padding-right: 13.4rem;
    }

    @include mq(md) {
      flex-flow: column;
      height: 66rem;
      justify-content: center;
      align-items: center;
    }

    .p-irGrowthPerformance__titleBox,
    .p-irGrowthPerformance__boxImage {
      display: flex;
      flex-flow: column;
      justify-content: center;
    }

    .p-irGrowthPerformance__titleBox {
      align-items: flex-start;
      padding-left: 4.8rem;
      @include mq(md) {
        display: flex;
        flex-flow: column;
        align-items: center;
        width: 100%;
        padding-left: 0;
      }
    }

    .p-irGrowthPerformance__titleBoxText {
      margin-top: 2.2rem;
      @include mq(md) {
        margin-top: 3.2rem;
      }
    }

    .p-irGrowthPerformance__boxImage {
      align-items: center;
      width: 50.7rem;

      @include mq(md, min) {
        margin-left: auto;
      }

      @include mq(md) {
        width: 69rem;
        margin-top: 5.6rem;
      }
      img {
        width: 100%;
        height: auto;
      }
    }
  }

  &.-box2 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 69rem;
    height: 35.2rem;
    padding-bottom: 1.4rem;

    @include mq(md, min) {
      padding-right: 5rem;
      padding-left: 5rem;
    }

    @include mq(md) {
      align-items: center;
      width: 100%;
      height: 59.5rem;
    }

    .p-irGrowthPerformance__titleBox {
      display: flex;
      align-items: baseline;
      @include mq(md) {
        flex-flow: column;
        justify-content: center;
        align-items: center;
      }
    }

    .p-irGrowthPerformance__titleBoxText {
      margin-left: 2.8rem;
      @include mq(md) {
        margin-left: 0;
        margin-top: 3.2rem;
      }
    }

    .p-irGrowthPerformance__boxImage {
      width: 58.9rem;
      margin-top: 4rem;
      @include mq(md) {
        width: 66.7rem;
        margin-top: 5.6rem;
      }
      img {
        width: 100%;
        height: auto;
      }
    }
  }

  &.-box3 {
    display: flex;
    flex-flow: column;
    width: calc(100% - 69rem - 2rem);
    align-items: center;
    justify-content: center;
    height: 35.2rem;
    @include mq(md) {
      width: 100%;
      height: 46.2rem;
      margin-top: 5rem;
    }

    .p-irGrowthPerformance__titleBox {
      text-align: center;
    }

    .p-irGrowthPerformance__titleBoxTitle {
      line-height: 1.8;
    }

    .p-irGrowthPerformance__boxImageSvg {
      margin-top: 3.6rem;
      width: 4rem;
      @include mq(md) {
        width: 8rem;
      }
    }

    .p-irGrowthPerformance__boxImageSvg_img {
      width: 100%;
      height: auto;
    }

    .p-irGrowthPerformance__boxText {
      font-size: 3.8rem;
      margin-top: 2.6rem;
      @include mq(md) {
        font-size: 6rem;
      }
    }
  }
}

.p-irGrowthPerformance__titleBoxTitle {
  font-size: 2rem;
  @include mq(md) {
    font-size: 4rem;
  }
}

.p-irGrowthPerformance__titleBoxText,
.p-irGrowthPerformance__boxText {
  @include font-serif;
  font-size: 4.2rem;
  @include mq(md) {
    font-size: 6rem;
  }
}

.p-irGrowthPerformance__outerBox {
  display: flex;
  justify-content: space-between;
  margin-top: 2rem;
  @include mq(md) {
    display: block;
    margin-top: 5rem;
  }
}

.p-irGrowthPerformance__text {
  font-size: 1.6rem;
  line-height: 2.2;
  margin-bottom: 3.6rem;
  @include mq(md) {
    font-size: 3rem;
    line-height: 2;
    margin-bottom: 5.6rem;
  }
}

.p-irGrowthPerformance__boxImageCaption {
  margin-bottom: 2rem;
  font-size: 1.5rem;
}

.p-irGrowthPerformance__summary {
  .p-irGrowthPerformance__box {
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 2.4rem 3.8rem;
    background-color: color(gray-200);
    @include mq(md) {
      flex-direction: column;
      justify-content: center;
      padding: 5.2rem 2rem;
    }
    &.-box2 {
      padding: 2.4rem 3.8rem;
      @include mq(md) {
        height: 100%;
        padding: 5.2rem 2rem;
      }
      .p-irGrowthPerformance__titleBoxText {
        margin-top: 0;
      }
    }
  }
  .p-irGrowthPerformance__titleBox {
    padding: 0;
    text-align: center;

    @include mq(md) {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
  .p-irGrowthPerformance__captionText {
    margin: 2rem 0;
    font-size: 1.8rem;
    font-weight: bold;
    text-align: center;
    @include mq(md) {
      margin: 0 0 2rem;
      font-size: 2.6rem;
    }
  }
  .p-irGrowthPerformance__graphBox {
    @include mq(md) {
      margin-top: 3.6rem;
    }
  }
  .p-irGrowthPerformance__graph {
    display: flex;
    align-items: center;
    justify-content: space-around;
  }
  .p-irGrowthPerformance__graphItem {
    &+.p-irGrowthPerformance__graphItem {
      margin-left: 3rem;
    }
    dt {
      margin-bottom: 2.2rem;
      font-size: 1.8rem;
      font-weight: bold;
      text-align: center;
      @include mq(md) {
        font-size: 2.4rem;
      }
    }
  }
}



.c-graph {
  &__pie {
    position: relative;
    margin-right: auto;
    margin-left: auto;
    width: 17rem;
    height: 17rem;
    border-radius: 50%;
    background-image: conic-gradient(#DFE8E2 0% var(--value-ratio), #FCFCFC var(--value-ratio) 100%);
    @include mq(md) {
      width: 21rem;
      height: 21rem;
    }
    span {
      position: absolute;
      top: 50%;
      right: 2.8rem;
      transform: translateY(-50%);
      color: #000;
      font-size: 1.6rem;
      font-weight: 700;
    }
    &.-small {
      width: 13rem;
      height: 13rem;
      @include mq(md) {
        width: 14.5rem;
        height: 14.5rem;
      }
      span {
        font-size: 1.4rem;
      }
    }
    &[data-graph='consolidated-single'] {
      span {
        top: 50%;
        right: 2.8rem;
      }
    }
    &[data-graph='consolidated-domestic'] {
      span {
        top: 24%;
        right: 3.2rem;
      }
    }
    &[data-graph='consolidated-overseas'] {
      span {
        top: 16%;
        right: 4.7rem;
      }
    }
    &[data-graph='composition-tea'] {
      span {
        top: 50%;
        right: 2rem;
      }
    }
    &[data-graph='composition-vegetable'] {
      span {
        top: 16%;
        right: 3.2rem;
      }
    }
    &[data-graph='composition-coffee'] {
      span {
        top: 16%;
        right: 4rem;
      }
    }
    &[data-graph='composition-other'] {
      span {
        top: 16%;
        right: 3.6rem;
      }
    }
  }
}

