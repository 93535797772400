@use '../../../foundation/config' as *;

.p-researchBlock5 {
  margin: 6.8rem 0 0;
}

.p-researchBlock5__list {
  @include mq(md, min) {
    display: flex;
    flex-wrap: wrap;
  }
}

.p-researchBlock5__item {
  @include mq(md, min) {
    width: calc((100% - 7rem * 2) / 3.0001);
    margin: 7rem 7rem 0 0;

    &:nth-child(3n) {
      margin-right: 0;
    }

    &:nth-child(-n + 3) {
      margin-top: 0;
    }
  }

  @include mq(md) {
    & + & {
      margin-top: 8rem;
    }
  }
}

.p-researchBlock5__image {
  img {
    width: 100%;
    height: auto;
  }
}

.p-researchBlock5__title {
  font-weight: bold;
  @include mq(md, min) {
    font-size: 2rem;
    line-height: 2;
    margin: 1.9rem 0 0;
  }

  @include mq(md) {
    font-size: 4rem;
    line-height: (60/40);
    margin: 3.3rem 0 0;
  }
}

.p-researchBlock5__description {
  @include mq(md, min) {
    font-size: 1.54rem;
    line-height: (36/16);
    margin: 1rem 0 0;
  }

  @include mq(md) {
    font-size: 3rem;
    line-height: 2;
    margin: 2rem 0 0;
  }
}

.p-researchBlock5__button {
  @include mq(md, min) {
    margin: 4rem 0 0;
  }

  @include mq(md) {
    margin: 3.7rem 0 0;
  }
}
