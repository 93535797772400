@use '../../../foundation/config' as *;

.p-formBlock1 {
  margin: 13.7rem 0 0;
  @include mq(md, min) {
    margin: 14.5rem 0 0;
  }
  @at-root {
    form > input[style='display: none;'] + .p-formBlock1 {
      display: none;
    }
  }
}

.p-formBlock1__text1 {
  margin: 3rem 0 0;
  @include mq(md, min) {
    margin: 3.7rem 0 0;
  }

  ul:not([class]) li:not([class]) {
    position: relative;
    padding-left: 2rem;

    @include mq(md) {
      padding-left: 4rem;
    }

    &::before {
      content: '';
      display: inline-block;
      position: absolute;
      top: 1.8rem;
      left: 0;
      width: 0.8rem;
      height: 0.8rem;
      margin-right: 2rem - 0.8rem;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='8px' height='8px'%3E%3Cpath fill-rule='evenodd' fill='rgb(0, 160, 64)' d='M4.000,-0.000 C6.209,-0.000 8.000,1.791 8.000,4.000 C8.000,6.209 6.209,8.000 4.000,8.000 C1.791,8.000 -0.000,6.209 -0.000,4.000 C-0.000,1.791 1.791,-0.000 4.000,-0.000 Z'/%3E%3C/svg%3E");
      background-repeat: no-repeat;
      background-size: contain;
      transform: translateY(-50%);

      @include mq(md) {
        top: 3rem;
        width: 1.6rem;
        height: 1.6rem;
        margin-right: 4rem - 1.6rem;
      }
    }
  }

  p {
    font-size: 3rem;
    line-height: (60/30);
    margin: 6rem 0 0;
    @include mq(md, min) {
      font-size: 1.6rem;
      line-height: (36/16);
      margin: 3.6rem 0 0;
    }
    &:first-child {
      margin-top: 0;
    }
  }

  b {
    font-weight: bold;
  }

  span {
    color: color(red);
  }

  a {
    color: color(gray-600);
    margin: 0 1.5rem 0 0;
    transition-property: color, text-decoration-color;
    transition-duration: 400ms;
    transition-timing-function: easing(easeOutCubic);
    text-decoration: underline;
    text-underline-offset: 0.4rem;

    &.-arrowRight {
      i {
        width: 2.4rem;
        height: 1.7rem;
        font-size: 0;
        margin: 0 0 0 1.8rem;
        @include mq(md, min) {
          width: 1.6rem;
          height: 1.1rem;
          margin: 0 0 0 1rem;
        }
      }
      svg {
        width: 2.4rem;
        height: 1.7rem;
        vertical-align: baseline;
        @include mq(md, min) {
          width: 1.6rem;
          height: 1.1rem;
        }
      }
    }
    &:hover {
      color: color(green-600);
      text-decoration-color: rgba(color(gray-600), 0);
    }
  }
}
