@use '../../../foundation/config' as *;

.p-irDisclosure__section {
  margin: 13rem 0 22rem;
  @include mq(md, min) {
    max-width: 1050px;
    margin: 0 auto 15rem;
    padding: 0 0 0 6.1rem;
  }
}

.p-irDisclosure__text {
  &:first-child {
    margin-top: 0;
  }

  & > p {
    font-size: 3rem;
    line-height: (60/30);
    text-align: justify;
    margin: 1em 0 0;
    @include mq(md, min) {
      font-size: 1.6rem;
      line-height: (36/16);
    }

    &:first-child {
      margin-top: 0;
    }

    &.-alignRight {
      text-align: right;
    }

    & > small {
      font-size: 2.4rem;
      line-height: (40/24);
      @include mq(md, min) {
        font-size: 1.4rem;
        line-height: (26/14);
      }
    }
  }
}

.p-irDisclosure__title {
  margin: 17.5rem 0 5rem;
  @include mq(md, min) {
    margin: 18.2rem 0 6.4rem;
  }

  &:nth-of-type(1) {
    margin: 17.3rem 0 5rem;
    @include mq(md, min) {
      margin: 0 0 6.4rem;
    }
  }
}

.p-irDisclosure__signatureSection {
  display: flex;
  margin-top: 2.6rem;
  @include mq(md) {
    display: block;
  }
}

.p-irDisclosure__position {
  font-size: 1.6rem;
  font-weight: 700;
  line-height: 2.2;
  margin-top: 0.5rem;
  margin-right: 2.8rem;
  @include mq(md) {
    font-size: 3rem;
    line-height: 2;
    margin-top: 0.7rem;
  }
}

.p-irDisclosure__signature {
  width: 27rem;
  img {
    width: 100%;
    height: auto;
  }
  @include mq(md) {
    width: 36rem;
    margin-top: 2rem;
  }
}

.p-irDisclosure__table {
  margin: 7.5rem 0 0;
  @include mq(md) {
    margin: 6.9rem 0 0;
  }
  + .p-irDisclosure__text {
    margin: 7.5rem 0 0;
    @include mq(md) {
      margin: 6.9rem 0 0;
    }
  }
}

.p-irGrowth__table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  th {
    background: color(gray-200);
    line-height: 1.54;
    font-size: 1.6rem;
    border-top: 1px solid color(gray-300);
    border-bottom: 1px solid color(gray-300);
    font-weight: bold;
    min-width: 29rem;
    max-width: 29rem;
    padding: 0 3rem;
    text-align: left;
    @include mq(md) {
      font-size: 2.6rem;
      line-height: 2.25;
      padding: 0 2.1rem;
      min-width: 44rem;
      max-width: 44rem;
    }
  }
  td {
    padding: 1.7rem 0 1.9rem 1.9rem;
    font-size: 1.6rem;
    line-height: 2.25;
    border-left: 1px solid color(gray-300);
    width: 100%;
    border-top: 1px solid color(gray-300);
    border-bottom: 1px solid color(gray-300);
    @include mq(md) {
      padding: 2.3rem 0 2.4rem 3rem;
      font-size: 2.6rem;
      line-height: 1.5384615385;
    }
  }
}
