@use '../../../foundation/config' as *;

.p-topHero {
  position: relative;
  width: 100%;
  height: auto;
  overflow: hidden;

  @include mq(md, min) {
    height: auto !important;
  }

  @include mq(md) {
    // height: calc(100vh - 15rem) !important;
    height: auto !important;
  }

  // PCのみアスペクト比を固定する
  &::before {
    content: '';
    display: none;
    @include mq(md) {
      display: none;
    }
  }
}

.p-topHero__yt,
.p-topHero__image {

  @include mq(md, min) {
    // position: absolute;
    // top: 50%;
    // left: 50%;
    // transform: translate(-50%, -50%);
    // height: 100%;
    // overflow: hidden;
    // width: 100%;
    // pointer-events: none;
  }
  overflow: hidden;
  width: 100% !important;
}

.p-topHero__container {
  position: relative;
  width: 100%;
  height: 100%;
  top: 0;

  a {
    display: block;
    @include mq(md) {
      pointer-events: none;
    }
  }

  img,
  iframe {

    max-width: none;
    @include mq(md, min) {
      // position: absolute;
      // top: 0;
      // left: 0;
      // width: 100%;
      // height: 100%;
    }
  }
  img {
    width: 100%;
    height: auto;
    @include mq(md, min) {
      // object-fit: cover;
    }
    @include mq(md) {
      // object-fit: contain;
    }
  }
}

.p-topHero__title {
  position: absolute;
  top: 40%;
  left: 15%;
  width: 17%;

  @include mq(md) {
    top: 28%;
    left: 9%;
    width: 47%;
  }
}

.p-topHero__scroller {
  position: absolute;
  left: 5rem;
  bottom: 4rem;
  width: 0.1rem;
  height: 10rem;
  > div {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
    &::before,
    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      display: block;
      width: 100%;
      height: 100%;
    }

    @keyframes scroll {
      0% {
        transform: translate3d(0, -100%, 0);
      }
      15% {
        transform: translate3d(0, -98%, 0);
      }
      85% {
        transform: translate3d(0, 98%, 0);
      }
      100% {
        transform: translate3d(0, 100%, 0);
      }
    }

    &::before {
      background: #fff;
      animation: scroll 2.4s infinite normal;
    }

    &::after {
      background: rgba(#fff, 0.5);
    }
  }
}

.p-topBanner {
  position: fixed;
  right: 10px;
  bottom: 10px;
  display: block;
  z-index: z(banner);
  pointer-events: none;
  opacity: 0;
  transition: opacity 0.4s;
  background-color: #fff;
  width: 320px;
  @include mq(md) {
    width: calc(100% - 20px);
    // z-index: z(content);
  }
  @include mq(md, min) {
    &:hover {
      .p-topBanner__inner {
        opacity: 0.8;
      }
    }
  }
  .p-topBanner__inner {
    transition: opacity 0.4s;
  }
  &.is-show {
    opacity: 1;
    pointer-events: all;
  }
  &.is-bottom {
    position: absolute;
    bottom: 10px;
  }
  img {
    width: 100%;
    height: auto;
  }
}

body[data-page="home"] {
  .l-footer {
    .l-footer__pageTop {
      display: none !important;
    }
  }
}