@use '../../../foundation/config' as *;

.p-irLibraryInvReportBacknumber {
  .l-irContent {
    @include mq(md) {
      margin-top: 15rem;
    }
  }
}

.p-irLibraryInvReportBacknumber__tabContent {
  margin-top: 9.6rem;
}

.p-irLibraryInvReportBacknumber__tabContentTitle {
  margin-bottom: 5rem;
}

.p-irLibraryInvReportBacknumber__tabContentFiscalYearItem {
  margin-top: 9rem;
  .p-irLibraryInvReportBacknumber__tabContentList {
    margin-top: 5rem;
  }
}

.p-irLibraryInvReportBacknumber__tabContentLinkGroup {
  margin-top: 9.5rem;
  @include mq(md) {
    margin-top: 9rem;
  }
  > h2 {
    font-size: 2.6rem;
    @include mq(md) {
      font-size: 4rem;
    }
  }
}

.p-irLibraryInvReportBacknumber__tabContentLinkGroup.c-linkGroup.-gutter {
  > ul {
    margin: 4.2rem -5rem 0;
    @include mq(md) {
      margin: 4.2rem 0 0;
    }
  }
}

/**
 * 画像つきリンク
 */
.p-irLibraryInvReportBacknumber__tabContentImageItem + .p-irLibraryInvReportBacknumber__tabContentImageItem {
  margin-top: 3.2rem;
  @include mq(md) {
    margin-top: 6rem;
  }
}

.p-irLibraryInvReportBacknumber__tabContentImageItem a {
  display: inline-flex;
  max-width: 100%;
  font-weight: 700;
  text-align: left;
  border: 0;
  background-color: color(gray-200);
  transition: color 0.4s cubic-bezier(0.215, 0.61, 0.355, 1);
  justify-content: flex-start;
  width: 105rem;
  padding: 3rem;
  font-size: 2rem;
  line-height: 1.6;
  @include mq(md) {
    width: 100%;
    padding: 4rem;
    font-size: 3.4rem;
    line-height: 1.59;
  }
  &:hover {
    color: color(green-600);
    .p-irLibraryInvReportBacknumber__tabContentImageItem__separator {
      color: color(green-600);
    }
  }
}

.p-irLibraryInvReportBacknumber__tabContentImageItem__item {
  display: flex;
  flex-grow: 1;
  align-items: center;
  @include mq(md) {
    flex-flow: column;
    align-items: normal;
  }
}

.p-irLibraryInvReportBacknumber__tabContentImageItem__itemInner {
  display: inline-block;
  flex-grow: 1;
  padding-right: 1rem;
  @include mq(md) {
    padding-right: 0;
  }
}

.p-irLibraryInvReportBacknumber__tabContentImageItem__image {
  flex-shrink: 0;
  margin-right: 3rem;
  @include mq(md) {
    width: 30%;
    display: flex;
    align-items: center;
    > img {
      width: 100%;
      height: auto;
    }
  }
}

.p-irLibraryInvReportBacknumber__tabContentImageItem__text {
  display: inline;
  vertical-align: middle;
  margin-right: 2rem;
}

.p-irLibraryInvReportBacknumber__tabContentImageItem__pdf {
  display: inline-block;
  vertical-align: middle;
  padding: 0.45rem 2.807rem;
  border-radius: 12.5rem;
  border: 1px solid;
  color: color(gray-600);
  font-family: Barlow, sans-serif;
  font-weight: 600;
  font-size: 1.4rem;
  line-height: 1;
  @include mq(md) {
    padding: 0.1rem 2.652rem;
    font-size: 2.4rem;
    line-height: 1.2;
    position: relative;
    bottom: -0.2rem;
  }
}

.p-irLibraryInvReportBacknumber__tabContentImageItem__separator {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  width: 6rem;
  height: 6rem;
  transition: color 0.4s cubic-bezier(0.215, 0.61, 0.355, 1);
  margin-top: auto;
  border: 1px solid;
  color: color(gray-450);
  @include mq(md) {
    width: 8rem;
    height: 8rem;
    margin-top: 1.6rem;
    margin-left: auto;
  }
}

.p-irLibraryInvReportBacknumber__tabContentImageItem__arrow {
  position: relative;
  flex-shrink: 0;
  width: 1.6rem;
  height: 1.1rem;
  transition: color 0.4s cubic-bezier(0.215, 0.61, 0.355, 1);
  @include mq(md) {
    width: 2.4rem;
    height: 1.7rem;
  }
}

/**
 * テキストのみ リンク
 */
.p-irLibraryInvReportBacknumber__tabContentItem {
  margin-top: 3.2rem;
  @include mq(md) {
    margin-top: 6rem;
  }
}

.p-irLibraryInvReportBacknumber__tabContentItem a {
  display: inline-flex;
  align-items: center;
  max-width: 100%;
  font-weight: bold;
  text-align: left;
  justify-content: space-between;
  width: 105rem;
  padding: 3rem 3rem 3rem 4rem;
  border: 0;
  background-color: color(gray-200);
  transition: color 400ms cubic-bezier(0.215, 0.61, 0.355, 1);
  font-size: 2rem;
  color: color(brack);
  line-height: 1.6;
  &:hover {
    color: color(green-600);
    .p-irLibraryInvReportBacknumber__tabContentItem__separator {
      color: color(green-600);
    }
  }
  @include mq(md) {
    font-size: 3.4rem;
    line-height: 1.59;
    width: 100%;
    padding: 4.468rem 4rem;
  }
  > div {
    display: inline-block;
    padding-right: 2.3rem;
    @include mq(md) {
      padding-right: 0;
    }
  }
}

.p-irLibraryInvReportBacknumber__tabContentItem__text {
  display: inline;
  vertical-align: middle;
  margin-right: 2rem;
}

.p-irLibraryInvReportBacknumber__tabContentItem__pdf {
  display: inline-block;
  vertical-align: middle;
  padding: 0.45rem 2.807rem;
  border-radius: 12.5rem;
  border: 1px solid;
  color: color(gray-600);
  font-family: 'Barlow', sans-serif;
  font-weight: 600;
  font-size: 1.4rem;
  line-height: 1.15;
  @include mq(md) {
    padding: 0.1rem 2.652rem;
    font-size: 2.4rem;
    line-height: 1.2;
    text-indent: 0;
    position: relative;
    bottom: -0.2rem;
  }
}

.p-irLibraryInvReportBacknumber__tabContentItem__separator {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  width: 6rem;
  height: 6rem;
  transition: color 400ms cubic-bezier(0.215, 0.61, 0.355, 1);
  border: 1px solid;
  color: color(gray-450);
  @include mq(md) {
    width: 8rem;
    height: 8rem;
    margin-left: 5.6rem;
  }
}

.p-irLibraryInvReportBacknumber__tabContentItem__arrow {
  position: relative;
  flex-shrink: 0;
  width: 1.6rem;
  height: 1.1rem;
  transition: color 400ms cubic-bezier(0.215, 0.61, 0.355, 1);
  @include mq(md) {
    width: 2.4rem;
    height: 1.7rem;
  }
}

.p-irLibraryInvReportBacknumber__tabContentItem
  a:not(:hover)
  .p-irLibraryInvReportBacknumber__tabContentItem__arrow {
  color: color(gray-450);
}
