@use "../../foundation/config" as *;

.p-footer {
  background-color: color(gray-200);
}

.p-footer__logoLink {
  display: inline-block;
}

.p-footer__logo {
  width: 18.7rem;
  height: 4.5rem;

  @include mq(md) {
    width: 26.4rem;
    height: 6.4rem;
  }
}

.p-footer__primaryNavList {
  @include mq(md, min) {
    display: grid;
    grid-template-columns: repeat(2, 50%);
    grid-template-rows: repeat(4, auto);
    grid-auto-flow: column;
  }

  @include mq(md) {
    border-top: 1px solid color(gray-300);
  }
}

.p-footer__primaryNavItem {
  font-size: 1.6rem;
  font-weight: bold;

  @include mq(md) {
    font-size: 3.4rem;
    border-bottom: 1px solid color(gray-300);
  }
}

.p-footer__primaryNavLink {
  display: inline-block;
  padding-top: 0.68em;
  padding-bottom: 0.68em;
  transition: color 400ms easing(easeOutCubic);

  @include mq(md) {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.994em 4rem;
  }

  &:hover {
    color: color(green-600);
  }
}

.p-footer__primaryNavIcon {
  width: 2.4rem;
  height: 1.7rem;
}

.p-footer__secondaryNavList {
  @include mq(md, min) {
    margin-top: 0.6rem;
  }

  @include mq(md) {
    display: flex;
    flex-wrap: wrap;
  }
}

.p-footer__secondaryNavItem {
  font-size: 1.4rem;

  @include mq(md) {
    flex: 1 1 50%;
    padding-left: 1rem;
    font-size: 2.2rem;
  }
}

.p-footer__secondaryNavLink {
  display: inline-block;
  padding-top: 0.7em;
  padding-bottom: 0.7em;
  transition: color 400ms easing(easeOutCubic);

  @include mq(md) {
    padding: 0.62em 3rem;
  }

  &:hover {
    color: color(green-600);
  }
}

.p-footer__externalNavList {
  display: flex;
  border-top: 1px solid color(gray-300);
  border-bottom: 1px solid color(gray-300);

  @include mq(md) {
    flex-wrap: wrap;
  }
}

.p-footer__externalNavItem {
  flex: 1;
  font-size: 1.6rem;
  font-weight: bold;

  @include mq(md, min) {
    & + & {
      border-left: 1px solid color(gray-300);
    }
  }

  @include mq(md) {
    font-size: 2.6rem;
    flex: 0 0 50%;
    border-bottom: 1px solid color(gray-300);

    &:last-of-type {
      border-bottom: none;
    }
    &:nth-of-type(odd) {
      border-right: 1px solid color(gray-300);
      
      &:nth-last-of-type(2) {
        border-bottom: none;
      }
    }
  }
}

.p-footer__externalNavLink {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 4.6rem 3rem;
  transition: color 400ms easing(easeOutCubic);

  @include mq(md) {
    padding: 5.6rem 4rem;
  }

  &:hover {
    color: color(green-600);
  }
}

.p-footer__externalIcon {
  width: 1.5rem;
  height: 1.5rem;

  @include mq(md) {
    width: 2.3rem;
    height: 2.3rem;
  }
}

.p-footer__copyright {
  color: color(gray-400);
  @include font-barlow;
  font-size: 1rem;
  letter-spacing: 0.05em;

  @include mq(md) {
    font-size: 2rem;
    text-align: center;
  }
}
