@use '../../../foundation/config' as *;

.p-companyTopmessage__mv {
  position: relative;
  margin: 0 0 6.4rem;

  @include mq(md) {
    margin: 0 -4rem 6.4rem;

    .-has-gradation {
      position: relative;
      display: block;
      max-width: 100%;
      width: 100%;
      height: 100%;
  
      &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-image: linear-gradient(180deg, transparent 0 80%, #FFF 100%);
      }
    }
  }
  img {
    width: 100%;
    height: auto;
  }

  &-textBlock {
    @include mq(md) {
      padding: 4rem;
      background-color: #ffffff;
    }
  }

  &-message {
    @include font-serif;
    line-height: 1.56;

    @include mq(md) {
      font-size: 5.4rem;
      line-height: 1.4;
    }

    @include mq(md, min) {
      position: absolute;
      top: 12rem;
      right: 4rem;
    }

    @media (min-width: 751px) and (max-width: 2600px) {
      font-size: 3.9rem;
    }

    @media (min-width: 2601px) {
      font-size: 3.6rem;
      right: 2.8rem;
    }
  }

  &-sign {
    @include mq(md, min) {
      display: none;
    }

    @include mq(md) {
      font-size: 3rem;
      font-weight: bold;
      line-height: 1.8;
      text-align: right;
    }

    img {
      height: auto;

      @include mq(md) {
        display: block;
        width: 36rem;
        margin-top: 1.5rem;
        margin-left: auto;
      }
    }
  }
}
