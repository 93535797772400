@use '../../../foundation/config' as *;

.p-sustainabilityTop {
  padding-bottom: 25rem;

  .p-irTop__imgLinkText {
    left: inherit;
    top: 7rem;
    font-size: 2.2rem;
    line-height: 1.8;
    color: #000;
    @media (min-width: 751px) and (max-width: 1920px) {
      width: 63rem;
      right: 2.2rem;
    }
    @media (min-width: 1921px) {
      right: 3.5rem;
    }
    @media (min-width: 2601px) {
      width: 60rem;
      top: 6rem;
      right: 2rem;
      font-size: 2.1rem;
    }
  }
  .p-irTop__imgLinkSeparator {
    color: #000;
  }
  .p-irTop__imgLinkButton:hover .p-irTop__imgLinkSeparator,
  .p-irTop__imgLinkButton:hover .p-irTop__imgLinkText {
    color: inherit;
  }
  .p-materiality {
    display: none;
  }
  @include mq(md) {
    .p-irTop__imgLinkText {
      display: none;
    }
    .p-materiality {
      display: block;
      margin: 4rem 0 0;
      font-size: 3.5rem;
      font-weight: 700;
      line-height: 1.5;
    }
    .p-sustainabilityTop__section {
      margin-top: 15rem;
    }
  }
}

.p-sustainabilityTop__inner {
  padding-top: 10rem;
}

.p-sustainabilityTop__section {
  margin-top: 10rem;

  @include mq(md) {
    margin-top: 19.8rem;
  }

  &.-flex {
    @include mq(md, min) {
      display: flex;
    }
  }
}

.p-sustainabilityTop__sectionHeading {
  font-size: 3.4rem;
  @include lhCrop(44 / 34);

  @include mq(md) {
    font-size: 6rem;
    @include lhCrop(80 / 60);
  }
}

.p-sustainabilityTop__article {
  @include mq(md, min) {
    flex: 0 0 6 / 8 * 100%;
    margin-left: auto;
  }

  @include mq(md) {
    margin-top: 8rem;
  }
}

.p-sustainabilityTop__text {
  font-size: 1.6rem;
  line-height: (36 / 16);

  @include mq(md) {
    font-size: 3rem;
    line-height: 2;
  }

  .c-heading.-level4 + & {
    margin-top: 1.8rem;

    @include mq(md) {
      margin-top: 2.4rem;
    }
  }
}

.p-sustainabilityTop__button {
  margin-top: 3.8rem;

  @include mq(md) {
    margin-top: 3.2rem;
  }
}

.p-sustainabilityTop__messageBody {
  margin-top: 0;
}

.p-sustainabilityTop__messageText {
  @include mq(md, min) {
    margin-top: -1.2rem;
    padding-right: 9rem;
  }

  @include mq(md) {
    margin-top: 6.4rem;
  }
}

.p-sustainabilityTop__message__body {
  display: flex;

  @include mq(md) {
    flex-direction: column-reverse;
  }
}

.p-sustainabilityTop__message__text {
  @include mq(md, min) {
    width: 50%;
    padding: 0 9.2rem 0 0;
  }

  @include mq(md) {
    width: 100%;
  }
}

.p-sustainabilityTop__message__description {
  @include mq(md, min) {
    font-size: 1.6rem;
    line-height: (36/16);
    margin: 2rem 0 0;
  }

  @include mq(md) {
    font-size: 3rem;
    line-height: (30/15);
    margin: 1.4rem 0 0;
  }

  p + p {
    margin-top: 4rem;
  }
}

.p-sustainabilityTop__message__position {
  font-weight: bold;
}

.p-sustainabilityTop__message__image {
  @include mq(md, min) {
    width: 50%;
    text-align: right;
    margin: 1.2rem 0 0;
  }

  @include mq(md) {
    width: 100%;
    margin: 0 0 6.4rem;
  }

  img {
    width: 100%;
    height: auto;
  }
}


.p-sustainabilityTop__csrPolicyBody {
  margin-top: 6.3rem;
}

.p-sustainabilityTop__keyIssuesBody {
  margin-top: 6.8rem;
  margin-bottom: -2.2rem;

  @include mq(md) {
    margin-bottom: -4rem;
  }
}

.p-sustainabilityTop__keyIssuesList {
  margin-top: 8rem;

  @include mq(md, min) {
    display: flex;
  }

  @include mq(md) {
    width: 60rem;
    margin-right: auto;
    margin-left: auto;
  }
}

.p-sustainabilityTop__keyIssuesItem {
  display: flex;
  position: relative;
  flex-direction: column-reverse;
  flex: 1;
  font-size: 2rem;
  font-weight: bold;

  @include mq(md) {
    font-size: 3.4rem;
    line-height: (58 / 34);
  }

  &:nth-child(n + 2) {
    @include mq(md, min) {
      margin-left: 7rem;
    }

    @include mq(md) {
      margin-top: 4.8rem;
    }
  }
}

.p-sustainabilityTop__keyIssuesLink {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1.2rem;
  padding-top: 0.6em;
  padding-bottom: 0.6em;
  transition: color 400ms easing(easeOutCubic);

  &:hover {
    color: color(green-600);
  }

  &::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
}

.p-sustainabilityTop__keyIssueArrow {
  width: 1.6rem;
  height: 1.1rem;

  @include mq(md) {
    width: 2.4rem;
    height: 1.7rem;
  }
}

.p-sustainabilityTop__keyIssuesMedia {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 28.4rem;
  background-color: color(gray-50);

  @include mq(md) {
    height: 39rem;
  }
}

.p-sustainabilityTop__keyIssuesImage01 {
  width: 17.3rem;
  height: 12.8rem;

  @include mq(md) {
    width: 25.6rem;
    height: 19.1rem;
  }
}

.p-sustainabilityTop__keyIssuesImage02 {
  width: 21.9rem;
  height: 61.3rem;

  @include mq(md) {
    width: 32.9rem;
    height: 17.1rem;
  }
}

.p-sustainabilityTop__keyIssuesImage03 {
  width: 15.3rem;
  height: 13.7rem;

  @include mq(md) {
    width: 23.4rem;
    height: 20.7rem;
  }
}

.p-sustainabilityTop__cultureSection {
  .p-sustainabilityTop__sectionHeading + & {
    margin-top: 7.4rem;
  }
  & + & {
    margin-top: 9.4rem;
  }
  &.-cl3 {
    .p-sustainabilityTop__cultureItem {
      flex: 0 0 31%;
    }
    .p-sustainabilityTop__cultureText {
      font-size: 1.8rem;
    }
    @include mq(md, min) {
      .p-sustainabilityTop__cultureSeparator {
        width: 5rem;
        height: 5rem;
      }
      .p-sustainabilityTop__cultureText {
        bottom: 1.6rem;
        left: 1.6rem;
      }
    }
    @include mq(md) {
      .p-sustainabilityTop__cultureText {
        font-size: 3.2rem;
      }
    }
  }
}

.p-sustainabilityTop__cultureList {
  margin-top: 5rem;

  @include mq(md, min) {
    display: flex;
    justify-content: space-between;
  }
}

.p-sustainabilityTop__cultureItem {
  position: relative;
  flex: 0 0 48%;
  background-color: gray;
  font-size: 2.6rem;
  font-weight: bold;
  overflow: hidden;

  @include mq(md) {
    font-size: 4rem;
    & + .p-sustainabilityTop__cultureItem {
      margin-top: 5rem;
    }
  }
}

.p-sustainabilityTop__cultureLink {
  display: block;
  color: color(white);

  &::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transition: background-color 400ms easing(easeOutCubic);
    z-index: z(content);
  }

  &:hover {
    &::before {
      background-color: color(green-300);
    }
  }
}

.p-sustainabilityTop__cultureImage {
  width: 100%;
  height: auto;
  transition: transform 400ms easing(easeOutCubic);
  backface-visibility: hidden;

  .p-sustainabilityTop__cultureLink:hover & {
    transform: scale(1.04);
  }
}

.p-sustainabilityTop__cultureText {
  position: absolute;
  bottom: 3.2rem;
  left: 3.6rem;
  z-index: z(content);

  @include mq(md, min) {
    font-size: 2.6rem;
  }
  
  @include mq(md) {
    bottom: 3rem;
    left: 3rem;
    font-size: 4rem;
  }
}

.p-sustainabilityTop__linkGroup {
  margin-top: 8rem;
  @include mq(md, min) {
    .c-linkGroup__item {
      a {
        font-size: 1.9rem;
      }
    }
  }
}

.p-sustainabilityTop__cultureSeparator {
  display: flex;
  position: absolute;
  justify-content: center;
  align-items: center;
  right: 2rem;
  bottom: 2rem;
  width: 6rem;
  height: 6rem;
  border: 1px solid;
  color: rgba(color(white), 0.5);
  transition: box-shadow 400ms easing(easeOutCubic);
  z-index: z(content);

  @include mq(md) {
    right: 4rem;
    bottom: 4rem;
    width: 8rem;
    height: 8rem;
  }

  .p-sustainabilityTop__cultureLink:hover & {
    box-shadow: 0 0 0 1px;
  }
}

.p-sustainabilityTop__cultureArrow {
  width: 1.6rem;
  height: 1.1rem;
  color: color(white);

  @include mq(md) {
    width: 2.4rem;
    height: 1.7rem;
  }
}

.p-sustainabilityTop__cultureExternal {
  width: 1.4rem;
  height: 1.4rem;
  color: color(white);

  @include mq(md) {
    width: 2.1rem;
    height: 2.1rem;
  }
}
