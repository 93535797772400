@use '../../../foundation/config' as *;

.p-irGrowthPerformanceHighlight__image {
  margin-top: 8rem;
}

.p-irGrowthPerformanceHighlight__section {
  .p-irGrowthPerformanceHighlight__section + & {
    margin-top: 19rem;
  }
}

.p-irGrowthPerformanceHighlight__table1 {
  @include mq(md, min) {
    margin-top: 4rem;
  }
}

@include mq(md, min) {
  .p-irGrowthPerformanceHighlight__images {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .p-irGrowthPerformanceHighlight__image {
    width: 47%;
  }
}

@include mq(md) {
  .c-table__spSize.-irHighlight {
    width: 150rem;
  }
}
