// テキストの先頭行と最終行の、line-heightでできてしまう余白をカットする
// $lineHeight: CSSのライン高

// How to Use:
// @include lhCrop(1.5)

@mixin lhCrop($lineHeight) {
  // 先頭行
  &::before {
    content: '';
    display: block;
    height: 0;
    width: 0;
    margin-top: calc((1 - #{$lineHeight}) * 0.5em);
  }

  &::after {
    // 最終行
    content: '';
    display: block;
    height: 0;
    width: 0;
    margin-top: calc((1 - #{$lineHeight}) * 0.5em);
  }
}
