@use '../../foundation/config' as *;

.c-linkGroup {
  position: relative;

  &.-column4 > ul,
  &.-column3 > ul,
  &.-column2 > ul {
    @include mq(md, min) {
      display: flex;
      flex-wrap: wrap;
    }
  }

  &:not(.-gutter) ul {
    &::after {
      @include mq(md, min) {
        content: '';
        display: block;
        width: 100%;
        height: 1px;
        position: absolute;
        bottom: 0;
        right: 0;
        border-bottom: 1px solid color(gray-500);
      }
    }
  }

  // 2カラムでアイテムが1つの場合は右側の下線を消す
  &.-column2:not(.-gutter) {
    @include mq(md, min) {
      .c-linkGroup__item {
        &:first-child:last-child {
          position: relative;
          &::after {
            content: '';
            width: 100%;
            height: 1px;
            background-color: color(gray-100);
            position: absolute;
            bottom: 0;
            right: -100%;
            z-index: 1;
          }
        }
      }
    }
  }

  &.-gutter > ul {
    @include mq(md, min) {
      margin: 0 -5rem;
    }
  }
}
.c-linkGroup__item {
  margin: -1px 0 0;

  .c-linkGroup.-column2 & {
    @include mq(md, min) {
      min-width: 50%;
      max-width: 50%;
    }
  }
  .c-linkGroup.-column3 & {
    @include mq(md, min) {
      min-width: 33.333%;
      max-width: 33.333%;
    }
  }
  .c-linkGroup.-column4 & {
    @include mq(md, min) {
      min-width: 25%;
      max-width: 25%;
    }
  }
  .c-linkGroup.-gutter & {
    @include mq(md, min) {
      padding: 0 5rem;
    }
  }

  > span,
  > a {
    display: block;
    font-size: 3.4rem;
    line-height: (54/34);
    font-weight: bold;
    padding: 2.3rem 10rem 2.3rem 3.1rem;
    position: relative;
    border-top: 1px solid color(gray-500);
    border-bottom: 1px solid color(gray-500);
    min-height: 18.1rem;
    transition: color 600ms easing(easeOutCubic);
    overflow: hidden;

    @include mq(md, min) {
      font-size: 2rem;
      line-height: (40/20);
      padding: 1.8rem 6rem 1.8rem 3rem;
      min-height: 13.1rem;
    }

    &[href]:hover {
      color: color(green-600);
    }

    &::before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      width: 0;
      transition: width 800ms easing(easeOutCubic);
      background-color: color(green-200);
      border-bottom: 2px solid color(green-600);
      z-index: -1;
    }

    &[href]:hover::before {
      left: 0;
      width: 100%;
      transition: width 600ms easing(easeOutCubic);
    }

    .c-linkGroup.-column2 &,
    .c-linkGroup.-column3 &,
    .c-linkGroup.-column4 & {
      @include mq(md, min) {
        border-right: 1px solid color(gray-500);
      }
    }

    @at-root {
      .c-linkGroup.-column3.-gutter .c-linkGroup__item > a,
      .c-linkGroup.-column2 .c-linkGroup__item:nth-of-type(2n) > a,
      .c-linkGroup.-column3 .c-linkGroup__item:nth-of-type(3n) > a,
      .c-linkGroup.-column4 .c-linkGroup__item:nth-of-type(4n) > a {
        @include mq(md, min) {
          border-right: none;
        }
      }
      .c-linkGroup.-gutter .c-linkGroup__item > a {
        @include mq(md, min) {
          border-right: none;
        }
      }
      .c-linkGroup__item.-small > a {
        padding: 2.8rem 10rem 2.8rem 3.1rem;
        min-height: 12.2rem;
        @include mq(md, min) {
          font-size: 1.6rem;
          line-height: (36/16);
          padding: 1.6rem 6rem 1.6rem 3.1rem;
          min-height: 7.1rem;
        }
      }
    }
    i {
      display: block;
      position: absolute;
      bottom: 2rem;
      right: 2rem;
      @at-root {
        .c-linkGroup__item.-small i {
          display: flex;
          height: 100%;
          align-items: center;
          bottom: 0;
          right: 2.8rem;
          @include mq(md, min) {
            right: 3.1rem;
          }
        }
      }
    }
    svg {
      width: 2.4rem;
      height: 1.7rem;
      @at-root {
        .c-linkGroup__item.-external svg {
          width: 2.1rem;
          height: 2.1rem;
          @include mq(md, min) {
            width: 1.4rem;
            height: 1.4rem;
          }
        }
      }
      @include mq(md, min) {
        width: 1.6rem;
        height: 1.1rem;
      }
    }
  }
}

.c-imgLinkGroup {
  display: flex;
  gap: 4rem;
  margin-top: 4rem;
  @include mq(md) {
    display: block;
  }
}

.c-imgLinkGroup__item {
  width: 50%;
  font-size: 2.6rem;
  font-weight: bold;
  overflow: hidden;
  @include mq(md) {
    width: 100%;
    & + & {
      margin-top: 4rem;
    }
  }
}

.c-imgLinkGroup__link {
  position: relative;
  display: block;
  color: color(white);

  &::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transition: background-color 400ms easing(easeOutCubic);
    z-index: z(content);
  }

  &:hover {
    &::before {
      background-color: color(green-300);
    }
  }
}

.c-imgLinkGroup__image {
  width: 100%;
  height: auto;
  transition: transform 400ms easing(easeOutCubic);
  backface-visibility: hidden;

  .c-imgLinkGroup__link:hover & {
    transform: scale(1.04);
  }
}

.c-imgLinkGroup__text {
  position: absolute;
  left: 4rem;
  bottom: 3.2rem;
  font-size: 2.6rem;
  z-index: z(content);

  @include mq(md) {
    font-size: 4rem;
    left: 2.9rem;
    bottom: 5rem;
  }
}

.c-imgLinkGroup__button {
  display: flex;
  position: absolute;
  justify-content: center;
  align-items: center;
  right: 2rem;
  bottom: 2rem;
  width: 6rem;
  height: 6rem;
  border: 1px solid;
  transition: box-shadow 400ms easing(easeOutCubic);
  z-index: z(content);

  @include mq(md) {
    right: 4rem;
    bottom: 4rem;
    width: 8rem;
    height: 8rem;
  }

  .c-imgLinkGroup__link:hover & {
    box-shadow: 0 0 0 1px;
  }
}

.c-imgLinkGroup__icon {
  width: 1.6rem;
  height: 1.1rem;

  @include mq(md) {
    width: 2.5rem;
    height: 1.6rem;
    vertical-align: middle;
  }
}