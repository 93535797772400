@use '../../../foundation/config' as *;

.p-irGrowthPerformanceIndicators__image {
  margin-top: 8rem;
}

.p-irGrowthPerformanceIndicators__section {
  .p-irGrowthPerformanceIndicators__section + & {
    margin-top: 19rem;
  }
}

.p-irGrowthPerformanceIndicators__table1 {
  @include mq(md) {
    th[scope='col'][colspan='6'] {
      text-align: left;
    }
  }
  @include mq(md, min) {
    margin-top: 4rem;
  }
  .p-irGrowthPerformanceIndicators__images + & {
    margin-top: 10rem;
  }
  th[scope='row'] {
    width: 19%;
  }
}

@include mq(md, min) {
  .p-irGrowthPerformanceIndicators__images {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .p-irGrowthPerformanceIndicators__image {
    width: 47%;
  }
}

.p-irGrowthPerformanceIndicators__smallText {
  font-size: 2.4rem;
  line-height: 1.7;
  margin-top: 4rem;
  @include mq(md, min) {
    font-size: 1.4rem;
    margin-top: 2rem;
  }
}

@include mq(md) {
  .c-table__spSize.-irIndicators {
    width: 155rem;
  }
}
