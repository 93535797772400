@use '../../foundation/config' as *;

.c-box {
  padding: 4rem;
  border: 1px solid color(gray-300);

  @include mq(md) {
    padding: 3rem 4rem 5.6rem;
  }

  &.-bold {
    border-width: 3px;
  }
}

.c-box__heading {
  font-size: 2rem;
  font-weight: bold;
  line-height: 2;
  @include lhCrop(2);

  @include mq(md) {
    font-size: 3.4rem;
    line-height: (54 / 34);
    @include lhCrop(54 / 34);
  }

  .c-box__body + & {
    margin-top: 3.2rem;

    @include mq(md) {
      margin-top: 5.2rem;
    }
  }
}

.c-box__body {
  font-size: 1.6rem;
  @include lhCrop(36 / 16);

  @include mq(md) {
    font-size: 3rem;
    @include lhCrop(2);
  }

  &.-large {
    font-size: 2rem;
    line-height: 2;

    @include mq(md) {
      font-size: 3.4rem;
      line-height: (54 / 34);
    }
  }

  .c-box__heading + & {
    margin-top: 1.6rem;

    @include mq(md) {
      margin-top: 2.6rem;
    }
  }
}

.c-box__text {
  font-size: inherit;
  line-height: (36 / 16);

  @include mq(md) {
    line-height: 2;
  }
}

.c-box__textLink {
  color: color(gray-600);
  transition-property: color, text-decoration-color;
  transition-duration: 400ms;
  transition-timing-function: easing(easeOutCubic);
  text-decoration: underline;
  text-underline-offset: 0.4rem;

  &:hover {
    color: color(green-600);
    text-decoration-color: rgba(color(gray-600), 0);
  }

  > span {
    @include mq(md, min) {
      display: inline-flex;
      align-items: center;
    }
  }
}

.c-box__externalIcon {
  width: 1.4rem;
  height: 1.4rem;
  margin-left: 0.5em;
  vertical-align: baseline;

  @include mq(md) {
    width: 2.1rem;
    height: 2.1rem;
  }
}

.c-flexbox {
  @include mq(md, min) {
    display: flex;
    align-items: center;
    &.-half {
      > * {
        flex: 0 0 50%;
      }
    }
    &.-column2__feature {
      > * {
        flex: 0 0 40%;
      }
      > .-feature {
        flex: 0 0 60%;
      }
    }
    &.-reverse {
      flex-direction: row-reverse;
    }
  }
}