@use "../../../foundation/config" as *;

$nav-anim-duration: 0.1s;

.p-header {
  padding-left: 10rem;
  border-bottom: 1px solid color(gray-500);
  background-color: color(gray-100);

  @include mq(md, min) {
    display: flex;
  }

  @include mq(md) {
    padding-left: 4rem;
  }
}

.p-header__logoLink {
  display: block;
  padding-top: 4rem;
  padding-bottom: 4rem;

  @include mq(md) {
    padding-top: 4.8rem;
    padding-bottom: 4.6rem;
  }

  .p-header.-dense & {
    @include mq(md, min) {
      padding-top: 2.4rem;
      padding-bottom: 2.4rem;
    }
  }
}

.p-header__navigation {
  display: flex;
  flex-grow: 1;
}

.p-header__logo {
  width: 18.7rem;
  height: 4.5rem;
  transition-property: width, height;
  transition-duration: 200ms;
  transition-timing-function: easing(easeOutCubic);

  @include mq(md) {
    width: 22.4rem;
    height: 5.4rem;
  }

  .p-header.-dense & {
    @include mq(md, min) {
      width: 15.1rem;
      height: 3.6rem;
    }
  }
}

.p-header__navGroup {
  margin-left: auto;

  @include mq(md, min) {
    display: flex;
    margin-right: 1.2rem;
    padding-bottom: 2rem;
    transition: padding-bottom 200ms easing(easeOutCubic);
  }

  .p-header.-dense & {
    padding-bottom: 0;
  }
}

// SPハンバーガーメニュー（閉じているとき）
.p-header__menuButton {
  width: 15rem;
  height: 100%;
  border-left: 1px solid color(gray-500);
  position: relative;

  .p-header__menuButton__hamburger {
    display: inline-flex;
    width: 4.4rem;
    height: 3.4rem;
    position: relative;

    // 一番上のライン
    &::before {
      content: '';
      width: 100%;
      height: 0.5rem;
      background: color(black);
      position: absolute;
      top: 0;
      left: 0;
      transition: transform 0.15s ease;
    }

    // 一番下のライン
    &::after {
      content: '';
      width: 100%;
      height: 0.5rem;
      background: color(black);
      position: absolute;
      bottom: 0;
      left: 0;
      transition: transform 0.15s ease;
    }
  }

  // 真ん中のライン
  .p-header__menuButton__label {
    position: absolute;
    width: 100%;
    height: 0.5rem;
    background: color(black);
    top: calc(50% - 0.25rem);
    left: 0;
    transition: transform, opacity 0.15s ease;
  }
}

// SPハンバーガーメニュー（開いているとき）
.p-header__menuButton[aria-expanded='true'] {
  .p-header__menuButton__hamburger {
    // 一番上のライン
    &::before {
      content: '';
      width: 100%;
      height: 0.5rem;
      background: color(black);
      position: absolute;
      top: 50%;
      left: 0;
      transform: translate3d(0, -50%, 0) rotate(45deg);
    }

    // 一番下のライン
    &::after {
      content: '';
      width: 100%;
      height: 0.5rem;
      background: color(black);
      position: absolute;
      top: 50%;
      left: 0;
      transform: translate3d(0, -50%, 0) rotate(135deg);
    }

    // 真ん中のライン
    .p-header__menuButton__label {
      position: absolute;
      width: 100%;
      height: 0.5rem;
      background: color(black);
      opacity: 0;
      top: calc(50% - 0.25rem);
      left: 0;
      transform: rotate(-45deg) translate3d(-0.285em, -0.3em, 0);
    }
  }
}

.p-header__navGroupContent {
  @include mq(md, min) {
    display: flex;
  }

  @include mq(md) {
    position: fixed;
    top: 15rem;
    right: 0;
    left: 0;
    height: 0;
    background-color: color(gray-50);
    overflow: auto;
    transition: height 500ms easing(easeOutCubic);

    // 表示状態
    &.-open {
      height: calc(100% - 15rem);
    }
  }
}

.p-header__navGroupContentInner {
  @include mq(md, min) {
    display: flex;
    flex-direction: column-reverse;
  }

  @include mq(md) {
    padding-bottom: 10rem;
  }

  .p-header.-dense & {
    @include mq(md, min) {
      flex-direction: row;
    }
  }
}

.p-header__primaryNavList {
  @include mq(md, min) {
    display: flex;
  }

  .p-header.-dense & {
    align-items: center;
  }
}

.p-header__primaryNavButton,
.p-header__primaryNavLink {
  display: inline-flex;
  position: relative;
  align-items: center;
  padding: 0.5em 1.18em;
  font-size: 1.8rem;
  font-weight: bold;
  line-height: (40 / 18);
  transition-property: font-size, color;
  transition-duration: 200ms, 400ms;
  transition-timing-function: easing(easeOutCubic);

  @include mq(md, min) {
    &::before {
      content: '';
      display: block;
      position: absolute;
      right: 0;
      bottom: -2.5rem;
      left: 0;
      height: 2.5rem;
    }
  }

  @include mq(md) {
    width: 100%;
    padding: 1.1456em 4rem;
    border-bottom: 1px solid color(gray-300);
    font-size: 4rem;
  }

  &:hover,
  &[aria-expanded='true'] {
    @include mq(md, min) {
      color: color(green-600);
    }
  }

  &[aria-current='true'] {
    &::after {
      @include mq(md, min) {
        content: '';
        display: block;
        position: absolute;
        bottom: 0;
        left: 50%;
        width: 0.6rem;
        height: 0.6rem;
        background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 6 6' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='6' height='6' fill='%23131314'/%3E%3C/svg%3E%0A");
        background-repeat: no-repeat;
        background-size: contain;
        transform: translateX(-50%);
        transition: background-color 400ms easing(easeOutCubic);
      }
    }

    &:hover::after,
    &[aria-expanded='true']::after {
      background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 6 6' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='6' height='6' fill='%2300a040'/%3E%3C/svg%3E%0A");
    }
  }

  .p-header.-dense & {
    @include mq(md, min) {
      font-size: 1.6rem;
    }
  }
}

.p-header__primaryNavMinus {
  width: 2.6rem;
  height: 0.4rem;
  margin-left: auto;

  @include mq(md, min) {
    display: none;
  }

  .p-header__primaryNavButton[aria-expanded='false'] &,
  .p-header__primaryNavButton:not([aria-expanded]) & {
    display: none;
  }
}

.p-header__primaryNavPlus {
  width: 2.6rem;
  height: 2.6rem;
  margin-left: auto;

  @include mq(md, min) {
    display: none;
  }

  .p-header__primaryNavButton[aria-expanded='true'] & {
    display: none;
  }
}

.p-header__primaryNavArrow {
  width: 2.4rem;
  height: 1.7rem;
  margin-left: auto;

  @include mq(md, min) {
    display: none;
  }
}

.p-header__secondaryNavButton {
  margin-right: 1.9rem;

  @include mq(md) {
    display: none;
  }

  &:hover {
    color: color(green-600);
  }

  .p-header:not(.-dense) & {
    display: none;
  }
}

.p-header__otherIcon {
  width: 3rem;
  height: 0.6rem;
}

.p-header__secondaryNavButtonWrapper {
  .p-header.-dense & {
    @include mq(md, min) {
      display: flex;
    }
  }
}

.p-header__secondaryNav {
  .p-header.-dense & {
    @include mq(md, min) {
      position: absolute;
      top: calc(100% + 1px);
      right: 10rem;
      width: 22rem;
      height: 0;
      overflow: hidden;
    }
  }

  .p-header.-dense .p-header__secondaryNavButton[aria-expanded='true'] + & {
    @include mq(md, min) {
      opacity: 1;
      pointer-events: auto;
    }
  }
}

.p-header__secondaryNavList {
  display: flex;
  justify-content: flex-end;

  @include mq(md, min) {
    margin-right: 1.1rem;
  }

  @include mq(md) {
    flex-wrap: wrap;
    border-bottom: 1px solid color(gray-300);
  }

  .p-header.-dense & {
    @include mq(md, min) {
      display: block;
      margin-right: 0;
      background-color: color(gray-250);
    }
  }
}

.p-header__secondaryNavItem {
  font-size: 1.6rem;
  font-weight: bold;
  text-transform: uppercase;

  @include mq(md) {
    flex: 0 0 50%;
    border-bottom: 1px solid color(gray-300);
    font-size: 2.6rem;

    &:last-of-type {
      border-bottom: none;
    }

    &:nth-of-type(odd) {
      border-right: 1px solid color(gray-300);
    }

    &:nth-last-of-type(2) {
      border-bottom: none;
    }
  }

  &:nth-child(n + 2) {
    .-dense & {
      @include mq(md, min) {
        border-top: 1px solid color(gray-400);
      }
    }
  }
}

.p-header__secondaryNavLink {
  display: inline-flex;
  align-items: center;
  padding: 0.6em 1em 0;
  transition: color 400ms easing(easeOutCubic);
  font-weight: normal;
  @include mq(md) {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 2.108em 4rem;
  }

  &:hover {
    color: color(green-600);
  }

  .p-header.-dense & {
    @include mq(md, min) {
      display: flex;
      justify-content: space-between;
      padding: 1.125em 2.2rem 1.125em 3rem;
    }
  }
}

.p-header__secondaryNavArrow {
  width: 1.6rem;
  height: 1.1rem;

  @include mq(md) {
    width: 2.3rem;
    height: 1.6rem;
  }

  .p-header:not(.-dense) & {
    @include mq(md, min) {
      display: none;
    }
  }
}

.p-header__externalIcon {
  width: 1.4rem;
  height: 1.4rem;
  margin-left: 0.7rem;

  @include mq(md) {
    width: 2.3rem;
    height: 2.3rem;
  }
}

.p-header__searchArea {
  flex-shrink: 0;
}

.p-header__searchButton {
  width: 10rem;
  height: 100%;
  border-left: 1px solid color(gray-500);

  @include mq(md) {
    position: absolute;
    top: 0;
    right: 0;
    width: 15rem;
    height: 14.8rem;
    transform: translateX(-100%);
  }
}

.p-header__searchIcon {
  width: 1.9rem;
  height: 1.9rem;

  @include mq(md) {
    width: 3.8rem;
    height: 3.8rem;
  }
}
