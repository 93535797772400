@use '../../../foundation/config' as *;

.p-irStockGift__section {
  & + & {
    margin-top: 20rem;

    @include mq(md) {
      margin-top: 19.8rem;
    }
  }
}

.p-irStockGift__sectionHeading {
  font-size: 3.4rem;
  @include lhCrop(44 / 34);

  @include mq(md) {
    font-size: 6rem;
    @include lhCrop(80 / 60);
  }
}

.p-irStockGift__text {
  font-size: 1.6rem;
  line-height: (36 / 16);
  @include lhCrop(36 / 16);

  @include mq(md) {
    font-size: 3rem;
    line-height: 2;
    @include lhCrop(2);
  }

  .p-irStockGift__sectionHeading + & {
    margin-top: 7.4rem;
  }

  .c-heading.-level3 + & {
    margin-top: 5rem;

    @include mq(md) {
      margin-top: 4.4rem;
    }
  }

  .p-irStockGift__smallText + & {
    margin-top: 3rem;

    @include mq(md) {
      margin-top: 2.4rem;
    }
  }
}

.p-irStockGift__smallSection {
  margin-top: 9rem;
  @include mq(md, min) {
    margin-top: 9.5rem;
  }
  .p-irStockGift__sectionHeading + & {
    margin-top: 6.4rem;
  }

  .p-irStockGift__text + & {
    margin-top: 9.6rem;
  }

  & + & {
    margin-top: 7.2rem;
    @include mq(md, min) {
      margin-top: 9.2rem;
    }
  }
}

.p-irStockGift__xsmallSection {
  margin-top: 3.4rem;
  @include mq(md, min) {
    .p-irStockGift__xsmallSection + & {
      margin-top: 7rem;
    }
  }
  .c-heading.-level5 {
    font-size: 4rem;
    margin-top: 8rem;
    @include mq(md, min) {
      margin-top: 7rem;
      font-size: 2.6rem;
    }
  }
}

.p-irStockGift__table1 {
  margin-top: 8rem;
}

.p-irStockGift__table1 th[scope='col'] {
  background-color: color(gray-240);
  border-right-color: color(gray-100);
}

.p-irStockGift__table1 th[scope='row'] {
  width: 33%;
}

.c-table.-style1.p-irStockGift__table1 td {
  text-align: left;
}

.p-irStockGift__image {
  width: 104.8rem;
  margin-top: 2rem;
  margin-right: auto;
  margin-left: auto;

  @include mq(md) {
    width: 100%;
    margin-top: 3rem;
  }

  img {
    width: 100%;
    height: auto;
  }
}

.p-irStockGift__smallText {
  font-size: 2.4rem;
  line-height: 1.7;
  margin-top: 4rem;
  @include mq(md, min) {
    font-size: 1.4rem;
    margin-top: 3rem;
  }
  .p-irStockGift__table1 + & {
    margin-top: 2rem;
  }
}

.p-irStockGift__arrowLink {
  align-items: center;
  color: color(gray-600);
  transition-property: color, text-decoration-color;
  transition-duration: 400ms;
  transition-timing-function: easing(easeOutCubic);
  text-decoration: underline;
  text-underline-offset: 0.4rem;

  &:hover {
    color: color(green-600);
    text-decoration-color: rgba(color(gray-600), 0);
  }

  svg {
    vertical-align: top;

    @include mq(md, min) {
      margin: 1.3rem 0 0 0.5rem;
      width: 1.6rem;
      height: 1.1rem;
    }

    @include mq(md) {
      width: 2.4rem;
      height: 1.7rem;
      margin: 2.3rem 0 0 0.5rem;
    }
  }
}
