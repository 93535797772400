@use '../../../foundation/config' as *;

.p-topInfo {
  margin-top: 10rem;

  @include mq(md, min) {
    display: flex;
  }
}

.p-topInfo__heading {
  flex-grow: 1;
}

.p-topInfo__headingText {
  position: relative;
  padding-bottom: 1.6rem;
  @include font-serif;
  font-size: 2.2rem;
  overflow: hidden;

  @include mq(md) {
    padding-bottom: 2.2rem;
    font-size: 4rem;
  }

  &::before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0;
    // アニメーションでワイドを広げる
    // width: 1.6rem;
    height: 0.3rem;
    background-color: color(green-600);
    transition: width 0.5s easing(easeOutCubic);

    @include mq(md) {
      width: 0;
      height: 0.6rem;
    }
  }

  // アニメーション実行時
  &.-active {
    &::before {
      width: 1.6rem;

      @include mq(md) {
        width: 4rem;
      }
    }
  }
}

.p-topInfo__body {
  @include mq(md, min) {
    flex: 0 0 percentage(6 /8);
    max-width: percentage(6 /8);
  }

  @include mq(md) {
    margin-top: 6rem;
  }
}
