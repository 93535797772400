@use '../../foundation/config' as *;

.c-section {
  @include mq(md, min) {
  }
  &.-bg__white {
    padding-top: 10rem;
    padding-bottom: 10rem;
    background-color: color(white);
    .c-section__inner {
      margin-top: 0;
      margin-bottom: 0;
    }
  }
}

.c-section__inner {
  max-width: 1920px;
  margin: 10rem auto;
  padding: 0 6.25%;
  @include mq(md) {
    padding: 0 4rem;
  }
}