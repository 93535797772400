@use '../../../foundation/config' as *;

/* 日付+カテゴリ汎用 */
//日付
.p-newsListItemHaveThumb__pubDate {
  font-family: Barlow,sans-serif;
  font-weight: 600;
  font-size: 1.4rem;
  letter-spacing: .05em;
  line-height: 1;
  @include mq(md){
    font-size: 2.4rem;
  }
}

//カテゴリ表示
.p-newsListItemHaveThumb__categoryIconList {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-left: 0.5rem;
}
.p-newsListItemHaveThumb__categoryIcon {
  background-color: color(green-600);
  margin: 0.3rem 0.5rem;
  @include mq(md) {
    text-align: center;
    font-size: 2.4rem;
  }
}
.p-newsListItemHaveThumb__categoryIconLink {
  display: block;
  padding: 0.2rem 0.5rem;
  color: color(white);
}
.p-newsListItemHaveThumb__categoryIcon__Plain{
  background-color: color(green-600);
  margin: 0.3rem 0.75rem;
  @include mq(md) {
    text-align: center;
    font-size: 2.4rem;
  }
  display: block;
  padding: 0.5rem 1rem;
  line-height: 2.2;
  @include lhCrop(2.2);
  color: color(white);
  @include mq(md){
    padding: 0.8rem 2rem;
  }
}


/* 汎用カード */
.p-newsListItemHaveThumb{
  position: relative;
  overflow: hidden;
  &::before{
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 0;
    transition: width .8s cubic-bezier(.215,.61,.355,1);
    background-color: #f2f2ee;
    border-bottom: 2px solid #00a040;
    z-index: -1!important;
  }
  &::after{
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
  &:hover{
    &::before{
      left: 0;
      width: 100%;
      transition: width .6s cubic-bezier(.215,.61,.355,1);
    }
    .p-excerptedArticle__link+.p-excerptedArticle__arrowIcon{
      color: color(green-600);
    }
  }

  .card-link {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  }
  .u-fontExtended{
    display: inline;
  }
  .p-newsListItemHaveThumb__categoryIconLink{
    position: relative;
    z-index: 1;
    border: 1px solid #00a040;
    transition: background-color .4s cubic-bezier(.215,.61,.355,1);
    &:hover{
      background-color: #ffff;
      color: #00a040;
      border: 1px solid #00a040;
    }
  }

  .p-newsListItemHaveThumb__visual{
      overflow: hidden;
      border: 1px solid #ddddd5;
      background-color: #ffff;
      img{
        width: 100%;
        height: 100%;
        object-fit: contain;
        border: 1px solid color(gray-300);

        transition: transform .4s cubic-bezier(.215,.61,.355,1);
        backface-visibility: hidden;
        border: none;
      }
  }
  &:hover{
    .p-newsListItemHaveThumb__visual img{
      transform: scale(1.04);
    }
  }
  .c-heading{
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    .u-fontExtended{
      overflow: hidden;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2!important;
    }
  }
  .p-newsListItemHaveThumb__subtitle{
    font-size: 1.6rem;
    margin-top: 1rem;

    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    @include mq(md) {
      margin-top: 2.5rem;
      font-size: 3rem;
      line-height: 1.6;
    }
  }
  .p-newsListItemHaveThumb__body{
    display: flex;
    flex-direction: column;
  }
  .p-newsListItemHaveThumb__arrowIcon {
    position: absolute;
    right: 2rem;
    bottom: 2rem;
    width: 1.1rem;
    height: 0.8rem;
    transition: color 400ms easing(easeOutCubic);

    @include mq(md) {
      width: 1.7rem;
      height: 1.2rem;
    }
  }

  &:hover{
    .p-newsListItemHaveThumb__arrowIcon {
      color: color(green-600);
    }
    .p-newsListItemHaveThumb__pdf {
      color: color(green-600);
    }
  }
}

.p-newsListItemHaveThumb__wrap {
  &__typeA{//typeA 例：ニュースルーム最新情報１件目
    .p-newsListItemHaveThumb {
      display: flex;
      flex-direction: column;
      padding-bottom: 1.8rem;
    }
    .p-newsListItemHaveThumb__body {
      @include mq(md,min) {
        padding: 0 1.4rem;
      }
    }
    .p-newsListItemHaveThumb__visual {
      img{
        @include mq(md,min) {
          width: (757 / 12) * 1rem;
          height: (400 / 12) * 1rem;
        }
        @include mq(md) {
          width: (675 / 9.09375) * 1rem;
          height: (356.562 / 9.09375) * 1rem;
        }
      }
    }
    .p-newsListItemHaveThumb__info {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      margin-top: 1.4rem;
      @include mq(md){
        margin: 2.5rem 0 2.5rem;
      }
    }
    .p-newsListItemHaveThumb__pubDate {
      display: flex;
      align-items: center;
      padding: 0.6rem 0;
      @include lhCrop(2);
      @include mq(md){
        padding: 1rem 0;
      }
    }
  }
  &__typeB{//typeB 例：ニュースルーム最新情報2-4件目
    .p-newsListItemHaveThumb {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      padding: 1.8rem 0;
      border-top: 1px solid color(gray-300);
      @include mq(md) {
        padding: 4rem 0;
      }
      &:first-child {
        padding-top: 0;
        border-top: none;
        @include mq(md) {
          border-top: 1px solid #ddddd5;
          padding-top: 4rem;
        }
      }
    }
    .p-newsListItemHaveThumb__visual {
      @include mq(md,min) {
        img{
          width: (378.5 / 12) * 1rem;
          height: (200 / 12) * 1rem;
        }
      }
      @include mq(md) {
        img{
          width: (268.891 / 9.09375) * 1rem;
          height: (142.031 / 9.09375) * 1rem;
        }
      }
    }
    .p-newsListItemHaveThumb__body {
      flex: 1;
      padding-left: 1.4rem;
      @include mq(md,min) {
        padding: 1rem 1rem 0 1.4rem;
      }
    }
    .p-newsListItemHaveThumb__info {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      margin-top: 0;
    }
    .p-newsListItemHaveThumb__categoryIcon__Plain{
      &:first-child{
        margin-left: 0;
      }
    }
    .p-newsListItemHaveThumb__categoryIconList {
      margin-top: 1rem;
      margin-left: 0;
    }
    .c-heading {
      margin-top: 1rem;
      font-size: 1.6rem;
      line-height: 1.5;
      @include mq(md) {
        font-size: 2.8rem;
        line-height: (54 / 34);
      }
    }
    .p-newsListItemHaveThumb__subtitle {
      font-size: 1.4rem;
      margin-top: 1rem;
      @include mq(md) {
        font-size: 2.4rem;
        line-height: 1.4;
      }
    }
  }
  &__typeC{//typeC 例：ニュースルームピックアップ
    .p-newsListItemHaveThumb {
      display: flex;
      flex-direction: column;
      flex: 0 0 32%;
      padding: 0 0 1.8rem 0;
      &:nth-child(n + 4) {
        margin-top: 2.4rem;
      }
      @include mq(md) {
        padding: 4rem 0 4rem 0;
        border-top: 1px solid #ddddd5;
        &:first-child{
          padding: 0 0 4rem 0;
        }
        &:last-child{
          padding: 4rem 0 0 0;
        }
      }
    }
    .p-newsListItemHaveThumb__info {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-top: 1.4rem;
      @include mq(md){
        margin: 2.5rem 0 2.5rem;
      }
    }
    .p-newsListItemHaveThumb__pubDate {
      display: flex;
      align-items: flex-start;
      padding: 0.6rem 0;
      @include lhCrop(2);
      @include mq(md){
        padding: 1rem 0;
      }
    }
    .c-heading {
      margin-top: 1rem;
      font-size: 1.8rem;
      line-height: 1.5;
      height: 3em;
      @include mq(md){
        margin-top: 0;
        height:auto;
      }
    }
    .p-newsListItemHaveThumb__visual {
      display: block;
      img{
        @include mq(md,min) {
          width: (535 / 12) * 1rem;
          height: (282.688 / 12) * 1rem;
        }
        @include mq(md) {
          width: (675 / 9.09375) * 1rem;
          height: (356.562 / 9.09375) * 1rem;
        }
      }
    }
  }
  &__typeD{//typeD 例：サイトトップ、ニュースルーム＞ニュースリリース、ニュースリリース一覧、研究開発トップ、研究開詳細×3
    .p-newsListItemHaveThumb {
      display: flex;
      align-items: center;
      border-bottom:  1px solid color(gray-300);
      @include mq(md,min) {
        padding: 1.8rem 0;
      }
      @include mq(md) {
        padding: 4rem 0;
        align-items: flex-start;
      }
      &:first-child {
        border-top: 1px solid #ddddd5;
        padding-top: 1.8rem;
        @include mq(md) {
          border-top: 1px solid #ddddd5;
          padding-top: 4rem;
        }
      }
      &:hover{
        .p-newsListItemHaveThumb__visual img{
          transform: none;
        }
      }
    }
    .p-newsListItemHaveThumb__visual {
      overflow: hidden;
      position: relative;
      // margin: 1.5rem 0;
      border: 1px solid #ddddd5;
      img{
        -o-object-fit: contain;
        object-fit: contain;
        @include mq(md,min){
          width: (378.5 / 12) * 1rem;
          height: (200 / 12) * 1rem;
        }
        @include mq(md){
          width: (268.891 / 9.09375) * 1rem;
          height: (142.031 / 9.09375) * 1rem;
        }
      }
    }
    .p-newsListItemHaveThumb__body {
      flex: 1;
      @include mq(md,min){
        padding:0 6rem 0 3.5rem;
      }
      @include mq(md){
        padding-left: 1.4rem;
      }
    }
    .p-newsListItemHaveThumb__info {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      margin-top: 0;
      @include mq(md){
        display: flex;
        flex-direction: column;
        align-items: flex-start;
      }
    }
    .p-newsListItemHaveThumb__pubDate {
      display: flex;
      align-items: flex-start;
      padding: 0.6rem 0;
      @include lhCrop(2);
      @include mq(md){
        padding: 1rem 0;
      }
    }
    .p-newsListItemHaveThumb__categoryIcon__Plain{
      &:first-child{
        @include mq(md){
          margin-left: 0;
        }
      }
    }
    .p-newsListItemHaveThumb__categoryIconList {
      @include mq(md) {
        // margin-top: 1rem;
        margin-left: 0;
      }
    }

    .c-heading {
      margin-top: 1rem;
      font-size: 1.8rem;
      line-height: 1.5;
      @include mq(md) {
        font-size: 2.8rem;
        line-height: (54 / 34);
      }
    }
    .p-newsListItemHaveThumb__subtitle {
      @include mq(md) {
        margin-top: 1rem;
        margin-bottom: 2rem;
        font-size: 2.4rem;
        line-height: 1.4;
      }
    }
    .p-newsListItemHaveThumb__body__pdf {
      display: inline-block;
      padding: 0.1rem 1.934em;
      border-radius: 12.5rem;
      border: 1px solid;
      color: color(gray-600);
      margin-top: 1.3rem;
      // position: absolute;
      // right: 0.5rem;
      // bottom: 1rem;
      @include font-barlow;
      font-size: 1.4rem;
      transition: color 400ms cubic-bezier(0.215, 0.61, 0.355, 1);

      @include mq(md) {
        padding: 0.2rem 1.105em 0.5rem 1.105em;
        font-size: 2.4rem;
        line-height: 1.1;
        bottom: 2rem;
      }
    }
  }
}
