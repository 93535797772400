@use '../../../foundation/config' as *;

.p-topNews {
  margin-top: 25rem;

  @include mq(md) {
    margin-top: 23.4rem;
  }
}

.p-topNews__tab {
  margin-top: 8rem;

  @include mq(md, min) {
    display: flex;
    align-items: flex-start;
  }

  @include mq(md) {
    margin-top: 7rem;
  }
}

.p-topNews__tabList {
  @include mq(md, min) {
    flex-direction: column;
    width: 26rem;
    margin-left: auto;
  }

  @include mq(md) {
    margin: 0;
  }
}

.p-topNews__tabItem {
  @include mq(md, min) {
    flex: auto;
  }

  & + & {
    @include mq(md, min) {
      border-top: 1px solid color(gray-100);
      border-left: 0;
    }
  }
}

.p-topNews__tabPanel {
  flex: 0 0 percentage(6 / 8);
  order: -1;

  @include mq(md) {
    margin-top: 5rem;
  }
}
