// Breadcrumb Navigation
// ============================================================================

@use '../../foundation/config' as *;

.c-breadcrumb {
  border-bottom: 1px solid color(gray-300);
}

.c-breadcrumb__inner {
  display: flex;
  justify-content: flex-end;
  max-width: 1920px;
  margin-right: auto;
  margin-left: auto;
  overflow: auto;

  @include mq(md, min) {
    justify-content: flex-start;
    padding-right: 100 / 1600 * 100%;
    padding-left: 100 / 1600 * 100%;
  }

  @include mq(md) {
    padding-left: 4rem;
  }
}

.c-breadcrumb__list {
  display: inline-flex;
}

.c-breadcrumb__item {
  display: inline-flex;
  align-items: baseline;
  flex-shrink: 0;
  color: color(gray-600);
  font-size: 1.6rem;

  @include mq(md) {
    font-size: 2rem;
  }

  &:last-child {
    @include mq(md) {
      padding-right: 4rem;
    }
  }
}

.c-breadcrumb__link {
  padding-top: 1.094em;
  padding-bottom: 1.094em;
  transition: color 400ms easing(easeOutCubic);

  @include mq(md) {
    padding-top: 1.196em;
    padding-bottom: 1.196em;
  }

  &[aria-current]:not([aria-current='false']) {
    color: color(black);
    font-weight: bold;
  }

  &:not([href]) {
    color: color(black);
  }

  &[href]:hover {
    color: color(green-600);
  }
}

.c-breadcrumb__icon {
  width: 0.6rem;
  height: 1.1rem;
  margin-right: 2rem;
  margin-left: 2rem;

  @include mq(md) {
    width: 0.8rem;
    height: 1.7rem;
  }
}
