@use '../../../foundation/config' as *;

.p-companyLocalIfs {
  .l-main__inner {
    width: 100%;
    max-width: initial;
    margin: 0;
    padding: 0 0 15rem;
    font-size: 1.8rem;
    line-height: 1.8;
    @include mq(md) {
      font-size: 3.2rem;
    }
  }
  .c-heading.-level2 {
    & + p {
      margin-top: 4rem;
    }
  }
  p + p {
    margin: 2rem 0 0;
  }
}
.p-companyLocalIfs__logo {
  max-width: 110rem;
  padding: 0;
  .c-flexbox {
    justify-content: center;
    @include mq(md) {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    .p-companyLocalIfs__logoImage {
      flex: 0 0 48%;
      @include mq(md) {
        padding: 0 2rem;
      }
    }
    .p-companyLocalIfs__logoStatement {
      flex: 0 0 36%;
      @include mq(md, min) {
        margin-left: 7.2rem;
      }
    }
  }
}

.p-companyLocalIfs__logoStatement {
  @include font-serif;
  font-size: 3.5rem;
  font-weight: 600;
  line-height: 1.75;
  @include mq(md) {
    padding: 4rem 0 0;
    font-size: 5rem;
  }
}

.p-companyLocalIfs__president {
  .c-flexbox {
    align-items: flex-start;
    figure {
      flex: 0 0 40%;
      @include mq(md, min) {
        margin-top: .8rem;
      }
    }
    .-feature {
      flex: 0 0 60%;
    }
  }
}
.p-companyLocalIfs__message {
  display: flex;
  flex-direction: column;
  padding: 4rem 0 0;
  @include mq(md, min) {
    padding: 0 0 0 4rem;
  }
}
.p-companyLocalIfs__post {
  margin-top: 2rem;
  margin-left: auto;
  @include mq(md) {
    margin-top: 4rem;
  }
  p + p {
    margin-top: 0;
  }
}
.p-companyLocalIfs__sign {
  width: 25rem;
  margin-left: auto;
  @include mq(md) {
    width: 44rem;
    margin-top: 2rem;
  }
}