@use '../../foundation/config' as *;
//print
@include print {
  .target_print{
    //base.scss
    font-size: 3pt;
    body{
      background-color: color(white);
    }
    //main.scss
    .l-header.-dense + .l-content .l-main {
       padding-top: 0!important;
    }
    .l-printButton{
      display: none;
    }
    //header.scss
    .p-header__navGroup,.p-header__searchArea{
      display: none!important;
    }
    .c-pageHeader__inner{
      max-width: 1920px;
      margin-right: auto;
      margin-left: auto;
      padding: 9.95rem 10rem!important;
    }
    .c-pageHeader__inner.-active .c-pageHeader__text::after {
      left: 10rem;
    }
    //news,info詳細用
    .p-newsDetail{
      padding-bottom: 0;
    }
    .p-newsDetail .c-breadcrumb__inner{
      justify-content: flex-start!important;
    }
    .p-newsDetail__inner{
      max-width: 1920px;
      margin-right: auto;
      margin-left: auto;
      padding-right: 6.25%;
      padding-left: 6.25%;
      padding-top:4rem ;
    }
    .p-newsDetail__footer {
      display: none;
    }
    .p-post{
      max-width: 100%!important;
    }
    .p-post__body{
      max-width: none;
    }
    .p-post{
      h1:not([class]) {
        font-size: 3.7rem;
      }
      .wysiwyg{
        img{
            zoom: 0.5;
        }
      }
      // 記事要素：メインビジュアル
      .p-post__mainimg {
        img{
          width: 100%;
          // height:91rem !important;//ver full
          height:80rem !important;
        }
      }
      //画像
      .p-post__image{
          text-align: center;
          margin-left: auto;
          margin-right: auto;
          max-width: 50%;
      }
      //動画
      .p-post__youtube {
        text-align: center;
        iframe{
            width: 50%;
            height: 100%;
        }
        figcaption{
          width: 50%;
          margin: auto;
          text-align: left;
        }
      }
      //メディア+テキスト
      .p-post__mediaTextInner{
          flex-direction: row!important;
          //縦方向
          align-items: flex-start;//印刷スタイルではデフォ中央よせ
          //新規追加縦方向設定を反映
          &.-upper {
            align-items: flex-start;
          }
          &.-middle {
            align-items: center;
          }
          &.-lower {
            align-items: flex-end;
          }
          //画像の大きさ　screenの大きさだしわけに拘らず、固定
          img{
            width: 25%;
            max-width: 25%!important;
            min-width: 25%!important;
            &.alignleft{
              margin-right: 6.75%;
            }
            &.alignright{
              margin-left: 6.75%;
            }
          }
          .wysiwyg{
            width: 68.25%;;
            & > *:first-child {
              margin-top: 0;
            }
          }

      }

      //画像ダウンロード
      .p-post__download {
        margin-top: 6.46vw;
        padding-top: 5.48vw;
        border-top: 1px solid color(gray-300);
      }
      .p-post__downloadTitle {
        font-size: 2rem;
        line-height: 2;

        @include mq(md) {
          font-size: 2rem;
          line-height: 2;
        }
      }
      .p-post__downloadList {
        display: flex;
        flex-wrap: wrap;
        margin-top: 3.22vw;
      }
      .p-post__downloadItem {
        // flex: 0 0 31rem;
        flex-grow:0;
        flex-shrink:0;
        flex-basis: auto;
        width: 29.523809523%;

        &:nth-child(n + 1) {
          @include mq(md) {
            margin-top: 0;
          }
        }

        &:not(:nth-child(3n + 1)) {
          @include mq(md, min) {
            margin-left: 4.46vw;
          }
          @include mq(md) {
            margin-left: 4.46vw;
          }
        }

        &:nth-child(n + 4) {
          @include mq(md, min) {
            margin-top: 4.04vw;
          }
          @include mq(md) {
            margin-top: 4.04vw;
          }
        }
      }

      .p-post__downloadLink {
        display: flex;

        @include mq(md, min) {
          flex-direction: column;
        }

        @include mq(md) {
          flex-direction: column;
        }
      }

      .p-post__downloadText {
        font-size: 1.6rem;
        font-weight: bold;
        line-height: (36 / 16);
        transition: color 400ms easing(easeOutCubic);

        @include mq(md, min) {
          margin-top: 1.45vw;
        }

        @include mq(md) {
          margin-top: 1.29vw;
          font-size: 1.6rem;
          line-height: (36 / 16);
        }

        .p-post__downloadLink:hover & {
          color: color(green-600);
        }
      }

      .p-post__downloadImageBlock {
        position: relative;
        order: -1;
        width: 27vw;
        height: 17.8vw;
        background-color: color(white);

        @include mq(md) {
          flex-shrink: 1;
          width: 27vw;
          height: 17.8vw;
          margin-right: 0;
        }
      }

      .p-post__downloadImage {
        display: flex;
        justify-content: center;
        align-items: center;

        img {
          width: 20.77vw;
          height: 13.53vw;
          object-fit: contain;
          font-family: 'object-fit: contain'; /* stylelint-disable-line */

          @include mq(md) {
            width: 20.77vw;
            height: 13.53vw;
          }
        }
      }

      .p-post__downloadSign {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        right: 0;
        bottom: 0;
        width: 4.52vw;
        height:4.52vw;
        background-color: color(gray-200);

        @include mq(md) {
          width: 4.52vw;
          height:4.52vw;
        }
      }

      .p-post__downloadIcon {
        width: 1.54vw;
        height: 1.21vw;
        color: color(gray-450);
        transition: color 400ms easing(easeOutCubic);

        .p-post__downloadLink:hover & {
          color: color(green-600);
        }
      }
    }
  }
};
