@use '../../../foundation/config' as *;

.p-companyBlock6 {
  margin: 13rem 0 0;
  @include mq(md, min) {
    max-width: 1050px;
    margin: 14.5rem auto 0;
    padding: 0 0 0 6.1rem;
  }
}
.p-companyBlock6__text1 {
  &:first-child {
    margin-top: 0;
  }
  & > p {
    font-size: 3rem;
    line-height: (60/30);
    text-align: justify;
    margin: 1em 0 0;
    @include mq(md, min) {
      font-size: 1.6rem;
      line-height: (36/16);
    }
    &:first-child {
      margin-top: 0;
    }
    &.-alignRight {
      text-align: right;
    }
    & > small {
      font-size: 2.4rem;
      line-height: (40/24);
      @include mq(md, min) {
        font-size: 1.4rem;
        line-height: (26/14);
      }
    }
    &.-mt0 {
      margin-top: 0;
    }
  }
  a:not(.c-button) {
    color: color(gray-600);
    display: inline-flex;
    align-items: center;
    transition-property: color, text-decoration-color;
    transition-duration: 400ms;
    transition-timing-function: easing(easeOutCubic);
    text-decoration: underline;
    text-underline-offset: 0.4rem;
    &.-external {
      i {
        display: block;
        width: 2.1rem;
        height: 2.1rem;
        font-size: 0;
        margin: 0 0 0 1.8rem;
        @include mq(md, min) {
          width: 1.4rem;
          height: 1.4rem;
          margin: 0 0 0 1rem;
        }
      }
      svg {
        width: 2.1rem;
        height: 2.1rem;
        @include mq(md, min) {
          width: 1.4rem;
          height: 1.4rem;
        }
      }
    }
    &.-arrowRight {
      i {
        display: block;
        width: 2.4rem;
        height: 1.7rem;
        font-size: 0;
        margin: 0 0 0 1.8rem;
        @include mq(md, min) {
          width: 1.6rem;
          height: 1.1rem;
          margin: 0 0 0 1rem;
        }
      }
      svg {
        width: 2.4rem;
        height: 1.7rem;
        @include mq(md, min) {
          width: 1.6rem;
          height: 1.1rem;
        }
      }
    }
    &:hover {
      color: color(green-600);
      text-decoration-color: rgba(color(gray-600), 0);
    }
  }
}
.p-companyBlock6__list1 {
  margin: 1rem 0 0 0;
  & > li {
    font-size: 3rem;
    line-height: (60/30);
    text-align: justify;
    margin: 1.5rem 0 0;
    padding: 0 0 0 4rem;
    position: relative;
    @include mq(md, min) {
      font-size: 1.6rem;
      line-height: (36/16);
      padding-left: 2rem;
    }
    &:before {
      content: '・';
      display: block;
      font-size: 5.8rem;
      color: color(green-600);
      position: absolute;
      top: 0;
      left: -8px;
      line-height: 1;
      @include mq(md, min) {
        font-size: 3rem;
        top: 0.3rem;
      }
      @media screen and (min-width: 1900px) {
        top: 0.1rem;
      }
    }
    @at-root {
      .p-companyBlock6__list1.-type1 {
        margin-left: 4rem;
        @include mq(md, min) {
          margin-left: 2rem;
        }
      }
      .p-companyBlock6__list1.-type1 > li {
        list-style-type: decimal;
        margin: 1.5rem 0 0;
        padding: 0;
        &:before {
          display: none;
        }
      }
    }
  }
  .Charup-1em{
    display: block;
    text-indent: -1em;
    padding-left: 1em;
  }
}
.p-companyBlock6__title1 {
  margin: 17.5rem 0 5rem;
  @include mq(md, min) {
    margin: 18.2rem 0 6.4rem;
  }
  &:nth-of-type(1) {
    margin: 17.3rem 0 5rem;
    @include mq(md, min) {
      margin: 13.2rem 0 6.4rem;
    }
  }
}
.p-companyBlock6__title2 {
  margin: 5rem 0 3rem;
  @include mq(md, min) {
    margin: 6.4rem 0 4rem;
  }
}
.p-companyBlock6__title3 {
  margin: 5rem 0 1.4rem;
  @include mq(md, min) {
    margin: 6.4rem 0 2.1rem;
  }
}
.p-companyBlock6__title4 {
  margin: 2.5rem 0 0.5rem;
  @include mq(md, min) {
    margin: 3.2rem 0 1rem;
  }
}
.p-companyBlock6__button1 {
  margin: 5rem 0 0;
  text-align: center;
  @include mq(md, min) {
    display: none;
  }
}
.p-companyBlock6__name1 {
  text-align: right;
  img {
    width: 36.3rem;
    @include mq(md, min) {
      width: 27rem;
    }
  }
}
.p-companyBlock6__table {
  margin: 4rem 0;

  &.-mb30 {
    margin: 3rem 0;
  }
}

.p-companyBlock6__box {
  border: 2px solid color(gray-300);
  padding: 3.1rem 4.2rem 5.6rem 4.1rem;

  @include mq(md, min) {
    border: 1px solid color(gray-300);
    padding: 4rem 4rem 3.7rem 4.2rem;
  }
}
