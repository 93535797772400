// Pagination
// ============================================================================

// Note:
// WordPressの wp_pagenavi() を使えるように追加のclassは付与しないこと

@use '../../foundation/config' as *;

.c-pagination {
  display: flex;
  justify-content: center;
}

.c-pagination__inner {
  position: relative;
  padding: 0.4rem 18.6rem;

  @include mq(md) {
    padding-right: 0;
    padding-bottom: 15.4rem;
    padding-left: 0;
  }

  .wp-pagenavi {
    display: flex;
    font-size: 1.6rem;

    @include mq(md) {
      font-size: 2.6rem;
    }
  }

  .first,
  .page,
  .current,
  .extend,
  .last {
    display: inline-flex;
    justify-content: center;
    min-width: 5rem;
    margin-right: 0.5rem;
    margin-left: 0.5rem;
    padding: 0.813em 0.4em;
    border-bottom: 2px solid transparent;
    @include font-barlow;
    letter-spacing: 0.05em;

    @include mq(md) {
      margin-right: 1.5rem;
      margin-left: 1.5rem;
      padding: 1em 0.4em 0.8em;
    }
  }

  .first,
  .page,
  .last {
    transition: color 400ms easing(easeOutCubic);

    &:hover {
      color: color(green-600);
    }
  }

  .extend {
    padding: 0;
    font-size: 3rem;
    line-height: 1.1;

    @include mq(md) {
      padding: 0;
      font-size: 4.2rem;
      line-height: 1.5;
    }
  }

  .current {
    color: color(green-600);
    border-color: currentColor;
  }

  .previouspostslink,
  .nextpostslink {
    position: absolute;
    padding: 1em 5rem 1.25em;
    font-weight: bold;
    transition: color 400ms easing(easeOutCubic);

    @include mq(md, min) {
      top: 50%;
      transform: translateY(-50%);
    }

    @include mq(md) {
      bottom: 0;
      padding: 0.71em 7rem 0.9em;
    }

    &:hover {
      color: color(green-600);
    }

    &::before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      bottom: 0;
      width: 1.6rem;
      height: 2px;
      margin: auto;
      background-color: currentColor;

      @include mq(md) {
        width: 2.6rem;
      }
    }

    &::after {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      bottom: 0;
      width: 8px;
      height: 8px;
      margin: auto;
      border-top: 2px solid;
      border-right: 2px solid;
    }
  }

  .previouspostslink {
    left: 0;
    border-right: 1px solid color(gray-300);

    @include mq(md) {
      left: calc(50% + 1px);
      transform: translateX(-100%);
    }

    &::before {
      left: 2.2rem;

      @include mq(md) {
        left: 2.6rem;
      }
    }

    &::after {
      left: 2.1rem;
      transform: rotate(-135deg);

      @include mq(md) {
        left: 2.8rem;
      }
    }
  }

  .nextpostslink {
    right: 0;
    border-left: 1px solid color(gray-300);

    @include mq(md) {
      right: calc(50% + 1px);
      transform: translateX(100%);
    }

    &::before {
      right: 2.2rem;

      @include mq(md) {
        right: 2.6rem;
      }
    }

    &::after {
      right: 2.1rem;
      transform: rotate(45deg);

      @include mq(md) {
        right: 2.8rem;
      }
    }
  }
}
