@use '../../../foundation/config' as *;

.p-companyBlock7 {
  margin: 14rem 0 0;
  @include mq(md, min) {
    margin: 14.3rem 0 0;
  }
}
.p-companyBlock7__title2 {
  margin: 7rem 0 0;
  @include mq(md, min) {
    margin: 8.3rem 0 0;
  }
  @at-root {
    .p-companyBlock7__title1 + .p-companyBlock7__title2 {
      margin-top: 5.4rem;
      @include mq(md, min) {
        margin-top: 6.8rem;
      }
    }
  }
}
.p-companyBlock7__text1 {
  font-size: 3rem;
  text-align: justify;
  line-height: (60/30);
  margin: 3.5rem 0 0;
  @include mq(md, min) {
    font-size: 1.6rem;
    line-height: (36/16);
    margin: 3.9rem 0 0;
  }
}
.p-companyBlock7__image1 {
  display: block;
  margin: 6.3rem 0 0;
  @include mq(md, min) {
    text-align: center;
    margin: 6.8rem 0 0;
  }
}
.p-companyBlock7__linkGroup {
  margin-top: 8rem;
}
.p-companyBlock7__linkList1 {
  margin: 10.2rem 0 0;
}
