@use '../../../foundation/config' as *;

.p-researchStatement {
  padding-bottom: 25.2rem;

  @include mq(md) {
    padding-bottom: 24rem;
  }
}

.p-researchStatement__inner {
  margin-top: 14.5rem;

  @include mq(md) {
    margin-top: 13.6rem;
  }
}

.p-researchStatement__section {
  & + & {
    margin-top: 18rem;

    @include mq(md) {
      margin-top: 17rem;
    }
  }
}

.p-researchStatement__title {
  @include mq(md, min) {
    margin: 0 0 6rem;
  }

  @include mq(md) {
    margin: 0 0 5rem;
  }
}

.p-researchStatement__text {
  font-size: 1.6rem;
  line-height: 2.25;

  @include mq(md) {
    font-size: 3rem;
    line-height: 2;
  }
}

.p-researchStatement__image {
  @include mq(md, min) {
    margin-top: 7.9rem;
    padding-right: 9.3rem;
    padding-left: 9.3rem;
  }

  @include mq(md) {
    margin-top: 6rem;
  }

  img {
    width: 100%;
    height: auto;
  }
}

.p-researchStatement__button {
  text-align: center;
  margin: 5rem 0 0;
}
