@use '../../../foundation/config' as *;

.p-mediakit{
  overflow: hidden;
  .l-main__inner {
    @include mq(md, min) {
      padding-top: 2rem;
    }
    * {
      @include font-default;
    }
  }

  //level2見出し
  .c-heading {
    &.-level2 {
      margin: 4rem 0;
      font-size: 3rem;
      @include mq(md) {
        margin-top: 10rem;
        font-size: 4.5rem;
      }
    }
  }

  /** セクション余白設定**/
  &__sec {
    &:first-child{
      @include mq(md, min) {
        margin: 6rem 0 8rem;
      }
    }
    margin: 10rem 0;
  }

  /** 条項**/
  &__terms {
    p{
      font-size: 1.6rem;
      font-weight: normal;
      @include mq(md) {
        font-size: 2.6rem;
      }
    }
    .c-disclosure__body {
      display: block;
    }
    .c-heading.-level5 {
      margin-bottom: 2.5rem;
      @include mq(md) {
        margin: 5rem 0 2.5rem;
      }
    }
  }
  &__termsLinks {
    display: flex;
    align-items: center;
    @include mq(md) {
      display: block;
    }
    li:nth-child( n + 2 ) {
      .c-linkButton {
        margin-left: 4rem;
        @include mq(md) {
          margin-left: 0;
        }
      }
    }
    .c-linkButton {
      min-width: auto;
      margin: 4rem 0;
    }
  }
  &__termsList {
    list-style: outside;
    padding-left: 2.5rem;
    font-size: 1.6rem;
    font-weight: normal;
    @include mq(md) {
      font-size: 2.6rem;
    }
  }
  &__termsListItem {
    list-style: inherit;
  }

  /** 画像のリスト**/
  &__dlList {
    @include mq(md, min) {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      margin-top: 4rem;
    }
  }
  &__dlItem {
    //(1675 / 3) / 12px  =46.5
    @include mq(md, min) {
      width: 44.5rem;
      margin-left: calc(calc(100% - (44.5rem * 3)) / 2);
      &:nth-of-type(3n+1){
        margin-left: 0;
      }
    }
    @include mq(md) {
      width: 100%;
    }
    //SP時、アイテム間余白
    &:nth-child(n + 1) {
      @include mq(md) {
        margin-top: 5rem;
      }
    }
    //PC時、アイテム間余白
    &:nth-child(n + 4) {
      @include mq(md, min) {
        margin-top: 5rem;
      }
    }
  }
  &__dlLink {
    display: flex;
    flex-direction: column;
    align-items: center;

  }
  &__dlText {
    font-size: 1.6rem;
    font-weight: bold;
    line-height: (26 / 16);
    transition: color 400ms easing(easeOutCubic);

    @include mq(md, min) {
      margin-top: 1.6rem;
    }

    @include mq(md) {
      font-size: 3rem;
      line-height: 1.625;
      margin-top: 2rem;
    }

    .p-mediakit__dlLink:hover & {
      color: color(green-600);
    }
  }
  &__dlImageBlock {
    position: relative;
    order: -1;
    background-color: color(white);

    @include mq(md) {
      flex-shrink: 0;
      width: 85%;
      height:  42.2015853379rem;
    }
  }
  &__dlImage {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    border: 1px solid color(gray-300);

    img {
      width: (532 / 12) * 1rem;//boderを考慮
      height: 29.5833333333rem;
      object-fit: contain;
      font-family: 'object-fit: contain';

      @include mq(md) {
        width: 100%;
        height:  42.2015853379rem;
      }
    }
  }
  &__dlSign {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    right: 0;
    bottom: 0;
    width: 5rem;
    height: 5rem;
    background-color: color(gray-200);

    @include mq(md) {
      width: 8rem;
      height: 8rem;
    }
  }
  &__dlIcon {
    width: 2.9rem;
    height: 2.29rem;
    color: color(gray-450);
    transition: color 400ms easing(easeOutCubic);

    .p-mediakit__dlLink:hover & {
      color: color(green-600);
    }
  }
}
