@use '../../../foundation/config' as *;

.p-topCommunication {
  margin-top: 10rem;

  @include mq(md, min) {
    display: flex;
    align-items: flex-end;
  }

  @include mq(md) {
    // margin-top: 23.4rem;
  }
}

.p-topCommunication__text {
  margin-top: 8rem;
  font-size: 1.6rem;
  line-height: (36 / 16);

  @include mq(md) {
    margin-top: 5rem;
    font-size: 3rem;
    line-height: (60 / 30);
  }
}

.p-topCommunication__button {
  margin-top: 4rem;
}

.p-topCommunication__media {
  @include mq(md, min) {
    display: flex;
    justify-content: space-between;
    order: -1;
    flex: 0 0 97.5rem;
    margin-right: 9rem;
    margin-left: -10rem;
  }

  @include mq(md) {
    margin-top: 10rem;
  }
}

.p-topCommunication__image {
  img {
    width: 46.5rem;
    height: auto;
  }

  &:first-child {
    @include mq(md) {
      margin-left: -4rem;
    }

    img {
      @include mq(md) {
        width: 51.2rem;
      }
    }
  }

  &:last-child {
    @include mq(md) {
      display: block;
      margin-top: 5rem;
      text-align: right;
    }

    img {
      @include mq(md) {
        width: 40rem;
      }
    }
  }
}
