@use '../../../foundation/config' as *;

.p-topInquiry {
  position: relative;
  margin-top: 25rem;
  background-color: color(white);

  @include mq(md, min) {
    display: flex;
    align-items: center;
  }

  @include mq(md) {
    margin-right: -4rem;
    margin-left: -4rem;
    padding-top: 13.6rem;
  }

  &::after {
    @include mq(md, min) {
      content: '';
      position: absolute;
      right: -100%;
      top: 0;
      bottom: 0;
      width: 100%;
      background-color: color(white);
    }
  }
}

.p-topInquiry__textBlock {
  padding-right: 9.5rem;
  padding-left: 8rem;

  @include mq(md) {
    padding-right: 4rem;
    padding-left: 4rem;
  }
}

.p-topInquiry__text {
  margin-top: 8rem;
  font-size: 1.6rem;
  line-height: (36 / 16);

  @include mq(md) {
    margin-top: 5rem;
    font-size: 3rem;
    line-height: 2;
  }
}

.p-topInquiry__list {
  flex: 0 0 percentage(5 / 8);

  @include mq(md) {
    margin-top: 6.2rem;
    border-top: 1px solid color(gray-300);
  }
}

.p-topInquiry__item {
  font-size: 2rem;
  font-weight: bold;

  @include mq(md) {
    font-size: 3.4rem;
    line-height: (54 / 34);
  }

  &:not(:last-child) {
    border-bottom: 1px solid color(gray-300);
  }
}

.p-topInquiry__link {
  display: flex;
  position: relative;
  align-items: center;
  height: 12.3rem;
  padding-right: 3rem;
  padding-left: 3.4rem;
  transition: color 400ms easing(easeOutCubic);
  overflow: hidden;

  @include mq(md) {
    height: 18.4rem;
    padding-right: 4rem;
    padding-left: 4rem;
  }

  &:hover {
    color: color(green-600);
  }

  &::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 0;
    transition: width 800ms easing(easeOutCubic);
    border-bottom: 2px solid color(green-600);
  }

  &:hover::before {
    left: 0;
    width: 100%;
    transition: width 600ms easing(easeOutCubic);
  }
}

.p-topInquiry__listText {
  margin-left: 4rem;
}

.p-topInquiry__vendingMachine {
  width: 3.5rem;
  height: 3.5rem;

  @include mq(md) {
    width: 5.3rem;
    height: 5.3rem;
  }
}

.p-topInquiry__graph,
.p-topInquiry__comment {
  width: 3.4rem;
  height: 3.4rem;

  @include mq(md) {
    width: 5.1rem;
    height: 5.1rem;
  }
}

.p-topInquiry__arrow {
  width: 1.6rem;
  height: 1.1rem;
  margin-left: auto;

  @include mq(md) {
    width: 2.4rem;
    height: 1.7rem;
  }
}
.p-topInquiry__external {
  width: 1.4rem;
  height: 1.4rem;
  margin-left: auto;

  @include mq(md) {
    width: 2.1rem;
    height: 2.1rem;
  }
}
