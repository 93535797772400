@use '../../../foundation/config' as *;

.p-companyBlock16 {
  margin: 18.8rem 0 0;
  @include mq(md, min) {
    margin: 19.2rem 0 0;
  }
  &:first-child {
    margin: 14rem 0 0;
  }
}
.p-companyBlock16__title2 {
  margin: 7.1rem 0 0;
  @include mq(md, min) {
    margin: 8.5rem 0 0;
  }
  @at-root {
    .p-companyBlock16__title1 + .p-companyBlock16__title2 {
      margin: 5.6rem 0 0;
      @include mq(md, min) {
        margin: 7rem 0 0;
      }
    }
    .p-companyBlock16__table1 + .p-companyBlock16__title2 {
      margin: 9rem 0 0;
      @include mq(md, min) {
        margin: 9.4rem 0 0;
      }
    }
    .p-companyBlock16__notes1 + .p-companyBlock16__title2,
    .p-companyBlock16__list1 + .p-companyBlock16__title2,
    .p-companyBlock16__list2 + .p-companyBlock16__title2 {
      margin: 8.2rem 0 0;
      @include mq(md, min) {
        margin: 9.6rem 0 0;
      }
    }
    .p-companyBlock16__notes1 + .p-companyBlock16__title2 {
      @include mq(md, min) {
        margin: 8.7rem 0 0;
      }
    }
  }
}
.p-companyBlock16__title3 {
  position: relative;
  margin: 5.4rem 0 0;
  @include mq(md, min) {
    margin: 6rem 0 0;
  }
  @at-root {
    .p-companyBlock16__table1 + .p-companyBlock16__title3 {
      margin: 6.7rem 0 0;
    }
  }
}
.p-companyBlock16__title3Label1 {
  position: absolute;
  top: 0;
  right: -2rem;
  font-size: 3rem;
  line-height: (60/30);
  @include mq(md, min) {
    font-size: 1.6rem;
    line-height: (36/16);
    right: -1.3rem;
  }
}
.p-companyBlock16__text1 {
  font-size: 3rem;
  line-height: (60/30);
  text-align: justify;
  margin: 3.1rem 0 0;
  @include mq(md, min) {
    font-size: 1.6rem;
    line-height: (36/16);
    margin: 3.8rem 0 0;
  }
  &.-alignRight {
    text-align: right;
  }
}
.p-companyBlock16__list1 {
  margin: 5.2rem 0 0;
  border-bottom: 1px solid color(gray-300);
  @include mq(md, min) {
    margin: 5.1rem 0 0;
  }
  li {
    border-top: 1px solid color(gray-300);
    @include mq(md, min) {
      display: flex;
      align-items: center;
      padding: 1.7rem 0 2rem;
    }
  }
}
.p-companyBlock16__list1Title1 {
  font-size: 3.4rem;
  line-height: (54/34);
  font-weight: bold;
  padding: 3.4rem 0 0;
  @include mq(md, min) {
    font-size: 1.6rem;
    line-height: (36/16);
    padding: 0;
    width: 25%;
  }
}
.p-companyBlock16__list1Text1 {
  font-size: 3rem;
  line-height: (60/30);
  text-align: justify;
  margin: 0.8rem 0 0;
  padding: 0 0 3.8rem;
  @include mq(md, min) {
    padding: 0;
    margin: 0;
    font-size: 1.6em;
    line-height: (36/16);
    width: 75%;
  }
}
.p-companyBlock16__list2 {
  margin: 7.9rem 0 0;
  border-bottom: 1px solid color(gray-300);
  @at-root {
    .p-companyBlock16__title2 + .p-companyBlock16__list2 {
      margin: 5rem 0 0;
      @include mq(md, min) {
        margin: 4rem 0 0;
      }
    }
  }
  li {
    border-top: 1px solid color(gray-300);
    @include mq(md, min) {
      display: flex;
      padding: 2.5rem 0 2.3rem;
    }
    @at-root {
      .p-companyBlock16__title2 + .p-companyBlock16__list2 li {
        &:first-child {
          @include mq(md, min) {
            border: none;
            padding-top: 0;
          }
        }
      }
    }
  }
}
.p-companyBlock16__list2Title1 {
  font-size: 3.4rem;
  line-height: (36/34);
  font-weight: bold;
  padding: 4.4rem 0 0;
  @include mq(md, min) {
    font-size: 2rem;
    line-height: (36/20);
    padding: 0 0 0;
  }
}
.p-companyBlock16__list2Text1 {
  font-size: 3rem;
  line-height: (60/30);
  text-align: justify;
  margin: 2.1rem 0 0;
  padding: 0 0 3.2rem;
  @include mq(md, min) {
    font-size: 1.6rem;
    line-height: (36/16);
    padding: 0;
    margin: 0.3rem 0 0;
  }
  span {
    display: block;
    font-size: 2.4rem;
    line-height: (60/24);
    @include mq(md, min) {
      display: inline-block;
      font-size: 1.4rem;
      line-height: (26/14);
    }
  }
  a {
    color: color(gray-600);
    display: inline-flex;
    align-items: center;
    transition: color 400ms easing(easeOutCubic);
    &.-external {
      transition-property: color, text-decoration-color;
      transition-duration: 400ms;
      transition-timing-function: easing(easeOutCubic);
      text-decoration: underline;
      text-underline-offset: 0.4rem;

      i {
        display: block;
        width: 2.1rem;
        height: 2.1rem;
        font-size: 0;
        margin: 0 0 0 1.8rem;
        @include mq(md, min) {
          width: 1.4rem;
          height: 1.4rem;
          margin: 0 0 0 1rem;
        }
      }
      svg {
        width: 2.1rem;
        height: 2.1rem;
        @include mq(md, min) {
          width: 1.4rem;
          height: 1.4rem;
        }
      }
    }
    &:hover {
      color: color(green-600);
      text-decoration-color: rgba(color(gray-600), 0);
    }
  }
  .c-button {
    color: color(black);
    &:hover {
      color: color(green-600);
    }
  }
}
.p-companyBlock16__image1 {
  margin: 8rem 0 0;
  @include mq(md, min) {
    text-align: center;
  }
}
.p-companyBlock16__button1 {
  margin: 5rem 0 0;
  text-align: center;
  @include mq(md, min) {
    display: none;
  }
  @at-root {
    .p-companyBlock16__list2 .p-companyBlock16__button1 {
      margin-bottom: 2rem;
      @include mq(md, min) {
        margin-bottom: 0;
      }
    }
  }
}
.p-companyBlock16__button2 {
  margin: 3.4rem 0 0;
  @include mq(md, min) {
    margin: 4rem 0 0;
  }
}
.p-companyBlock16__list2Image1 {
  margin: 6.2rem 0 0;
  @include mq(md, min) {
    min-width: 52.5rem;
    max-width: 52.5rem;
    margin: 1.2rem 0 1rem 9rem;
  }
}
.p-companyBlock16__table1 {
  overflow: scroll;
  -ms-overflow-style: none;
  margin: 2rem -4rem 0 0;
  @include mq(md, min) {
    margin: 1.3rem 0 0;
  }
  @at-root {
    .p-companyBlock16__table1 + .p-companyBlock16__table1 {
      margin-top: 5.2rem;
      @include mq(md, min) {
        margin: 4rem 0 0;
      }
    }
    .p-companyBlock16__text1 + .p-companyBlock16__table1 {
      margin-top: 3.4rem;
      @include mq(md, min) {
        margin: 4rem 0 0;
      }
    }
  }
  table {
    border-collapse: collapse;
    width: 100%;
  }
  th,
  td {
    font-size: 2.6rem;
    line-height: (40/26);
    border-top: 1px solid color(gray-300);
    border-bottom: 1px solid color(gray-300);
    white-space: nowrap;
    @include mq(md, min) {
      font-size: 1.6rem;
      line-height: (36/16);
    }
  }
  thead {
    th {
      background: color(gray-200);
      padding: 2.6rem 0 2.5rem;
      border-left: 1px solid color(gray-300);
      @include mq(md, min) {
        min-width: 28rem;
        max-width: 28rem;
        padding: 1.6rem 0;
      }
      &:nth-of-type(1) {
        border-left: none;
      }
    }
  }
  tbody {
    th {
      text-align: left;
      background: color(gray-80);
      padding: 2.5rem 17.8rem 2.5rem 3rem;
      font-weight: normal;
      @include mq(md, min) {
        padding: 1.2rem 2.1rem;
        min-width: 52.5rem;
        max-width: 52.5rem;
      }
      @at-root {
        .p-companyBlock16__table1.-type1 tbody th {
          padding: 2.2rem 4.4rem 2.6rem 3rem;
          @include mq(md, min) {
            padding: 1.5rem 2.2rem 1.8rem 2.2rem;
          }
        }
        .p-companyBlock16__table1.-type2 tbody th {
          min-width: 45.8rem;
          max-width: 45.8rem;
          padding: 2.4rem 2.1rem 2.2rem 3rem;
          @include mq(md, min) {
            min-width: 52.5rem;
            max-width: 52.5rem;
            padding: 1.5rem 2.2rem 1.8rem 2.2rem;
          }
        }
        .p-companyBlock16__table1.-type3 tbody th {
          min-width: 63.5rem;
          max-width: 63.5rem;
          text-align: center;
          padding: 2.5rem 2.1rem 2.5rem 3rem;
          @include mq(md, min) {
            min-width: 69.9rem;
            max-width: 69.9rem;
            padding: 1.3rem 2.2rem 1.8rem 2.2rem;
          }
        }
      }
    }
    td {
      border-left: 1px solid color(gray-300);
      padding: 2.5rem 6.1rem 2.9rem 3rem;
      @include mq(md, min) {
        padding: 1.2rem 2.1rem 1.6rem 2.1rem;
      }
      &:nth-of-type(1) {
        @include mq(md, min) {
          min-width: 29rem;
          max-width: 29rem;
        }
      }
      &:nth-of-type(2) {
        @include mq(md, min) {
          width: 100%;
        }
      }
      @at-root {
        .p-companyBlock16__table1.-type1 tbody td {
          min-width: 23rem;
          max-width: 23rem;
          text-align: center;
          padding: 2.2rem 4.4rem 2.6rem 3rem;
          @include mq(md, min) {
            min-width: 29.2rem;
            max-width: 29.2rem;
            padding: 1.5rem 2.2rem 1.8rem 2.2rem;
          }
        }
        .p-companyBlock16__table1.-type2 tbody td {
          min-width: 27rem;
          max-width: 27rem;
          text-align: center;
          padding: 2.4rem 2.1rem 2.2rem 3rem;
          @include mq(md, min) {
            min-width: 29.2rem;
            max-width: 29.2rem;
            padding: 1.5rem 2.2rem 1.8rem 2.2rem;
          }
        }
        .p-companyBlock16__table1.-type3 tbody td {
          min-width: 63.5rem;
          max-width: 63.5rem;
          text-align: center;
          padding: 2.5rem 2.1rem 2.5rem 3rem;
          @include mq(md, min) {
            min-width: 69.9rem;
            max-width: 69.9rem;
            padding: 1.3rem 2.2rem 1.8rem 2.2rem;
          }
          &[colspan] {
            font-weight: bold;
            background: color(gray-200);
          }
        }
      }
    }
  }
  th {
    font-weight: bold;
    text-align: center;
  }
}
.p-companyBlock16__table1::-webkit-scrollbar {
  display: none;
}
.p-companyBlock16__notes1 {
  margin: 2.8rem 0 0;
  @include mq(md, min) {
    margin: 2.2rem 0 0;
  }
  p {
    font-size: 2.4rem;
    line-height: (40/24);
    text-align: justify;
    @include mq(md, min) {
      font-size: 1.4rem;
      line-height: (26/14);
    }
  }
}

.p-companyBlock16__text3 {
  &:first-child {
    margin-top: 0;
  }
  & > p {
    font-size: 3rem;
    line-height: (60/30);
    text-align: justify;
    margin: 1em 0 0;
    @include mq(md, min) {
      font-size: 1.6rem;
      line-height: (36/16);
    }
    &:first-child {
      margin-top: 0;
    }
    &.-alignRight {
      text-align: right;
    }
    & > small {
      font-size: 2.4rem;
      line-height: (40/24);
      @include mq(md, min) {
        font-size: 1.4rem;
        line-height: (26/14);
      }
    }
    &.-mt0 {
      margin-top: 0;
    }
  }
}
