@use '../../../foundation/config' as *;

.p-irSchedule__smallSection {
  margin-top: 6rem;
  .p-irSchedule__smallSection + & {
    margin-top: 8rem;
    @include mq(md, min) {
      margin-top: 9.4rem;
    }
  }
}

.p-irSchedule__table1 {
  margin-top: 9rem;
  @include mq(md, min) {
    margin-top: 10rem;
  }
}

.c-table.-style1.p-irSchedule__table1 {
  th[scope='row'] {
    font-weight: 700;

    @include mq(md) {
      width: 26rem;
    }
  }
  td {
    text-align: left;
  }
}

.p-irSchedule__table2 {
  margin-top: 9rem;
  @include mq(md, min) {
    margin-top: 0;
  }
  .p-irScheduele__subText {
    font-weight: 700;
  }
  tr{
    height: 10rem;
    @include mq(md) {
      height: 15rem;
    }
    th{
      &.p-irScheduele__table2Hd {
        padding: 0;
        width: 6.1rem;
        letter-spacing: 0.2rem;
        vertical-align: middle;
        text-align: center;
        font-size: 1.7rem;
        @include mq(md) {
          font-size: 2.6rem;
        }
        .quarter {
          height: 10em;
          padding: 1rem;
          writing-mode: vertical-rl;
          vertical-align: bottom;
          text-align: center;
          font-weight: bold;
          .month{
          text-combine-upright: all;
          }
        }
      }
      @at-root .c-table.-style1 .p-irSchedule__table2 {
        th[scope='row'].p-irScheduele__table2Hd {
          text-align: center;
        }
      }
      &:not(.p-irScheduele__table2Hd){
        border-right: none !important;
        background: none !important;
        font-size: 2.3rem;
        @include mq(md) {
          font-size: 2.6rem;
        }
        span{
          font-weight: 700;
        }
      }
    }
  }
}

.p-irSchedule__tabButton {
  @include mq(md) {
    padding-right: 1rem;
    padding-left: 1rem;
  }
}

.p-irSchedule__smallText {
  font-size: 2.4rem;
  line-height: 1.7;
  margin-top: 3rem;
  @include mq(md, min) {
    font-size: 1.4rem;
    margin-top: 2.8rem;
    line-height: 1.8;
  }

  a {
    color: color(gray-600);
    transition-property: color, text-decoration-color;
    transition-duration: 400ms;
    transition-timing-function: easing(easeOutCubic);
    text-decoration: underline;
    text-underline-offset: 0.4rem;

    &:hover {
      color: color(green-600);
      text-decoration-color: rgba(color(gray-600), 0);
    }
  }
}

.p-irSchedule__image {
  width: 105rem;
  margin-right: auto;
  margin-left: auto;

  @include mq(md) {
    width: 100%;
  }

  img {
    width: 100%;
    height: auto;
  }
}

.p-irSchedule__button {
  margin-top: 5rem;

  @include mq(md) {
    text-align: center;
  }

  &.-large {
    margin-top: 8rem;
    text-align: center;
  }
}

.p-irSchedule__tab {
  margin-top: 20rem;
}

.p-irSchedule__tab .p-irSchedule__tabButton {
  padding: 1.994rem 2rem;
  @include mq(md, min) {
    padding: 1.7rem 4rem;
  }
}

.p-irSchedule__textLink {
  color: color(gray-600);
  font-size: 1.6rem;

  @include mq(md) {
    font-size: 2.6rem;
  }

  &:hover {
    color: color(green-600);
  }

  > span {
    margin-right: 1rem;
    transition-property: color, text-decoration-color;
    transition-duration: 400ms;
    transition-timing-function: easing(easeOutCubic);
    text-decoration: underline;
    text-underline-offset: 0.4rem;

    @include mq(md) {
      margin-right: 2rem;
    }
  }

  &:hover > span {
    text-decoration-color: rgba(color(gray-600), 0);
  }
}

.p-irScheduele__arrowIcon {
  flex-shrink: 0;
  width: 1.6rem;
  height: 1.1rem;
  vertical-align: -0.03em;

  @include mq(md) {
    width: 2.4rem;
    height: 1.7rem;
  }
}

.p-irSchedule__table3 {
  margin-top: 1.2rem;
  .c-table.-style1 {
    th[scope='row'] {
      width: 12%;
      padding: 1rem;
      line-height: 1.8;
      text-align: center;
    }
    th:not([scope]) {
      width: 7.33333333333%;
      white-space: nowrap;
    }
    td {
      width: 7.33333333333%;
      position: relative;
      height: 8rem;
      @include mq(md) {
        height: 10rem;
      }
      .info{
        position: absolute;
        top: 1.5rem;
        line-height: 1.2;
        white-space: nowrap;
        text-align: left;
        &.early{
          left: 0.3rem;
        }
        &.mid{
          left: 3rem;
        }
        &.late{
          left: 4.5rem;
        }
        .period{
          font-size: 1.2rem;
          @include mq(md) {
            font-size: 1.7rem;
          }
        }
        .release{
          @include mq(md) {
            font-size: 2rem;
          }
        }
      }
    }
  }
}
