@use '../../../foundation/config' as *;

//共通スタイル
.p-communicationCommunitySupport {
  padding-bottom: 25.2rem;

  @include mq(md) {
    padding-bottom: 24rem;
  }
}

.p-communicationCommunitySupportText {
  font-size: 1.6rem;
  line-height: (36 / 16);

  @include mq(md) {
    font-size: 3rem;
    line-height: (60 / 30);
  }
}

// 最初のメッセージ
.p-communicationCommunitySupport__firstMessage {
  margin-top: 13.9rem;

  @include mq(md) {
    margin-top: 13.1rem;
  }
}

//コンセプト
.p-communicationCommunitySupport__concept {
  margin-top: 18.1rem;

  @include mq(md) {
    margin-top: 17rem;
  }
}

.p-communicationCommunitySupport__conceptWrapper {
  display: flex;
  flex-wrap: wrap;
  margin-top: 5.7rem;
}

.p-communicationCommunitySupport__conceptTitle {
  @include font-serif;
  font-size: 5.6rem;
  line-height: (76 / 56);
  width: 37.5%;

  @include mq(md) {
    width: 100%;
    font-size: 7rem;
    line-height: (90 / 70);
  }
}

.p-communicationCommunitySupport__conceptDescription {
  width: 62.5%;
  border: 1px solid color(gray-300);
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 4.2rem 2rem 3.9rem 0;
  margin-top: 1.7rem;

  @include mq(md) {
    width: 100%;
    margin-top: 6.6rem;
    padding: 3.4rem 1rem 4.7rem 0;

    ol li:nth-child(3) {
      display: flex;
      align-items: flex-start;
      line-height: (50 / 30);
      margin-top: -1rem;

      &::before {
        padding: 1.6rem 2.9rem 1.2rem;
      }
    }
  }

  ol {
    counter-reset: item;
  }

  ol li:not(:last-child) {
    padding-bottom: 1.4rem;

    @include mq(md) {
      padding-bottom: 4.1rem;
    }
  }

  ol li::before {
    content: counter(item);
    counter-increment: item;
    color: color(green-600);
    border: 1px solid color(green-600);
    border-radius: 50%;
    padding: 0.7rem 1.4rem;
    margin-right: 1.5rem;

    @include mq(md) {
      padding: 1.6rem 2.9rem 1.8rem;
      margin-right: 2rem;
    }
  }
}

.p-communicationCommunitySupport__conceptPolicyDetailLong {
  @include mq(md) {
    @include lhCrop(50 / 30);
  }
}

.p-communicationCommunitySupport__conceptPolicy {
  width: 41%;
  padding-left: 3.9rem;

  @include mq(md) {
    width: 100%;
  }
}

.p-communicationCommunitySupport__conceptPolicyDetail {
  width: 59%;
  font-size: 1.6rem;
  line-height: (36 / 16);
  font-weight: bold;

  @include mq(md) {
    width: 100%;
    padding-left: 3.9rem;
    font-size: 3rem;
    line-height: (60 / 30);
    margin-top: 3.9rem;
  }
}

// 復興支援への想い
.p-communicationCommunitySupport__mind {
  margin-top: 19.4rem;
  @include mq(md) {
    margin-top: 18.5rem;
  }
}

.p-communicationCommunitySupport__mindDescription {
  margin-top: 6.2rem;

  @include mq(md) {
    margin-top: 5.1rem;
  }
}

//東日本大震災に関する当社の取り組み
.p-communicationCommunitySupport__activityDetailWrapper {
  border: 1px solid color(gray-300);
  padding: 2.9rem 4.1rem 3rem 4rem;
  margin-top: 4rem;

  @include mq(md) {
    margin-top: 6.3rem;
    padding: 3.6rem 3.1rem 5rem 4rem;
  }
}
.p-communicationCommunitySupport__activity {
  margin-top: 18.2rem;
  @include mq(md) {
    margin-top: 16.9rem;
  }
}

.p-communicationCommunitySupport__activityDescription {
  margin-top: 6.2rem;
  @include mq(md) {
    margin-top: 5.1rem;
  }
}

.p-communicationCommunitySupport__activityDetailItem {
  &:not(:first-child) {
    margin-top: 2.1rem;
  }

  &.-mt23 {
    margin-top: 2.3rem;
  }
}

.p-communicationCommunitySupport__activityDetailItemFlex {
  display: flex;
  flex-wrap: wrap;
}

.p-communicationCommunitySupport__activityDetailItemHeading {
  position: relative;
  padding-left: 2rem;
  font-size: 2rem;
  line-height: (36 / 20);
  font-weight: bold;

  @include mq(md) {
    padding-left: 4rem;
    font-size: 3.4rem;
    line-height: (54 / 34);
  }

  &::before {
    content: '';
    display: inline-block;
    position: absolute;
    top: 1.8rem;
    left: 0;
    width: 0.8rem;
    height: 0.8rem;
    margin-right: 2rem - 0.8rem;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='8px' height='8px'%3E%3Cpath fill-rule='evenodd' fill='rgb(0, 160, 64)' d='M4.000,-0.000 C6.209,-0.000 8.000,1.791 8.000,4.000 C8.000,6.209 6.209,8.000 4.000,8.000 C1.791,8.000 -0.000,6.209 -0.000,4.000 C-0.000,1.791 1.791,-0.000 4.000,-0.000 Z'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-size: contain;
    transform: translateY(-50%);

    @include mq(md) {
      top: 3rem;
      width: 1.6rem;
      height: 1.6rem;
      margin-right: 4rem - 1.6rem;
    }
  }
}

.p-communicationCommunitySupport__activityDetailItemDescription {
  margin-top: 1.1rem;

  &.-mt1 {
    margin-top: 1rem;
  }
}

.p-communicationCommunitySupport__activityDetailItemText {
  width: 75%;
  padding-right: 5rem;
  @include mq(md) {
    width: 100%;
    padding-right: 0;
  }
}

.p-communicationCommunitySupport__activityDetailImage {
  width: 25%;
  margin-top: 5.8rem;
  text-align: right;
  @include mq(md) {
    width: 100%;
    text-align: center;
    margin-top: 4.3rem;

    img {
      width: 100%;
    }
  }
}

.p-communicationCommunitySupport__activityDetailItemLink {
  font-size: 1.6rem;
  line-height: (36 / 16);

  @include mq(md) {
    font-size: 3rem;
    line-height: (60 / 30);
  }

  a {
    color: color(gray-600);
    transition-property: color, text-decoration-color;
    transition-duration: 400ms;
    transition-timing-function: easing(easeOutCubic);
    text-decoration: underline;
    text-underline-offset: 0.4rem;

    &:hover {
      color: color(green-600);
      text-decoration-color: rgba(color(gray-600), 0);
    }
  }

  svg {
    position: relative;
    width: 1.4rem;
    height: 1.4rem;
    margin-left: 0.4rem;
    top: -1rem;

    @include mq(md) {
      width: 2.1rem;
      height: 2.1rem;
      margin-left: 0.7rem;
      top: -1.8rem;
    }
  }
}

.p-communicationCommunitySupport__activityDetailItemNotes {
  font-size: 1.4rem;
  line-height: (26 / 14);
  margin-top: 2.1rem;

  @include mq(md) {
    font-size: 2.4rem;
    line-height: (40 / 24);
  }
}

// お茶っこ会の実施
.p-communicationCommunitySupport__teaPartyConduct {
  margin-top: 19.5rem;
  display: flex;
  flex-wrap: wrap;

  @include mq(md) {
    margin-top: 20rem;
  }
}

.p-communicationCommunitySupport__teaPartyConductText {
  width: 68.9%;
  padding-right: 7.2rem;

  @include mq(md) {
    width: 100%;
    padding-right: 0;
  }
}

.p-communicationCommunitySupport__teaPartyConductDescription {
  margin-top: 6.2rem;

  @include mq(md) {
    margin-top: 5.3rem;
  }
}
.p-communicationCommunitySupport__teaPartyConductImage {
  margin-top: 0.8rem;
  width: 31.1%;

  @include mq(md) {
    width: 100%;
    padding: 2.5rem 7.3rem;

    img {
      width: 100%;
    }
  }
}

//その他の活動
.p-communicationCommunitySupport__other {
  margin-top: 19.2rem;

  @include mq(md) {
    margin-top: 15.7rem;
  }
}

.p-communicationCommunitySupport__otherDescription {
  margin-top: 6.2rem;

  @include mq(md) {
    margin-top: 5.1rem;
  }
}
