@use '../../../foundation/config' as *;

.p-sustainabilityStakeholder {
  padding-bottom: 25.2rem;

  @include mq(md) {
    padding-bottom: 24rem;
  }
}

.p-sustainabilityStakeholder__inner {
  margin-top: 0;
  padding-bottom: 1rem;

  @include mq(md) {
    margin-top: 13.6rem;
    padding-bottom: 0.9rem;
  }
}

.p-sustainabilityStakeholder__section {
  & + & {
    margin-top: 19.4rem;

    @include mq(md) {
      margin-top: 18.4rem;
    }
  }
}

.p-sustainabilityStakeholder__body {
  .c-heading.-level4 + & {
    margin-top: 2.5rem;

    @include mq(md) {
      margin-top: 3.6rem;
    }
  }

  .c-heading.-level2 + & {
    margin-top: 7.1rem;

    @include mq(md) {
      margin-top: 9.1rem;
    }
  }
}

.p-sustainabilityStakeholder__smallSection {
  &:not(:first-child) {
    margin-top: 9.4rem;

    @include mq(md) {
      margin-top: 9rem;
    }
  }
  &.-adjust__table3 {
    .c-heading.-level5 {
      margin-top: 4rem;
    }
  }
}

.p-sustainabilityStakeholder__text {
  @include lhCrop(2.15);
  font-size: 1.6rem;
  font-weight: normal;
  line-height: 2.25;

  @include mq(md) {
    @include lhCrop(2);
    font-size: 3rem;
    line-height: 2;
  }

  &.-normal {
    &::before,
    &::after {
      display: none;
    }
  }

  .c-heading.-level3 + & {
    margin-top: 5rem;
  }

  .p-sustainabilityStakeholder__box + & {
    margin-top: 8rem;
  }

  .p-sustainabilityStakeholder__imageList + &,
  .p-sustainabilityStakeholder__image + & {
    margin-top: 2.5rem;

    @include mq(md) {
      margin-top: 4.5rem;
    }
  }
}

.p-sustainabilityStakeholder__note {
  @include lhCrop(2);
  font-size: 1.4rem;
  font-weight: normal;
  line-height: 2.25;

  @include mq(md) {
    @include lhCrop(1.8);
    font-size: 2.4rem;
    line-height: 1.9;
  }

  &.-normal {
    &::before,
    &::after {
      display: none;
    }
  }

  .p-sustainabilityStakeholder__expertList + & {
    margin-top: 3.8rem;
  }
}

.p-sustainabilityStakeholder__list {
  font-size: 1.6rem;
  line-height: (36 / 16);

  @include mq(md) {
    font-size: 3rem;
    line-height: 2;
  }

  li {
    position: relative;
    padding-left: 2rem;

    @include mq(md) {
      padding-left: 3rem;
    }

    + li {
      margin-top: 3.6rem;

      @include mq(md) {
        margin-top: 6.1rem;
      }
    }

    &::before {
      content: '';
      display: inline-block;
      position: absolute;
      top: 1.8rem;
      left: 0;
      width: 0.8rem;
      height: 0.8rem;
      margin-right: 2rem - 0.8rem;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='8px' height='8px'%3E%3Cpath fill-rule='evenodd' fill='rgb(0, 160, 64)' d='M4.000,-0.000 C6.209,-0.000 8.000,1.791 8.000,4.000 C8.000,6.209 6.209,8.000 4.000,8.000 C1.791,8.000 -0.000,6.209 -0.000,4.000 C-0.000,1.791 1.791,-0.000 4.000,-0.000 Z'/%3E%3C/svg%3E");
      background-repeat: no-repeat;
      background-size: contain;
      transform: translateY(-50%);

      @include mq(md) {
        top: 3rem;
        width: 1.6rem;
        height: 1.6rem;
        margin-right: 4rem - 1.6rem;
      }
    }
  }

  .p-sustainabilityStakeholder__text + & {
    margin-top: 4rem;

    @include mq(md) {
      margin-top: 6rem;
    }
  }

  & + .p-sustainabilityStakeholder__text {
    margin-top: 4rem;

    @include mq(md) {
      margin-top: 6.5rem;
    }
  }

  &.-adjust {
    li {
      &::before {
        @include mq(md, min) {
          position: unset;
          top: 0;
          margin-left: -2rem;
        }
      }
      ul li {
        &::before {
          display: none;
        }
        span {
          margin-left: -1em;
        }
      }
    }
  }
}

.p-sustainabilityStakeholder__expert {
  margin-top: 8rem;

  @include mq(md) {
    margin-top: 5rem;
  }

  &.-adjust {
    margin-top: 4rem;

    .p-sustainabilityStakeholder__expertList {
      @include mq(md, min) {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        width: 105rem;
      }
    }

    .p-sustainabilityStakeholder__expertItem2 {
      display: flex;
      margin-top: 8rem;

      @include mq(md, min) {
        width: 52rem;
        margin-top: 4rem;
      }
    }

    .p-sustainabilityStakeholder__expertBody {
      padding: 0 1rem 0 2rem;
      @include mq(md) {
        padding: 0 0 0 2rem;
      }
    }

    .p-sustainabilityStakeholder__expertName {
      margin-bottom: 3rem;
        @include mq(md, min) {
          margin-bottom: 1.5rem;
        }
      }

    .p-sustainabilityStakeholder__expertText {
      margin-top: 0;
      font-size: 2.4rem;
      line-height: 1.6;

      @include mq(md, min) {
        font-size: 1.6rem;
      }
    }
  }
}

.p-sustainabilityStakeholder__expertList {
  @include mq(md, min) {
    display: flex;
    flex-wrap: wrap;
    width: 98rem;
  }
}

.p-sustainabilityStakeholder__expertItem {
  display: flex;

  @include mq(md, min) {
    &:nth-child(odd) {
      width: 42.8rem;
    }

    &:nth-child(even) {
      flex: 1 0 0%;
    }

    &:nth-child(n + 3) {
      margin-top: 4rem;
    }
  }

  & + & {
    @include mq(md) {
      margin-top: 8rem;
    }
  }
}

.p-sustainabilityStakeholder__expertImage {
  img {
    width: 13.5rem;
    height: auto;

    @include mq(md) {
      width: 25rem;
    }
  }
}

.p-sustainabilityStakeholder__expertBody {
  padding-left: 2rem;
  padding-right: 4.8rem;
  flex: 1 0 0%;

  @include mq(md) {
    padding-left: 3rem;
    padding-right: 0;
  }
}

.p-sustainabilityStakeholder__expertName {
  @include lhCrop(2.25);
  line-height: 2.25;
  font-size: 2rem;
  font-weight: bold;

  @include mq(md) {
    font-size: 4rem;
  }
}

.p-sustainabilityStakeholder__expertText {
  margin-top: 0.7rem;
  font-size: 1.6rem;
  line-height: 2.25;

  @include mq(md) {
    margin-top: 3rem;
    font-size: 3rem;
    line-height: 2;
  }
}

.p-sustainabilityStakeholder__box {
  margin-top: 8rem;

  .c-box__body + .c-box__heading {
    margin-top: 4.8rem;

    @include mq(md) {
      margin-top: 8.2rem;
    }
  }

  .p-sustainabilityStakeholder__note {
    margin-top: 1.5rem;
    line-height: 2.45;

    @include mq(md, min) {
      text-align: right;
    }
  }

  br.-adjust {
    @include mq(832px, min) {
      display: none;
    }
  }
}

.p-sustainabilityStakeholder__imageList {
  @include mq(md, min) {
    display: flex;
    justify-content: space-between;
  }

  .c-heading.-level3 + & {
    margin-top: 8rem;

    @include mq(md) {
      margin-top: 5rem;
    }
  }

  .p-sustainabilityStakeholder__text + & {
    margin-top: 8rem;
  }

  &.-adjust {
    margin-top: 5rem;
  }
}

.p-sustainabilityStakeholder__image {
  @include mq(md, min) {
    width: 47%;
  }

  @include mq(md) {
    padding-right: 7.5rem;
    padding-left: 7.5rem;
  }

  & + & {
    @include mq(md) {
      margin-top: 7.8rem;
    }
  }

  img {
    width: 100%;
    height: auto;
  }

  .c-heading.-level3 + & {
    margin-top: 8rem;

    @include mq(md) {
      margin-top: 5rem;
    }
  }
}

.p-sustainabilityStakeholder {
  &__table1 {
    &.-style4 {
      tbody {
        th, td {
          padding: 2rem;
          @include mq(md) {
            padding: 4rem 2rem;
          }
        }
        td[scope='row'] {
          background-color: color(gray-80);
        }
        .-alignCenter {
          text-align: center;
        }
      }
    }
    .c-table__spSize {
      @include mq(md) {
        width: 133rem;
      }
    }
  }
  &__table2 {
    .-style3 {
      tbody {
        th[scope='row'] {
          background-color: color(gray-80);
          @include mq(md, min) {
            width: 14rem;
          }
        }
        .-alignCenter {
          text-align: center;
        }
      }
    }
  }
  &__table3 {
    margin-top: 1rem;
    th[scope='row'] {
      width: 17%;
    }
    @include mq(md) {
      margin-top: 2rem;
      font-size: 3rem;
      th[scope='row'] {
        width: 20%;
      }
    }
    &.-style1 {
      th[scope='row'] {
        font-weight: 700;
      }
      td {
        text-align: left;
      }
    }
    .c-list {
      margin-bottom: 0;
    }
    .c-list li li,
    .c-list li .c-list li {
      margin-left: 0;
    }
  }
}
