@use '../../foundation/config' as *;

.c-button {
  display: inline-flex;
  align-items: center;
  max-width: 100%;
  border: 1px solid;
  font-weight: bold;
  text-align: left;
  line-height: 1.15;

  &:hover {
    color: color(green-600);
  }

  &.-medium {
    justify-content: space-between;
    width: 27rem;
    font-size: 1.6rem;
    padding: 0 0 0 3rem;
    transition-property: box-shadow, color;
    transition-duration: 400ms;
    transition-timing-function: easing(easeOutCubic);

    @include mq(md) {
      width: 100%;
      font-size: 3rem;
    }
    &.-auto {
      width: auto;
      @include mq(md) {
        width: 100%;
      }
      .c-button__label {
        padding-right: 3rem;
      }
    }
    &:hover {
      box-shadow: inset 0 0 0 1px;
    }
  }

  &.-large:not(.-plain) {
    justify-content: space-between;
    width: 105rem;
    padding: 3rem 3rem 3rem 4rem;
    border: 0;
    background-color: color(gray-200);
    font-size: 2rem;
    transition: color 400ms easing(easeOutCubic);

    @include mq(md) {
      width: 100%;
      padding: 4.468rem 4rem;
      font-size: 3.4rem;
      line-height: (54 / 34);
    }
  }

  &.-small:not(.-pdf) {
    justify-content: space-between;
    padding: 1.1rem 1.9rem;
    width: 18rem;
    font-size: 1.4rem;
    transition-property: box-shadow, color;
    transition-duration: 400ms;
    transition-timing-function: easing(easeOutCubic);

    @include mq(md) {
      width: 31.8rem;
      padding: 2.294rem 2.8rem;
      font-size: 2.6rem;
    }
    &.-auto {
      width: auto;
      @include mq(md) {
        width: 100%;
      }
      .c-button__label {
        padding-right: 3rem;
      }
    }

    &:hover {
      box-shadow: inset 0 0 0 1px;
    }
  }

  &.-plain {
    padding: 0;
    border: 0;
    font-size: 1.6rem;
    line-height: (36 / 16);
    transition: color 400ms easing(easeOutCubic);

    @include mq(md) {
      font-size: 2.6rem;
      line-height: (60 / 26);
    }
  }

  &.-plain.-large {
    justify-content: center;
    width: 100%;
    padding: 3.15rem 4rem;
    border-top: 1px solid color(gray-300);
    border-bottom: 1px solid color(gray-300);

    @include mq(md) {
      padding: 3.387rem 4rem;
    }
  }

  &.-pdf {
    justify-content: space-between;
    border: 0;
    background-color: color(gray-200);
    transition: color 400ms easing(easeOutCubic);
  }

  &.-pdf.-small {
    width: 57rem;
    padding: 0 3rem;
    font-size: 1.6rem;

    &.-auto {
      width: auto;
      @include mq(md, min) {
        padding: 0 1.2rem 0 3rem;
      }
    }

    &.-adjusted {
      @media (min-width: 751px) and (max-width: 904px) {
        width: 65rem;
      }
    }
    &.-lineHight_2x {
      @include mq(md) {
        line-height: 4;
      }
    }

    @include mq(md) {
      width: 100%;
      padding: 1.98rem 4rem;
      font-size: 3rem;
      line-height: (60 / 30);
    }
  }

  &.-pdf.-smallWide {
    width: 67rem;
    padding: 0 3rem;
    font-size: 1.6rem;

    &.-adjusted {
      @media (min-width: 751px) and (max-width: 914px) {
        width: 78rem;
      }
    }

    @include mq(md) {
      width: 100%;
      padding: 1.98rem 4rem;
      font-size: 3rem;
      line-height: (60 / 30);
    }
  }

  &.-pdf.-wide {
    width: 90rem;
    padding: 0 3rem;
    font-size: 1.6rem;

    &.-adjusted {
      @media (min-width: 751px) and (max-width: 849px) {
        width: 100rem;
      }
      @media (min-width: 1000px) {
        width: 80rem;
      }
    }

    @include mq(md) {
      width: 100%;
      padding: 1.98rem 4rem;
      font-size: 3rem;
      line-height: (60 / 30);
    }
  }

  &.-image {
    justify-content: flex-start;
    width: 105rem;
    padding: 3rem;
    font-size: 2rem;
    line-height: 2;

    @include mq(md) {
      width: 100%;
      padding: 4rem;
      font-size: 3.4rem;
      line-height: (54 / 34);
    }
  }
}

.c-button__separator {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  width: 6rem;
  height: 6rem;
  transition: color 400ms easing(easeOutCubic);

  .c-button.-medium & {
    border-left: 1px solid;

    @include mq(md) {
      width: calc(12rem - 2px);
      height: calc(12rem - 2px);
    }
  }

  .c-button.-large & {
    border: 1px solid;
    color: color(gray-450);

    @include mq(md) {
      width: 8rem;
      height: 8rem;
      margin-left: 5.6rem;
    }
  }

  .c-button:hover & {
    color: color(green-600);
  }

  .c-button.-large:hover &,
  .c-button.-pdf.-image:hover & {
    border-width: 2px;
  }

  .c-button.-image & {
    margin-top: auto;
    border: 1px solid;

    @include mq(md) {
      width: 8rem;
      height: 8rem;
    }
  }

  .c-button.-image:not(:hover) & {
    color: color(gray-450);
  }
}

.c-button__arrow {
  position: relative;
  flex-shrink: 0;
  width: 1.6rem;
  height: 1.1rem;
  transition: color 400ms easing(easeOutCubic);

  @include mq(md) {
    width: 2.4rem;
    height: 1.7rem;
  }

  .c-button:hover & {
    color: color(green-600);
  }

  .c-button.-large:not(.-plain):not(:hover) &,
  .c-button.-pdf:not(:hover) & {
    color: color(gray-450);
  }

  .c-button.-small:not(.-pdf) & {
    width: 1.1rem;
    height: 0.8rem;

    @include mq(md) {
      width: 1.7rem;
      height: 1.2rem;
    }
  }

  .c-button.-plain & {
    margin-left: 1.1rem;

    @include mq(md) {
      margin-left: 2.2rem;
    }
  }
}

.c-button__external {
  position: relative;
  flex-shrink: 0;
  width: 1.4rem;
  height: 1.4rem;
  transition: color 400ms easing(easeOutCubic);

  @include mq(md) {
    width: 2.1rem;
    height: 2.1rem;
  }

  .c-button:hover & {
    color: color(green-600);
  }

  .c-button.-large:not(.-plain):not(:hover) &,
  .c-button.-pdf:not(:hover) & {
    color: color(gray-450);
  }

  .c-button.-plain & {
    margin-left: 1.1rem;

    @include mq(md) {
      margin-left: 2.2rem;
    }
  }
}

.c-button__textGroup {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-grow: 1;

  @include mq(md) {
    align-self: flex-start;
    margin-top: calc((1 - 54 / 34) * 0.5em);
    margin-right: -8rem;
  }
}

.c-button__text {
  margin-right: 2rem;

  .c-button.-pdf.-large & {
    margin-right: 3rem;
  }
}

.c-button__pdf {
  display: inline-block;
  padding: 0.45rem 2.807rem;
  border-radius: 12.5rem;
  border: 1px solid;
  color: color(gray-600);
  @include font-barlow;
  font-size: 1.4rem;

  @include mq(md) {
    padding: 0.1rem 2.652rem;
    font-size: 2.4rem;
    line-height: 1.2;
    vertical-align: 0.2em;
  }

  .c-button.-pdf.-large & {
    line-height: 1;
  }

  .c-button.-image & {
    line-height: 1;

    @include mq(md) {
      margin-top: 1rem;
    }
  }
}

.c-button__image {
  flex-shrink: 0;
  margin-right: 2.4rem;

  > img {
    @include mq(md) {
      width: 22rem;
      height: 29.2rem;
    }
  }
}

.c-linkButton {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: fit-content;
  min-width: 27rem;
  min-height: 6rem;
  margin: 2.5rem 0;
  padding: 1em 8.8rem 1em 3rem;
  font-size: 1.6rem;
  font-weight: bold;
  line-height: 1.15;
  transition: 400ms;
  @include mq(md) {
    width: 100%;
    min-height: calc(12rem - 2px);
    margin: 1em 0;
    padding: 3rem;
    font-size: 2.6rem;
  }
  &.-radius {
    border: .3rem solid color(green-600);
    border-radius: 10rem;
  }
  &.-internal {
    &::after {
      position: absolute;
      right: 2rem;
      content: "";
      width: 1.6rem;
      height: 1.6rem;
      background-color: color(gray-600);
      mask-image: url('data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%20viewBox%3D%220%200%2016%2011%22%3E%3Cpath%20d%3D%22M10.4%209.53674e-07L9.4%201.1L13.8%205.4L9.3%209.6L10.3%2010.7L15.2%205.9C15.3%205.8%2015.4%205.6%2015.4%205.4C15.4%205.2%2015.3%205%2015.2%204.9L10.4%209.53674e-07Z%22%2F%3E%3Cpath%20d%3D%22M14.8%206.1H0V4.6H14.8V6.1Z%22%2F%3E%3C%2Fsvg%3E');
      mask-repeat: no-repeat;
      mask-position: center;
      transition: 400ms;
      @include mq(md) {
        right: calc(5rem - 1px);
        width: 2rem;
        height: 2rem;
      }
    }
  }
  &.-external {
    &::after {
      position: absolute;
      right: 2rem;
      content: "";
      width: 1.6rem;
      height: 1.6rem;
      background-color: color(gray-600);
      mask-image: url('data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%20viewBox%3D%220%200%2018%2018%22%3E%3Cpath%20fill%3D%22%2397938e%22%20d%3D%22M18%206.85075H15.9699V2.01493H11.0977V0L17.0526%200C17.3233%200%2017.594%200.134328%2017.7293%200.268657C17.8647%200.402985%2018%200.671642%2018%200.940299V6.85075Z%22%2F%3E%3Cpath%20fill%3D%22%2397938e%22%20d%3D%22M16.2238%200.327817L7.22826%209.25627L8.66372%2010.681L17.6593%201.75257L16.2238%200.327817Z%22%2F%3E%3Cpath%20fill%3D%22%2397938e%22%20d%3D%22M15.1579%2018H1.08271C0.541353%2018%200%2017.597%200%2016.9254V2.95523C0%202.41791%200.406015%201.8806%201.08271%201.8806H8.1203V4.02985H2.03008V15.9851H14.0752V10.0746H16.1053V17.0597C16.1053%2017.597%2015.6992%2018%2015.1579%2018Z%22%2F%3E%3C%2Fsvg%3E');
      mask-repeat: no-repeat;
      mask-position: center;
      transition: 400ms;
      @include mq(md) {
        right: calc(5rem - 1px);
        width: 2rem;
        height: 2rem;
      }
    }
  }
  &.-others {
    &::after {
      position: absolute;
      right: 2rem;
      content: "";
      width: 1.6rem;
      height: 1.6rem;
      background-color: color(gray-600);
      mask-image: url('data:image/svg+xml;base64,PGRlZnM+PHN5bWJvbCBpZD0iaWNvbi1vdGhlciIgdmlld0JveD0iMCAwIDMwIDYiPjxwYXRoIGQ9Ik0xNDMzLDQ3YTMsMywwLDEsMS0zLDNBMywzLDAsMCwxLDE0MzMsNDdabTEyLDBhMywzLDAsMSwxLTMsM0EzLDMsMCwwLDEsMTQ0NSw0N1ptMTIsMGEzLDMsMCwxLDEtMywzQTMsMywwLDAsMSwxNDU3LDQ3WiIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTE0MzAgLTQ3KSIvPjwvc3ltYm9sPjwvZGVmcz48c3ZnIGNsYXNzPSJjLWJ1dHRvbl9fYXJyb3ciIGZvY3VzYWJsZT0iZmFsc2UiPjx1c2UgeGxpbms6aHJlZj0iI2ljb24tb3RoZXIiPjwvdXNlPjwvc3ZnPg==');
      mask-repeat: no-repeat;
      mask-position: center;
      transition: 400ms;
      @include mq(md) {
        right: calc(5rem - 1px);
        width: 2rem;
        height: 2rem;
      }
    }
  }
  &.-print {
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-width: 14rem;
    padding: 1em 2rem;
    @include mq(md) {
      min-width: 20rem;
      min-height: unset;
      padding: 1em 2rem!important;
    }
    &::after {
      content: "";
      width: 2.4rem;
      height: 2.4rem;
      background-color: color(gray-600);
      mask-image: url('data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%2024%2024%22%3E%20%3Cpath%20d%3D%22M18%207H6V3h12v4Zm0%205.5q.425%200%20.713-.288T19%2011.5q0-.425-.288-.713T18%2010.5q-.425%200-.713.288T17%2011.5q0%20.425.288.713T18%2012.5ZM16%2019v-4H8v4h8Zm2%202H6v-4H2v-6q0-1.275.875-2.138T5%208h14q1.275%200%202.138.863T22%2011v6h-4v4Z%22%2F%3E%3C%2Fsvg%3E');
      mask-repeat: no-repeat;
      mask-position: center;
      transition: 400ms;
      @include mq(md) {
        width: 5rem;
        height: 5rem;
      }
    }
  }
  &.-underline {
    min-width: initial;
    min-height: initial;
    padding: 0;
    color: color(gray-600);
    font-weight: normal;
    text-decoration: underline;
    text-underline-offset: 0.4rem;
    @include mq(md) {
      width: fit-content;
      justify-content: flex-start;
    }
    &::after {
      position: initial;
      right: initial;
      margin-left: 1rem;
    }
  }
  &.-background {
    background-color: color(gray-200);
    @include mq(md) {
      padding: 3rem 12rem 3rem 3rem;
    }
    &.-green {
      color: color(white);
      background-color: color(green-600);
      &.-internal, &.-external, &.-print {
        &::after {
          background-color: color(white);
        }
      }
    }
  }
  &.-border {
    border: 1px solid color(black);
    @include mq(md) {
      padding: 3rem 14.6rem 3rem 3rem;
    }
    &.-internal,
    &.-external {
      &::after {
        background-color: color(black);
      }
    }
    &::before {
      content: "";
      position: absolute;
      right: 5.6rem;
      display: block;
      width: 1px;
      height: 100%;
      border-left: 1px solid color(black);
      @include mq(md) {
        right: calc(12rem - 2px);
      }
    }
  }
  &.-txtpos-c{
    display: inline-block;
    text-align: center;
    padding: 1em 3rem;
    @include mq(md) {
      padding: 3rem;
    }
  }
  &:hover {
    color: color(green-600);
    &.-border {
      border: 1px solid color(green-600);
      box-shadow: inset 0 0 0 1px;
      &::before {
        border-left: 1px solid color(green-600);
      }
    }
    &.-underline {
      text-decoration-color: rgba(color(gray-600), 0);
    }
    &.-internal,
    &.-external,
    &.-print {
      &::after {
        background-color: color(green-600);
      }
    }
    &.-green {
      color: color(green-600);
      background-color: color(white);
      &.-internal, &.-external, &.-print {
        &::after {
          background-color: color(green-600);
        }
      }
    }
  }
}

.c-socialButton {
  display: flex;
  align-items: center;
  font-size: 1.6rem;
  line-height: (60/16);
  font-weight: bold;
  color: color(gray);
  transition: color 400ms easing(easeOutCubic);
  &:hover {
    color: color(green-600);
  }
  .c-socialButton_icon {
    width: 2rem;
    height: 2rem;
    margin-right: 1.5rem;
  }
}
