@use '../../../foundation/config' as *;

.p-news__inner {
  padding-bottom: 16rem;
}

.p-news__headerUpper {
  @include mq(md, min) {
    padding-top: 5rem;
    margin-bottom: 4.3rem;
  }

  @include mq(md) {
    margin-right: -4rem;
    margin-left: -4rem;
    // background-color: color(gray-240);
    overflow: hidden;
    margin-bottom: 6rem;
  }
}

.p-news__rss {
  margin-right: -2rem;
  font-size: 1.6rem;
  font-weight: bold;
  text-align: right;

  @include mq(md) {
    padding-right: 4rem;
    padding-left: 4rem;
    border-bottom: 1px solid color(gray-350);
    font-size: 3rem;
  }
}

.p-news__rssLink {
  display: inline-flex;
  align-items: center;
  padding: 1.283rem 2rem;
  color: color(gray-600);
  transition: color 400ms easing(easeOutCubic);

  @include mq(md) {
    padding: 3.19rem 2rem;
  }

  &:hover {
    color: color(green-600);
  }
}

.p-news__rssIcon {
  width: 1.3rem;
  height: 1.3rem;
  margin-right: 1.3rem;

  @include mq(md) {
    width: 2.3rem;
    height: 2.3rem;
    margin-right: 1.8rem;
  }
}

.p-news__category {
  margin-top: 2rem;
}

.p-news__categoryList {
  display: flex;

  @include mq(md) {
    display: none;
  }
}

.p-news__categoryItem {
  flex: 1;
  background-color: color(gray-200);
  font-size: 1.6rem;
  font-weight: bold;

  &:not(:last-child) {
    margin-right: 1px;
  }
}

.p-news__categoryLink {
  display: block;
  padding: 2.3rem 3.7rem;
  transition: color 400ms easing(easeOutCubic);

  &:hover {
    color: color(green-600);
  }

  // 現在のページ
  &[aria-current]:not([aria-current='false']) {
    position: relative;
    color: color(green-600);

    &::before {
      content: '';
      display: block;
      position: absolute;
      top: 50%;
      left: 2rem;
      width: 0.375em;
      height: 0.375em;
      background-color: currentColor;
      transform: translateY(-50%);
    }
  }
}

.p-news__categorySelect {
  padding: 4.8rem 4rem 5rem;
  border-bottom: 1px solid color(gray-350);

  @include mq(md, min) {
    display: none;
  }
}

.p-news__categorySelectWrapper {
  display: flex;
  position: relative;
  align-items: center;
}

.p-news__categorySelectForm {
  width: 100%;
  padding: 1.096em 16rem 1.096em 4rem;
  border: 0;
  border-radius: inherit;
  background-color: color(gray-200);
  font-size: 3rem;
  font-weight: bold;
  appearance: none;

  &:focus {
    outline: none;
  }

  html[data-whatintent]:not([data-whatintent='mouse']):not([data-whatintent='touch'])
    &:focus {
    box-shadow: 0 0 0 1px color(black);
  }
}

.p-news__categorySelectAppearance {
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
  justify-content: center;
  align-items: center;
  width: 10rem;
  height: 100%;
  background-color: color(black);
  pointer-events: none;
}

.p-news__categorySelectIcon {
  width: 1.2rem;
  height: 2.3rem;
  color: color(white);
  transform: rotate(90deg);
}

.p-news__smallCategory {
  // background-color: color(gray-240);

  ::-webkit-scrollbar {
    display: none;
  }

  // スクロールバーのスタイル
  .simplebar-scrollbar {
    height: 0.4rem;

    @include mq(md) {
      height: 0.8rem;
    }

    &::before {
      background-color: color(black);
      border-radius: 0.2rem;

      @include mq(md) {
        border-radius: 0.4rem;
      }
    }
  }

  // スクロールバーの背景
  .simplebar-track {
    background-color: color(gray-420);
  }

  .simplebar-track.simplebar-horizontal {
    height: 0.4rem;

    @include mq(md) {
      height: 0.8rem;
    }
  }

  .simplebar-track.simplebar-horizontal .simplebar-scrollbar:before {
    height: 56%;
    left: 0;
    right: 0;
  }

  .simplebar-track.simplebar-horizontal .simplebar-scrollbar {
    top: 0;
  }

  // デフォルトだとスクロールバーに透過がかかっているので、透過を無くしたい場合はopacity: 1;を追加する
  .simplebar-scrollbar.simplebar-visible::before {
    opacity: 1;
  }
}

.p-news__smallCategoryList {
  display: flex;
  padding: 3.5rem 3rem 3.1rem 0;

  @include mq(md) {
    padding: 4.3rem 4rem 3.7rem;
  }
}

.p-news__smallCategoryItem {
  flex-shrink: 0;

  & + & {
    margin-left: 1rem;
  }

  &:last-child {
    padding-right: 3rem;
  }
}

.p-news__smallCategoryButton {
  padding: 0.3em 2.9rem 0.416em;
  border: 1px solid color(gray-300);
  border-radius: 1.5rem;
  background-color: color(white);
  font-size: 1.4rem;
  transition: color 400ms easing(easeOutCubic);

  @include mq(md) {
    padding: 0.6em 3.876rem 0.646em;
    border-radius: 3rem;
    font-size: 2.4rem;
  }

  &:hover {
    color: color(green-600);
  }

  &[aria-selected='true'] {
    color: color(green-600);
    border-color: currentColor;
    box-shadow: inset 0 0 0 0.1rem;
  }
}

.p-news__selectYear {
  width: 32rem;
  margin-top: 7rem;

  @include mq(md) {
    width: 34.8rem;
  }

  .-whatsnew & {
    @include mq(md, min) {
      margin-top: 7rem;
    }
  }

  .c-select {
    width: 100%;
  }

  .c-select__form {
    padding-top: 1.313em;
    padding-bottom: 1.313em;

    @include mq(md) {
      padding: 0.864em 16rem 0.864em 4rem;
    }
  }

  .c-select__appearance {
    @include mq(md) {
      width: 8.966rem;
    }
  }
}

.p-news__empty {
  margin-top: 7rem;
  padding-top: 3.1rem;
  padding-bottom: 3.1rem;
  border-top: 1px solid color(gray-300);
  border-bottom: 1px solid color(gray-300);

  @include mq(md) {
    margin-top: 8rem;
    padding-top: 3.4rem;
    padding-bottom: 3.4rem;
  }
}

.p-news__emptyText {
  font-size: 1.6rem;
  line-height: (36 / 16);

  @include mq(md) {
    font-size: 3rem;
    line-height: 2;
  }
}

.p-news__article {
  margin-top: 10rem;
  .news-ir &{
  margin-top: 0;
  }
  .news-release &{
    margin-top: 0;
  }
}


.p-news__pagination {
  margin-top: 8rem;

  @include mq(md) {
    margin-top: 4rem;
  }
}


.p-news__link{
  @include mq(md, min) {
    margin-bottom: 2.5rem;
  }
  @include mq(md) {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 4rem 0 3rem 0;
  }
  .c-linkButton{
    margin: auto 0 auto auto;
    min-width: 17rem;
    @include mq(md) {
      font-size: 2.8rem;
      margin: 0 2rem 0 auto;
    }
  }
}

.p-news__search{
  display: flex;
  align-items: center;
  @include mq(md) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }
  &.-keyword{
    background-color: #e4e4e2;;
    @include mq(md, min) {
      padding: 2rem 0 2rem 0;
      margin-bottom: 0.833rem;
    }
    @include mq(md) {
      padding: 5.14rem 4.57rem 4rem 4.57rem;
      margin-bottom: 3rem;
    }
    @at-root body.news-ir .p-news__search.-keyword{
      @include mq(md, min) {
        margin-bottom: 4.3rem;
      }
    }
  }
  &.-smallcat{
    @include mq(md, min) {
      margin-bottom: 0.833rem;
    }
    @include mq(md) {
      margin-bottom: 3rem;
    }
  }
  &.-period{
    @include mq(md, min) {
    }
    @include mq(md) {
      padding: 0 4.57rem;
    }
  }

  .label{
    font-weight: bold;
    @include mq(md, min) {
      width: 22rem;
      min-width: 22rem;
      padding: 0 3.9rem;
      font-size: 1.6rem;
      white-space: nowrap;
    }
    @include mq(md) {
      width: 100%;
      font-size: 3rem;
      line-height: 1;
      margin-bottom: 3rem;
    }
    &.label-smallcat{
      @include mq(md, min) {
        padding-top: 1.8rem;
        padding-bottom: 1.8rem;
      }
      @include mq(md) {
        padding: 0 4.57rem;
        margin-bottom: 0;
      }
    }
  }
  .content{
    width: 100%;
    @include mq(md) {
      width: 100%;
      // padding: 0 4rem;
    }
    &.--fill-sp{
      @include mq(md) {
        margin: 0 calc(50% - 50vw);
        width: 100vw;
      }
    }
    .p-news__smallCategoryList {
      display: flex;
      padding: 1.5rem 3rem 1.5rem 0;

      @include mq(md) {
        padding: 3rem 4rem 3rem;
      }
    }

    .p-news__selectYear{
      margin: 0;
    }
    .eir_controller_year{
      margin-top: 0;
    }
  }

  //検索box
  .p-newsChildMenu__searchGroup {
    display: flex;
    width: 75rem;

    @include mq(md) {
      width: 100%;
    }

    .p-news__searchButton[aria-expanded='true'] + .p-newsChildMenu & {
      transform: translateY(0);
      visibility: visible;
      transition: transform 400ms easing(easeOutQuart);
    }
  }

  .p-newsChildMenu__input {
    flex: 1 1 65rem;
    padding: 3.8rem 4rem;
    border-radius: 0;
    border: 1px solid transparent;
    background-color: color(gray-250);
    font-size: 1.6rem;
    line-height: 1.5;
    appearance: none;

    @include placeholder {
      color: color(black);
    }

    @include mq(md) {
      width: calc(100% - 15rem);
      padding: 4.882rem 4rem;
      font-size: 3.2rem;
    }

    &:focus {
      outline: none;
    }

    &::-webkit-search-cancel-button {
      -webkit-appearance: none;
    }
  }

  .p-newsChildMenu__submit {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 10rem;
    background-color: color(black);
    color: color(white);
    font-size: 1.6rem;
    font-weight: bold;

    @include mq(md) {
      flex-shrink: 0;
      width: 15rem;
      font-size: 3.2rem;
    }
  }

  .p-newsChildMenu__searchGroup{
    display: flex;
    width: 75rem;
    @include mq(md) {
      width: 100%;
    }
  }
  .p-newsChildMenu__input{
    background-color: #ffff;
    padding: 1.313em 3rem;
    line-height: unset;
    @include mq(md) {
      padding: 1.096em 4rem 1.096em 4rem;
    }
  }
  .p-newsChildMenu__submit{
    position: relative;
    @include mq(md, min) {
      width: 10rem;
    }
    @include mq(md) {
      width: 15.43rem;
    }
    &::after {
      content: "";
      background-color: #ffff;
      mask-image: url('data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%20viewBox%3D%220%200%2018%2018%22%3E%3Cpath%20fill%3D%22%2397938e%22%20d%3D%22M18%206.85075H15.9699V2.01493H11.0977V0L17.0526%200C17.3233%200%2017.594%200.134328%2017.7293%200.268657C17.8647%200.402985%2018%200.671642%2018%200.940299V6.85075Z%22%2F%3E%3Cpath%20fill%3D%22%2397938e%22%20d%3D%22M16.2238%200.327817L7.22826%209.25627L8.66372%2010.681L17.6593%201.75257L16.2238%200.327817Z%22%2F%3E%3Cpath%20fill%3D%22%2397938e%22%20d%3D%22M15.1579%2018H1.08271C0.541353%2018%200%2017.597%200%2016.9254V2.95523C0%202.41791%200.406015%201.8806%201.08271%201.8806H8.1203V4.02985H2.03008V15.9851H14.0752V10.0746H16.1053V17.0597C16.1053%2017.597%2015.6992%2018%2015.1579%2018Z%22%2F%3E%3C%2Fsvg%3E');
      mask-repeat: no-repeat;
      mask-position: center;
      transition: 400ms;
      @include mq(md, min) {
        width: 1.6rem;
        height: 1.6rem;
        margin-left: 1rem;
      }
      @include mq(md) {
        width: 2.5rem;
        height: 2.5rem;
        margin-left: 1rem;
      }
    }
  }
}

