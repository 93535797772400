@use '../../../foundation/config' as *;

.p-companyBlock {
  margin: 5rem 0;

  &.-mt_2x {
    margin: 10rem 0 5rem;
  }

  > .p-companyBlock {
    margin: 5rem 0;
  }

  .p-companyBlock18__list {
    font-size: inherit;
    line-height: inherit;
  }
}

.p-content {
  margin: 8.37rem auto 0;
  font-size: 3.4rem;
  line-height: (54 / 34);
  text-align: justify;

  @include mq(md, min) {
    margin: 10rem auto 5rem;
    font-size: 2rem;
    line-height: (60/30);
  }

  &.p-contentIR {
    margin: 0 auto 15rem;

    :first-child {
      margin-top: 0;
    }
  }

  &.-narrow {
    @include mq(md, min) {
      max-width: 1050px;
      /*padding: 0 5rem;*/
    }
  }

  .-level2 {
    margin: 15rem 0 7rem;
    @include mq(md, min) {
      margin: 10rem 0 5rem;
    }
    + [class*='-level'] {
      margin-top: 5rem;
    }
  }
  .-level3 {
    margin: 9rem 0 3rem;
    @include mq(md, min) {
      margin: 7rem 0 2.5rem;
    }
    + [class*='-level'] {
      margin-top: 2.5rem;
    }
  }
  .-level4 {
    margin: 5rem 0 2.5rem;
  }
  .-level5 {
    margin: 5rem 0 2.5rem;
    @include mq(md, min) {
      margin: 2.5rem 0;
    }
  }
  p,
  ol,
  ul {
    margin-bottom: 1em;

    @include mq(md, min) {
      margin-bottom: 2.5rem;
    }
  }

  > *:last-child {
    margin-bottom: 0;
  }

  .c-button.-plain {
    font-size: inherit;
    @include mq(md) {
      line-height: 1.2;
    }
  }

  .p-lead {
    margin: 5rem 0;
  }

  .p-list {
    li {
      list-style-type: inherit;
      margin: 0 0 0 1em;
    }
    &.-declared {
      > li {
        margin-bottom: 1em;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
    .-listStyleReset {
      li {
        list-style-type: initial;
      }
    }
  }

  .p-note {
    margin-bottom: 2.5rem;
    font-size: 3rem;
    line-height: 2;
    @include mq(md, min) {
      font-size: 1.6rem;
      line-height: 2.25;
    }
  }

  .p-figure {
    margin: 5rem 0;
  }

  .c-table.-style1-adjusted {
    @include mq(md, min) {
      font-size: 2rem;
    }
    @include mq(md) {
      margin-right: 0;
      font-size: 3rem;
      line-height: (54 / 34);
    }
  }
  .c-table.-style1-widened {
    th[scope=row] {
      @include mq(md, min) {
        width: 20%;
      }
    }
    td {
      text-align: left;
    }
    @include mq(md) {
      tbody, tr {
        display: flex;
        flex-direction: column;
      }
      th {
        border-right: none;
      }
    }
  }

  .p-companyBlock3__wrap1 {
    .-logo-corporate {
      width: 50%;
      @include mq(md) {
        width: 70%;
      }
    }
    .p-companyBlock3__text1 {
      font-size: 2rem;

      @include mq(md) {
        font-size: 3.4rem;
      }
    }
  }

  .p-companyBlock3__wrap1Subtitle1.-adjusted {
    padding-right: 3rem;
  }
}

.c-col2 {
  @include mq(md, min) {
    display: flex;
    align-items: flex-start;
    justify-content: space-around;
  }
  .c-box {
    @include mq(md, min) {
      width: auto;
      min-width: 40%;
      margin-left: 2rem;
      padding: 3rem;
      font-size: 1.7rem;
    }
    @include mq(md) {
      padding: 4rem;
    }
    .c-box__heading {
      margin-bottom: 2rem;
      @include mq(md) {
        margin-bottom: 4rem;
      }
    }
    .c-list:last-of-type {
      margin-bottom: 0;
    }
  }
}