@use '../../../foundation/config' as *;

.p-communication {
  padding-bottom: 25.2rem;
  @include mq(md) {
    padding-bottom: 24rem;
  }
}
.p-communicationBasic {
  margin-top: 14.2rem;

  @include mq(md) {
    margin-top: 13.6rem;
    margin-bottom: 85.9rem;
  }
}

.p-communicationBasic__spaceSmall {
  letter-spacing: -0.2rem;

  @include mq(md) {
    letter-spacing: 0;
  }
}

.p-communicationText {
  font-size: 1.6rem;
  line-height: (36 / 16);

  @include mq(md) {
    font-size: 3rem;
    line-height: (60 / 30);
  }
}

.p-communicationBasic__textWrapper {
  display: flex;
  flex-wrap: wrap;
}

.p-communicationBasic__heading {
  @include mq(md, min) {
    flex-grow: 1;
  }
}

.p-communicationBasic__text {
  @include font-serif;
  font-size: 4.2rem;
  line-height: (62 / 42);
  font-weight: bold;

  @include mq(md, min) {
    flex-shrink: 0;
  }

  @include mq(md) {
    font-size: 6rem;
    line-height: (80 / 60);
    margin-top: 5.4rem;
  }
}

.p-communicationBasic__image {
  margin-top: 7rem;

  img {
    width: 100%;
  }

  @include mq(md) {
    img {
      position: absolute;
      left: 0;
    }
  }
}

.p-communicationActivity {
  margin-top: 19.2rem;
}

.p-communicationActivity__itemList {
  margin-top: 7.4rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  @include mq(md) {
    margin-top: 6.8rem;
  }
}

.p-communicationActivity__item {
  width: calc(33.333% - 4.6rem);
  @include mq(md) {
    width: 100%;

    img {
      width: 100%;
    }

    & + & {
      margin-top: 7.9rem;
    }
  }
}

.p-communicationActivity__itemTitle {
  margin-top: 1.9rem;
  font-size: 2rem;
  line-height: (40 / 20);
  font-weight: bold;

  @include mq(md) {
    margin-top: 3.7rem;
    font-size: 4rem;
    line-height: (60 / 40);
  }
}

.p-communicationActivity__itemDescription {
  margin-top: 1rem;
  @include mq(md) {
    margin-top: 2.1rem;
  }
}

.p-communicationActivity__itemButton {
  margin-top: 4rem;
  @include mq(md) {
    margin-top: 3.4rem;
  }
}

.p-communicationTeaTaster {
  margin-top: 20rem;
  @include mq(md) {
    margin-top: 20.1rem;
  }
}

.p-communicationTeaTaster__wrapper {
  display: flex;
  flex-wrap: wrap;

  @include mq(md) {
    flex-direction: column;
  }
}

.p-communicationTeaTaster__text {
  width: 50%;
  background-color: color(gray-200);
  padding: 5.1rem 8rem 6rem 8rem;
  @include mq(md) {
    width: 100%;
    order: 2;
    padding: 3.7rem 4rem 5rem 4rem;
  }
}

.p-communicationTeaTaster__description {
  margin-top: 1.7rem;
  @include mq(md) {
    margin-top: 2.1rem;
  }
}

.p-communicationTeaTaster__button {
  margin-top: 4rem;
  @include mq(md) {
    margin-top: 3.4rem;
  }
}

.p-communicationTeaTaster__image {
  width: 50%;

  img {
    width: 100%;
  }

  @include mq(md) {
    width: 100%;
    order: 1;
  }
}
