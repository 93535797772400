@use '../../../foundation/config' as *;

.p-sustainabilityTcfdList__adjust {
  .p-list {
    font-weight: normal;
    li {
      list-style: initial;
    }
  }
}

.c-table {
  &.p-sustainabilityTcfdTable__adjust {
    .-category {
      margin-bottom: 1em;
      font-weight: bold;
    }
    .-light {
      border-bottom: 1px solid color(gray-200);
    }
  }
}

.p-content {
  .p-sustainabilityTcfdNav__adjust {
    margin-top: 0;

    @include mq(md, min) {
      .c-inPageLinkGroup__item {
        display: flex;
        flex-direction: column;
        justify-content: center;
        line-height: 1.3;
      }
    }
  }
}
