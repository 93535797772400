@use '../../../foundation/config' as *;

.p-404 {
  padding-bottom: 25.2rem;

  @include mq(md) {
    padding-bottom: 24rem;
  }
}

.p-404__firstMessage {
  margin-top: 13.8rem;
  text-align: center;

  @include mq(md) {
    margin-top: 20.1rem;
    text-align: left;
  }
}

.p-404__description {
  margin-top: 1.9rem;
  font-size: 1.6rem;
  line-height: (36 / 16);

  @include mq(md) {
    margin-top: 4.8rem;
    font-size: 3rem;
    line-height: (60 / 30);
  }
}

.p-404__button {
  margin-top: 6rem;

  @include mq(md) {
    margin-top: 8rem;
  }
}

.p-404__footerLink {
  margin-top: 20rem;
}
