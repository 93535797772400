@use '../../../foundation/config' as *;

.p-sitemap {
  padding-bottom: 25.2rem;

  @include mq(md) {
    padding-bottom: 24rem;
  }
}

.p-sitemap__top {
  margin-top: 13.9rem;

  @include mq(md) {
    margin-top: 12.7rem;
  }
}

.p-sitemap__groupTitle {
  font-size: 2.6rem;
  line-height: (44/26);
  font-weight: bold;
  text-align: left;

  @include mq(md) {
    font-size: 4rem;
    line-height: (60/40);
  }
}

.p-sitemap__button {
  display: inline-flex;
  align-items: center;
  max-width: 100%;
  font-weight: bold;
  text-align: left;
  padding: 0;
  border: 0;
  transition: color 400ms easing(easeOutCubic);

  &:hover {
    color: color(green-600);
  }

  & > span {
    font-size: 2.6rem;
    line-height: (44/26);

    @include mq(md) {
      font-size: 4rem;
      line-height: (60/40);
    }
  }
}

.p-sitemapButton__arrow {
  position: relative;
  flex-shrink: 0;
  width: 1.6rem;
  height: 1.1rem;
  margin-left: 2.2rem;

  @include mq(md) {
    width: 2.4rem;
    height: 1.7rem;
    margin-left: 1.9rem;
  }
}

.p-sitemap__company {
  margin-top: 7.9rem;

  @include mq(md) {
    margin-top: 7.4rem;
  }
}

.p-sitemap__sustainability {
  margin-top: 8.8rem;
}

.p-sitemap__health {
  margin-top: 8.9rem;

  @include mq(md) {
    margin-top: 8.3rem;
  }
}

.p-sitemap__research {
  margin-top: 8.8rem;

  @include mq(md) {
    margin-top: 8.2rem;
  }
}
.p-sitemap__ir {
  margin-top: 8.8rem;
}
.p-sitemap__news {
  margin-top: 8.9rem;
}
.p-sitemap__question {
  margin-top: 7.9rem;
}

.p-sitemapLinkGroup {
  margin-top: 4.1rem;

  ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
}

.p-sitemapLinkGroup__item {
  width: 46.4%;
  border-top: 1px solid color(gray-500);
  border-bottom: 1px solid color(gray-500);

  &:nth-child(n + 3) {
    border-top: none;
  }

  @include mq(md) {
    width: 100%;

    & + & {
      border-top: none;
    }
  }
  a {
    display: block;
    font-size: 1.6rem;
    line-height: (40/16);
    font-weight: bold;
    position: relative;
    padding: 1.5rem 6rem 1.4rem 3rem;
    min-height: 6.9rem;
    transition: color 400ms easing(easeOutCubic);

    @include mq(md) {
      font-size: 3.4rem;
      line-height: (54/34);
      padding: 2.9rem 6rem 3.5rem 3rem;
    }

    &:hover {
      color: color(green-600);
    }

    &::before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      width: 0;
      transition: width 800ms easing(easeOutCubic);
      background-color: color(green-200);
      border-bottom: 2px solid color(green-600);
      z-index: -1;
    }

    &:hover::before {
      left: 0;
      width: 100%;
      transition: width 600ms easing(easeOutCubic);
    }
    i {
      display: block;
      position: absolute;
      bottom: 3rem;
      right: 3rem;

      @include mq(md) {
        bottom: 5.2rem;

        &.p-sitemapLinkGroupB8 {
          bottom: 8rem;
        }
      }
    }
    svg {
      width: 1.6rem;
      height: 1.1rem;

      @include mq(md) {
        width: 2.4rem;
        height: 1.7rem;
      }
    }
  }
}
