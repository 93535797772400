@use '../../../foundation/config' as *;

.p-companyBlock19 {
  margin: 18.8rem 0 0;
  @include mq(md, min) {
    margin: 19.2rem 0 0;
  }
  &:first-child {
    margin: 14rem 0 0;
  }
}
.p-companyBlock19__text1 {
  font-size: 3.4rem;
  line-height: (54 / 34);
  text-align: justify;
  margin: 3.1rem 0 0;
  @include mq(md, min) {
    font-size: 2rem;
    line-height: (60/30);
    margin: 3.8rem 0 0;
  }
  &.txt-right{
    text-align: right;
  }
}
.p-companyBlock19__list1.-type1 {
  margin-left: 4rem;
  @include mq(md, min) {
    margin-left: 2rem;
  }
}
.p-companyBlock19__list1.-type1 > li {
  list-style-type: decimal;
  margin: 5rem 0 1.4rem;
  padding: 0;
  font-size: 3.4rem;
  line-height: (54 / 34);
  @include mq(md, min) {
    margin: 6.4rem 0 2.1rem;
    font-size: 2rem;
    line-height: (60/30);
  }
  &:before {
    display: none;
  }
}
