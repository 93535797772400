@use '../../../foundation/config' as *;

.p-companyBlock17 {
  margin: 13rem 0 0;
  @include mq(md, min) {
    margin: 15rem 0 0;
  }
}
.p-companyBlock17__main1 {
  //
}
.p-companyBlock17__title1 {
  margin-top: 68.6rem;
  font-size: 6rem;
  line-height: (80/60);
  font-weight: bold;
  @include font-serif;
  @include font-extended;
  @include mq(md, min) {
    margin-top: 6.4rem;
    margin-left: 69rem;
    font-size: 4.2rem;
    line-height: (68/42);
  }
  @include mq(md) {
    font-size: 5.5rem;
  }
}

.p-companyBlock17__name1 {
  margin: 2.7rem 0 0;
  transform: translateX(0);
  @include mq(md, min) {
    margin-top: 4rem;
    margin-left: 70.8rem;
    display: flex;
    align-items: center;
  }
}
.p-companyBlock17__name1Name1 {
  font-size: 3rem;
  line-height: (60/30);
  font-weight: bold;
  z-index: z(content);
  @include mq(md, min) {
    font-size: 1.6rem;
    line-height: (36/16);
  }
}
.p-companyBlock17__name1Image1 {
  margin: 1.2rem 0 0;
  display: block;
  z-index: z(content);
  @include mq(md, min) {
    margin: 0 0 0 2.9rem;
  }
  img {
    width: 36.3rem;
    @include mq(md, min) {
      width: 27rem;
    }
  }
}
.p-companyBlock17__image1 {
  margin: -115.4rem -4rem 0;
  display: block;
  @include mq(md, min) {
    margin: -37.6rem 0 0;
  }

  img {
    width: 100%;
    height: auto;
  }
}
.p-companyBlock17__text1 {
  margin: 6.4rem 0 0;
  @include mq(md, min) {
    margin-top: 6.7rem;
  }
  & > p {
    font-size: 3rem;
    line-height: (60/30);
    text-align: justify;
    @include mq(md, min) {
      // font-size: 1.6rem;
      font-size: 2rem;
      line-height: (36/16);
      margin: 3.6rem 0 0;
    }
    &:first-child {
      margin-top: 0;
    }
  }
}
