@use '../../foundation/config' as *;

// 平体
.u-fontExtended {
  @include font-extended;
}
// デフォルト書体
.u-fontDefault {
  @include font-default;
}

// 左揃え
.u-alignLeft {
  text-align: left;
}

// 右揃え
.u-alignRight {
  text-align: right;
}

// 中央揃え
.u-alignCenter {
  text-align: center;
}

// 太字
.u-weightBold {
  font-weight: bold;
}
// 太字（解除）
.u-weightNormal {
  font-weight: normal;
}

// 下線
.u-hasUnderline {
  text-decoration: underline;
}

// 取り消し戦
.u-lineThrough {
  text-decoration: line-through;
}

// アクセントカラー適用
.u-accent {
  color: color(green-600);
}

// インデント（塊）
.u-hasIndent {
  margin-left: 1em;
}
// インデント（解除）
.u-hasNoIndent {
  margin-left: 0;
}
