@use '../../../foundation/config' as *;

.p-behaviorPlan-col2 {
  @include mq(md, min) {
    display: flex;
    justify-content: space-between;
    .p-sustainabilityFormat__text:nth-child(2) {
      flex: 1;
    }
  }
  @include mq(md) {
    .p-sustainabilityFormat__text:first-child {
      margin-bottom: .5em;
    }
  }
  .p-sustainabilityFormat__text {
    ul {
      li {
        list-style-type: inherit;
        margin: 0 0 0 1em;
      }
    }
  }
}

.p-actPlan-list {
  ul {
    li {
      list-style-type: inherit;
      margin: 0 0 0 1em;
      @include mq(md, min) {
        margin: 0 0 0 1.5em;
      }
    }
  }
}

.p-sustainabilityPlans {
  @include mq(md) {
    .c-heading.-level4:not(:first-of-type) {
      margin-top: 8rem;
    }
  }
  .p-sustainabilityFormat__layout {
    @include mq(md, min) {
      margin-top: 2rem;
    }
    &.p-sustainabilityPlans_noMargin {
      margin-top: 0;
    }
  }
  .p-sustainabilityFormat__text {
    &::before {
      margin-top: 0;
    }
  }
  .p-sustainabilityPlans_box {
    display: inline-block;
    padding: 1rem 1.5rem;
    border: 1px solid color(gray-300);
  }
}
