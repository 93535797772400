@use '../../../foundation/config' as *;

.p-formBlock2 {
  margin: 10rem 0 25rem;
  font-size: 3rem;
  line-height: (60/30);
  @include mq(md, min) {
    font-size: 1.6rem;
    line-height: (36/16);
  }
  input::placeholder {
    font-size: 1em;
    color: color(gray-300);
  }
}
.p-formBlock2__unit1Image1 {
  margin: 5rem 0 0;
  @include mq(md, min) {
    margin: 2rem 0 0;
  }
  @at-root {
    form
      > input[style='display: none;']
      + .p-formBlock1
      + .p-formBlock2
      .p-formBlock2__unit1Image1 {
      display: none;
    }
  }
}
.p-formBlock2__formText1 {
  input {
    border: 1px solid color(gray-300);
    border-radius: 4px;
    height: 12rem;
    width: 100%;
    padding: 4rem;
    color: color(black);
    @include mq(md, min) {
      height: 6rem;
      padding: 2rem 3rem;
    }
  }

  &-f{
    display: flex;
    align-items: center;
  }
}
.p-formBlock2__textArea1 {
  textarea {
    border: 1px solid color(gray-300);
    border-radius: 4px;
    width: 100%;
    padding: 4rem;
    color: color(black);
    @include mq(md, min) {
      padding: 1.7rem 3rem;
    }
  }
  textarea::placeholder {
    font-size: 1em;
    color: color(gray-300);
  }
}
.p-formBlock2__formSelect1 {
  border: 1px solid color(gray-300);
  background: color(white);
  border-radius: 4px;
  position: relative;
  @at-root {
    form
      > input[style='display: none;']
      + .p-formBlock1
      + .p-formBlock2
      .p-formBlock2__formSelect1 {
      background: none;
      border: none;
    }
  }
  &:before {
    position: absolute;
    top: 0;
    right: 0;
    content: '';
    display: block;
    width: 12rem;
    height: 12rem;
    background: color(gray-200);
    border-radius: 0 4px 4px 0;
    border-left: 1px solid color(gray-300);
    @include mq(md, min) {
      width: 6rem;
      height: 6rem;
    }
    @at-root {
      form
        > input[style='display: none;']
        + .p-formBlock1
        + .p-formBlock2
        .p-formBlock2__formSelect1:before {
        display: none;
      }
    }
  }
  &:after {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 5rem;
    margin: auto;
    content: '';
    display: block;
    width: 1.6rem;
    height: 1.6rem;
    border-right: 2px solid color(black);
    border-bottom: 2px solid color(black);
    transform: rotate(45deg);
    @include mq(md, min) {
      width: 1.1rem;
      height: 1.1rem;
      top: -0.3rem;
      right: 2.3rem;
    }
    @at-root {
      form
        > input[style='display: none;']
        + .p-formBlock1
        + .p-formBlock2
        .p-formBlock2__formSelect1:after {
        display: none;
      }
    }
  }
  select {
    position: relative;
    z-index: 1;
    outline: none;
    text-indent: 0.01px;
    text-overflow: '';
    background: none transparent;
    vertical-align: middle;
    color: inherit;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    font-size: 3rem;
    line-height: (60/30);
    width: 100%;
    padding: 0 4rem;
    height: 12rem;
    border: none;
    @include mq(md, min) {
      font-size: 1.6rem;
      height: 6rem;
      padding: 0 2.7rem;
    }
  }
  option {
    background-color: color(white);
    color: #333;
  }
  select::-ms-expand {
    display: none;
  }
  select:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #828c9a;
  }
}
.p-formBlock2__formSelect2 {
  border: 1px solid color(gray-300);
  background: color(white);
  border-radius: 4px;
  position: relative;
  flex: 1;
  @at-root {
    form
      > input[style='display: none;']
      + .p-formBlock1
      + .p-formBlock2
      .p-formBlock2__formSelect2 {
      background: none;
      border: none;
    }
  }
  &:before {
    position: absolute;
    top: 0;
    right: 0;
    content: '';
    display: block;
    width: 12rem;
    height: 12rem;
    background: color(gray-200);
    border-radius: 0 4px 4px 0;
    border-left: 1px solid color(gray-300);
    @include mq(md, min) {
      width: 6rem;
      height: 6rem;
    }
    @at-root {
      form
        > input[style='display: none;']
        + .p-formBlock1
        + .p-formBlock2
        .p-formBlock2__formSelect2:before {
        display: none;
      }
    }
  }
  &:after {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 5rem;
    margin: auto;
    content: '';
    display: block;
    width: 1.6rem;
    height: 1.6rem;
    border-right: 2px solid color(black);
    border-bottom: 2px solid color(black);
    transform: rotate(45deg);
    @include mq(md, min) {
      width: 1.1rem;
      height: 1.1rem;
      top: -0.3rem;
      right: 2.3rem;
    }
    @at-root {
      form
        > input[style='display: none;']
        + .p-formBlock1
        + .p-formBlock2
        .p-formBlock2__formSelect2:after {
        display: none;
      }
    }
  }
  select {
    position: relative;
    z-index: 1;
    outline: none;
    text-indent: 0.01px;
    text-overflow: '';
    background: none transparent;
    vertical-align: middle;
    color: inherit;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    font-size: 3rem;
    line-height: (60/30);
    width: 100%;
    padding: 0 4rem;
    height: 12rem;
    border: none;
    @include mq(md, min) {
      font-size: 1.6rem;
      height: 6rem;
      padding: 0 2rem;
    }
  }
  option {
    background-color: color(white);
    color: #333;
  }
  select::-ms-expand {
    display: none;
  }
  select:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #828c9a;
  }
}
.p-formBlock2__datelabel {
  display: flex;
  align-items: center;
  padding: 0 0.5em;
  min-width: 12%;
  @include mq(md, min) {
    min-width: 20%;
  }
}
.p-formBlock2__formRadio1 {
  margin: 0 0 0 11rem;
  @include mq(md, min) {
    margin: 0 0 0 6.1rem;
    align-items: center;
    display: flex;
    min-height: 6rem;
  }
  &:first-child {
    margin-left: 0;
  }
  label {
    position: relative;
    display: block;
    padding: 0 11rem 0 0;
    min-height: 8rem;
    @include mq(md, min) {
      min-height: 4rem;
      padding: 0 5.6rem 0 0;
    }
    &:before {
      position: absolute;
      top: 0;
      right: 0;
      content: '';
      display: block;
      width: 8rem;
      height: 8rem;
      background: color(white);
      border: 1px solid color(gray-300);
      border-radius: 4rem;
      transition: all 0.7s easing(easeOutQuint);
      @include mq(md, min) {
        width: 4rem;
        height: 4rem;
      }
      @at-root {
        .p-formBlock2__formRadio1 > input:checked + label:before {
          background: color(black);
          border: none;
        }
      }
    }
    &:after {
      position: absolute;
      top: 0;
      bottom: 0;
      right: 3rem;
      content: '';
      opacity: 0;
      display: block;
      width: 2rem;
      height: 2rem;
      background: color(white);
      border-radius: 1rem;
      margin: auto;
      transition: all 0.7s easing(easeOutQuint);
      transform: scale(0);
      @include mq(md, min) {
        width: 1.2rem;
        height: 1.2rem;
        right: 1.4rem;
      }
      @at-root {
        .p-formBlock2__formRadio1 > input:checked + label:after {
          opacity: 1;
          transform: scale(1);
        }
      }
    }
  }
  span {
    font-size: 3.4rem;
    line-height: 8rem;
    @include mq(md, min) {
      font-size: 1.6rem;
      line-height: 4rem;
    }
  }
  input {
    display: none !important;
  }
}
.p-formBlock2__formCheck1 {
  margin: 0 0 0 11rem;
  @include mq(md, min) {
    margin: 0 0 0;
  }
  &:first-child {
    margin-left: 0;
  }
  label {
    position: relative;
    display: block;
    padding: 0 11rem 0 0;
    min-height: 8rem;
    @include mq(md, min) {
      min-height: 6rem;
      display: inline-flex;
      align-items: center;
      padding: 0 5.7rem 0 0;
    }
    &:before {
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      margin: auto;
      content: '';
      display: block;
      width: 8rem;
      height: 8rem;
      background: color(white);
      border: 1px solid color(gray-300);
      border-radius: 4px;
      transition: all 0.7s easing(easeOutQuint);
      @include mq(md, min) {
        width: 4rem;
        height: 4rem;
      }
      @at-root {
        .p-formBlock2__formCheck1 > input:checked + label:before {
          background: color(black);
          border: none;
        }
      }
    }
    &:after {
      position: absolute;
      top: -0.7rem;
      bottom: 0;
      right: 2.8rem;
      content: '';
      opacity: 0;
      display: block;
      width: 2.6rem;
      height: 1.6rem;
      border-bottom: 3px solid color(white);
      border-left: 3px solid color(white);
      border-radius: 0 0 0 2px;
      margin: auto;
      transform: scale(0) rotate(-45deg);
      transition: all 0.7s easing(easeOutQuint);
      @include mq(md, min) {
        width: 1.4rem;
        height: 0.8rem;
        top: -0.2rem;
        right: 1.3rem;
      }
      @at-root {
        .p-formBlock2__formCheck1 > input:checked + label:after {
          opacity: 1;
          transform: scale(1) rotate(-45deg);
        }
      }
    }
  }
  span {
    font-size: 3.4rem;
    line-height: 8rem;
    @include mq(md, min) {
      font-size: 1.6rem;
      line-height: 1;
    }
  }
  input {
    display: none !important;
  }
}
.p-formBlock2__unit1 {
  margin: 5.5rem 0 0;
  @include mq(md, min) {
    margin: 4.4rem 0 0;
    display: flex;
  }
  @at-root {
    form
      > input[style='display: none;']
      + .p-formBlock1
      + .p-formBlock2
      .p-formBlock2__unit1 {
      border-top: 1px solid color(gray-300);
      margin: 3.8rem 0 0;
      padding: 3.7rem 0 0 0;
      @include mq(md, min) {
        padding: 0.7rem 0 0;
        margin: 1.2rem 0 0;
      }
      &:last-child {
        border-bottom: 1px solid color(gray-300);
        padding-bottom: 4rem;
        @include mq(md, min) {
          padding-bottom: 1.2rem;
        }
      }
    }
  }
  &:first-child {
    margin-top: 0;
  }
}
.p-formBlock2__unit1Label1 {
  font-size: 3.4rem;
  line-height: (54/34);
  font-weight: bold;
  @include mq(md, min) {
    min-width: 35rem;
    font-size: 1.6rem;
    line-height: (36/16);
    min-height: 6rem;
    max-height: 6rem;
    display: flex;
    align-items: center;
  }
  span {
    display: inline-block;
    color: color(red);
  }
  &.-emphasis {
    color: color(green-600);
  }
}
.p-formBlock2__unit1Body1 {
  margin: 2rem 0 0;
  @include mq(md, min) {
    margin: 0 0 0;
    width: 100%;
    overflow: hidden;
  }
  @at-root {
    form
      > input[style='display: none;']
      + .p-formBlock1
      + .p-formBlock2
      .p-formBlock2__unit1Body1 {
      margin: 0.8rem 0 0;
      @include mq(md, min) {
        display: flex;
        align-items: center;
        min-height: 6rem;
        margin: 0;
      }
    }
  }
}
.p-formBlock2__unit1FormGroup2 {
  display: flex;
}
.p-formBlock2__unit1FormGroup3 {
  @include mq(md, min) {
    display: flex;
    margin: 0 -2rem;
  }
  & > div {
    margin: 3rem 0 0;
    @include mq(md, min) {
      margin: 0 0 0;
      min-width: 50%;
      padding: 0 2rem;
    }
    &:first-child {
      margin-top: 0;
    }
  }

  &-f {
    & > div {
      @include mq(md, min) {
        display: flex;
        align-items: center;
      }
      &:first-child {
        @include mq(md) {
          @at-root {
            form
              > input[style='display: none;']
              + .p-formBlock1
              + .p-formBlock2
              .p-formBlock2__unit1FormGroup3-f > div:first-child {
                display: flex;
                align-items: center;
                font-size: 3rem;
            }
          }
        }
      }
      & > div {
        display: flex;
        justify-content: center;
        @include mq(md, min) {
          width: 290px;
          @at-root {
            form
              > input[style='display: none;']
              + .p-formBlock1
              + .p-formBlock2
              .p-formBlock2__unit1FormGroup3 > div > div {
                width: auto;
              }
          }
        }
        & + div {
          @include mq(md) {
            margin: 3rem 0 0;
            @at-root {
              form
                > input[style='display: none;']
                + .p-formBlock1
                + .p-formBlock2
                .p-formBlock2__unit1FormGroup3 > div > div + div {
                  margin: 0;
                }
            }
          }
        }
      }
    }
  }
}
.p-formBlock2__unit1FormGroup4 {
  @extend .p-formBlock2__unit1FormGroup3;
  & > div {
    @include mq(md, min) {
      min-width: 25%;
    }
    & > div {
      display: flex;
      justify-content: center;
    }
  }
}
.p-formBlock2__unit1Error1 {
  color: color(red);
  font-size: 3rem;
  line-height: (60/30);
  @include mq(md, min) {
    font-size: 1.4rem;
    line-height: (26/14);
  }
}
.p-formBlock2__unit1Text1 {
  margin: 2rem 0 0;
  @include mq(md, min) {
    margin: 0.9rem 0 0;
  }
  @at-root {
    form
      > input[style='display: none;']
      + .p-formBlock1
      + .p-formBlock2
      .p-formBlock2__unit1Text1 {
      display: none;
    }
  }
  p {
    font-size: 2.4rem;
    line-height: (40/24);
    @include mq(md, min) {
      font-size: 1.4rem;
      line-height: (26/14);
    }
  }
  a {
    color: color(gray-600);
    transition-property: color, text-decoration-color;
    transition-duration: 400ms;
    transition-timing-function: easing(easeOutCubic);
    text-decoration: underline;
    text-underline-offset: 0.4rem;
    &.-arrowRight {
      margin: 0 1.5rem 0 0;
      i {
        width: 2.4rem;
        height: 1.7rem;
        font-size: 0;
        margin: 0 0 0 1.8rem;
        @include mq(md, min) {
          width: 1.6rem;
          height: 1.1rem;
          margin: 0 0 0 1rem;
        }
      }
      svg {
        width: 2.4rem;
        height: 1.7rem;
        vertical-align: baseline;
        @include mq(md, min) {
          width: 1.6rem;
          height: 1.1rem;
        }
      }
    }
    &:hover {
      color: color(green-600);
      text-decoration-color: rgba(color(gray-600), 0);
    }
  }
}
.p-formBlock2__buttonGroup1 {
  margin: 8.1rem 0 0;
  @include mq(md, min) {
    margin: 6.8rem 0 0;
    text-align: center;
  }
}
.p-formBlock2__button1 {
  position: relative;
  display: inline-flex;
  align-items: center;
  max-width: 100%;
  border: 1px solid;
  font-weight: bold;
  text-align: left;
  line-height: 1.15;
  justify-content: space-between;
  width: 27rem;
  font-size: 1.6rem;
  padding: 0 0 0 3rem;
  transition-property: background-color, box-shadow, color, letter-spacing;
  transition-duration: 400ms, 400ms, 400ms, 200ms;
  transition-timing-function: easing(easeOutCubic);
  background-color: color(black);
  color: color(white);
  cursor: pointer;
  input[type='button'] {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 12rem;
    opacity: 0;
  }
  @include mq(md) {
    width: 100%;
    font-size: 3rem;
  }

  &.disabled{
    background-color: rgba(color(black), 0.5);
    cursor: default;

    .p-formBlock2__button1Arrow{
      display: none;
    }
  }

  &:not(.disabled){
    &:hover {
      color: color(black);
      background-color: transparent;
    }
  }

}
.p-formBlock2__button1Separator {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  width: 6rem;
  height: 6rem;
  transition: color 400ms easing(easeOutCubic);
  border-left: 1px solid;

  @include mq(md) {
    width: calc(12rem - 2px);
    height: calc(12rem - 2px);
  }
}
.p-formBlock2__button1Arrow {
  position: relative;
  flex-shrink: 0;
  width: 1.6rem;
  height: 1.1rem;
  transition: color 400ms easing(easeOutCubic);

  @include mq(md) {
    width: 2.4rem;
    height: 1.7rem;
  }

  .p-formBlock2__button1:not(.disabled):hover & {
    color: color(black);
  }
}
.p-formBlock2__button2 {
  font-size: 2.6rem;
  font-weight: bold;
  line-height: 7.2rem;
  border: 1px solid color(black);
  width: 20rem;
  @include mq(md, min) {
    font-size: 1.6rem;
    line-height: 5.7rem;
    width: 17.5rem;
    position: absolute;
    top: 0;
    left: 0;
  }
}
.p-formBlock2__buttonGroup1List1 {
  margin: 3.2rem 0 0;
  position: relative;
  @include mq(md, min) {
    margin: 1.9rem 0 0;
  }
  & > li {
    &:last-child {
      margin: 5rem 0 0;
    }
  }
}
.p-formBlock2__buttonGroup1Text1 {
  p {
    font-size: 3rem;
    line-height: (60/30);
    text-align: justify;
    @include mq(md, min) {
      font-size: 1.6rem;
      line-height: (36/16);
      text-align: center;
    }
  }
}
.p-formBlock2__agree1 {
  border: 1px solid color(gray-300);
  margin: 9.7rem 0 0;
  padding: 2.4rem 3.6rem 4.4rem 3.6rem;
  @include mq(md, min) {
    margin: 7rem 0 0;
    padding: 2.8rem 4rem 3.9rem 4rem;
  }
}
.p-formBlock2__agree1Title1 {
  font-size: 3.4rem;
  line-height: (54/34);
  font-weight: bold;
  @include mq(md, min) {
    font-size: 2rem;
    line-height: (40/20);
  }
}
.p-formBlock2__agree1Text1 {
  font-size: 3rem;
  line-height: (60/30);
  text-align: justify;
  margin: 1rem 0 0;
  @include mq(md, min) {
    font-size: 1.6rem;
    line-height: (36/16);
    margin: -0.5rem 0 0;
  }
}
.p-formBlock2__agree1Text2 {
  font-size: 3rem;
  line-height: (60/30);
  text-align: justify;
  margin: 1rem 0 0;
  @include mq(md, min) {
    font-size: 1.6rem;
    line-height: (32/16);
  }
}
.p-formBlock2__agree1Form1 {
  background: color(gray-200);
  margin: 2.3rem 0 0;
  padding: 3.8rem 6.2rem 3.8rem 5.5rem;
  @include mq(md, min) {
    text-align: center;
    margin: 2.1rem 0 0;
    padding: 1.6rem 0 2.3rem 0;
  }
}

.p-formBlock2__validationError {
  font-weight: bold;
  margin-bottom: 2rem;
  background: rgba(255, 0, 0, 0.3);
  text-align: center;
}

//
.p-formBlock2__formAmount {
  display: flex;
  align-items: center;
  input {
    border: 1px solid color(gray-300);
    border-radius: 4px;
    height: 12rem;
    width: 100%;
    padding: 4rem;
    color: color(black);
    margin: 0 0.5em;
    @include mq(md, min) {
      height: 6rem;
      padding: 2rem 3rem;
    }
  }
}
.p-formBlock2__formAmountSelect {
  flex: initial;
  min-width: 20rem;
  margin: 0 1rem;
}

// p-form-espfTourSagara
.p-form-espfTourSagara {
  .p-formBlock1 {
    margin-top: 5.2rem;
  }
  .p-formBlock2__agree1 {
    margin-top: 0;
  }
}