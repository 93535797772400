@use '../../../foundation/config' as *;

.c-chartArea {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 4rem;
  @include mq(md) {
    flex-direction: column;
    justify-content: center;
  }
  &.-col2 {
    flex-wrap: wrap;
    .c-chartItem {
      flex: none;
      width: 48%;
      @include mq(md, min) {
        &:nth-of-type(n+3) {
          margin-top: 8rem;
        }  
      }
      @include mq(md) {
        width: 100%;
      }
    }
  }
}

.c-chartItem {
  width: 100%;
  &.-has-usage {
    @include mq(md, min) {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex: 1;
    }
  }
}

.c-chart {
  position: relative;
  width: 100%;
  height: 24rem;
  @include mq(md) {
    margin: 3rem 0 10rem;
  }
  &.-row4 {
    height: 32rem;
    .c-chartBackground {
      height: 32rem;
      p {
        height: calc(100% / 4);
      }
    }
    .c-chartBody {
      height: 32rem;
    }
    .c-chartBar {
      background-color: #E0E9E1;
      border-top: 1px solid #E0E9E1;
      height: calc(var(--value-ratio) / 100 * 24rem);
    }
  }
  &.-row5 {
    height: 32rem;
    .c-chartBackground {
      height: 32rem;
      p {
        height: calc(100% / 5);
      }
    }
    .c-chartBody {
      height: 32rem;
    }
    .c-chartBar {
      background-color: #E0E9E1;
      border-top: 1px solid #E0E9E1;
      height: calc(var(--value-ratio) / 100 * 25.6rem);
    }
  }
  &.-row6 {
    height: 32rem;
    .c-chartBackground {
      height: 32rem;
      p {
        height: calc(100% / 6);
      }
    }
    .c-chartBody {
      height: 32rem;
    }
    .c-chartBar {
      background-color: #E0E9E1;
      border-top: 1px solid #E0E9E1;
      height: calc(var(--value-ratio) / 100 * 26.66rem);
    }
  }
}

.c-chartBackground {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 24rem;

  p {
    position: relative;
    border-bottom: 1px solid color(gray-450);
    height: calc(100% / 4);
    color: color(gray-450);
    font-size: 1.4rem;
    font-weight: bold;
    /*
    &:first-child {
      border-top: 1px solid #000;
    }
    */
    span {
      position: absolute;
      bottom: 0;
      left: 0;
    }
  }
}

.c-chartBody {
  display: flex;
  align-items: flex-end;
  justify-content: space-around;
  height: 24rem;
  margin-left: 6rem;
}

.c-chartBars {
  position: relative;
  display: flex;
  align-items: flex-end;
  justify-content: center;

  dt {
    position: absolute;
    left: 0;
    bottom: -2em;
    width: 100%;
    font-size: 1.4rem;
    font-weight: bold;
    text-align: center;
    @include mq(md) {
      bottom: -3.5em;
      line-height: 1;
    }
  }

  .c-chartBar {
    z-index: 1;
    width: 5rem;
    height: calc(var(--value-ratio) / 100 * 24rem);
    border-bottom: 1px solid color(gray-450);
    font-size: 1.4rem;
    font-weight: bold;
    text-align: center;
    @include mq(md) {
      width: 6rem;
    }

    &.-a {
      background-color: #AFC6B6;
      border-top: 1px solid #AFC6B6;
    }
    &.-b {
      background-color: #E0E9E1;
      border-top: 1px solid #E0E9E1;
    }
    &.-c {
      background-color: #DDDDDD;
      border: none;
    }
    &.-d {
      background-color: #EBE8CA;
      border: none;
    }
    &.-e {
      background-color: #EBD7C8;
      border: none;
    }
    &.-f {
      background-color: #E2EBF2;
      border: none;
    }
    &.-g {
      background-color: #AEC6B6;
      border-top: none;
    }
  }

  &.-stack {
    flex-direction: column;
    .c-chartBar {
      width: 20rem;
    }
  }
}

.c-chartUsage {
  @include mq(md, min) {
    width: 20%;
    margin-left: 2rem;
    padding: 2rem;
  }
}

.c-chartUsageList {
  line-height: 2;
  font-size: 1.8rem;
  font-weight: bold;
  @include mq(md) {
    display: flex;
    justify-content: center;
    padding: 0;
  }
  .-a {
    &::before {
      background-color: #AFC6B6;
    }
  }
  .-b {
    &::before {
      background-color: #E0E9E1;
    }
  }
  .-c {
    &::before {
      background-color: #DDDDDD;
    }
  }
  .-d {
    &::before {
      background-color: #EBE8CA;
    }
  }
  .-e {
    &::before {
      background-color: #EBD7C8;
    }
  }
  .-f {
    &::before {
      background-color: #E2EBF2;
    }
  }
  .-g {
    &::before {
      background-color: #AEC6B6;
    }
  }
}
.c-chartUsageItem {
  display: flex;
  align-items: center;
  &::before {
    content: "";
    width: .8em;
    height: .8em;
    margin-right: .5em;
  }
  @include mq(md) {
    &+.c-chartUsageItem {
      margin-left: 2rem;
    }
  }
}