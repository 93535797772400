@use '../../../foundation/config' as *;

/* -----------------------
共通スタイル
----------------------- */

.p-communicationShokuiku {
  padding-bottom: 25.2rem;

  @include mq(md) {
    padding-bottom: 24rem;
  }
}

// 基本のテキスト
.p-communicationShokuikuText {
  font-size: 1.6rem;
  line-height: 2.25;

  @include mq(md) {
    @include lhCrop(2);
    font-size: 3rem;
    line-height: 2;
  }
}

// 項目のテキストエリア
.p-communicationShokuiku_itemText {
  width: 69%;

  @include mq(md) {
    width: 100%;
  }
}

// 項目の画像エリア
.p-communicationShokuiku_itemImage {
  width: 31%;
  text-align: right;

  img {
    width: 100%;
  }

  @include mq(md) {
    width: 100%;
    text-align: center;

    &.-mt39 {
      margin-top: 3.9rem;
    }

    &.-mt03 {
      margin-top: 0.3rem;
    }

    img {
      padding: 1.1rem 7.3rem 0;
    }
  }
}

// 項目の説明部分
.p-communicationShokuiku_itemTextDescription {
  margin: 1.5rem 8.3rem 0 0;

  button {
    margin-top: 4rem;
  }

  @include mq(md) {
    margin: 3.7rem 0 3.5rem 0;
  }
}

/* -----------------------
各セクションのスタイル
----------------------- */

// First Message
.p-communicationShokuikuFirstMessage {
  margin-top: 15rem;
  display: flex;
  flex-wrap: wrap;

  @include mq(md) {
    margin-top: 15.1rem;
  }
}

.p-communicationShokuikuFirstMessage__text {
  width: 50%;

  @include mq(md) {
    width: 100%;
  }
}

.p-communicationShokuikuFirstMessage__heading {
  @include font-serif;
  @include lhCrop(1.47619048);
  margin-top: -0.5rem;
  font-size: 4.2rem;
  line-height: 1.47619048;

  @include mq(md) {
    font-size: 6rem;
    line-height: (80 / 60);
    @include lhCrop(80 / 60);
  }
}

.p-communicationShokuikuFirstMessage__description {
  margin: 3.7rem 8.3rem 0 0;

  @include mq(md) {
    margin: 5rem 0 7.9rem 0;
  }
}

.p-communicationShokuikuFirstMessage__image {
  width: 50%;
  text-align: right;

  img {
    width: 100%;
  }

  @include mq(md) {
    width: 100%;
    text-align: center;
  }
}

// お茶に関する活動
.p-communicationShokuikuActivity {
  margin-top: 19.2rem;

  @include mq(md) {
    margin-top: 18.8rem;
  }
}

.p-communicationShokuikuActivity_items {
  margin-top: 7.4rem;

  @include mq(md) {
    margin-top: 6.9rem;
  }
}

.p-communicationShokuikuActivity_item {
  display: flex;
  flex-wrap: wrap;

  & + & {
    margin-top: 8rem;
  }
}

.p-communicationShokuiku_itemTextHeading {
  margin-top: -1.3rem;
}

.p-communicationShokuikuActivity_itemImage {
  width: 37.5%;
  text-align: right;

  @include mq(md) {
    width: 100%;
    text-align: center;
  }
}

.p-communicationShokuiku_itemButton {
  margin-top: 4rem;
  @include mq(md) {
    margin-top: 4.7rem;
  }
}

// その他の活動
.p-communicationShokuikuOtherActivity {
  margin-top: 19.4rem;
  @include mq(md) {
    margin-top: 18.5rem;
  }
}

.p-communicationShokuikuOtherActivity_item {
  margin-top: 7.4rem;
  display: flex;
  flex-wrap: wrap;

  @include mq(md) {
    margin-top: 6.8rem;
  }
}

.p-communicationShokuikuOtherActivity_itemTextHeader {
  margin-top: -1.3rem;
}

// フッターリンク
.p-communicationShokuikuFooterLink {
  margin-top: 20.1rem;
}
