@use '../../../foundation/config' as *;

.p-irStockInfo__section {
  margin-top: 19rem;
}

.p-irStockInfo__smallSection {
  margin-top: 6.8rem;
  @include mq(md) {
    margin-top: 5.8rem;
  }
}

.p-irStockInfo__heading3 {
  font-size: 2rem;
  @include mq(md) {
    font-size: 3.4rem;
  }
}

.p-irStockInfo__table1 {
  margin-top: 1.2rem;
  @include mq(md) {
    .c-table.-style1 {
      margin-right: 0;
    }
  }
  .c-table.-style1 th {
    width: 24%;
    font-weight: 700;
    @include mq(md) {
      width: 32%;
      padding: 1em 2.5rem;
    }
  }
  .c-table.-style1 td {
    text-align: left;
  }
}

.p-irStockInfo__arrowIcon {
  width: 1.6rem;
  height: 1.1rem;

  @include mq(md) {
    width: 2.4rem;
    height: 1.7rem;
  }
}

.p-irStockInfo__additionalText {
  font-size: 1.4rem;
  @include mq(md) {
    font-size: 2.4rem;
  }
}

.p-irStockInfo__table2,
.p-irStockInfo__table3 {
  margin-top: 1.2rem;
  .c-table.-style1 th[scope='row'] {
    width: 50%;
  }
}

.p-irStockInfo__imageArea {
  margin-top: 7.6rem;
}

.p-irStockInfo__image {
  width: 88%;
  margin: 0 auto;
}

.p-irStockInfo__button {
  text-align: center;
  margin-top: 5.6rem;
}

.p-irStockInfo__text {
  font-size: 1.6rem;
  line-height: 2.2;
  margin-top: 6.4rem;
  @include mq(md) {
    font-size: 3rem;
    line-height: 2;
  }
}

.p-irStockInfo__table3 {
  .c-table.-style1 td {
    width: 25%;
    span {
      display: inline-block;
      text-align: left;
    }
  }
}

.p-irStockPreferredStocks__box {
  padding: 3.5rem 4rem;
  .c-box__body {
    overflow: visible;
  }
  dl {
    display: flex;
    + dl {
      margin-top: 1.4rem;
    }
  }
  dt {
    margin-right: 3rem;
  }
  @include mq(md) {
    font-size: 2.6rem;
    padding: 2.6rem 2.6rem 5.2rem;
    dl {
      flex-flow: column;
    }
    dd {
      margin-top: 1.4rem;
      word-break: break-word;
    }
  }
}
