@use '../../../foundation/config' as *;

.p-companyBlock10 {
  margin: 13.8rem 0 0;
}
.p-companyBlock10__unit1 {
  margin: 18.8rem 0 0;
  @include mq(md, min) {
    margin: 18.7rem 0 0;
  }
  &:first-child {
    margin: 0;
  }
}
.p-companyBlock10__unit1Text1 {
  font-size: 3rem;
  line-height: (60/30);
  margin: 2.1rem 0 0;
  text-align: justify;
  @include mq(md, min) {
    font-size: 1.6rem;
    line-height: (36/16);
    margin: 1.4rem 0 0;
  }
}
.p-companyBlock10__unit1Table1 {
  margin: 3.5rem 0 0;
  @include mq(md, min) {
    margin: 4rem 0 0;
  }
  table {
    width: 100%;
    border-collapse: collapse;
    border-bottom: 1px solid color(gray-300);
  }
  th,
  td {
    font-size: 2.6rem;
    line-height: (40/26);
    border-top: 1px solid color(gray-300);
    @include mq(md, min) {
      font-size: 1.6rem;
      line-height: (36/16);
    }
  }
  th {
    min-width: 25.8rem;
    max-width: 25.8rem;
    font-weight: bold;
    text-align: left;
    background: color(gray-200);
    padding: 2.3rem 3rem;
    vertical-align: top;
    @include mq(md, min) {
      min-width: 29rem;
      max-width: 29rem;
      padding: 1.7rem 2.1rem;
    }
  }
  td {
    width: 100%;
    border-left: 1px solid color(gray-300);
    padding: 2.3rem 3rem 2.7rem 3rem;
    @include mq(md, min) {
      padding: 1.7rem 1.9rem;
    }
  }
}
.p-companyBlock10__unit1Button1 {
  margin: 4.7rem 0 0;
  @include mq(md, min) {
    margin: 5.3rem 0 0;
  }
}
