@use '../../../foundation/config' as *;

.p-sustainabilityOther {
  padding-bottom: 25.2rem;

  @include mq(md) {
    padding-bottom: 24rem;
  }
}

.p-sustainabilityOther__inner {
  margin-top: 0;
  padding-bottom: 1rem;

  @include mq(md) {
    margin-top: 13.6rem;
    padding-bottom: 0.9rem;
  }
}

.p-sustainabilityOther__section {
  & + & {
    margin-top: 19.4rem;

    @include mq(md) {
      margin-top: 18.4rem;
    }
  }
}

.p-sustainabilityOther__body {
  .c-heading.-level2 + & {
    margin-top: 7.1rem;
  }
}

.p-sustainabilityOther__smallSection {
  &:not(:first-child) {
    margin-top: 9.4rem;
  }
}

.p-sustainabilityOther__text {
  @include lhCrop(2.25);
  font-size: 1.6rem;
  font-weight: normal;
  line-height: 2.25;

  @include mq(md) {
    @include lhCrop(2);
    font-size: 3rem;
    line-height: 2;
  }

  &.-normal {
    margin-top: 1rem;

    @include mq(md) {
      margin-top: 2rem;
    }

    &::before,
    &::after {
      display: none;
    }
  }

  &.-enactment {
    text-align: right;

    @include mq(md, min) {
      position: absolute;
      top: 2.7rem;
      right: 4rem;
    }

    &::before,
    &::after {
      display: none;
    }
  }

  .c-heading.-level3 + & {
    margin-top: 5rem;
  }

  .c-heading.-level5 + & {
    margin-top: 1rem;

    @include mq(md) {
      margin-top: 5rem;
    }
  }
}

.p-sustainabilityOther__table {
  margin-top: 8rem;

  a {
    color: color(gray-600);
  }

  th {
    width: 29.1rem;

    @include mq(md) {
      width: 26.1rem;
    }
  }
}

.p-sustainabilityOther__textLink {
  color: color(gray-600);
  transition-property: color, text-decoration-color;
  transition-duration: 400ms;
  transition-timing-function: easing(easeOutCubic);
  text-decoration: underline;
  text-underline-offset: 0.4rem;

  &:hover {
    color: color(green-600);
    text-decoration-color: rgba(color(gray-600), 0);
  }

  > span {
    @include mq(md, min) {
      display: inline-flex;
      align-items: center;
    }
  }
}

.p-sustainabilityOther__arrowIcon {
  width: 1.6rem;
  height: 1.1rem;
  margin-left: 0.5em;
  vertical-align: baseline;

  @include mq(md) {
    width: 2.4rem;
    height: 1.7rem;
  }
}

.p-sustainabilityOther__accordionBlock {
  & + & {
    margin-top: 5rem;
    border-top: 1px solid color(gray-300);

    @include mq(md, min) {
      .c-heading.-level5 {
        margin-top: 3.8rem;
      }
    }

    @include mq(md) {
      margin-top: 8rem;
    }
  }

  @include mq(md) {
    .c-heading.-level5 {
      margin-top: 5.5rem;
    }
  }
}

.p-sustainabilityOther__list {
  @include mq(md) {
    margin-top: 1.5rem;
  }

  li {
    margin-left: 1em;
    font-size: 1.6rem;
    text-indent: -1em;
    line-height: 2.25;

    @include mq(md) {
      font-size: 3rem;
    }
  }
}
