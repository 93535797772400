@use '../../../foundation/config' as *;

.p-irLibraryReportBacknumber {
  .l-irContent {
    @include mq(md) {
      margin-top: 15rem;
    }
  }
}

.p-irLibraryReportBacknumber__tabContent {
  margin-top: 9.6rem;
}

.p-irLibraryReportBacknumber__tabContentFiscalYearItem {
  margin-top: 9rem;
  .p-irLibraryReportBacknumber__tabContentList {
    margin-top: 5rem;
  }
  .p-irLibraryReportBacknumber__tabContentItem
    + .p-irLibraryReportBacknumber__tabContentItem {
    margin-top: 3.2rem;
    @include mq(md) {
      margin-top: 6rem;
    }
  }
}

.p-irLibraryReportBacknumber__tabContentItem a {
  font-size: 1.6rem;
  color: color(brack);
  line-height: 1.8;
  .c-button__pdf {
    line-height: 1.15;
  }
  @include mq(md) {
    font-size: 3rem;
    line-height: 2;
  }
}

.p-irLibraryReportBacknumber__tabContentLinkGroup {
  margin-top: 9.5rem;
  @include mq(md) {
    margin-top: 9rem;
  }
  > h2 {
    font-size: 2.6rem;
    @include mq(md) {
      font-size: 4rem;
    }
  }
}

.p-irLibraryReportBacknumber__tabContentLinkGroup.c-linkGroup.-gutter {
  > ul {
    margin: 4.2rem -5rem 0;
    @include mq(md) {
      margin: 4.2rem 0 0;
    }
  }
}

.p-irLibrary__directoryNav {
  margin: 20rem 0 24.4rem;
}

.p-irLibraryReportBacknumber__items{
  .p-irLibraryReportBacknumber__item {
    display: flex;
    align-items: center;
    padding: 3rem;
    background-color: color(gray-200);
  
    @include mq(md) {
      align-items: flex-start;
    }
  }
  & +.p-irLibraryReportBacknumber__item {
    margin-top: 3rem;
  }

  .p-irLibraryReportBacknumber__itemBody {
    font-size: 2rem;
    line-height: 2;
    font-weight: bold;

    @include mq(md) {
      margin: auto 0;
      font-size: 3.4rem;
      line-height: (54 / 34);
    }  

    .c-heading {
      font-size: 2.4rem;
  
      @include mq(md) {
        font-size: 3.5rem;
      }
    }

    .p-irLibraryMaterialBacknumber__linkText {
      font-feature-settings: "palt" 1;
    }
  }
  .p-irLibraryReportBacknumber__itemLinks {
    display: flex;
    flex-direction: column;

    @include mq(md, min) {
      padding-left: 1em;
    }

    @include mq(md) {
      a {
        margin-top: 4rem;
        font-size: 3rem;
      }
    }
  }
}
