@use '../../../foundation/config' as *;
.p-sustainabilityFormat__layout.-layout-social_contribution {
  margin-top: 5rem;

  @include mq(md, min) {
    display: flex;
    align-items: center;
  }

  .p-sustainabilityFormat__layoutImage {
    @include mq(md, min) {
      flex-shrink: 0;
      max-width: 50%;
    }

    @include mq(md) {
      margin-top: 5rem;
    }

    img {
      height: auto;
    }

    &.-photo.-center {
      picture {
        width: 40rem;
      }

      img {
        width: 40rem;
        @include mq(md) {
          width: 60rem;
          margin-bottom: 5rem;
        }
      }
    }
  }
}


