@use '../foundation/config' as *;

.l-main {
  padding-top: 12.5rem;
  @include mq(md) {
    padding-top: 15rem;
  }
  transition: padding 400ms easing(easeOutQuart);

  @include print {
    padding: 0;
  }

  .l-header.-dense + .l-content & {
    padding-top: 10.1rem;
  }
}

.l-main__container {
  padding-bottom: 25.2rem;
  @include mq(md) {
    padding-bottom: 24rem;
  }
}

.l-main__inner {
  max-width: 1920px;
  margin-right: auto;
  margin-left: auto;
  padding-right: 100 / 1600 * 100%;
  padding-left: 100 / 1600 * 100%;

  @include mq(md) {
    padding-right: 4rem;
    padding-left: 4rem;
  }
}
