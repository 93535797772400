@use '../../../foundation/config' as *;

.p-human_rights {

  @include mq(md, min) {
    .c-inPageLinkGroup__item {
      display: flex;
      flex-direction: column;
      justify-content: center;
      font-size: 1.8rem;
      line-height: 1.5;
    }
  }
}
