@use '../../foundation/config' as *;

.c-carousel {
  position: relative;
  margin-top: 4rem;
  padding-left: 6.25%;

  @include mq(md) {
    margin-top: 2.8rem;
    padding-left: 4rem;
  }

  .swiper-container {
    //左右の余白はcontainerに指定する
    padding: 0;
  }

  .swiper-wrapper {
    padding: 30px 0;
  }

  .swiper-slide {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    .c-carouselBox {
      height: 100%;
      img {
        width: 100%;
        height: 100%;
      }
    }

    &.-column3 {
      width: 35%;
      height: auto;

      @media (max-width: 1599px) {
        height: 32rem;
      }

      @include mq(md) {
        width: 66%;
      }
    }
  }
}

.carousel {
  width: 100%;
  height: 100%;
}

.c-carousel__arrow {
  position: relative;
  flex-shrink: 0;
  width: 1.6rem;
  height: 1.1rem;
  transition: color 400ms easing(easeOutCubic);

  @include mq(md) {
    width: 2.4rem;
    height: 1.7rem;
  }

  &:hover {
    color: color(green-600);
  }
}

.c-carousel__buttonWrapper {
  display: flex;
  justify-content: flex-end;
  margin-top: 1rem;
  padding-right: 6.25%;

  @include mq(md) {
    padding-right: 4rem;
  }
}

.c-carousel__button {
  display: flex;
  justify-content: center;
  align-items: center;
  position: static;
  width: 6rem;
  height: 6rem;
  margin-top: 0;
  border: 1px solid;
  background: none;
  cursor: pointer;
  z-index: z(content);

  &:hover {
    color: color(green-600);
  }

  @include mq(md) {
    width: 8rem;
    height: 8rem;
  }

  &.-next {
    margin-left: 2.1rem;
  }

  &.-prev {
    svg {
      transform: scale(-1, 1);
    }
  }
}
