@use '../../foundation/config' as *;

.c-accordion {
  button {
    position: relative;
    width: 100%;
    padding: 4rem;
    font-size: 2rem;
    font-weight: bold;
    text-align: left;
    border-top: 1px solid #ddddd5;
    border-bottom: 1px solid #ddddd5;
    transition: color 400ms cubic-bezier(0.215, 0.61, 0.355, 1);
    @include mq(md) {
      font-size: 3.4rem;
    }
    &[aria-expanded=true] {
      .c-accordion__iconMinus {
        display: block;
      }
      .c-accordion__iconPlus {
        display: none;
      }
    }
  }
  .c-accordion__iconMinus {
    display: none;
    position: absolute;
    top: 50%;
    right: 4rem;
    width: 1.4rem;
    height: 0.2rem;
    transform: translate(0, -50%);
    @include mq(md) {
      right: 3rem;
      width: 2.6rem;
      height: 0.4rem;
    }
  }
  .c-accordion__iconPlus {
    position: absolute;
    top: 50%;
    right: 4rem;
    width: 1.4rem;
    height: 1.4rem;
    transform: translate(0, -50%);
    @include mq(md) {
      right: 3rem;
      width: 2.6rem;
      height: 2.6rem;
    }
  }
  .c-accordion__body {
    overflow: hidden;
    padding: 4rem;
    background-color: #ededeb;
    text-align: initial;
  }
}
