// ページ内リンクグループ
// ============================================================================

@use '../../foundation/config' as *;

.c-inPageLinkGroup {
  @include mq(md, min) {
    display: flex;
    flex-wrap: wrap;
  }
}

.c-inPageLinkGroup__item {
  background-color: color(gray-200);
  font-size: 2rem;
  font-weight: bold;

  @include mq(md) {
    font-size: 3rem;
  }

  &:not(:first-child) {
    @include mq(md) {
      border-top: 1px solid color(gray-100);
    }
  }

  .c-inPageLinkGroup.-column4 & {
    @include mq(md, min) {
      flex: 0 0 25%;
    }

    &:not(:nth-child(4n + 1)) {
      @include mq(md, min) {
        border-left: 1px solid color(gray-100);
      }
    }

    &:not(:nth-child(-n + 4)) {
      @include mq(md, min) {
        border-top: 1px solid color(gray-100);
      }
    }
  }

  .c-inPageLinkGroup.-column3 & {
    @include mq(md, min) {
      flex: 0 0 1 / 3 * 100%;
    }

    &:not(:nth-child(3n + 1)) {
      @include mq(md, min) {
        border-left: 1px solid color(gray-100);
      }
    }

    &:not(:nth-child(-n + 3)) {
      @include mq(md, min) {
        border-top: 1px solid color(gray-100);
      }
    }
  }

  .c-inPageLinkGroup.-column2 & {
    @include mq(md, min) {
      flex: 0 0 50%;
    }

    &:not(:nth-child(2n + 1)) {
      @include mq(md, min) {
        border-left: 1px solid color(gray-100);
      }
    }

    &:not(:nth-child(-n + 2)) {
      @include mq(md, min) {
        border-top: 1px solid color(gray-100);
      }
    }
  }
}

.c-inPageLinkGroup__link {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.181em 2rem 1.181em 3rem;
  transition: color 400ms easing(easeOutCubic);

  @include mq(md) {
    padding: 0.897em 3rem;
  }

  &:hover {
    color: color(green-600);
  }
}

.c-inPageLinkGroup__chevron {
  flex-shrink: 0;
  width: 0.6rem;
  height: 1.1rem;
  transform: rotate(90deg);

  @include mq(md) {
    width: 1.2rem;
    height: 2.2rem;
  }
}
