@use '../../../foundation/config' as *;

.p-researchBlock3 {
  &.-researchTop {
    margin: 9rem 0 0;
  }
}

.p-researchBlock3__item {
  & + & {
    margin-top: 8rem;
  }

  @include mq(md, min) {
    display: flex;
  }
}

.p-researchBlock3__text {
  @include mq(md, min) {
    font-size: 1.6rem;
    line-height: (36/16);
    width: 100%;
  }

  @include mq(md) {
    font-size: 3rem;
    line-height: (60/30);
  }
}

.p-researchBlock3__title {
  @include mq(md, min) {
    margin: 0 0 2.4rem;
    font-size: 2.6rem;
    @include lhCrop(46 / 26);
  }

  @include mq(md) {
    margin: 0 0 3.2rem;
    font-size: 4rem;
    @include lhCrop(60 / 40);
  }
}

.p-researchBlock3__description {
  p + p {
    margin-top: 2rem;
  }
}

.p-researchBlock3__note {
  @include mq(md, min) {
    font-size: 1.4rem;
    line-height: (26/14);
  }

  @include mq(md) {
    font-size: 2.4rem;
    line-height: (40/24);
  }
}

.p-researchBlock3__image {
  @include mq(md, min) {
    width: 43.5rem;
    flex-shrink: 0;
    margin: 0 0 0 9rem;
  }

  @include mq(md) {
    margin: 3.5rem 7.4rem 0;
  }

  img {
    width: 100%;
    height: auto;
  }
}

.p-researchBlock3__button {
  @include mq(md, min) {
    margin: 4rem 0 0;
    display: flex;

    .c-button + .c-button {
      margin-left: 5rem;
    }
  }

  @include mq(md) {
    margin: 3.5rem 0 5rem 0;

    .c-button + .c-button {
      margin-top: 5rem;
    }
  }
}
