@use '../../foundation/config' as *;

.c-sustainabilityNav {
  background: color(white);

  // 【スマホのみ】ナビゲーションヘッダー
  .c-sustainabilityNavHeader {
    display: flex;
    align-items: center;
    flex-grow: 1;
    padding-left: 4rem;
    cursor: pointer;
    h2 {
      display: none;
      @include mq(md) {
        display: block;
        font-size: 4rem;
      }
    }
  }

  // 【スマホのみ】ナビゲーションヘッダー メニュー部分
  .p-sustainabilityNav__navGroup {
    height: 12.6rem;
    margin-left: auto;

    @include mq(md, min) {
      height: 0;
      display: flex;
      margin-right: 1.2rem;
      padding-bottom: 0;
    }
  }

  // 【スマホのみ】ナビゲーションハンバーガーメニュー
  .p-sustainabilityNav__menuButton {
    width: 12.6rem;
    height: 100%;
    border-left: 1px solid color(gray-300);
    position: relative;
    text-align: center;
  }

  // メニューボタン
  .p-sustainabilityNav__menuIcon {
    width: 4.4rem;
    height: 3.3rem;
  }

  // クローズボタン
  .p-sustainabilityNav__closeIcon {
    width: 3.4rem;
    height: 3.5rem;
    display: none;
  }

  &.-show {
    .p-sustainabilityNav__menuIcon {
      display: none;
    }
    .p-sustainabilityNav__closeIcon {
      display: inline-block;
    }
  }

  /*
   * ナビゲーションコンテンツ本体
   */
  .p-sustainabilityNav__navGroupContent {
    @include mq(md) {
      background-color: color(gray-50);
      overflow: hidden;
      height: 0;
    }
  }

  .p-sustainabilityNav__navGroupContentInner {
    @include mq(md, min) {
      display: flex;
      flex-direction: column-reverse;
    }

    @include mq(md) {
      padding-bottom: 0;
    }

    .p-sustainabilityNav.-dense & {
      @include mq(md, min) {
        flex-direction: row;
      }
    }
  }

  // ドロップダウン 親
  .p-sustainabilityNav__parentNavList {
    border-top: 1px solid color(gray-300);
    @include mq(md, min) {
      display: block;
      border-top: none;
    }

    .p-sustainabilityNav.-dense & {
      align-items: center;
    }
  }

  .p-sustainabilityNav__parentNavItem {
    @include mq(md) {
      border-bottom: 1px solid color(gray-100);
    }

    + .p-sustainabilityNav__parentNavItem {
      @include mq(md, min) {
        border-top: 1px solid color(gray-100);
      }
    }

    &.-has-staticChild {
      .p-sustainabilityNav__parentNavLink:before {
        @include mq(md, min) {
          display: none;
        }
      }
      .p-sustainabilityNavChildMenu__contents {
        border-top: 0;
      }
      .p-sustainabilityNavChildMenu__navList {
        padding-top: 0;
      }
      .p-sustainabilityNavChildMenu__navItem:first-child {
        .p-sustainabilityNavChildMenu__navLink {
          padding-top: 0;
        }
      }
      .p-sustainabilityNavChildMenu__navLink {
        @include mq(md) {
          padding: 0.5em 0 0.5em 4rem;
        }
      }
    }
  }

  .p-sustainabilityNav__parentNavItem:last-child {
    @include mq(md) {
      border-bottom: none;
    }
  }

  // ナビゲーションのリンク および ドロップダウンのボタン
  .p-sustainabilityNav__parentNavButton,
  .p-sustainabilityNav__parentNavLink {
    display: inline-flex;
    position: relative;
    align-items: center;
    padding: 1.1456em 4rem;
    font-size: 3.4rem;
    font-weight: bold;
    line-height: (40 / 18);
    transition-property: font-size, color;
    transition-duration: 200ms, 400ms;
    transition-timing-function: easing(easeOutCubic);
    width: 100%;

    @include mq(md, min) {
      font-size: 1.6rem;
      padding: 0.77em 1.18em 0.77em 1.88em;
      &::before {
        content: '';
        display: block;
        position: absolute;
        right: 0;
        bottom: -2.5rem;
        left: 0;
        height: 2.5rem;
      }
    }

    @include mq(md) {
      line-height: 1.2;
    }

    &:hover {
      @include mq(md, min) {
        color: color(green-600);
      }
    }

    // 開いた時
    &.ui-accordion-header-active {
      .p-sustainabilityNav__parentNavMinus {
        display: inline-block;
      }

      .p-sustainabilityNav__parentNavPlus {
        display: none;
      }
    }

    // マイナスボタン
    .p-sustainabilityNav__parentNavMinus {
      width: 2.6rem;
      height: 0.4rem;
      margin-left: auto;
      display: none;

      // PC
      @include mq(md, min) {
        display: none;
        width: 1.3rem;
        height: 0.2rem;
      }
    }

    // プラスボタン
    .p-sustainabilityNav__parentNavPlus {
      width: 2.6rem;
      height: 2.6rem;
      margin-left: auto;

      // PC
      @include mq(md, min) {
        display: inline-block;
        width: 1.3rem;
        height: 1.3rem;
      }
    }
  }

  .p-sustainabilityNav__parentNavArrow {
    width: 2.4rem;
    height: 1.7rem;
    margin-left: auto;

    @include mq(md, min) {
      display: none;
    }
  }

  .p-sustainabilityNavChildMenu__contents {
    line-height: 2;
    background-color: transparent;
    padding: 1.4rem 5rem 3rem;
    border-top: 1px solid color(gray-100);

    @include mq(md, min) {
      display: block;
      padding: 0;
      line-height: 1.4;
    }

    @include mq(md) {
      padding-bottom: 6rem;
      background-color: transparent;
    }
  }

  .p-sustainabilityNavChildMenu__nav {
    @include mq(md) {
      flex: 0 0 52.5rem;
      padding: 3.6rem 0 1.6rem;
    }
  }

  .p-sustainabilityNavChildMenu__navList {
    @include mq(md, min) {
      padding: 1.8rem 0 1.2rem;
    }
  }

  .p-sustainabilityNavChildMenu__navItem {
    font-size: 2rem;
    font-weight: bold;

    @include mq(md) {
      font-size: 3.6rem;
    }
  }

  .p-sustainabilityNavChildMenu__navLink {
    display: inline-block;
    font-size: 3rem;
    font-weight: 400;
    padding: 0.5em 2.2rem;

    @include mq(md) {
      display: block;
      padding: 0.5em 4rem;
    }

    @include mq(md, min) {
      display: block;
      font-size: 1.4rem;
      padding: 1.125rem 2rem 1.125rem 5rem;
    }

    &:hover {
      color: color(green-600);
    }
  }
}
