@use '../../../foundation/config' as *;

.p-companyBlock2 {
  margin: 13.7rem 0 0;
  @include mq(md, min) {
    margin: 14.2rem 0 0;
  }
}
.p-companyBlock2__wrap1 {
  margin: 5.2rem 0 0;
  @include mq(md, min) {
    margin-top: 6.1rem;
  }
}
.p-companyBlock2__wrap1Image1 {
  text-align: center;

  img {
    width: 64.8rem;
    height: auto;
    @include mq(md, min) {
      width: 49rem;
    }
  }
}
.p-companyBlock2__wrap1Body1 {
  margin: 6.3rem 0 0;
  @include mq(md, min) {
    margin: 3.6rem 0 0;
  }
}
.p-companyBlock2__text {
  font-size: 3.4rem;
  line-height: (64/34);
  font-weight: bold;
  margin: 2.6rem 0 0;
  @include mq(md, min) {
    font-size: 2rem;
    text-align: center;
    line-height: (40/20);
    margin: 6.9rem 0 0;
  }
}
