@use '../../../foundation/config' as *;

.p-companyBlock5 {
  margin: 13.8rem 0 0;
  @include mq(md, min) {
    margin: 14.1rem 0 0;
  }
  @at-root {
    .p-companyBlock5 + .p-companyBlock5 {
      margin: 18.5rem 0 0;
      @include mq(md, min) {
        margin: 19.2rem 0 0;
      }
    }
    .p-companyPageNav1 + .p-companyBlock5 {
      @include mq(md, min) {
        margin: 7.4rem 0 0;
      }
    }
  }
}
.p-companyBlock5__title2 {
  margin: 3.9rem 0 -5.1rem;
  @include mq(md, min) {
    margin: 3.9rem 0 -5.6rem;
  }
  @at-root {
    .p-companyBlock5__table1 + .p-companyBlock5__title2 {
      margin-top: 6.6rem;
      @include mq(md, min) {
        margin-top: 6.8rem;
      }
    }
  }
}
.p-companyBlock5__table1 {
  margin: 6.9rem 0 0;
  @include mq(md, min) {
    margin: 7.5rem 0 0;
  }
  &:first-child {
    margin: 14.8rem 0 0;
    @include mq(md, min) {
      margin: 14.9rem 0 0;
    }
  }
}
.p-companyTable1 {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  th,
  td {
    font-size: 2.6rem;
    line-height: (40/26);
    border-top: 1px solid color(gray-300);
    border-bottom: 1px solid color(gray-300);
    @include mq(md, min) {
      font-size: 1.6rem;
      line-height: (36/16);
    }
  }
  th {
    background: color(gray-200);
    font-weight: bold;
    min-width: 25.7rem;
    max-width: 25.7rem;
    padding: 0 0 0 3rem;
    text-align: left;
    @include mq(md, min) {
      min-width: 29rem;
      max-width: 29rem;
      padding: 0 0 0 2.1rem;
    }
  }
  td {
    border-left: 1px solid color(gray-300);
    padding: 2.3rem 0 2.4rem 3rem;
    width: 100%;
    @include mq(md, min) {
      padding: 1.7rem 0 1.9rem 1.9rem;
    }
  }
  p {
    margin: 2rem 0 0;
    @include mq(md, min) {
      margin: 1rem 0 0;
    }
    &:first-child {
      margin-top: 0;
    }
  }
  a {
    color: color(gray-600);
    display: inline-flex;
    align-items: center;
    transition: color 400ms easing(easeOutCubic);
    &.-external {
      transition-property: color, text-decoration-color;
      transition-duration: 400ms;
      transition-timing-function: easing(easeOutCubic);
      text-decoration: underline;
      text-underline-offset: 0.4rem;

      i {
        display: block;
        width: 2.1rem;
        height: 2.1rem;
        font-size: 0;
        margin: 0 0 0 1.8rem;
        @include mq(md, min) {
          width: 1.4rem;
          height: 1.4rem;
          margin: 0 0 0 1rem;
        }
      }
      svg {
        width: 2.1rem;
        height: 2.1rem;
        @include mq(md, min) {
          width: 1.4rem;
          height: 1.4rem;
        }
      }
    }
    &.-arrowRight {
      i {
        display: block;
        width: 2.4rem;
        height: 1.7rem;
        font-size: 0;
        margin: 0 0 0 1.8rem;
        @include mq(md, min) {
          width: 1.6rem;
          height: 1.1rem;
          margin: 0 0 0 1rem;
        }
      }
      svg {
        width: 2.4rem;
        height: 1.7rem;
        @include mq(md, min) {
          width: 1.6rem;
          height: 1.1rem;
        }
      }
    }
    &:hover {
      color: color(green-600);
      text-decoration-color: rgba(color(gray-600), 0);
    }
  }
}

.p-companyBlock5__list {
  max-width: 60rem;
  margin-top: 2rem;

  @include mq(md, min) {
    display: flex;
    flex-wrap: wrap;
  }

  > li:nth-child(even) {
    @include mq(md, min) {
      margin-left: 2rem;
    }
  }

  > li:nth-child(n + 3) {
    @include mq(md, min) {
      margin-top: 2rem;
    }
  }

  > li:nth-child(n + 2) {
    @include mq(md) {
      margin-top: 2rem;
    }
  }

  a {
    color: inherit;
  }
}

p.p-companyBlock5__map1 {
  position: relative;
  padding-top: 65.5%;
  margin: 5rem 0 0;
  @include mq(md, min) {
    margin: 2.1rem 0 0;
    padding-top: 46.7%;
  }
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
