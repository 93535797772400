@use '../../../foundation/config' as *;

.p-formBlock3 {
  margin: 8.6rem 0 25rem;
  @include mq(md, min) {
    margin: 6.8rem auto 25rem;
    width: 80rem;
  }
}
.p-formBlock3__title1 {
  font-size: 4rem;
  line-height: (60/40);
  font-weight: bold;
  text-align: center;
  @include mq(md, min) {
    font-size: 2.6rem;
    line-height: (46/26);
  }
}
.p-formBlock3__text1 {
  margin: 2.1rem 0 0;
  @include mq(md, min) {
    margin: 1.4rem 0 0;
  }
  & > p {
    font-size: 3rem;
    line-height: (60/30);
    text-align: center;
    @include mq(md, min) {
      font-size: 1.6rem;
      line-height: (36/16);
    }
  }
  & > p:nth-of-type(2) {
    margin-top: 6.8rem;
    text-align: left;
  }
}
.p-formBlock3__button1 {
  margin: 3.1rem 0 0;
  @include mq(md, min) {
    text-align: center;
    margin: 4rem 0 0;
  }
}
