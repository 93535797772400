@use '../../../foundation/config' as *;

.p-companyBlock4 {
  margin: 18.9rem 0 0;
  @include mq(md, min) {
    margin: 19.1rem 0 0;
  }
}

.p-companyBlock4__text1 {
  font-size: 3rem;
  line-height: (60/30);
  margin: 3.4rem 0 0;
  text-align: justify;
  @include mq(md, min) {
    font-size: 1.6rem;
    line-height: (36/16);
    margin: 3.8rem 0 0;
  }
  & > p {
    margin: 6rem 0 0;
    @include mq(md, min) {
      // margin: 3.6rem 0 0;
      margin: 1.6rem 0 0;
    }
    &:first-child {
      margin-top: 0;
    }
  }
}
