@use '../../foundation/config' as *;

.c-block {
  &.-hasIndent {
    padding-left: 2em;
    @include mq(md) {
      padding-left: 1em;
    }
  }
  &.-isNote {
    margin-bottom: 2.5rem;
    font-size: 3rem;
    line-height: 2;
    @include mq(md, min) {
      font-size: 1.6rem;
      line-height: 2.25;
    }
  }
  .c-heading {
    .p-content & {
      margin-bottom: 0;
    }
  }
}

.c-cards {
  @include mq(md, min) {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    text-align: center;

    &.-column2 {
      .c-card {
        width: 48%;
      }
    }
  }
  @include mq(md) {
    &.-column2 {
      .c-card {
        margin-top: 2.5rem;
      }
    }
  }
  img {
    width: 100%;
  }
}
