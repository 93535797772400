@use '../../../foundation/config' as *;

.p-content {
  &.-hasSideNav,
  &.-sus__top {
    margin-top: 0;
  }
  .p-sustainabilityFormat__section {
    margin: 10rem 0;
  }
  .p-sustainabilityFormat__text {
    font-size: 2rem;
    line-height: (60/30);
    @include mq(md) {
      font-size: 3.4rem;
      line-height: (54 / 34);
    }
  }
  .p-sustainabilityFormat__image {
    @include mq(md, min) {
      margin-bottom: 7.9rem;
    }
  }
  .p-sustainabilityFormat__button {
    margin-top: 2.5rem;
    margin-bottom: 5rem;
  }
  .p-sustainabilityFormat__list {
    font-size: inherit;
    
    li::before {
      top: 2.4rem;

      @include mq(md) {
        top: 3.5rem;
      }
    }
  }
  .-isAnnotation {
    font-size: 1.6rem;
  }

  .-hasGap {
    @include mq(md, min) {
      margin-left: 5rem;
    }
    @include mq(md) {
      margin-top: 3rem;
    }
  }

  .c-heading.-hasNoMarginTop {
    margin-top: 0;
  }

  .-colorInitial:not(:hover) {
    color: initial;
  }

  .p-sustainabilityFormat__layout {
    &.-narrow {
      @include mq(md, min) {
        .p-sustainabilityFormat__text {
          width: 60%;
        }
        .p-sustainabilityFormat__layoutImage {
          width: 30%;
        }
      }
    }
  }
  .p-sustainabilityEnvironment__tableList {
    &.-adjusted {
      margin-bottom: 0;
    }
  }
  .-hasDisc {
    display: flex;
    align-items: center;

    &:before {
      content: "";
      width: 0.6em;
      height: 0.6em;
      border-radius: 50%;
      background-color: #00a040;
      font-weight: 700;
      line-height: inherit;
      margin-right: 0.4em;
      margin-top: 0.1em;
    }
    &.-hasDisclack:before {
      background-color: #000;
    }
  }
  .p-sustainabilityKpiTable {
    margin: 5rem 0;
    text-align: initial;
    @include mq(md) {
      margin-top: 7rem;
    }

    .col_materiality {
      width: 20rem;
      @include mq(md) {
        width: 32rem;
      }
    }
    .col_theme {
      width: 28rem;
      @include mq(md) {
        width: 44rem;
      }
    }
    .col_SDGsLogo {
      width: 15rem;
      @include mq(md) {
        width: 26rem;
      }
    }

    th.-bgRow {
      background-color: #ededeb;
    }

    th.-bgColumn {
      background-color: #f1f1ee;
    }

    ul {
      margin-bottom: 0;
    }
    &__kpi {
      li {
        list-style: disc;
        margin-left: 1.6rem;
      }
    }

    &__logo {
      display: flex;
      flex-wrap: wrap;

      li {
        width: 50%;
        padding: .5rem;
      }
    }

    dd {
      text-indent: 1.6rem;
    }
    .-inherit {
      list-style: inherit;
    }
  }
}

.p-inner {
  margin: 1em 0;
}

.p-cards {
  .p-card {
    margin-top: 10rem;
  }
  @include mq(md) {
    .p-cardBodyText {
      font-size: 3rem;
      line-height: 2;
    }
  }

  @include mq(md, min) {
    display: flex;
    justify-content: center;

    .p-card {
      display: flex;
      flex-direction: column;
      margin: 0 4rem;
    
      .p-cardBody {
        display: flex;
        flex-direction: column;
        height: 100%;

        .c-heading {
          margin-bottom: 0;
        }

        > * {
          margin: 2rem 0;
        }
      }
      .p-cardBodyText {
        height: 100%;
      }
    }
    &.p-cards__column2 {
      .p-card {
        width: 40%;
      }
    }
  }
}


.p-sustainabilityKV {
  position: relative;

  @include mq(md) {
    margin: 0 -4rem 0;

    .-has-gradation {
      position: relative;
      display: block;
      max-width: 100%;
      width: 100%;
      height: 100%;
  
      &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-image: linear-gradient(180deg, transparent 0 80%, #F6F6F3 100%);
      }
    }
  }

  img {
    width: 100%;
    height: auto;
  }

  .p-sustainabilityKV__text {
    padding: 4rem;

    @include mq(md, min) {
      position: absolute;
      top: 0;
      left: 0;
      display: flex;
      align-items: center;
      height: 100%;
      padding: 0 7rem 0 4rem;
    }
  }

  .p-sustainabilityKV__textStatement {
    @include font-serif;
    font-size: 3rem;
    font-weight: 600;
    line-height: 1.75;

    @include mq(md) {
      font-size: 4rem;
    }  
  }
}

.p-irIndividualKeywords {
  &.p-materialityKeywords {
    margin-top: 0;

    .p-materialityKeywordsNav {
      background-color: transparent;

      ul {
        flex-wrap: wrap;
        justify-content: center;
      }

      li {
        background-color: #fff;

        @include mq(md, min) {
          margin: 2rem;
        }
      }

      li + li {
        border: none;

        @include mq(md) {
          margin: 2rem 0 0;
        }
      }

      a {
        img {
          width: 7rem;
          height: 7rem;
        }
        @include mq(md) {
          padding: 3.5rem 0.5rem 3.5rem 2.4rem;
        }
          i {
          @include mq(md) {
            border: 1px solid #000;
            padding: 1.5rem;
          }
          svg {
            transform: rotate(0);
            @include mq(md) {
              vertical-align: middle;
            }
          }
        }
      }

      .p-materialityKeywordsText {
        @include mq(md, min) {
          display: flex;
          flex-direction: column;
          justify-content: center;
          flex-grow: 2;
          padding-top: 2rem;
        }
        @include mq(md) {
          padding-left: 1.7rem;
          font-size: 2.5rem;
        }
      }
    }
  }
}

.p-sustainabilityMaterialityNav__group {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

.p-sustainabilityMaterialityNav__item {
  height: 100%;
  padding: 2rem;
  background-color: #ededeb;
  text-align: center;

  > img {
    width: 8rem;
    height: 8rem;
  }
  
  > a {
    display: flex;
    flex-direction: column;
    align-items: center;  
  }
}


.p-materiality__flame {
  background-color: #fff;

  @include mq(md) {
    margin: 0 -4rem;
    border-right: 0;
    border-left: 0;
  }

  .-hasIcon {
    display: flex;
    align-items: center;

    img {
      width: 7rem;
      height: 7rem;
      margin-right: 1.5rem;

      @include mq(md) {
        width: 8rem;
        height: 8rem;
      }

    }

    .p-materiality__icon {
      width: 8rem;
      height: auto;
    
      @include mq(md) {
        width: 10rem;
      }
    }
  }

  .c-heading.-level2 {
    font-family: "Noto Sans JP", sans-serif;
    font-size: 2.3rem;
    margin: 5rem 0 2.5rem;
    padding-left: 2rem;

    @include mq(md) {
      margin-top: 7rem;
      padding-left: 2.2rem;
      font-size: 3.8rem;
    }

    &::before {
      width: 0.4rem;
      height: 100%;

      @include mq(md) {
        width: 0.8rem;
      }
    }
  }
  .c-heading.-level3 {
    margin-top: 0;
    font-family: "Noto Sans JP", sans-serif;

    @include mq(md) {
      font-size: 4.4rem;
    }
  }
  .c-box__heading.-level4 {
    margin: 0 0 1rem;

    &:last-of-type {
      margin: 0;
    }
  }
  .p-materiality__card {
    padding: 2.5rem;
    background-color: #ededeb;

    @include mq(md) {
      padding: 4rem;
    }

    + .p-materiality__card {
      margin-top: 2rem;
    }

    & .-inner {
      margin: 2rem 0 0;
      padding: 2rem 2.5rem;
      background-color: #fff;
      border-radius: 1.4rem;

      @include mq(md) {
        margin-left: -4rem;
      }  
    }

    > p {
      margin-top: 1rem;
      margin-bottom: 1rem;

      @include mq(md) {
        font-size: 2.8rem;
      }
    }
    > p:last-child {
      margin-bottom: 0;
    }

    .p-list {
      margin-bottom: 0;

      li {
        margin: 2rem 2.5rem 0;
        line-height: 1.5;

        @include mq(md) {
          margin: 4rem 0 0 4rem;
          font-size: 3rem;
        }  
      }
    }
  }
  .c-table.-style1 table{
    table-layout: fixed;
    width: 100%;
    border-top: none;

    th, td {
      height: 4rem;
      padding: 1rem;
      line-height: 1.2;

      @include mq(md) {
        padding: 1rem;
        line-height: 1.2;
      }
    }

    tr:first-child td, tr:first-child th{
      padding-top: 0;
    }

    tr:last-child td, tr:last-child th{
      border-bottom: none;
      padding-bottom: 0;
    }

    tr:not(:first-of-type):not(:last-of-type) {
      > th, td {
        padding: 1.5rem 0;
      }
    }
  }
  .c-box__body {
    font-size: 2rem;

    @include mq(md) {
      font-size: 3.4rem;
    }

    .c-box__lead {
      margin: 5rem 0 0;

      @include mq(md) {
        margin: 7rem 0 0;
      }  
    }
  }
  .p-materiality__table {
    .heading-row {
      font-size: 2rem;

      &.-green {
        color: #00a040;
      }

      @include mq(md) {
        font-size: 2.5rem;
      }
    }
    .heading-label {
      padding: .1rem 4rem;
      background-color: #00a040;
      border-radius: 2rem;
      color: #fff;
      font-size: 1.8rem;

      @include mq(md) {
        padding: .1rem 1.5rem;
        border-radius: 3rem;
        font-size: 2rem;
      }
    }
    .value-indicator {
      font-size: 2rem;
      font-weight: 700;
      line-height: 1.2;
      color: #00a040;

      @include mq(md) {
        font-size: 3rem;

        .u-hidden-md-up + .-hasSpace {
          margin-left: 0;
        }
      }

      > strong {
        font-size: 2.5rem;

        @include mq(md) {
          font-size: 4rem;
        }  

        &.-hasSpace {
          margin-left: 1.5rem;
        }
      }

      .-isSmall {
        display: inline-block;
        font-size: 1.6rem;

        @include mq(md) {
          font-size: 2.5rem;
        }  
      }

      &.fs1 {
        font-size: 2.5rem;
        line-height: 0;
      
        span {
          font-size: 1.6rem;
        }
      }

      &.fs2 {
        span {
          font-size: 1.4rem;
        }
      }
    }
  }
  .p-materiality__block {
    padding: 0 2.5rem;

    @include mq(md) {
      padding: 0;
    }

    .logo-sdgs {
      width: 8rem;

      @include mq(md) {
        width: 10rem;
      }
    }
  }
  .p-materiality__sdgsLogos {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    margin-bottom: 0;

    @include mq(md) {
      margin-bottom: 1rem;
    }

    li {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      margin: .5rem;

      small {
        margin-top: .5rem;
        font-size: 1.6rem;
        line-height: 1.2;

        @include mq(md) {
          font-size: 2rem;
        }
      }
    }
  }
}
