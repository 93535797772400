@use '../../../foundation/config' as *;

.p-irGrowth__section {
  display: block;
  margin: 0 auto 15rem;
  @include mq(md) {
    display: block;
    margin: 13rem 0 22rem;
  }
}

.p-irGrowth__catch {
  h2 {
    @include font-serif;
    font-size: 4.23rem;
    line-height: 1.45;
    @include mq(md) {
      font-size: 6rem;
      line-height: 1.32;
    }
  }
}

.p-irGrowth__text {
  margin-top: 3rem;

  & > p {
    font-size: 1.6rem;
    line-height: 2.28;
    text-align: justify;
    margin: 2.3em 0 0;

    @include mq(md) {
      font-size: 2.8rem;
      line-height: 2.15;
      padding-right: 0;
    }

    &:first-child {
      margin-top: 0;
    }
  }
}

.p-irGrowth__signatureSection {
  display: flex;
  margin-top: 2.4rem;
  @include mq(md) {
    display: block;
  }
}

.p-irGrowth__position {
  font-size: 1.6rem;
  font-weight: 700;
  line-height: 2.2;
  margin-top: 0.5rem;
  margin-right: 2.8rem;
  @include mq(md) {
    font-size: 3rem;
    line-height: 2;
    margin-top: 0.7rem;
  }
}

.p-irGrowth__signature {
  width: 27rem;
  img {
    width: 100%;
    height: auto;
  }
  @include mq(md) {
    width: 36rem;
    margin-top: 2rem;
  }
}

.p-irGrowth__imageSection {
  margin-top: 8rem;
  @include mq(md) {
    margin-top: 6.8rem;
  }
}

.p-irGrowth__imageSection2 {
  margin: 8rem 0 4rem;
  @include mq(md) {
    margin: 6.8rem 0;
  }
}

.p-irGrowthVision_statement {
  margin: 7.1rem 0 9.4rem;

  > h3 {
    font-size: 5rem;
    line-height: 1;
  
    @include mq(md) {
      font-size: 7.2rem;
      line-height: (80 / 70);
    }
  }

  > p {
    margin-top: 1.5rem;
    font-size: 2.4rem;
    line-height: 1;
  
    @include mq(md) {
      font-size: 5rem;
      line-height: 1.2;
    }
  }
}

.p-irGrowthSection {
  &.-statement {
    margin: 7.5rem 0;
  }

  font-size: 100%;

  @include mq(md, min) {
    font-size: 120%;
  }

  > h3 {
    font-size: 170%;
    @include mq(md, min) {
      font-size: 160%;
    }
  }
}
