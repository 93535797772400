@use '../../../foundation/config' as *;
.p-sustainabilityFormat__section{
  &.p-sustainabilityEnvironment__sectionAnchor{
    margin-top: 9.7rem;

    @include mq(md) {
      margin-top: 9.2rem;
    }
  }
  &#environmental_management{
    margin-top: 9.7rem;

    @include mq(md) {
      margin-top: 9.2rem;
    }
  }
}

.c-inPageLinkEnvironmentGroup__item {
  background-color: color(gray-200);
  font-size: 1.6rem;
  font-weight: bold;
  @include mq(md) {
    font-size: 3rem;
  }
}

.p-sustainabilityEnvironment__table {
  margin: 5rem 0;

  @include mq(md, min) {
    .-evenly {
      col {
        width: 25%;
      }
    }
  }
  @include mq(md) {
    .-evenly {
      .w29 {
        width: 29%;
      }
    }
    .-vertical {
      writing-mode: vertical-lr;
    }
  }
}

.p-sustainabilityEnvironment__tableList {
  margin-left: 1em;
  padding-left: 1em;

  li {
    list-style-type: disc;
  }
}
