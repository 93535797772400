@use '../../../foundation/config' as *;

.p-companyPageNav1 {
  margin: 14.8rem 0 0;
  @include mq(md, min) {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
  }
  & > li {
    margin: 1px 0 0;
    @include mq(md, min) {
      min-width: 25%;
      max-width: 25%;
      margin: 0;
      border-left: 1px solid color(white);
    }
    @at-root {
      .p-companyPageNav1.-column3 > li {
        @include mq(md, min) {
          max-width: 33.333%;
          min-width: 33.333%;
          margin: 1px 0 0;
        }
      }
    }
    &:first-child {
      @include mq(md, min) {
        border: none;
      }
    }
    & > a {
      display: block;
      background: color(gray-200);
      font-size: 3rem;
      line-height: (60/30);
      font-weight: bold;
      padding: 2rem 7rem 1.8rem 3rem;
      position: relative;
      transition: color 400ms easing(easeOutCubic);
      @include mq(md, min) {
        font-size: 2rem;
        line-height: (40/20);
        padding: 2rem 0 2rem 3rem;
      }
      &:hover {
        color: color(green-600);
      }
    }
    i {
      position: absolute;
      top: -0.5rem;
      bottom: 0;
      right: 3.3rem;
      display: block;
      margin: auto;
      width: 1.6rem;
      height: 1.6rem;
      border-bottom: 2px solid;
      border-right: 2px solid;
      transform: rotate(45deg);
      @include mq(md, min) {
        width: 0.8rem;
        height: 0.8rem;
        top: 0;
        right: 2.1rem;
      }
    }
  }
}
