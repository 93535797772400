@use '../../foundation/config' as *;

.p-excerptedArticle {
  //
}

.p-excerptedArticle__list {
  border-top: 1px solid color(gray-300);
}

.p-excerptedArticle__item {
  position: relative;
  padding-top: 3.5rem;
  padding-bottom: 3.5rem;
  padding-left: 3rem;
  border-bottom: 1px solid color(gray-300);

  @include mq(md) {
    padding-top: 5rem;
    padding-bottom: 6.2rem;
  }

  .-topNotifications & {
    display: flex;
    padding-top: 1.85rem;
    padding-bottom: 1.85rem;

    @include mq(md) {
      flex-direction: column;
      padding-top: 3.2rem;
      padding-bottom: 3.2rem;
    }
  }
}

.p-excerptedArticle__header{
  display: flex;
  margin-bottom: 1.3rem;
  @include mq(md) {
    margin-bottom: 2.2rem;
  }
}
.p-excerptedArticle__time {
  width: 10.2rem;
  @include mq(md) {
    width: 17rem;
  }
  display: block;
  @include font-barlow;
  font-size: 1.4rem;
  letter-spacing: 0.05em;

  @include mq(md) {
    font-size: 2.4rem;
  }

  .-topNotifications & {
    line-height: (32 / 14);
  }
}

.p-excerptedArticle__categories {
  display: block;
  .-topNotifications & {
    display: none;
  }
}

.p-excerptedArticle__category {
  display: inline-block;
  padding-right: 1rem;
  padding-left: 1rem;
  color: color(green-600);
  font-size: 1.4rem;

  @include mq(md) {
    font-size: 2.4rem;
  }
}

.p-excerptedArticle__link {
  display: block;
  padding-right: 6rem;
  overflow: hidden;

  @include mq(md) {
    padding-right: 4.6rem;
  }

  &::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 0;
    transition: width 800ms easing(easeOutCubic);
    background-color: color(green-200);
    border-bottom: 2px solid color(green-600);
    z-index: -1;
  }

  &::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  &:hover::before {
    left: 0;
    width: 100%;
    transition: width 600ms easing(easeOutCubic);
  }

  .-topNotifications & {
    @include mq(md, min) {
      display: flex;
      align-items: center;
    }

    @include mq(md) {
      padding-right: 7.6rem;
    }
  }
}

.p-excerptedArticle__title {
  font-size: 1.6rem;
  font-weight: bold;
  line-height: (36 / 16);

  @include mq(md) {
    font-size: 3.4rem;
    line-height: (54 / 34);
  }

  &:not(:last-child) {
    margin-right: 2.8rem;
  }

  .-topNotifications & {
    font-weight: normal;
    overflow: hidden;

    @include mq(md, min) {
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    @include mq(md) {
      font-size: 3rem;
      line-height: 2;
    }
  }
}

.p-excerptedArticle__pdf {
  display: inline-block;
  padding: 0.1rem 1.934em;
  border-radius: 12.5rem;
  border: 1px solid;
  color: color(gray-600);
  @include font-barlow;
  font-size: 1.4rem;

  @include mq(md) {
    padding: 0.2rem 1.105em;
    font-size: 2.4rem;
    line-height: 1.1;
  }
}

.p-excerptedArticle__arrowIcon {
  position: absolute;
  right: 2rem;
  bottom: 2rem;
  width: 1.1rem;
  height: 0.8rem;
  transition: color 400ms easing(easeOutCubic);

  @include mq(md) {
    width: 1.7rem;
    height: 1.2rem;
  }

  .p-excerptedArticle__link:hover + & {
    color: color(green-600);
  }

  .-topNotifications & {
    bottom: 3.1rem;

    @include mq(md) {
      right: 3rem;
      bottom: 50%;
      transform: translateY(50%);
    }
  }
}

.p-excerptedArticle__footer {
  border-bottom: 1px solid color(gray-300);
  font-size: 1.6rem;
  font-weight: bold;

  @include mq(md) {
    font-size: 3rem;
    line-height: 2;
  }
}

.p-excerptedArticle__footerLink {
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  padding-top: 3.75rem;
  padding-bottom: 3.75rem;
  overflow: hidden;

  @include mq(md) {
    padding-top: 3.4rem;
    padding-bottom: 3.4rem;
  }

  &::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 0;
    transition: width 600ms easing(easeOutCubic);
    background-color: color(green-200);
    border-bottom: 2px solid color(green-600);
    z-index: -1;
  }

  &:hover::before {
    left: 0;
    width: 100%;
    transition: width 400ms easing(easeOutCubic);
  }
}

.p-excerptedArticle__footerIcon {
  flex-shrink: 0;
  width: 1.6rem;
  height: 1.4rem;
  margin-left: 2rem;

  @include mq(md) {
    width: 2.4rem;
    height: 2.1rem;
  }
}
