// Visually Hidden for Accessibility
// ============================================================================

// How to Use:
// @include(visually-hidden)

@mixin visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  overflow: hidden;
  white-space: nowrap;
}
