@use '../../../foundation/config' as *;

.p-teaTasterBasic {
  margin-top: 14.2rem;

  @include mq(md) {
    margin-top: 13.6rem;
    margin-bottom: 85.9rem;
  }
}

.p-teaTasterBasic__spaceSmall {
  letter-spacing: -0.2rem;

  @include mq(md) {
    letter-spacing: 0;
  }
}

.p-teaTasterBasic__textWrapper {
  display: flex;
  flex-wrap: wrap;
}

.p-teaTasterBasic__heading {
  @include mq(md, min) {
    flex-grow: 1;
  }
}

.p-teaTasterBasic__text {
  @include font-serif;
  font-size: 4.2rem;
  line-height: (62 / 42);
  font-weight: bold;

  @include mq(md, min) {
    flex-shrink: 0;
  }

  @include mq(md) {
    font-size: 6rem;
    line-height: (80 / 60);
    margin-top: 5.4rem;
  }
}

.p-teaTasterSystem {
  padding-bottom: 25.2rem;

  @include mq(md) {
    padding-bottom: 24rem;
  }
}

.p-teaTasterSystemText {
  font-size: 1.6rem;
  line-height: (36 / 16);

  @include mq(md) {
    font-size: 3rem;
    line-height: (60 / 30);
  }
}

.p-teaTasterSystemOverview {
  margin-top: 14.1rem;

  @include mq(md) {
    margin-top: 13.7rem;
  }
}

.p-teaTasterSystemOverview__description {
  margin-top: 6.3rem;

  @include mq(md) {
    margin-top: 5.1rem;
  }
}

.p-teaTasterSystemOverview__pdf {
  margin-top: 4.1rem;

  @include mq(md) {
    margin-top: 3.5rem;
  }
}

.p-teaTasterSystemOverview__tableWrapper {
  margin-top: 8.7rem;

  @include mq(md) {
    margin-top: 6.6rem;
  }
}

.p-teaTasterSystemOverview__table {
  table {
    margin: 1.1rem 0;

    @include mq(md) {
      margin: 1.8rem 0;
    }
  }
}

.p-teaTasterSystemActivity {
  margin-top: 17.7rem;

  @include mq(md) {
    margin-top: 16.1rem;
  }
}

.p-teaTasterSystemActivity__items {
  margin-top: 7.4rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  @include mq(md) {
    margin-top: 6.9rem;
  }
}

.p-teaTasterSystemActivity__item {
  width: calc(33.3% - 4.6rem);
  margin-left: 4.6rem;

  @include mq(md) {
    width: 100%;
    margin-left: 0;

    & + & {
      margin-top: 7.8rem;
    }
  }

  &:nth-child(3n + 1) {
    margin-left: 0;
  }

  &:nth-child(n + 4) {
    margin-top: 7.9rem;
  }
}

.p-teaTasterSystemActivity__title {
  margin-top: 2rem;

  @include mq(md) {
    margin-top: 3.9rem;
  }
}

.p-teaTasterSystemActivity__description {
  margin-top: 0.9rem;

  @include mq(md) {
    margin-top: 2.2rem;
  }
}

.p-teaTasterSystemActivity__button {
  margin-top: 4rem;

  @include mq(md) {
    margin-top: 3.5rem;
  }
}

.p-teaTasterSystemResult {
  margin-top: 18.9rem;

  @include mq(md) {
    margin-top: 18.4rem;
  }
}

.p-teaTasterSystemResult__tableTitle {
  margin-top: 6.2rem;

  @include mq(md) {
    margin-top: 5.9rem;
  }
}

.p-teaTasterSystemResult__table {
  table {
    margin: 1.1rem 0;

    @include mq(md) {
      margin: 1.8rem 0;
    }

    [scope='row'] {
      background-color: color(gray-80);
      font-weight: normal;
    }
  }
}

.p-teaTasterSystemResult__col1 {
  width: 25%;

  @include mq(md) {
    width: 19.7%;
  }
}

.p-teaTasterSystemResult__col2 {
  width: 50.1%;

  @include mq(md) {
    width: 63.6%;
  }
}

.p-teaTasterSystemResult__col3 {
  width: 43.9%;

  @include mq(md) {
    width: 50%;
  }
}
