@use '../../../foundation/config' as *;

/* ------------
 共通スタイル
----------------*/
.p-socialMedia {
  margin-top: 13.7rem;
  padding-bottom: 25.2rem;
  @include mq(md) {
    padding-bottom: 24rem;
  }
}

.p-socialMedia__title {
  margin: 17.5rem 0 5rem;
  @include mq(md, min) {
    margin: 18.2rem 0 6.4rem;
  }
  &:nth-of-type(1) {
    margin: 17.3rem 0 5rem;
    @include mq(md, min) {
      margin: 13.2rem 0 6.4rem;
    }
  }
}

.p-socialMedia__text {
  & > p {
    font-size: 1.6rem;
    line-height: (36/16);
    text-align: justify;
    @include mq(md) {
      font-size: 3rem;
      line-height: (60/30);
    }
  }

  & > ul > li {
    position: relative;
    padding-left: 2rem;
    font-size: 1.6rem;
    line-height: (36/16);

    @include mq(md) {
      padding-left: 4rem;
    }

    &::before {
      content: '';
      display: inline-block;
      position: absolute;
      top: 1.8rem;
      left: 0;
      width: 0.8rem;
      height: 0.8rem;
      margin-right: 2rem - 0.8rem;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='8px' height='8px'%3E%3Cpath fill-rule='evenodd' fill='rgb(0, 160, 64)' d='M4.000,-0.000 C6.209,-0.000 8.000,1.791 8.000,4.000 C8.000,6.209 6.209,8.000 4.000,8.000 C1.791,8.000 -0.000,6.209 -0.000,4.000 C-0.000,1.791 1.791,-0.000 4.000,-0.000 Z'/%3E%3C/svg%3E");
      background-repeat: no-repeat;
      background-size: contain;
      transform: translateY(-50%);

      @include mq(md) {
        top: 3rem;
        width: 1.6rem;
        height: 1.6rem;
        margin-right: 4rem - 1.6rem;
      }
    }

    @include mq(md) {
      font-size: 3rem;
      line-height: (60/30);
    }
  }
}

.p-socialMedia__smallText {
  margin-top: 2.5rem;

  @include mq(md) {
    margin-top: 1.3rem;
  }
  & > p {
    font-size: 1.4rem;
    line-height: (26/14);
    text-align: justify;
    @include mq(md) {
      font-size: 2.4rem;
      line-height: (40/24);
    }
  }
}

.p-socialMedia__largeLink {
  margin-top: 3.9rem;

  & > ul > li + li {
    margin-top: 2rem;
  }

  @include mq(md) {
    margin-top: 6.4rem;
  }
}

/* ------------
各セクションのスタイル
----------------*/
.p-socialMediaPolicy__rightText {
  font-size: 1.6rem;
  line-height: (36/16);
  text-align: right;
  margin-bottom: 1.9rem;
  & > p {
    @include mq(md) {
      font-size: 3rem;
      line-height: (60/30);
    }
  }
}

.p-socialMediaPolicyOfficialAccount__title {
  margin: 19.3rem 0 6.2rem;
  @include mq(md) {
    margin: 18.4rem 0 5.2rem;
  }
}

.p-socialMediaPolicyOfficialAccount__list {
  margin-top: 6.1rem;
  @include mq(md) {
    margin-top: 5.3rem;
  }
}

.p-socialMediaPolicyOfficialAccount__genre {
  & + & {
    margin-top: 7.1rem;
  }

  @include mq(md) {
    & + & {
      margin-top: 6.8rem;
    }
  }
}

.p-socialMediaPolicyOfficialAccount__genreTitle {
  font-size: 2rem;
  line-height: (36/20);
  margin-bottom: 1.2rem;
  font-weight: bold;

  @include mq(md) {
    font-size: 3.4rem;
    line-height: (54/34);
    margin-bottom: 0;
  }
}
.p-socialMediaPolicyOfficialAccount__groupWrapper {
  margin-top: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  @include mq(md) {
    margin-top: 0.9rem;
  }
}

.p-socialMediaPolicyOfficialAccount__group {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  border-top: 1px solid color(gray-300);
  border-bottom: 1px solid color(gray-300);
  padding: 1.8rem 0 2.1rem;
  width: 46.7%;

  &:nth-child(n + 3) {
    border-top: none;
  }

  br {
    display: none;
  }

  @media (min-width: 751px) and (max-width: 910px) {
    br {
      display: block;
    }
  }

  @include mq(md) {
    padding: 3.7rem 0 3.2rem;
    width: 100%;

    & + & {
      border-top: none;
    }

    br {
      display: none;
    }
  }
}

.p-socialMediaPolicyOfficialAccount__groupTitle {
  font-size: 1.6rem;
  line-height: (36/16);
  font-weight: bold;
  width: 37.7%;

  @media (min-width: 751px) and (max-width: 910px) {
    width: 30%;
  }

  @include mq(md) {
    font-size: 3.4rem;
    line-height: (54/34);
    width: 100%;
    margin-bottom: 0.8rem;

    &.-spMb12 {
      margin-bottom: 1.2rem;
    }
  }
}
.p-socialMediaPolicyOfficialAccount__itemList {
  display: flex;
  flex-wrap: wrap;
  width: 62.3%;

  @media (min-width: 751px) and (max-width: 910px) {
    width: 70%;
  }

  @include mq(md) {
    width: 100%;
  }
}

.p-socialMediaPolicyOfficialAccount__item {
  position: relative;
  display: inline-block;
  font-size: 1.6rem;
  line-height: (60/16);
  font-weight: bold;
  color: #7c878c;
  margin-right: 3rem;

  &.-spMr69 {
    @media (min-width: 400px) and (max-width: 750px) {
      margin-right: 6.9rem;
    }
    @media (min-width: 280px) and (max-width: 399px) {
      margin-right: 4rem;
    }

    @media (max-width: 279px) {
      margin-right: 3rem;
    }
  }

  &:nth-of-type(3) {
    margin-right: 0;
  }

  a {
    transition: color 400ms easing(easeOutCubic);

    &:hover {
      color: color(green-600);
    }
  }

  svg {
    position: relative;
    top: -1.8rem;
    margin-right: 1.5rem;

    &.-mr12 {
      margin-right: 1.2rem;

      @include mq(md) {
        margin-right: 1.9rem;
      }
    }

    @media (min-width: 751px) and (max-width: 910px) {
      top: -2.5rem;
    }

    @include mq(md) {
      top: -1.9rem;
      margin-right: 1.2rem;
    }
  }

  @include mq(md) {
    font-size: 3rem;
    line-height: (89.5/30);
    margin-right: 4rem;

    &.-spMr102 {
      margin-right: 10.2rem;
    }
  }
}

.p-socialMediaPolicyOfficialAccount__facebookIcon {
  width: 2rem;
  height: 2rem;
  @include mq(md) {
    width: 5rem;
    height: 5rem;
  }
}

.p-socialMediaPolicyOfficialAccount__twitterIcon {
  width: 2.3rem;
  height: 1.9rem;
  @include mq(md) {
    width: 5rem;
    height: 4.1rem;
  }
}

.p-socialMediaPolicyOfficialAccount__xIcon {
  width: 2rem;
  height: 2rem;
  @include mq(md) {
    width: 5rem;
    height: 5rem;
  }
}

.p-socialMediaPolicyOfficialAccount__lineIcon {
  width: 2.1rem;
  height: 2rem;
  @include mq(md) {
    width: 5rem;
    height: 4.8rem;
  }
}

.p-socialMediaPolicyOfficialAccount__instagramIcon {
  width: 2rem;
  height: 2rem;
  @include mq(md) {
    width: 5rem;
    height: 5rem;
  }
}
