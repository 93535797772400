@use '../../../foundation/config' as *;

.p-irLibraryMonthlySalesBacknumber__archive {
  margin-top: 8.3rem;
  + .p-irLibraryMonthlySalesBacknumber__archive {
    margin-top: 9.2rem;
  }
}

.p-irLibraryMonthlySalesBacknumber__list {
  margin-top: 8.2rem;
  @include mq(md) {
    margin-top: 4.2rem;
  }
}

.p-irLibraryMonthlySalesBacknumber__listItem
  + .p-irLibraryMonthlySalesBacknumber__listItem {
  margin-top: 2.8rem;
  @include mq(md) {
    margin-top: 4.8rem;
  }
}
