@use '../../../foundation/config' as *;

.p-healthCreation__wrap {
  overflow: hidden;
  padding-bottom: 25.2rem;
  .c-heading {
    & + .p-healthCreation__media,
    & + .p-healthCreation__cards,
    & + .p-healthCreation__linkHover {
      margin-top: 2rem;
    }
  }
  .l-main__inner {
    margin-top: 8rem;
  }
  @include mq(md) {
    padding-bottom: 24rem;
  }
}

.p-healthCreation .c-pageHeader__text {
  @include font-default;
}

.p-healthCreation__section {
  margin: 8rem 0 0;
  &.as-white {
    margin-left: calc(50% - 50vw);
    margin-right: calc(50% - 50vw);
    padding: 2rem 0 4rem;
    background-color: #fff;
    .p-healthCreation__sectionInner {
      max-width: 1920px;
      padding-right: 100 / 1600 * 100%;
      padding-left: 100 / 1600 * 100%;
    }
  }
}

.p-healthCreation__sectionInner {
  margin: 6rem auto 4rem;
  p {
    font-size: 1.8rem;
    line-height: 2;
    @include mq(md) {
      font-size: 3rem;
    }
  }
}

.p-healthCreation__sectionInnerArea {
  margin: 4rem 0;
  @include mq(md) {
    & + & {
      margin: 8rem 0;
    }
  }
}

.p-healthCreation__mv {
  display: flex;
  margin-top: 8rem;
  margin-bottom: 8rem;
  @include mq(md) {
    flex-direction: column-reverse;
    margin: 0 -4rem;
    padding: 0;
  }
}

.p-healthCreation__mvImage {
  flex: 0 0 50%;

  .-has-gradation2 {
    position: relative;
    display: block;
    max-width: 100%;
    width: 100%;
    height: 100%;

    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-image: linear-gradient(-90deg, transparent 80% 0, #F6F6F3 100%);
      @include mq(md) {
        background-image: linear-gradient(180deg, transparent 0 80%, #F6F6F3 100%);
      }
    }
  }
}
.p-healthCreation__mvText {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 0 0 50%;
  padding: 4rem 1.6rem 4rem 2.4rem;
  p {
    @include font-serif;
    font-size: 2.8rem;
    line-height: 1.75;

    @include mq(md) {
      font-size: 4rem;
    } 
  }
  @include mq(md) {
    padding: 4rem 4rem 0 4rem;
    background-color: transparent;
  }
}

.p-healthCreation__media {
  display: flex;
  margin-top: 4rem;
  @include mq(md) {
    display: block;
  }
  .c-linkButton {
    margin-bottom: 0;
  }
  &.as-half {
    .p-healthCreation__mediaText {
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 4.4rem 6rem;
      @include mq(md) {
        padding: 1rem 6rem 6rem;
      }
    }
    .p-healthCreation__mediaImage {
      flex: 0 0 46%;
      padding: 0;
    }
  }
  &.is-reverse {
    @include mq(md, min) {
      flex-direction: row-reverse;
      .p-healthCreation__featureImage {
        padding: 0 0 0 6rem;
        @include mq(md) {
          padding: 0;
        }
      }
    }
  }
  &.has-bg-gray {
    background-color: color(gray-200);
  }
  &.has-bg-white {
    background-color: color(white);
  }
  &.has-bg-gradation {
    .p-healthCreation__cardImage {
      position: relative;
      display: block;
      max-width: 100%;
      width: 100%;
      height: 100%;

      @include mq(md, min) {
        &::after {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-image: linear-gradient(90deg, transparent 80% 0, #FFFFFF 100%);
        }
      }
    }
  }
}
.p-healthCreation__mediaImage {
  flex: 0 0 35%;
  padding: 0 0 0 6rem;
  @include mq(md) {
    padding: 0;
  }
  .c-carousel {
    margin-top: 0;
    padding-left: 0;
    .swiper-wrapper {
      padding: 0;
    }
    .c-carouselBox {
      width: 100%;
    }
  }
  .c-carousel__buttonWrapper {
    padding-right: 0;
  }
}
.p-healthCreation__mediaText {
  @include mq(md) {
    margin-top: 3rem;
  }
  &.as-message {
    p {
      font-size: 2.2rem;
      font-weight: 700;
      line-height: 1.8;
      @include mq(md) {
        font-size: 3.5rem;
        line-height: 1.5;
      }
    }
  }
}
.p-healthCreation__mediaHeader {
  margin-bottom: 2.5rem;
  @include mq(md) {
    margin-bottom: 2rem;
  }
}

.p-healthCreation__cards {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 4rem 0 0;
  @include mq(md) {
    display: block;
  }
}
.p-healthCreation__card {
  display: flex;
  flex-direction: column;
  flex: 0 0 48.5%;
  padding: 4rem;
  background-color: color(gray-200);
  &:nth-child(n+3) {
    margin-top: 4rem;
  }
  @include mq(md) {
    & + & {
      margin-top: 4rem;
    }
  }
}
.p-healthCreation__cardBody {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  .c-linkButton {
    margin: 2.5rem auto 0;
  }
}
.p-healthCreation__cardHeader {
  margin-top: -1.4rem;
  margin-bottom: 1rem;
  @include mq(md) {
    margin: 0 0 4rem;
  }
}
.p-healthCreation__cardLead {
  flex-grow: 1;
  margin-top: 1rem;
  font-size: 1.8rem;
  line-height: 2;
  @include mq(md) {
    margin-top: 4rem;
  }
}
.p-healthCreation__cardImages {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  @include mq(md) {
    flex-direction: column;
    justify-content: center;
  }
}
.p-healthCreation__cardImage {
  @include mq(md, min) {
    flex: 0 0 48%;
  }
  @include mq(md) {
    & + & {
      margin-top: 4rem;
    }
  }
}

.p-healthCreation__features {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 4rem;
  @include mq(md) {
    display: block;
  }
}
.p-healthCreation__feature {
  display: flex;
  flex-direction: column;
  flex: 0 0 31%;
  .c-linkButton {
    margin-bottom: 0;
  }
  &.as-primary {
    flex-direction: row;
    flex: 0 0 100%;
    .p-healthCreation__featureImage {
      flex: 0 0 50%;
      padding: 0 6rem 0 0;
      @include mq(md) {
        padding: 0;
      }
    }
    .p-healthCreation__featureTitle {
      @include mq(md, min) {
        margin-top: 0;
      }
    }
  }
  &.is-reverse {
    @include mq(md, min) {
      flex-direction: row-reverse;
      .p-healthCreation__featureImage {
        padding: 0 0 0 6rem;
        @include mq(md) {
          padding: 0;
        }
      }
    }
  }
  @include mq(md) {
    display: block;
    & + & {
      margin-top: 8rem;
      padding-top: 8rem;
      border-top: 1px solid color(gray-200);
    }
  }
}
.p-healthCreation__featureTitle {
  margin: 2rem 0;
  font-size: 2.6rem;
  line-height: 2;
  @include mq(md) {
    margin: 2.6rem 0;
    font-size: 4rem;
  }
}

.p-healthCreation__linkHover {
  position: relative;

  .p-healthCreation__media {
    margin-top: 2rem;
  }

  @include mq(md) {
    pointer-events: none;

    .p-healthCreation__imgLinkText,
    .p-healthCreation__imgLinkSeparator {
      display: none;
    }
    .p-healthCreation__media {
      &.as-half {
        .p-healthCreation__mediaText {
          padding-bottom: 21rem;
        }
      }
    }
  }

  @include mq(md, min) {
    .p-healthCreation__mediaImage {
      overflow: hidden;
      img {
        width: 100%;
        height: auto;
        transition: transform .4s cubic-bezier(.215,.61,.355,1);
        backface-visibility: hidden;
      }
    }
    .p-healthCreation__imgLinkText {
      position: absolute;
      right: 10rem;
      bottom: 2rem;
      font-size: 1.6rem;
      font-weight: 700;
      line-height: 6rem;
    }
    .p-healthCreation__imgLinkSeparator {
      display: flex;
      position: absolute;
      justify-content: center;
      align-items: center;
      right: 2rem;
      bottom: 2rem;
      width: 6rem;
      height: 6rem;
      border: 1px solid;
      transition: box-shadow 400ms cubic-bezier(0.215, 0.61, 0.355, 1);
      z-index: 1;
      .p-healthCreation__imgLinkIcon {
        width: 1.6rem;
        height: 1.1rem;
      }
    }
    &::before {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      transition: background-color .4s cubic-bezier(.215,.61,.355,1);
      z-index: 1;
    }
    &:hover {
      &::before {
        background-color: rgba(131, 170, 138, .6);
      }
      .p-healthCreation__mediaImage {
        img {
          transform: scale(1.04);
        }
      }
      .p-healthCreation__mediaText {
        p {
          z-index: 2;
          color: color(white);
        }
      }
      .p-healthCreation__imgLinkText {
        z-index: 2;
        color: color(white);
      }
      .p-healthCreation__imgLinkSeparator {
        box-shadow: 0 0 0 1px;
        color: color(white);
      }
    }
  }
}

.as-relative {
  position: relative;
}
.for-sp {
  @include mq(md, min) {
    display: none;
  }
  @include mq(md) {
    position: absolute;
    left: 6rem;
    bottom: 3rem;
    width: calc( 100% - 12rem );
  }
}
