@use '../../../foundation/config' as *;

.p-researchSystemDetail {
  @include mq(md, min) {
    margin-top: 4rem;
  }

  @include mq(md) {
    margin-top: 6rem;
  }
}

.p-researchSystemDetail__item {
  border: 1px solid color(gray-300);

  @include mq(md, min) {
    display: flex;
    align-items: center;
    padding: 3.8rem 0;

    & + & {
      margin-top: 3rem;
    }
  }

  @include mq(md) {
    padding: 4rem 4rem;

    & + & {
      margin-top: 5rem;
    }
  }
}

.p-researchSystemDetail__description {
  @include mq(md, min) {
    padding: 0 5.2rem 0 0;
    font-size: 1.6rem;
    line-height: (36/16);
  }

  @include mq(md) {
    font-size: 3rem;
    line-height: (30/15);
  }
}

.p-researchSystemDetail__title {
  font-weight: bold;
  flex-shrink: 0;

  @include mq(md, min) {
    font-size: 2rem;
    width: 37.6rem;
    padding: 0 0 0 5.2rem;
  }

  @include mq(md) {
    font-size: 4rem;
    text-align: center;
    margin: 0 0 2rem;
  }
}

.p-researchSystemDetail__listRow {
  @include mq(md, min) {
    display: flex;
    & + & {
      margin-top: 0.9rem;
    }
  }

  @include mq(md) {
    & + & {
      margin-top: 2rem;
    }
  }
}

.p-researchSystemDetail__listTitle {
  font-weight: bold;
  flex-shrink: 0;
  position: relative;
  padding-right: 1.5rem;

  &::before {
    content: '';
    display: inline-block;
    position: absolute;
    top: 1.8rem;
    left: 0;
    width: 0.8rem;
    height: 0.8rem;
    margin-right: 2rem - 0.8rem;
    margin-left: -2rem;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='8px' height='8px'%3E%3Cpath fill-rule='evenodd' fill='rgb(0, 160, 64)' d='M4.000,-0.000 C6.209,-0.000 8.000,1.791 8.000,4.000 C8.000,6.209 6.209,8.000 4.000,8.000 C1.791,8.000 -0.000,6.209 -0.000,4.000 C-0.000,1.791 1.791,-0.000 4.000,-0.000 Z'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-size: 100%;
    transform: translateY(-50%);

    @include mq(md) {
      top: 3rem;
      width: 1.6rem;
      height: 1.6rem;
      margin-right: 4rem - 1.6rem;
      margin-left: 0;
    }
  }

  @include mq(md, min) {
    .-type1 & {
      width: 24.2rem;
    }

    .-type2 & {
      width: 34.2rem;
    }
  }

  @include mq(md) {
    font-size: 3.4rem;
    line-height: (54/34);
    padding-left: 4rem;
  }
}

.p-researchSystemDetail__listText {
  @include mq(md) {
    margin: 1rem 0 0 0;
  }
}
