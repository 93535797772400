@use '../../../foundation/config' as *;

.p-companyBlock15 {
  margin: 13.9rem 0 0;
  @include mq(md, min) {
    margin: 15.1rem 0 0;
  }
  .c-heading.-level2:not(:first-of-type) {
    margin: 6.9rem 0 0;
    @include mq(md, min) {
      margin: 8.1rem 0 0;
    }
  }
}
.p-companyBlock15__unit1,
.p-companyBlock15__unit2 {
  margin: 6.9rem 0 0;
  @include mq(md, min) {
    display: flex;
    margin: 8.1rem 0 0 3rem;
  }
  &:first-child {
    margin: 0;
  }
}
.p-companyBlock15__unit2 {
  justify-content: flex-end;
  @include mq(md, min) {
    margin: 4rem 0 0;
  }
}

.p-companyBlock15__unit2AsOf {
  font-size: 3rem;
  line-height: (60/30);
  padding: 0 0 2.1rem 0;

  @include mq(md, min) {
    font-size: 1.6rem;
    line-height: (36/16);
  }
}

.p-companyBlock15__unit1Name1 {
  font-size: 3.4rem;
  font-weight: bold;
  line-height: (54/34);
  @include mq(md, min) {
    min-width: 35rem;
    max-width: 35rem;
    font-size: 2rem;
    line-height: (36/20);
    margin: 2rem 0 0;
  }
}
.p-companyBlock15__unit1List1 {
  margin: 2.4rem 0 0;
  @include mq(md, min) {
    width: 100%;
    margin: 0;
  }
  & > ul {
    @include mq(md, min) {
      display: flex;
      flex-wrap: wrap;
      margin: 0 -4.5rem;
    }
    & > li {
      margin: -1px 0 0;
      @include mq(md, min) {
        min-width: 50%;
        max-width: 50%;
        padding: 0 4.5rem;
      }
      span,
      & > a {
        border-top: 1px solid color(gray-300);
        border-bottom: 1px solid color(gray-300);
        position: relative;
        display: block;
        font-size: 3rem;
        line-height: (60/30);
        padding: 3.2rem 2.9rem 3.6rem 2.9rem;
        transition: color 400ms easing(easeOutCubic);
        @include mq(md, min) {
          font-size: 1.6rem;
          line-height: (36/16);
          padding: 2.2rem 0 2.1rem 0;
        }
        i {
          position: absolute;
          top: 0;
          bottom: 0;
          right: 2.9rem;
          margin: auto;
          width: 2.6em;
          height: 2.6rem;
          display: block;
          font-size: 1rem;
          @include mq(md, min) {
            width: 1.4rem;
            height: 1.4rem;
            right: 2rem;
          }
          &:after,
          &:before {
            content: '';
            display: block;
            width: 2.6rem;
            height: 2px;
            background-color: currentColor;
            position: absolute;
            top: 0;
            bottom: 0;
            margin: auto;
            @include mq(md, min) {
              width: 1.4rem;
            }
          }
          &:after {
            transform: rotate(-90deg);
          }
        }
      }
      & > a {
        &:hover {
          color: color(green-600);
        }
      }
    }
  }
}
