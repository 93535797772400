@use '../../../foundation/config' as *;

.p-companyBlock8 {
  margin: 18.5rem 0 0;
  @include mq(md, min) {
    margin: 19.2rem 0 0;
  }
}
.p-companyBlock8__unit1 {
  margin: 7rem 0 0;
  @include mq(md, min) {
    display: flex;
    width: 100%;
    margin: 6.8rem 0 0;
  }
  @at-root {
    .p-companyBlock8__title1 + .p-companyBlock8__unit1 {
      margin: 5.6rem 0 0;
      @include mq(md, min) {
        margin: 6.1rem 0 0;
      }
    }
  }
}
.p-companyBlock8__unit1Body1 {
  @include mq(md, min) {
    min-width: 50%;
    max-width: 50%;
    padding: 0 9.2rem 0 0;
  }
}
.p-companyBlock8__unit1Text1 {
  font-size: 3rem;
  line-height: (60/30);
  text-align: justify;
  margin: 2.1rem 0 0;
  @include mq(md, min) {
    font-size: 1.6rem;
    line-height: (36/16);
    margin: 1.4rem 0 0;
  }
}
.p-companyBlock8__unit1Button1 {
  margin: 3.2rem 0 0;
  @include mq(md, min) {
    margin: 4.1rem 0 0;
  }
}
.p-companyBlock8__unit1Image1 {
  display: block;
  margin: 8rem 0 0;
  @include mq(md, min) {
    flex: 0 0 50%;
    margin: 1.2rem 0 0;
  }

  img {
    width: 100%;
    height: auto;
  }
}
