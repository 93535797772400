@use "../foundation/config" as *;

.l-footer {
  position: relative;
  padding-top: 11rem;
  padding-bottom: 10rem;

  @include mq(md) {
    padding-top: 10rem;
  }

  @include print {
    display: none;
  }
}

.l-footer__inner {
  @include mq(md, min) {
    display: grid;
    grid-template-columns: 1fr 87.5rem;
    grid-template-rows: 1fr 1fr 1.5rem;
    grid-template-areas: 'logo nav' 'search nav' 'copyright nav';
    max-width: 1920px;
    margin-right: auto;
    margin-left: auto;
    padding-right: 100 / 1600 * 100%;
    padding-left: 100 / 1600 * 100%;
  }
}

.l-footer__logo {
  grid-area: logo;
  @include mq(md) {
    padding-right: 4rem;
    padding-left: 4rem;
  }
}

.l-footer__navGroup {
  grid-area: nav;

  @include mq(md, min) {
    display: grid;
    grid-template-columns: 1fr auto;
    grid-template-rows: repeat(2, auto);
    grid-template-areas: 'primary secondary' 'external external';
    row-gap: 8rem;
  }
}

.l-footer__primaryNav {
  grid-area: primary;

  @include mq(md) {
    margin-top: 7.6rem;
  }
}

.l-footer__secondaryNav {
  grid-area: secondary;

  @include mq(md) {
    margin-top: 6rem;
  }
}

.l-footer__externalNav {
  grid-area: external;

  @include mq(md) {
    margin-top: 6rem;
  }
}

.l-footer__copyright {
  grid-area: copyright;

  @include mq(md) {
    margin-top: 7.3rem;
  }
}

.l-footer__pageTop {
  position: absolute;
  top: 0;
  right: 0;
  transform: translateY(-100%);
}

.footer__search {
  grid-area: search;
	order: 3;
	width: 100%;
  @include mq(md, min) {
    margin-top: 0;
  }
  @include mq(md) {
    margin-top: 8rem;
  }
	// f-search
	.f-search {
		display: flex;
		background: #f6f6f6;
		@include mq(md, min) {
			width: 50rem;
			margin-right: 3rem;
		}
		@include mq(md) {
			margin: 0.994em 4rem;
		}
		// &__input
		&__input {
			margin: 0;
			padding: 0 0 0 3rem;
			border: none;
			border-radius: 0;
			background: none;
			color: color(black);
			font-family:'Noto Sans JP', 'Hiragino Kaku Gothic ProN', 'Hiragino Sans', 'メイリオ', Meiryo, 'ＭＳ Ｐゴシック', sans-serif;
			font-weight: 900;
			&::placeholder { color:color(gray) }
			&:placeholder-shown { color:color(gray) }
			&::-webkit-input-placeholder { color:color(gray) }
			&:-moz-placeholder { color:color(gray) }
			&::-moz-placeholder { color:color(gray) }
			&:-ms-input-placeholder { color:color(gray) }
			@include mq(md, min) {
				width: calc(100% - 10rem);
        font-size: 1.4rem;
				height: 10rem;
			}
			@include mq(md) {
				width: calc(100% - 6.5rem);
        font-size: 3.4rem;
				padding-left: 4rem;
			}
		}
		// &__submit
		&__submit {
			box-sizing: border-box;
			display: flex;
			justify-content: center;
			align-items: center;
			margin: 0;
			padding: 0;
			border: none;
			border-left: #fbfcfc solid 1px;
			border-radius: 0;
			background: none;
			@include mq(md, min) {
				width: 10rem;
				height: 10rem;
			}
			@include mq(md) {
				width: 12rem;
				height: 12rem;
			}
			.icon {
				fill: color(black);
				@include mq(md, min) {
					width: 1.7rem;
					height: 1.7rem;
				}
				@include mq(md) {
					width: 3.8rem;
					height: 3.8rem;
				}
			}
		}
	}
  }
