@use '../../../foundation/config' as *;

.p-companyLocalNav2 {
  display: flex;
  margin: 0 -4rem 0;
  @include mq(md, min) {
    margin: 0 -6.25vw 0;
  }
  & > li {
    display: flex;
    justify-content: center;
    min-width: 50%;
    max-width: 50%;
    border-bottom: 1px solid #dcddd4;
    border-left: 1px solid #dcddd4;
    &:first-child {
      border-left: none;
    }
    & > a {
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: center;
      font-size: 4rem;
      line-height: (60/40);
      font-weight: bold;
      text-align: center;
      min-height: 22.7rem;
      background: color(white);
      position: relative;
      transition: all 0.7s easing(easeOutQuint);
      @include mq(md, min) {
        font-size: 2.6rem;
        line-height: (46/26);
        min-height: 16.9rem;
      }
      &:hover,
      &.-active {
        color: color(green-600);
      }
      &:before {
        position: absolute;
        bottom: -1px;
        left: 0;
        right: 0;
        margin: auto;
        content: '';
        display: block;
        opacity: 0;
        width: 7rem;
        height: 0.4rem;
        background: color(green-600);
        transition: all 0.7s easing(easeOutQuint);
        @at-root {
          .p-companyLocalNav2 > li > a:hover:before,
          .p-companyLocalNav2 > li > a.-active:before {
            opacity: 1;
          }
        }
      }
    }
  }
}
