@use '../../../foundation/config' as *;

.p-sustainabilityCsrPolicy {
  padding-bottom: 25rem;
}

.p-sustainabilityCsrPolicy__inner {
  padding-top: 0;
  @include mq(md) {
    padding-top: 15rem;
  }
}

.p-sustainabilityCsrPolicy__section {
  & + & {
    margin-top: 20rem;

    @include mq(md) {
      margin-top: 19.8rem;
    }
  }

  &.-flex {
    @include mq(md, min) {
      display: flex;
    }
  }
}

.p-sustainabilityCsrPolicy__sectionHeading {
  font-size: 3.4rem;
  @include lhCrop(44 / 34);

  @include mq(md) {
    font-size: 6rem;
    @include lhCrop(80 / 60);
  }
}

.p-sustainabilityCsrPolicy__text {
  font-size: 1.6rem;
  line-height: (36 / 16);
  @include lhCrop(36 / 16);

  @include mq(md) {
    font-size: 3rem;
    line-height: 2;
    @include lhCrop(2);
  }

  .p-sustainabilityCsrPolicy__sectionHeading + & {
    margin-top: 7.4rem;
  }

  .c-heading.-level3 + & {
    margin-top: 5rem;

    @include mq(md) {
      margin-top: 4.4rem;
    }
  }
}

.p-sustainabilityCsrPolicy__smallSection {
  .p-sustainabilityCsrPolicy__sectionHeading + & {
    margin-top: 6.4rem;
  }

  .p-sustainabilityCsrPolicy__text + & {
    margin-top: 9.6rem;
  }

  .p-sustainabilityCsrPolicy__esg + & {
    margin-top: 9rem;
  }

  & + & {
    margin-top: 9.4rem;
  }
}

.p-sustainabilityCsrPolicy__linkGroup {
  margin-top: 5.4rem;
}

.p-sustainabilityCsrPolicy__figure1 {
  width: 70.4rem;
  margin-top: 8rem;
  margin-right: auto;
  margin-left: auto;

  @include mq(md) {
    width: 100%;
  }

  img {
    width: 100%;
    height: auto;
  }
}

.p-sustainabilityCsrPolicy__figure2 {
  width: 129.4rem;
  max-width: 100%;
  margin-top: 4.9rem;
  margin-right: auto;
  margin-left: auto;

  @include mq(md) {
    width: 100%;
  }

  img {
    width: 100%;
    height: 76.6rem;

    @include mq(md) {
      height: auto;
    }
  }
}

.p-sustainabilityCsrPolicy__esg {
  margin-top: 7.9rem;
}

.p-sustainabilityCsrPolicy__esgFigure {
  @include mq(md, min) {
    display: flex;
    justify-content: space-between;
  }

  img {
    // width: 62.2rem;
    // height: 66.3rem;
    width: 49%;

    @include mq(md) {
      width: 100%;
      height: auto;
    }
  }
}

.p-sustainabilityCsrPolicy__figCaption {
  margin-top: 2.4rem;
  font-size: 1.4rem;
  line-height: (26 / 14);

  @include mq(md) {
    margin-top: 3rem;
    font-size: 3rem;
    line-height: 2;
  }
}

.p-sustainabilityCsrPolicy__esgDescription {
  margin-top: -3rem;
  flex: 0 0 50%;

  @include mq(md) {
    margin-top: 5rem;
  }
}

.p-sustainabilityCsrPolicy__esgItem {
  padding-top: 2.2rem;
  padding-bottom: 2.8rem;
  border-bottom: 1px solid color(gray-300);
  font-size: 1.4rem;
  line-height: (26 / 14);

  @include mq(md, min) {
    display: flex;
  }

  @include mq(md) {
    padding-top: 3rem;
  }

  em {
    width: 17.5rem;
    font-weight: bold;

    @include mq(md) {
      display: block;
      width: 100%;
      font-size: 3.4rem;
    }
  }

  span {
    width: calc(100% - 17.5rem);

    @include mq(md) {
      display: block;
      width: 100%;
      margin-top: 1rem;
      font-size: 3rem;
      line-height: 2;
    }
  }
}

.p-sustainabilityCsrPolicy__esgCaption {
  margin-top: 2.2rem;
  font-size: 1.4rem;

  @include mq(md, min) {
    text-align: right;
  }

  @include mq(md) {
    margin-top: 3rem;
    font-size: 3rem;
    line-height: 2;
  }
}

.p-sustainabilityCsrPolicy__box1 {
  margin-top: 8rem;
}

.p-sustainabilityCsrPolicy__box2 {
  margin-top: 8rem;

  @include mq(md, min) {
    display: flex;
  }
}

.p-sustainabilityCsrPolicy__boxImage {
  width: 14.6rem;
  height: 6.3rem;

  @include mq(md, min) {
    margin-top: -0.8rem;
    margin-right: 5rem;
  }

  @include mq(md) {
    width: 30.6rem;
    height: auto;
    margin-top: 1.1rem;
    margin-bottom: 5rem;
    margin-left: -0.3rem;
  }
}

.p-sustainabilityCsrPolicy__button {
  margin-top: 7.8rem;
  text-align: center;

  @include mq(md) {
    margin-top: 5.2rem;
  }

  & + & {
    @include mq(md) {
      margin-top: 8rem;
    }
  }

  .p-sustainabilityCsrPolicy__figure2 + & {
    @include mq(md) {
      margin-top: 3rem;
    }
  }
}

.p-sustainabilityCsrPolicy__scrollArea {
  margin-top: 5rem;

  @include mq(md) {
    margin-right: -4rem;
    overflow: auto;
  }
}

.p-sustainabilityCsrPolicy__tableArea {
  @include mq(md) {
    width: 171rem;
    padding-right: 4rem;
  }
}

.p-sustainabilityCsrPolicy__table {
  border-collapse: collapse;
  font-size: 1.6rem;
  line-height: (36 / 16);
  @include mq(md) {
    font-size: 2.6rem;
    line-height: (40 / 26);
  }

  td {
    padding-right: 2rem;
    padding-left: 2rem;
  }

  th,
  td {
    padding-top: 1em;
    padding-bottom: 1em;
    border: 1px solid color(gray-300);

    @include mq(md) {
      padding-top: 0.95em;
      padding-bottom: 0.95em;
    }
  }

  th[scope='col'] {
    background-color: color(gray-240);
  }

  th[scope='col']:nth-child(1) {
    width: 102 / 1400 * 100%;
    border-right-color: color(gray-100);

    @include mq(md) {
      width: 100 / 1670 * 100%;
    }
  }

  th[scope='col']:nth-child(2) {
    width: 171 / 1400 * 100%;
    border-right-color: color(gray-100);

    @include mq(md) {
      width: 250 / 1670 * 100%;
    }
  }

  th[scope='col']:nth-child(3) {
    width: 252 / 1400 * 100%;
    border-right-color: color(gray-100);

    @include mq(md) {
      width: 350 / 1670 * 100%;
    }
  }

  th[scope='col']:nth-child(4) {
    width: 700 / 1400 * 100%;
    border-right-color: color(gray-100);

    @include mq(md) {
      width: 694 / 1670 * 100%;
    }
  }

  th[scope='col']:nth-child(5) {
    width: 175 / 1400 * 100%;

    @include mq(md) {
      width: 272 / 1670 * 100%;
    }
  }

  picture {
    display: block;
    width: 11rem;
    margin-right: auto;
    margin-left: auto;

    @include mq(md) {
      width: 16.7rem;
    }

    img {
      width: 100%;
      height: auto;
    }
  }

  picture + picture {
    margin-top: 3rem;
  }
}

.p-sustainabilityCsrPolicy__tableG {
  background-color: #dfebf2;
}

.p-sustainabilityCsrPolicy__tableS {
  background-color: #f6eada;
}

.p-sustainabilityCsrPolicy__tableE {
  background-color: #dde8e0;
}

.p-sustainabilityCsrPolicy__tableG,
.p-sustainabilityCsrPolicy__tableS,
.p-sustainabilityCsrPolicy__tableE {
  @include mq(md) {
    vertical-align: top;
  }

  em {
    display: inline-block;
    width: 4rem;
    height: 4rem;
    border-radius: 50%;
    background-color: color(white);
    transform: rotate(-90deg);
  }

  span {
    height: 10em;
    writing-mode: vertical-rl;
    vertical-align: bottom;
  }
}

.p-sustainabilityCsrPolicy__verticalText {
  margin-top: 2rem;
}

.p-sustainabilityCsrPolicy__subText {
  font-weight: normal;
}
