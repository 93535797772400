@use '../../foundation/config' as *;

.c-disclosure {
  margin-top: 3.9rem;
  border-top: 1px solid color(gray-300);

  @include mq(md) {
    margin-top: 6.5rem;
  }
}

.c-disclosure__head {
  position: relative;
  width: 100%;
  padding: 4.8rem 4rem;
  font-size: 2rem;
  font-weight: bold;
  text-align: left;
  border-bottom: 1px solid color(gray-300);
  transition: color 400ms easing(easeOutCubic);

  @include mq(md) {
    padding: 4rem 3rem;
    font-size: 3rem;
  }

  &:hover {
    @include mq(md, min) {
      color: color(green-600);
    }
  }
}

.c-disclosure__minusIcon,
.c-disclosure__plusIcon {
  position: absolute;
  top: 50%;
  right: 4rem;
  width: 1.4rem;
  transform: translate(0, -50%);

  @include mq(md) {
    right: 3rem;
    width: 2.6rem;
  }
}

.c-disclosure__minusIcon {
  height: 0.2rem;
  display: none;

  @include mq(md) {
    height: 0.4rem;
  }

  .c-disclosure__head[aria-expanded='true'] & {
    display: block;
  }
}

.c-disclosure__plusIcon {
  height: 1.4rem;

  @include mq(md) {
    height: 2.6rem;
  }

  .c-disclosure__head[aria-expanded='true'] & {
    display: none;
  }
}

.c-disclosure__body {
  background-color: color(gray-200);
  overflow: hidden;
  display: none;
}

.c-disclosure__bodyInner {
  position: relative;
  padding: 2.7rem 4rem 3.2rem;

  @include mq(md) {
    padding: 2.7rem 4rem 3rem;
  }
}
