@use '../../../foundation/config' as *;

.p-topSustainability {
  margin-top: 10rem;

  @include mq(md, min) {
    display: flex;
    align-items: flex-end;
  }

  @include mq(md) {
    // margin-top: 23.4rem;
  }
}

.p-topSustainability__text {
  margin-top: 8rem;
  font-size: 1.6rem;
  line-height: (36 / 16);

  @include mq(md) {
    margin-top: 5.4rem;
    font-size: 3rem;
    line-height: (60 / 30);
  }
}

.p-topSustainability__button {
  margin-top: 4rem;

  @include mq(md) {
    margin-top: 3rem;
  }
}

.p-topSustainability__media {
  @include mq(md, min) {
    order: -1;
    flex: 0 0 97.5rem;
    margin-right: 9rem;
    margin-left: -10rem;
  }

  @include mq(md) {
    display: block;
    margin-top: 10rem;
    margin-right: -4rem;
    margin-left: -4rem;
  }

  img {
    width: 100%;
    height: auto;
  }
}
