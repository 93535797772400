@use '../../../foundation/config' as *;

.p-companyBlock9 {
  margin: 18.7rem 0 0;
  @include mq(md, min) {
    margin: 19.3rem 0 0;
  }
}
.p-companyBlock9__unit1 {
  margin: 6.8rem 0 0;
  @include mq(md, min) {
    display: flex;
    width: 100%;
    margin: 6.7rem 0 0;
  }
  @at-root {
    .p-companyBlock9__title1 + .p-companyBlock9__unit1 {
      margin: 5.4rem 0 0;
      @include mq(md, min) {
        margin: 6.2rem 0 0;
      }
    }
  }
}
.p-companyBlock9__unit1Body1 {
  @include mq(md, min) {
    width: 100%;
  }
}
.p-companyBlock9__unit1Text1 {
  font-size: 3rem;
  line-height: (60/30);
  text-align: justify;
  margin: 2.2rem 0 0;
  @include mq(md, min) {
    font-size: 1.6rem;
    line-height: (36/16);
    margin: 1.4rem 0 0;
  }
}
.p-companyBlock9__unit1Image1 {
  display: block;
  margin: 3.2rem 0 0;
  text-align: center;
  @include mq(md, min) {
    min-width: 43.5rem;
    max-width: 43.5rem;
    margin: 1.3rem 0 0 9rem;
    text-align: right;
  }
}
.p-companyBlock9__button1 {
  margin: 4.7rem 0 0;
  @include mq(md, min) {
    margin: 7.8rem 0 0;
    text-align: center;
  }
}
.p-companyBlock9__unit1Button1 {
  margin: 3.4rem 0 4.9rem;
  @include mq(md, min) {
    margin: 3.9rem 0 -1rem;
  }
  @at-root {
    .p-companyBlock9__unit1:nth-last-of-type(1) .p-companyBlock9__unit1Button1 {
      @include mq(md, min) {
        margin-bottom: 0;
      }
    }
  }
}
