@use '../../../foundation/config' as *;

.p-researchSystem {
  padding-bottom: 25.2rem;

  @include mq(md) {
    padding-bottom: 24rem;
  }
}

.p-researchSystem__inner {
  margin-top: 14.5rem;

  @include mq(md) {
    margin-top: 13.6rem;
  }
}

.p-researchSystem__section {
  & + & {
    margin-top: 19.5rem;

    @include mq(md) {
      margin-top: 18.4rem;
    }
  }
}

.p-researchSystem__title {
  margin: 0 0 6rem;
}

.p-researchSystem__text {
  font-size: 1.6rem;
  line-height: 2.25;

  @include mq(md) {
    font-size: 3rem;
    line-height: 2;
  }
}

.p-researchSystem__image {
  @include mq(md, min) {
    margin-top: 7rem;
    padding-right: 5.5rem;
    padding-left: 5.5rem;
  }

  @include mq(md) {
    margin-top: 6.2rem;
  }

  img {
    width: 100%;
    height: auto;
  }
}

.p-researchSystem__button {
  text-align: center;
  margin: 5rem 0 0;
}
