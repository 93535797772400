@use '../../foundation/config' as *;

.p-text {
  margin-top: 5rem;
  margin-bottom: 5rem;

  .p-textBody {
    &>*:last-child {
      margin-bottom: 0;
    }
  }

  @include mq(md, min) {
    &.-hasImage {
      display: flex;
      align-items: flex-start;
      .p-textBody {
        margin-right: 6rem;
      }
      .p-textImage {
        flex: 0 0 48%;
        &.-w40 {
          flex: 0 0 40%;
        }
      }
    }
  }

  @include mq(md) {
    &.-hasImage {
      .p-textImage {
        width: 100%;
        margin-top: 5rem;
      }
    }
  }
}
// specific inner style
.-level5 + .p-text {
  margin-top: 2.5rem;
  margin-bottom: 2.5rem;
}

.p-table {
  margin-bottom: 2.5rem;
  @include mq(md) {
    overflow-x: auto;
    margin-bottom: 1em;
    table {
      white-space:nowrap
    }
  }
  table {
    border-collapse: collapse;
    width: 100%;
    // specific inner style
    ul {
      margin: 0 0 0 1em;
    }
    li {
      list-style-type: initial;
    }
  }
  thead {
    th {
      background-color: #EDEDEB;
      border-top: 1px solid #ddddd5;
    }
    th:first-child {
      border-left: none;
    }
  }
  tbody {
    th {
      background-color: #F1F1EE;
      border-left: none;
    }
  }
  th, td {
    padding: 1em 2rem;
    border-bottom: 1px solid #ddddd5;
    border-left: 1px solid #ddddd5;
  }
  .align {
    &-l {
      text-align: left;
    }
    &-c {
      text-align: center;
    }
    &-r {
      text-align: right;
    }
  }
}