@use '../../../foundation/config' as *;

.p-communicationDaichakai {
  padding-bottom: 25.2rem;

  @include mq(md) {
    padding-bottom: 24rem;
  }
}

.p-communicationDaichakaiText {
  font-size: 1.6rem;
  line-height: (36 / 16);

  @include mq(md) {
    font-size: 3rem;
    line-height: (60 / 30);
  }
}

.p-communicationDaichakaiActivity__heading {
  margin-top: 14.2rem;

  @include mq(md) {
    margin-top: 13.5rem;
  }
}

.p-communicationDaichakaiActivity__description {
  margin-top: 6.2rem;

  @include mq(md) {
    margin-top: 5rem;
  }
}

.p-communicationDaichakaiActivity__images {
  margin-top: 7rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  @include mq(md) {
    margin-top: 6.6rem;
  }
}

.p-communicationDaichakaiActivity__image {
  width: 50%;
  padding-right: 3.5rem;

  &.-right {
    padding-left: 3.5rem;
    padding-right: 0;
  }

  img {
    width: 100%;
  }

  @include mq(md) {
    width: 100%;
    padding-right: 0;

    &.-right {
      padding-left: 0;
      padding-right: 0;
    }

    & + & {
      margin-top: 5rem;
    }
  }
}

.p-communicationDaichakaiActivity__system {
  margin-top: 10rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}

.p-communicationDaichakaiActivity__systemImage {
  width: 50%;
  text-align: center;
  padding-right: 1.7rem;

  @include mq(md) {
    width: 100%;
    padding-right: 0;

    img {
      width: 100%;
    }
  }
}

.p-communicationDaichakaiActivity__systemText {
  width: 50%;
  padding-left: 3.5rem;

  @include mq(md) {
    margin-top: 6.1rem;
    width: 100%;
    padding-left: 0;
  }
}
