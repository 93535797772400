@use '../../foundation/config' as *;

.c-table {
  font-size: 1.6rem;
  line-height: (36 / 16);

  @include mq(md) {
    font-size: 2.6rem;
    line-height: (40 / 26);
  }

  th,
  td {
    padding: 1em 2rem;
    &.border-double {
      border-right: 3px double color(gray-300)!important;
    }
  }
  tr {
    &.border-double {
      border-top: 3px double color(gray-300);
    }
  }

  &.-style1 {
    @include mq(md) {
      margin-right: -4rem;
      overflow: auto;
    }

    table {
      min-width: 100%;
      border-collapse: collapse;
      border-top: 1px solid color(gray-300);
    }

    th[scope='col'] {
      background-color: color(gray-200);
    }

    th[scope='row'] {
      background-color: color(gray-80);
      text-align: left;
      font-weight: normal;
    }

    th,
    td {
      border-bottom: 1px solid color(gray-300);
      text-align: center;

      &.-bold {
        font-weight: bold;
      }
    }

    th:not(:last-child),
    td:not(:last-child),
    th.-border,
    td.-border {
      border-right: 1px solid color(gray-300);
    }

    caption {
      text-align: right;

      @include mq(md) {
        font-size: 3rem;
        line-height: 2;
      }
    }

    .u-alignCenter {
      display: block;
    }
  }

  &.-style2 {
    @include mq(md) {
      margin-right: -4rem;
      overflow: auto;
    }

    table {
      min-width: 100%;
      border-collapse: collapse;
      border-top: 1px solid color(gray-300);
    }

    th[scope='col'] {
      background-color: color(gray-200);
    }

    th[scope='row'] {
      background-color: color(gray-80);
      font-weight: normal;
    }

    th[rowspan] {
      background-color: color(gray-240);
    }

    th[colspan] {
      background-color: color(gray-240);
    }

    th,
    td {
      border-bottom: 1px solid color(gray-300);
      text-align: center;

      &.-bold {
        font-weight: bold;
      }

      &.-alignLeft {
        text-align: left;
      }
    }

    th:not(:last-child),
    td:not(:last-child) {
      border-right: 1px solid color(gray-300);
    }

    caption {
      text-align: right;

      @include mq(md) {
        font-size: 3rem;
        line-height: 2;
      }
    }

    .u-alignCenter {
      display: block;
    }
  }

  &.-style3 {
    min-width: 100%;
    border-collapse: collapse;
    border-top: 1px solid color(gray-300);

    th[scope='row'] {
      background-color: color(gray-200);
      text-align: left;
    }

    th,
    td {
      border-bottom: 1px solid color(gray-300);
    }

    th:not(:last-child),
    td:not(:last-child) {
      border-right: 1px solid color(gray-300);
    }

    tbody[class='u-alignCenter'] {
      th,
      td {
        text-align: center;
      }
    }

    caption {
      text-align: right;
    }
  }

  &.-style4 {
    @include mq(md) {
      margin-right: -4rem;
      overflow: auto;
    }

    table {
      min-width: 100%;
      border-collapse: collapse;
      border-top: 1px solid color(gray-300);
    }

    thead {
      th, td {
        background-color: color(gray-80);
        line-height: 1.5;
        text-align: center;
      }
    }

    th[scope='col'] {
      background-color: color(gray-200);
    }

    th[scope='row'] {
      background-color: color(gray-80);
      text-align: left;
      font-weight: normal;
    }

    th,
    td {
      padding: 1rem;
      border-bottom: 1px solid color(gray-300);
      line-height: 1.5;

      &.-bold {
        font-weight: bold;
      }
      .-small {
        font-size: 80%;
      }

      @include mq(md) {
        padding: 2rem;
      }
    }

    th:not(:last-child),
    td:not(:last-child),
    th.-border,
    td.-border {
      border-right: 1px solid color(gray-300);
    }

    caption {
      text-align: right;

      @include mq(md) {
        font-size: 3rem;
        line-height: 2;
      }
    }

    .u-alignCenter {
      display: block;
    }
  }

  &.-style5 {
    @include mq(md) {
      margin-right: -4rem;
      overflow: auto;
    }

    table {
      min-width: 100%;
      border-collapse: collapse;
      border-top: 1px solid color(gray-300);
      text-align: initial;
    }

    thead {
      th, td {
        background-color: color(gray-80);
        line-height: 1.5;
        text-align: center;
      }
    }

    th[scope='col'] {
      background-color: color(gray-200);
    }

    th[scope='row'] {
      background-color: color(gray-80);
      text-align: left;
      font-weight: normal;
    }

    th {
      word-break: keep-all;
    }
    th,
    td {
      padding: 2rem;
      border-bottom: 1px solid color(gray-300);
      line-height: 1.6;

      &.-bold {
        font-weight: bold;
      }
      .-small {
        font-size: 80%;
      }
    }

    th:not(:last-child),
    td:not(:last-child),
    th.-border,
    td.-border {
      border-right: 1px solid color(gray-300);
    }

    p {
      margin-bottom: .6rem;
    }

    ul {
      margin-bottom: 0;

      li {
        list-style: disc;
        margin-left: 1.6rem;
        @include mq(md) {
          margin-left: 3rem;
        }
        + li {
          margin-top: .6rem;
        }
        &.none {
          list-style: none;
          margin-top: .6rem;
          margin-left: 0;
          li {
            margin-top: .6rem;
            margin-left: 3.2rem;
          }
        }
      }
      &.normal {
        margin-top: .6rem;
        li {
          list-style: none;
        }
      }
      &.none {
        margin-top: .6rem;
        > li {
          list-style: none;
          margin-left: 0;
        }
        ul {
          li {
            margin-left: 1.6rem;
          }
        }
      }
    }

    .u-alignCenter {
      display: block;
    }
  }

  &.-style6 {
    @include mq(md) {
      margin-right: -4rem;
      overflow: auto;
    }

    table {
      min-width: 100%;
      border-collapse: collapse;
      border-top: 1px solid color(gray-300);
      text-align: initial;
      table-layout: fixed;
      width: 100%;
    }
    .-w20 {
      width: 20%;
    }

    thead {
      th, td {
        background-color: color(gray-80);
        line-height: 1.5;
        text-align: center;
      }
    }

    th[scope='col'] {
      background-color: color(gray-200);
    }

    th[scope='row'] {
      text-align: left;
    }

    th {
      word-break: keep-all;
    }
    th,
    td {
      padding: 2rem;
      border-bottom: 1px solid color(gray-300);
      line-height: 1.6;
      text-align: center;

      &.-bold {
        font-weight: bold;
      }
      .-small {
        font-size: 80%;
      }
    }

    th:not(:last-child),
    td:not(:last-child),
    th.-border,
    td.-border {
      border-right: 1px solid color(gray-300);
    }

    p {
      margin-bottom: .6rem;
    }

    ul {
      margin-bottom: 0;

      li {
        list-style: disc;
        margin-left: 1.6rem;
        @include mq(md) {
          margin-left: 3rem;
        }
        + li {
          margin-top: .6rem;
        }
      }
      &.normal {
        margin-top: .6rem;
        li {
          list-style: none;
        }
      }
    }

    .u-alignCenter {
      display: block;
    }
  }

  &.-style7 {
    @include mq(md) {
      margin-right: -4rem;
      overflow: auto;
    }

    table {
      min-width: 100%;
      border-collapse: collapse;
      border-top: 1px solid color(gray-300);
      text-align: initial;
    }

    thead {
      th, td {
        background-color: color(gray-80);
        line-height: 1.5;
        text-align: center;
      }
    }
    th[scope='col'] {
      background-color: color(gray-200);
    }

    th[scope='row'] {
      text-align: left;
    }

    th {
      word-break: keep-all;
    }
    th,
    td {
      padding: 2rem;
      border-bottom: 1px solid color(gray-300);
      line-height: 1.6;

      &.-bold {
        font-weight: bold;
      }
      .-small {
        font-size: 80%;
      }
    }

    th:not(:last-child),
    td:not(:last-child),
    th.-border,
    td.-border {
      border-right: 1px solid color(gray-300);
    }
  }
}
.c-table2 {
  font-size: 1.6rem;
  line-height: (36 / 16);
  @include mq(md) {
    width: 100%;
    overflow: auto;
    font-size: 2.4rem;
    line-height: (40 / 26);
  }
  table {
    min-width: 100%;
    border-collapse: collapse;
    border-top: 1px solid color(gray-300);
    @include mq(md) {
      min-width: 95rem;
    }
  }
  th {
    padding: 2rem;
    border-bottom: 1px solid color(gray-300);
    text-align: left;
    font-weight: initial;
    line-height: 1.3;
    word-break: keep-all;
    @include mq(md) {
      padding: 2rem 1.5rem;
      font-size: 2rem;
    }
  }
  td {
    padding: 2rem;
    border-bottom: 1px solid color(gray-300);
    text-align: center;
  }
  th:not(:last-child),
  td:not(:last-child),
  th.-border,
  td.-border {
    border-right: 1px solid color(gray-300);
  }
  thead {
    th, td {
      background-color: color(gray-200);
      line-height: 1.5;
      text-align: center;
      font-weight: bold;
      @include mq(md) {
        padding: 2rem 4rem;
      }
    }
  }
  tbody {
    tr {
      th {
        background-color: color(gray-80);
      }
    }
  }
  col {
    &.-sp_w35 {
      @include mq(md) {
        width: 35rem;
      }
    }
    &.-pc_w18p {
      @include mq(md, min) {
        width: 18%;
      }
    }
  }
}

.c-table__spSize {
  @include mq(md) {
    padding-right: 4rem;
  }

  &.-w110 {
    @include mq(md) {
      width: 110rem;
    }
  }

  &.-w130 {
    @include mq(md) {
      width: 130rem;
    }
  }

  &.-w167 {
    @include mq(md) {
      width: 167rem;
    }
  }

  &.-w188 {
    @include mq(md) {
      width: 188rem;
    }
  }

  &.-w200 {
    @include mq(md) {
      width: 200rem;
    }
  }

  &.-w207 {
    @include mq(md) {
      width: 207rem;
    }
  }
}

.c-table__indent {
  text-align: left;

  &.-size1 {
    padding-left: 2.5em;
  }

  &.-size2 {
    padding-left: 4em;
  }

  &.-size3 {
    padding-left: 5.5em;
  }

  &.-size4 {
    padding-left: 7em;
  }
}

.c-table__unit1 {
  margin: 4rem 0 0;
  table {
    width: 100%;
    border-collapse: collapse;
    border-bottom: 1px solid color(gray-300);
  }
  th,
  td {
    font-size: 2.6rem;
    line-height: (40/26);
    border-top: 1px solid color(gray-300);
    @include mq(md, min) {
      font-size: 1.6rem;
      line-height: (36/16);
    }
  }
  th {
    min-width: 25.8rem;
    max-width: 25.8rem;
    font-weight: bold;
    text-align: left;
    background: color(gray-200);
    padding: 2.3rem 3rem;
    vertical-align: top;
    @include mq(md, min) {
      min-width: 29rem;
      max-width: 29rem;
      padding: 1.7rem 2.1rem;
    }
  }
  td {
    width: 100%;
    border-left: 1px solid color(gray-300);
    padding: 2.3rem 3rem 2.7rem 3rem;
    @include mq(md, min) {
      padding: 1.7rem 1.9rem;
    }
  }
}

.c-table__caption {
  margin-top: 2rem;
  text-align: right;
  @include mq(md) {
    font-size: 2.6rem;
  }
  &.-bottom {
    caption-side: bottom;
  }
}