@use '../../../foundation/config' as *;

.p-post {
  max-width: 125rem;
  margin-right: auto;
  margin-left: auto;
  overflow-wrap: anywhere;
  word-break: normal;
  line-break: strict;

 /**
  .p-post内での汎用文字サイズ、行高
  **/
  p:not([class]),
  ul:not([class]),
  ol:not([class]) {
    font-size: 1.8rem;
    line-height: (36 / 20);
    @include mq(md) {
      font-size: 3.2rem;
      line-height: (36 / 20);
    }
  }

  //最初の子要素でない場合の余白
  ol:not([class]):not(:first-child),
  ul:not([class]):not(:first-child),
  dl:not([class]):not(:first-child),
  p:not([class]):not(:first-child) {
    margin-top: 2rem;
    @include mq(md) {
      margin-top: 4rem;
    }
  }

  //各コンポーネント間の余白設定
  .p-post__lead,
  .p-post__mainimg img,
  blockquote,
  .p-post__youtube,
  .p-post__mediaText,
  .p-post__image {
    margin-top: 4rem;

    & + h3,
    & + p {
      margin-top: 7.5rem;
    }
    //リード文+メイン画像の場合
    & + .p-post__mainimg img{
      margin-top: 0;
    }
  }

  //各コンポーネント間の余白設定：兄弟要素の上マージン
  .p-post__image,
  blockquote,
  .p-post__youtube,
  .p-post__mediaText,
  h2:not([class]),
  .p-post__downloadList {
    & + .p-post__image,
    & + blockquote,
    & + .p-post__youtube,
    & + .p-post__mediaText,
    & + h2:not([class]),
    & + .p-post__downloadList{
      margin-top: 4rem;
    }
  }

  //リンク汎用スタイル
  a:not([class]) {
    display: inline;
    color: color(gray-600);
    transition: color 400ms easing(easeOutCubic);
    transition-property: color, text-decoration-color;
    transition-duration: 400ms;
    transition-timing-function: easing(easeOutCubic);
    text-decoration: underline;
    text-underline-offset: 0.4rem;

    &:hover {
      color: color(green-600);
      text-decoration-color: rgba(color(gray-600), 0);
    }

    &::after {
      content: '';
      display: inline-block;
      margin-left: 0.5em;
      background-size: contain;
      background-repeat: no-repeat;
      transition: background-image 400ms easing(easeOutCubic);
    }

    &:not(:last-child) {
      margin-right: 1em;
    }
  }

  a:not([class]):not([target='_blank']) {
    &::after {
      width: 1.6rem;
      height: 1.1rem;
      background-image: url("data:image/svg+xml,%3Csvg width='16' height='11' viewBox='0 0 16 11' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10.4559 11L9.45054 9.86916L13.8742 5.4486L9.35 1.13084L10.3554 0L15.2817 4.93458C15.3823 5.03738 15.4828 5.24299 15.4828 5.4486C15.4828 5.65421 15.3823 5.85981 15.2817 5.96262L10.4559 11Z' fill='%2397938E'/%3E%3Cpath d='M14.8796 4.72897H0V6.27103H14.8796V4.72897Z' fill='%2397938E'/%3E%3C/svg%3E%0A");

      @include mq(md) {
        width: 2.4rem;
        height: 1.7rem;
      }
    }

    &:hover::after {
      background-image: url("data:image/svg+xml,%3Csvg width='16' height='11' viewBox='0 0 16 11' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10.4559 11L9.45054 9.86916L13.8742 5.4486L9.35 1.13084L10.3554 0L15.2817 4.93458C15.3823 5.03738 15.4828 5.24299 15.4828 5.4486C15.4828 5.65421 15.3823 5.85981 15.2817 5.96262L10.4559 11Z' fill='%2300a040'/%3E%3Cpath d='M14.8796 4.72897H0V6.27103H14.8796V4.72897Z' fill='%2300a040'/%3E%3C/svg%3E%0A");
    }
  }

  a:not([class])[target='_blank'] {
    &::after {
      width: 1.4rem;
      height: 1.4rem;
      background-image: url("data:image/svg+xml,%3Csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0)'%3E%3Cpath d='M18 6.85075H15.9699V2.01493H11.0977V0L17.0526 0C17.3233 0 17.594 0.134328 17.7293 0.268657C17.8647 0.402985 18 0.671642 18 0.940299V6.85075Z' fill='%2397938E'/%3E%3Cpath d='M16.2238 0.327817L7.22826 9.25627L8.66372 10.681L17.6593 1.75257L16.2238 0.327817Z' fill='%2397938E'/%3E%3Cpath d='M15.1579 18H1.08271C0.541353 18 0 17.597 0 16.9254V2.95523C0 2.41791 0.406015 1.8806 1.08271 1.8806H8.1203V4.02985H2.03008V15.9851H14.0752V10.0746H16.1053V17.0597C16.1053 17.597 15.6992 18 15.1579 18Z' fill='%2397938E'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0'%3E%3Crect width='18' height='18' fill='white'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A");

      @include mq(md) {
        width: 2.1rem;
        height: 2.1rem;
      }
    }

    &:hover::after {
      background-image: url("data:image/svg+xml,%3Csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0)'%3E%3Cpath d='M18 6.85075H15.9699V2.01493H11.0977V0L17.0526 0C17.3233 0 17.594 0.134328 17.7293 0.268657C17.8647 0.402985 18 0.671642 18 0.940299V6.85075Z' fill='%2300A040'/%3E%3Cpath d='M16.2238 0.327817L7.22826 9.25627L8.66372 10.681L17.6593 1.75257L16.2238 0.327817Z' fill='%2300A040'/%3E%3Cpath d='M15.1579 18H1.08271C0.541353 18 0 17.597 0 16.9254V2.95523C0 2.41791 0.406015 1.8806 1.08271 1.8806H8.1203V4.02985H2.03008V15.9851H14.0752V10.0746H16.1053V17.0597C16.1053 17.597 15.6992 18 15.1579 18Z' fill='%2300A040'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0'%3E%3Crect width='18' height='18' fill='white'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E ");
    }
  }
}

//コンテンツエリアラッパー
.p-post__body {
  max-width: 105rem;
  margin: 0 auto;
  padding-top: 2.4rem;
  padding-bottom: 7.8rem;
}

//日付、カテゴリアイコン、ページタイトルラッパー
.p-post__header {
  display: grid;
  grid-template-rows: repeat(2, auto);
  grid-template-columns: auto 1fr;
  grid-template-areas: 'publish-date small-category' 'post-title post-title';
  column-gap: 5.6rem;
  row-gap: 4.2rem;

  @include mq(md) {
    column-gap: 4.2rem;
  }
}

//ページタイトル
.p-post__title {
  grid-area: post-title;
  font-size: 2.6rem;
  line-height: (46 / 26);

  @include mq(md) {
    font-size: 4rem;
    line-height: (60 / 40);
  }
}

//日付
.p-post__publishDate {
  grid-area: publish-date;
  @include font-barlow;
  font-size: 2rem;
  letter-spacing: 0.05em;

  @include mq(md) {
    font-size: 4rem;
  }
}

//カテゴリアイコン
.p-post__smallCategoryList {
  display: flex;
  flex-wrap: wrap;

  @include mq(md) {
    justify-content: flex-start;
    width: 49rem;
    margin-top: auto;
  }
}
.p-post__smallCategoryItem {
  font-size: 1.4rem;
  background-color: color(green-600);

  @include mq(md) {
    margin-bottom: 1rem;
    text-align: center;
    font-size: 2.4rem;
  }

  &:not(:last-child) {
    margin-right: 1.2rem;
  }
}

.p-post__smallCategoryLink {
  display: block;
  padding: 0.5rem 1.5rem;
  @include lhCrop(1.5);
  @include mq(md) {
    padding: 0.7rem 2rem;
  }
  color: color(white);
}


// 記事要素：リード文
.p-post__lead {
  margin: 2rem 0 4rem;
  font-size: 1.8rem;
  line-height: (36 / 20);
  @include mq(md) {
    margin: 4rem 0 8rem;
    font-size: 3.2rem;
    line-height: 1.8;
  }
}

// 記事要素：メインビジュアル
.p-post__mainimg {
  display: flex;
  justify-content: center;
  align-items: center;
  img{
    object-fit: contain;
    backface-visibility: hidden;
    @include mq(md,min) {
      width: 100%;
      // height:(665.594 / 12) * 1rem;// height: 55.466166666667rem;
      height:(500 / 12)* 1rem;
    }
    @include mq(md) {
      width: 100%;
      // height: (357.750 /9.09375) * 1rem;
      height: (270 /9.09375) * 1rem;
    }
  }
}

//本文ブロック：注釈
.p-post small:not([class]) {
  display: block;
  font-size: 1.4rem;
  line-height: (26 / 14);

  @include mq(md) {
    font-size: 2.4rem;
    line-height: (40 / 24);
  }
}


// 本文ブロック：画像
.p-post__image {
  text-align: center;
  img {
    height: auto;
  }
}

//本文ブロック：ビジュアルエディタ
  /**
  ビジュアルエディタ用
  **/
.p-post .wysiwyg{
  margin: 4rem 0;
  &:first-child{
    > .wp-caption,.-level2,.-level3,.-level4,.-level5{
      &:first-child{
        margin-top: 4.2rem!important;
      }
    }
  }

  //タイポグラフィと余白
  & > *:not([class]):not([style]) {
    margin-top: 2rem;
    margin-bottom: 2rem;
    @include mq(md) {
      margin-top: 4rem;
      margin-bottom: 4rem;
    }
  }

  //エディタblock_formatの見出し要素
  h2:not([class]) {
    position: relative;
    padding: 1.4rem 3rem 1.6rem;
    background-color: color(gray-50);
    font-size: 2rem;
    line-height: 2;

    @include mq(md) {
      padding: 3.29rem 5rem;
      font-size: 3.4rem;
      line-height: (54 / 34);
    }

    &::before {
      content: '';
      display: block;
      position: absolute;
      top: 2rem;
      bottom: 2rem;
      left: 0;
      width: 0.4rem;
      height: calc(100% - 4rem);
      background-color: color(green-600);

      @include mq(md) {
        width: 0.8rem;
      }
    }
  }

  h3:not([class]) {
    font-size: 2rem;
    line-height: 2;

    @include mq(md) {
      font-size: 3.4rem;
      line-height: (54 / 34);
    }
  }

  //エディタstyle_formatの見出し要素
  .c-heading {
    &.-level2 {
      @include font-default;
      font-size: 2.8rem;
      margin: 4rem 0 2rem;
      @include mq(md) {
        font-size: 4.2rem;
        margin-top: 6rem;
        margin-bottom: 4rem;
      }
      &::before {
        top: 54%;
        height: calc(100% - 0.5rem);
      }
    }

    &.-level3 {
      @include font-default;
      font-size: 2.6rem;
      padding-bottom: 0.5rem;
      margin: 4rem 0 2rem;
      @include mq(md) {
        font-size: 4rem;
        padding-bottom: 1rem;
        margin-top: 6rem;
        margin-bottom: 4rem;
      }
    }

    &.-level4 {
      font-size: 2.4rem;
      margin: 4rem 0 1.5rem;
      @include mq(md) {
        font-size: 3.6rem;
        margin-top: 6rem;
        margin-bottom: 3rem;
      }
      & + * {
        margin-top: 1.5rem;
        @include mq(md) {
          margin-top: 3rem;
        }
      }
    }

    &.-level5 {
      font-size: 2rem;
      margin: 4rem 0 1rem;
      @include mq(md) {
        font-size: 3.4rem;
        margin-top: 6rem;
        margin-bottom: 2rem;
      }
      & + * {
        margin-top: 1rem;
        @include mq(md) {
          margin-top: 2rem;
        }
      }
    }
  }

  //style_format:文字サイズ指定
  .p-sizing {
    margin-top: 2rem;
    @include mq(md) {
      margin-top: 4rem;
    }
    &.-large {
      font-size: 2.8rem;
      @include mq(md) {
        font-size: 4.2rem;
      }
    }
    &.-middle {
      font-size: 2.6rem;
      @include mq(md) {
        font-size: 4rem;
      }
    }
    &.-default {
      font-size: 1.8rem;
      @include mq(md) {
        font-size: 3.2rem;
      }
    }
    &.-small {
      font-size: 1.5rem;
      @include mq(md) {
        font-size: 2.8rem;
      }
    }
    &.-notes {
      font-size: 1.4rem;
      line-height: (26 / 14);
      @include mq(md) {
        font-size: 2.4rem;
        line-height: (40 / 24);
      }
    }
  }

  //太字ボタン
  strong {
    font-weight: bolder;
  }
  //イタリックボタン
  em {
    font-style: italic;
  }
  //取り消し線
  del {
    text-decoration: line-through;
  }
  //追加したメディア画像
  img {
    max-width: 100%;
    height: auto;
    &.alignright { display: block; margin: 0 0 0 auto; }/* 配置位置 右 */
    &.alignleft { display: block; margin: 0 auto 0 0; }/* 配置位置 左 */
    &.aligncenter { display: block; margin: 0 auto; }/* 配置位置 中央 */
  }

  //画像キャプション
  .wp-caption{
    text-align: center;
    font-size: 1.4rem;
    line-height: 1.8;
    max-width: 100%;
    @include mq(md) {
      font-size: 2.4rem;
      line-height: 1.8;
    }
    &.aligncenter{
      margin: 0 auto;
    }
    &.alignright{
      margin: 0 0 0 auto;
    }
    .wp-caption-text{
      text-align: left;
      padding: 0 5px;
      margin-bottom: 2rem;
      @include mq(md) {
        margin-bottom: 4rem;
      }
    }
    .wp-caption-dd{
      padding: 0;
      text-align: left;
      margin-bottom: 2.5rem;
      @include mq(md) {
        margin-bottom: 1em;
      }
    }
  }

  //上付き
  sup {
    top: -0.5em;
    font-size: 75%;
    line-height: 0;
    position: relative;
    vertical-align: baseline;
  }
  //下付き
  sub {
    font-size: 75%;
    line-height: 0;
    position: relative;
    vertical-align: baseline;
    bottom: -0.25em;
  }
  //引用
  blockquote:not([class]) {
    padding: 3rem 4rem 3.24rem;
    border: 1px solid color(gray-300);
    //引用元
    cite {
      display: block;
      text-align: right;
      font-style: normal;
    }
  }
  //整形済みテキスト
  pre:not([class]) {
    display: block;
    padding: 3rem 4rem 3.24rem;
    border: 1px solid color(gray-300);
    font-size: 1.6rem;

    @include mq(md) {
      font-size: 3rem;
    }
  }
  //コードブロック
  code:not([class]) {
    display: block;
    padding: 3rem 4rem 3.24rem;
    border: 1px solid color(gray-300);
  }
  /**
  p要素
  **/
  p:not([class]) {
    font-size: 1.8rem;
    line-height: (36 / 20);

    @include mq(md) {
      font-size: 3.2rem;
      line-height: 1.8;
    }
  }

  /**
  番号なしリスト(ul)
  **/
  //汎用スタイル
  ul:not([class]) {
    font-size: 1.8rem;
    line-height: (36 / 20);
    margin: 2rem 0 2rem 2em;

    @include mq(md) {
      font-size: 3.2rem;
      line-height: 1.8;
    }
  }

  //「デフォルト」設定
  ul:not([class]) li {
    position: relative;
    list-style: none!important;
    padding-left: 1em;
    
    ul,ol{//入れ子の場合
      margin-top: 0!important;
      margin-left: 0!important;
      margin-bottom: 0!important;
    }

    &::before {
      position: absolute;
      top: 0;
      left: 0;
      content: '';
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='8px' height='8px'%3E%3Cpath fill-rule='evenodd' fill='rgb(0, 160, 64)' d='M4.000,-0.000 C6.209,-0.000 8.000,1.791 8.000,4.000 C8.000,6.209 6.209,8.000 4.000,8.000 C1.791,8.000 -0.000,6.209 -0.000,4.000 C-0.000,1.791 1.791,-0.000 4.000,-0.000 Z'/%3E%3C/svg%3E");
      background-repeat: no-repeat;
      background-size: 50%;
      width: 1em;
      height: 1em;
      margin-top: 0.65em;
    }
  }

  //デフォルト以外を使う場合、style属性指定される
  ul[style*="list-style-type"] > li:not([class]) {
    position: relative!important;
    list-style: inherit!important;
    text-indent: 0;
    margin-left: 1em;
    padding-left: 0;
    &::before {
      content: none!important;
    }
  }


  /**
  番号付きリスト（ol）
  **/
  //汎用スタイル
  ol:not([class]) {
    font-size: 1.8rem;
    line-height: (36 / 20);
    margin: 2rem 0 2rem 2em;

    @include mq(md) {
      font-size: 3.2rem;
      line-height: 1.8;
    }
  }

  //「デフォルト」設定
  ol:not([class]) > li {
    position: relative;
    counter-increment: li;
    list-style: inherit;
    margin-left: 1em;
    text-indent: 0;
    > ul,ol{//入れ子の場合
      margin-top: 0!important;
      margin-left: 0 !important;
      margin-bottom: 0!important;
    }
  }

   //デフォルト以外を使う場合、style属性指定される
  ol[style*="list-style-type"]>li:not([class]) {
    list-style-type: inherit!important;
    margin-left: 1em;
  }

  /**
  テーブル用
  **/
  .p-post__table {
    margin-top: 4rem;
    width: 100%;
    @include mq(md) {
      margin-top: 6.2rem;
    }
    overflow: auto;

    @include mq(md) {
      margin-right: -4rem;
    }

    & + h3,
    & + p {
      margin-top: 7.5rem;
    }
    & + & {
      margin-top: 10rem;
    }

    > div {
      min-width: 100%;

      @include mq(md, min) {
        max-width: 100%;
      }

      @include mq(md) {
        padding-right: 4rem;
      }
    }

    table {
      border-collapse: collapse;
      font-size: 1.6rem;
      line-height: (24 / 16);
      // border-top: 1px solid color(gray-300);

      @include mq(md, min) {
        width: 100%;
        max-width: 100%;
      }

      @include mq(md) {
        font-size: 2.6rem;
        line-height: (40 / 16);
        width: 100%!important;
        max-width: 100%;
      }
      &.alignright { display: block; margin: 0 0 0 auto; }/* 配置位置 右 */
      &.alignleft { display: block; margin: 0 auto 0 0; }/* 配置位置 左 */
      &.aligncenter { display: block; margin: 0 auto; }/* 配置位置 中央 */
    }

    th {
      background-color: color(gray-80);
    }

    th,
    td {
      padding: 1.38em 2.2rem;
      border-top: 1px solid color(gray-300);
      border-right: 1px solid color(gray-300);
      border-bottom: 1px solid color(gray-300);
      border-left: 1px solid color(gray-300);
    }

    th[scope='row'] {
      text-align: left;
    }

    td {
      text-align: center;
    }
  }
  //リンク
  a:not([class]) {
    display: inline;
    color: color(gray-600);
    transition: color 400ms easing(easeOutCubic);
    transition-property: color, text-decoration-color;
    transition-duration: 400ms;
    transition-timing-function: easing(easeOutCubic);
    text-decoration: underline;
    text-underline-offset: 0.4rem;

    &:hover {
      color: color(green-600);
      text-decoration-color: rgba(color(gray-600), 0);
    }

    &::after {
      content: '';
      display: inline-block;
      margin-left: 0.5em;
      background-size: contain;
      background-repeat: no-repeat;
      transition: background-image 400ms easing(easeOutCubic);
    }

    &:not(:last-child) {
      margin-right: 1em;
    }
  }

  a:not([class]):not([target='_blank']) {
    &::after {
      width: 1.6rem;
      height: 1.1rem;
      background-image: url("data:image/svg+xml,%3Csvg width='16' height='11' viewBox='0 0 16 11' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10.4559 11L9.45054 9.86916L13.8742 5.4486L9.35 1.13084L10.3554 0L15.2817 4.93458C15.3823 5.03738 15.4828 5.24299 15.4828 5.4486C15.4828 5.65421 15.3823 5.85981 15.2817 5.96262L10.4559 11Z' fill='%2397938E'/%3E%3Cpath d='M14.8796 4.72897H0V6.27103H14.8796V4.72897Z' fill='%2397938E'/%3E%3C/svg%3E%0A");

      @include mq(md) {
        width: 2.4rem;
        height: 1.7rem;
      }
    }

    &:hover::after {
      background-image: url("data:image/svg+xml,%3Csvg width='16' height='11' viewBox='0 0 16 11' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10.4559 11L9.45054 9.86916L13.8742 5.4486L9.35 1.13084L10.3554 0L15.2817 4.93458C15.3823 5.03738 15.4828 5.24299 15.4828 5.4486C15.4828 5.65421 15.3823 5.85981 15.2817 5.96262L10.4559 11Z' fill='%2300a040'/%3E%3Cpath d='M14.8796 4.72897H0V6.27103H14.8796V4.72897Z' fill='%2300a040'/%3E%3C/svg%3E%0A");
    }
  }

  a:not([class])[target='_blank'] {
    &::after {
      width: 1.4rem;
      height: 1.4rem;
      background-image: url("data:image/svg+xml,%3Csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0)'%3E%3Cpath d='M18 6.85075H15.9699V2.01493H11.0977V0L17.0526 0C17.3233 0 17.594 0.134328 17.7293 0.268657C17.8647 0.402985 18 0.671642 18 0.940299V6.85075Z' fill='%2397938E'/%3E%3Cpath d='M16.2238 0.327817L7.22826 9.25627L8.66372 10.681L17.6593 1.75257L16.2238 0.327817Z' fill='%2397938E'/%3E%3Cpath d='M15.1579 18H1.08271C0.541353 18 0 17.597 0 16.9254V2.95523C0 2.41791 0.406015 1.8806 1.08271 1.8806H8.1203V4.02985H2.03008V15.9851H14.0752V10.0746H16.1053V17.0597C16.1053 17.597 15.6992 18 15.1579 18Z' fill='%2397938E'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0'%3E%3Crect width='18' height='18' fill='white'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A");

      @include mq(md) {
        width: 2.1rem;
        height: 2.1rem;
      }
    }

    &:hover::after {
      background-image: url("data:image/svg+xml,%3Csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0)'%3E%3Cpath d='M18 6.85075H15.9699V2.01493H11.0977V0L17.0526 0C17.3233 0 17.594 0.134328 17.7293 0.268657C17.8647 0.402985 18 0.671642 18 0.940299V6.85075Z' fill='%2300A040'/%3E%3Cpath d='M16.2238 0.327817L7.22826 9.25627L8.66372 10.681L17.6593 1.75257L16.2238 0.327817Z' fill='%2300A040'/%3E%3Cpath d='M15.1579 18H1.08271C0.541353 18 0 17.597 0 16.9254V2.95523C0 2.41791 0.406015 1.8806 1.08271 1.8806H8.1203V4.02985H2.03008V15.9851H14.0752V10.0746H16.1053V17.0597C16.1053 17.597 15.6992 18 15.1579 18Z' fill='%2300A040'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0'%3E%3Crect width='18' height='18' fill='white'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E ");
    }
  }

  /**
  横ライン
  **/
  hr {
    margin-top: 4rem!important;
    margin-bottom: 4rem!important;
    @include mq(md) {
      margin-top: 8rem!important;
      margin-bottom: 8rem!important;
    }
  }
}

// 本文ブロック：メディア+テキスト
.p-post__mediaText {

  .p-post__mediaTextHeadings {
    margin: 0 0 2rem;
    font-size: 2.4rem;
    line-height: 2;

    @include mq(md) {
      font-size: 3.4rem;
      line-height: (54 / 34);
    }
  }

  .p-post__mediaTextInner {
    display: flex;

    //過去のフォールバック
    @include mq(md, min) {
      align-items: flex-start;
    }

    &.-upper {
      @include mq(md, min) {
        align-items: flex-start;
      }
    }
    &.-middle {
      @include mq(md, min) {
        align-items: center;
      }
    }
    &.-lower {
      @include mq(md, min) {
        align-items: flex-end;
      }
    }

    @include mq(md) {
      flex-direction: column;
    }
    img{
      @include mq(md) {
        width: 100%;
      }
    }

    .alignright {
      order: 2;

      @include mq(md, min) {
        margin-left: 6.6rem;
      }

      @include mq(md) {
        margin-top: 3.4rem;
      }
    }

    .alignleft {
      @include mq(md, min) {
        margin-right: 6.6rem;
      }

      @include mq(md) {
        margin-bottom: 3.4rem;
      }
    }

    .alignright,
    .alignleft {
      height: auto;

      @include mq(md, min) {
        max-width: 40rem;

        &.-large {
          min-width: 40rem;
          max-width: 40rem;
        }
        &.-medium {
          min-width: 30rem;
          max-width: 30rem;
        }
        &.-small {
          min-width: 20rem;
          max-width: 20rem;
        }
      }

      & + div {
        @include mq(md, min) {
          flex-grow: 1;
        }
      }
    }
    .wysiwyg {
      margin:0;
      @include mq(md, min) {
        & > *:first-child {
          margin-top: 0;
        }
        & > *:last-child {
          margin-bottom: 0;
        }
      }
      .c-heading {
        margin: 3rem 0 2rem;
      }
      //ul、olの修正
      ul:not([class]),ol:not([class]) {
        margin-left: 0;
      }
    }
  }
}

// 本文ブロック：動画
.p-post__youtube {
  @include mq(md,min) {
    width: 80rem;
    margin-right: auto;
    margin-left: auto;
  }
  @include mq(md) {
    width: 100%;
  }
  iframe {
    width: 100%;
    height: 45rem;
    @include mq(md) {
      width: 100%;
      height: 42rem;
    }
  }
  figcaption {
    padding-top: 1rem;
    font-size: 1.5rem;
    text-align: right;
    @include mq(md) {
      font-size: 2.4rem;
      line-height: (40 / 24);
      text-align: left;
    }
  }
}

// 本文ブロック：ダウンロード
.p-post__download {
  margin-top: 8rem;
  padding-top: 6.8rem;
  border-top: 1px solid color(gray-300);
}
.p-post__downloadTitle {
  font-size: 2rem;
  line-height: 2;

  @include mq(md) {
    font-size: 3.4rem;
    line-height: (54 / 34);
  }
}
.p-post__downloadList {
  @include mq(md, min) {
    display: flex;
    flex-wrap: wrap;
    margin-top: 4rem;
  }
}
.p-post__downloadItem {
  flex: 0 0 31rem;

  &:nth-child(n + 1) {
    @include mq(md) {
      margin-top: 5rem;
    }
  }

  &:not(:nth-child(3n + 1)) {
    @include mq(md, min) {
      margin-left: 6rem;
    }
  }

  &:nth-child(n + 4) {
    @include mq(md, min) {
      margin-top: 5rem;
    }
  }
}
.p-post__downloadLink {
  display: flex;

  @include mq(md, min) {
    flex-direction: column;
  }

  @include mq(md) {
    align-items: center;
  }
}
.p-post__downloadText {
  font-size: 1.6rem;
  font-weight: bold;
  line-height: (26 / 16);
  transition: color 400ms easing(easeOutCubic);

  @include mq(md, min) {
    margin-top: 1.6rem;
  }

  @include mq(md) {
    font-size: 3rem;
    line-height: 1.625;
  }

  .p-post__downloadLink:hover & {
    color: color(green-600);
  }
}
.p-post__downloadImageBlock {
  position: relative;
  order: -1;
  width: 31rem;
  height: 21rem;
  background-color: color(white);

  @include mq(md) {
    flex-shrink: 0;
    width: 22rem;
    height: 15rem;
    margin-right: 5rem;
  }
}
.p-post__downloadImage {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  border: 1px solid color(gray-300);

  img {
    width: 23rem;
    height: 15rem;
    object-fit: contain;
    font-family: 'object-fit: contain'; /* stylelint-disable-line */

    @include mq(md) {
      width: 15rem;
      height: 11rem;
    }
  }
}
.p-post__downloadSign {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 0;
  bottom: 0;
  width: 5rem;
  height: 5rem;
  background-color: color(gray-200);

  @include mq(md) {
    width: 4rem;
    height: 4rem;
  }
}
.p-post__downloadIcon {
  width: 1.9rem;
  height: 1.5rem;
  color: color(gray-450);
  transition: color 400ms easing(easeOutCubic);

  .p-post__downloadLink:hover & {
    color: color(green-600);
  }
}

//本文ブロック：引用
.p-post blockquote{
  padding: 3rem 4rem 3.24rem;
  border: 1px solid color(gray-300);
  //引用元
  cite {
    display: block;
    text-align: right;
    font-style: normal;
  }
  //「斜体」を選択時の文字種
  &.p-post__italic {
    font-style: italic;
    cite {
      font-style: italic;
    }
  }
  .wysiwyg {
    margin:0;
    p {
      margin: 1rem 0!important;
      font-size: 1.4rem!important;
      @include mq(md) {
        margin: 1.2rem 0!important;
        font-size: 2.4rem!important;
      }
    }
    ul:not([class]),ol:not([class]) {
      margin: 1rem 0;
      font-size: 1.4rem;
      @include mq(md) {
        margin: 1.2rem 0;
        font-size: 2.4rem;
      }
    }
  }
  cite{
    font-size: 1.4rem;
    @include mq(md) {
      font-size: 2.4rem;
    }
  }
}
