@mixin font-barlow {
  font-family: 'Barlow', sans-serif;
  font-weight: 600;
}

@mixin font-serif {
  font-family: YakuHanMP_Noto, 'Noto Serif JP', serif;
  font-weight: 600;
}

@mixin font-extended {
  display: inline-block;
  transform: scaleY(0.95);
}

@mixin font-default {
  font-family: 'Noto Sans JP', sans-serif;
}