@use '../../../foundation/config' as *;

.p-sustainabilitySousei {
  padding-bottom: 25.2rem;

  @include mq(md) {
    padding-bottom: 24rem;
  }
}

.p-sustainabilitySousei__inner {
  margin-top: 0;

  @include mq(md) {
    margin-top: 13rem;
  }
}

.p-sustainabilitySousei__text {
  @include mq(md, min) {
    font-size: 1.6rem;
    line-height: (36/16);
  }

  @include mq(md) {
    font-size: 3rem;
    line-height: (60/30);
  }

  a {
    color: color(gray-600);
    transition-property: color, text-decoration-color;
    transition-duration: 400ms;
    transition-timing-function: easing(easeOutCubic);
    text-decoration: underline;
    text-underline-offset: 0.4rem;

    &:hover {
      color: color(green-600);
      text-decoration-color: rgba(color(gray-600), 0);
    }
  }
}

.p-sustainabilitySousei__textIcon {
  vertical-align: top;

  @include mq(md, min) {
    width: 1.6rem;
    height: 1.1rem;
    margin: 1.3rem 0 0 0.5rem;
  }

  @include mq(md) {
    width: 2.4rem;
    height: 1.7rem;
    margin: 2.3rem 0 0 0.5rem;
  }
}

.p-sustainabilitySousei__subTitle {
  @include mq(md, min) {
    margin: 5.7rem 0 1rem;
  }

  @include mq(md) {
    margin: 7.5rem 0 2rem;
  }
}

.p-sustainabilitySouseiBox {
  border-bottom: 1px solid color(gray-300);
  @include mq(md, min) {
    font-size: 1.6rem;
    line-height: (36/16);
  }

  @include mq(md) {
    font-size: 2.6rem;
    line-height: (40/26);
  }
}

.p-sustainabilitySouseiBox__row {
  @include mq(md, min) {
    display: flex;
  }
}

.p-sustainabilitySouseiBox__item {
  width: 100%;
  text-align: center;

  @include mq(md, min) {
    & + & {
      border-left: 1px solid color(gray-300);
    }
  }
}

.p-sustainabilitySouseiBox__title {
  font-weight: bold;
  background-color: color(gray-200);
  border-top: 1px solid color(gray-300);
  border-bottom: 1px solid color(gray-300);

  @include mq(md, min) {
    padding: 1.7rem 0;
  }

  @include mq(md) {
    padding: 2.4rem 2rem;
  }
}

.p-sustainabilitySouseiBox__text {
  @include mq(md, min) {
    padding: 1.9rem 0;
  }

  @include mq(md) {
    padding: 2.4rem 4rem;
    min-height: 8.9rem;
  }
}

.p-sustainabilitySouseiBox__inner {
  text-align: left;
  display: inline-block;
}
