@use '../../../foundation/config' as *;

.p-irStockDividend__section {
  & + & {
    margin-top: 20rem;

    @include mq(md) {
      margin-top: 19.8rem;
    }
  }
}

.p-irStockDividend__sectionHeading {
  font-size: 3.4rem;
  @include lhCrop(44 / 34);

  @include mq(md) {
    font-size: 6rem;
    @include lhCrop(80 / 60);
  }
}

.p-irStockDividend__text {
  font-size: 1.6rem;
  line-height: (36 / 16);
  @include lhCrop(36 / 16);

  @include mq(md) {
    font-size: 3rem;
    line-height: 2;
    @include lhCrop(2);
  }

  .p-irStockDividend__sectionHeading + & {
    margin-top: 7.4rem;
  }

  .c-heading.-level3 + & {
    margin-top: 5rem;

    @include mq(md) {
      margin-top: 4.4rem;
    }
  }
}

.p-irStockDividend__smallSection {
  margin-top: 9rem;
  @include mq(md, min) {
    margin-top: 7.5rem;
  }
  .p-irStockDividend__sectionHeading + & {
    margin-top: 6.4rem;
  }

  .p-irStockDividend__text + & {
    margin-top: 9.6rem;
  }

  .p-irStockDividend__esg + & {
    margin-top: 9rem;
  }

  & + & {
    margin-top: 7.2rem;
    @include mq(md, min) {
      margin-top: 10.2rem;
    }
  }

  &.-transition {
    @include mq(md, min) {
      margin-top: 10rem;
    }
  }
}

.p-irStockDividend__xsmallSection {
  margin-top: 3.4rem;
  @include mq(md, min) {
    .p-irStockDividend__xsmallSection + & {
      margin-top: 7rem;
    }
  }
}

.p-irStockDividend__table1 {
  margin-top: 5rem;

  .c-heading.-level5 + & {
    margin-top: 2rem;
    @include mq(md, min) {
      margin-top: 1rem;
    }
  }
  @include mq(md) {
    .p-irTableStockDividend__titleCell {
      width: 42rem;
    }
  }
}
.p-irStockDividend__image {
  width: 92.8rem;
  margin-top: 7.2rem;
  margin-right: auto;
  margin-left: auto;

  @include mq(md) {
    width: 100%;
    margin-top: 5rem;
  }

  img {
    width: 100%;
    height: auto;
  }
}

.p-irStockDividend__button {
  margin-top: 5rem;

  @include mq(md) {
    text-align: center;
  }

  &.-large {
    margin-top: 8rem;
    text-align: center;
  }
}

.p-irStockDividend__smallText {
  font-size: 2.4rem;
  line-height: 1.7;
  margin-top: 1rem;
  @include mq(md, min) {
    font-size: 1.4rem;
    margin-top: 3rem;
  }
}

@include mq(md) {
  .c-table__spSize.-irDividend {
    width: 165rem;
  }
}
