@use '../../../foundation/config' as *;

.l-sustainabilityMain {
  display: flex;
  justify-content: space-between;
  flex-flow: row-reverse;
  margin-top: 15rem;
  .l-sustainabilityContent {
    width: 75%;
  }
  .l-sustainabilitySidebar {
    width: calc(25% - 9rem);
    margin-bottom: 15rem;
    .sustainabilityNav {
      background-color: color(white);
    }
  }
  @include mq(md) {
    display: block;
    margin-top: 4rem;
    .l-sustainabilityContent {
      width: 100%;
    }
    .l-sustainabilitySidebar {
      width: 100%;
    }
  }
}

