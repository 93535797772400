@use '../../../foundation/config' as *;

 // ↓ 230406 add
.p-researchResult {
  .p-researchResult__Block {
    display: flex;
    justify-content: space-between;
    padding: 1.6rem 0;
    border-bottom: 1px solid #ddddd5;
    @include mq(md) {
      padding: 2rem 0;
    }
  }
  .p-researchResult__label {
    font-weight: bold;
    letter-spacing: 0.2rem;
    padding-right: 1rem;
    margin-bottom: 0;
    @include mq(md) {
      padding: 1.5rem 1rem 1.5rem 0;
    }
  }
  .p-researchResult__checks {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 90%;
    @include mq(md) {
      width: 75%;
    }
    label {
      position: relative;
      display: inline-flex;
      align-items: center;
      min-width: 14rem;
      margin-right: 4rem;
      cursor: pointer;
      @include mq(md) {
        min-width: 22rem;
        margin-right: 4rem;
        padding: .4em 0;
      }
      input {
        opacity: 0;
        webkit-appearance: none;
        position: absolute;
      }
    }
    &.checkbox {
      label {
        &::before {
          content: "";
          display: block;
          width: 1em;
          height: 1em;
          margin-right: 0.4em;
          background-color: #fff;
          border: 1px solid #aaa;
        }
        &::after {
          opacity: 0;
          content: "";
          display: block;
          position: absolute;
          top: 0.77em;
          left: 0.32em;
          width: 0.35em;
          height: 0.7em;
          margin-top: -0.2em;
          border-right: 2px solid #fff;
          border-bottom: 2px solid #fff;
          transform: rotate(45deg);
          @include mq(md) {
            top: 1em;
            border-right: 3px solid #fff;
            border-bottom: 3px solid #fff;
          }
        }
      }
      label.checked::before {
        background-color: #00a040;
      }
      label.checked::after {
        opacity: 1;
      }
    }
    &.radiobox {
      label {
        &::before {
          content: "";
          display: block;
          width: 1em;
          height: 1em;
          margin-right: 0.4em;
          background-color: #fff;
          border-radius: 999px;
          border: 1px solid #aaa;
        }
        &::after {
          opacity: 0;
          content: "";
          display: block;
          position: absolute;
          width: 0.6em;
          height: 0.6em;
          background-color: #00a040;
          border-radius: 999px;
          top: 50%;
          left: 0.2em;
          transform: translateY(-50%);
        }
      }
      label.checked::after {
        opacity: 1;
      }
    }
  }
  .p-researchResult__searchBtnWrap {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 5rem 0;
  }
  .p-researchResult__searchBtn {
    position: relative;
    display: block;
    align-items: center;
    justify-content: center;
    width: 50rem;
    padding: 1em;
    border: 2px solid #00a040;
    background-color: transparent;
    color: #00a040;
    font-weight: bold;
    transition: 300ms;
    @include mq(md) {
      padding: 2.6rem;
    }
    &::after {
      content: "";
      display: block;
      position: absolute;
      top: 2.4rem;
      right: 3rem;
      width: 1.2rem;
      height: 1.2rem;
      border-right: 1px solid #00a040;
      border-bottom: 1px solid #00a040;
      transform: rotate(45deg);
      @include mq(md) {
        top: 3.4rem;
        width: 1.6rem;
        height: 1.6rem;
      }
    }
    &:hover {
      background-color: #00a040;
      color: #fff;
      &::after {
        border-right: 1px solid #fff;
        border-bottom: 1px solid #fff;
      }
    }
  }
  .p-researchResult__item {
    display: flex;
    flex-wrap: wrap;
    margin-top: 5rem;
    padding: 2rem 4rem;
    background-color: #ededeb;
    text-align: left;
    word-wrap: break-word;
    dt {
      width: 20%;
      padding: 1rem;
      @include mq(md) {
        display: flex;
        align-items: center;
        width: 28%;
        padding: 2rem 2rem 2rem 0;
        font-size: 2.8rem;
        line-height: 1.2;
      }
    }
    dt:not(:last-of-type) {
      border-bottom: 1px solid #999;
    }
    dd {
      width: 80%;
      padding: 1rem;
      @include mq(md) {
        width: 72%;
        padding: 2.5rem 0 2.5rem 2rem;
      }
    }
    dd:first-of-type {
      color: color(green-600);
      font-weight: bold;
    }
    dd:not(:last-of-type) {
      border-bottom: 1px solid #999;
    }
    a {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-basis: auto;
      min-width: 0;
      width: fit-content;
      color: color(gray-600);
      text-decoration: underline;
      text-underline-offset: 0.4rem;
      transition: 400ms;
      overflow-wrap: anywhere;
      &::after {
        content: "";
        width: 1.6rem;
        height: 1.6rem;
        margin-left: .5em;
        background-color: color(gray-600);
        mask-image: url('data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%20viewBox%3D%220%200%2016%2011%22%3E%3Cpath%20d%3D%22M10.4%209.53674e-07L9.4%201.1L13.8%205.4L9.3%209.6L10.3%2010.7L15.2%205.9C15.3%205.8%2015.4%205.6%2015.4%205.4C15.4%205.2%2015.3%205%2015.2%204.9L10.4%209.53674e-07Z%22%2F%3E%3Cpath%20d%3D%22M14.8%206.1H0V4.6H14.8V6.1Z%22%2F%3E%3C%2Fsvg%3E');
        -webkit-mask-image: url('data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%20viewBox%3D%220%200%2016%2011%22%3E%3Cpath%20d%3D%22M10.4%209.53674e-07L9.4%201.1L13.8%205.4L9.3%209.6L10.3%2010.7L15.2%205.9C15.3%205.8%2015.4%205.6%2015.4%205.4C15.4%205.2%2015.3%205%2015.2%204.9L10.4%209.53674e-07Z%22%2F%3E%3Cpath%20d%3D%22M14.8%206.1H0V4.6H14.8V6.1Z%22%2F%3E%3C%2Fsvg%3E');
        mask-repeat: no-repeat;
        mask-position: center;
        -webkit-mask-repeat: no-repeat;
        -webkit-mask-position: center;
        transition: 400ms;
        @include mq(md) {
          width: 4rem;
          height: 4rem;
        }
      }
      &[target="_blank"]::after {
        mask-image: url('data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%20viewBox%3D%220%200%2018%2018%22%3E%3Cpath%20fill%3D%22%2397938e%22%20d%3D%22M18%206.85075H15.9699V2.01493H11.0977V0L17.0526%200C17.3233%200%2017.594%200.134328%2017.7293%200.268657C17.8647%200.402985%2018%200.671642%2018%200.940299V6.85075Z%22%2F%3E%3Cpath%20fill%3D%22%2397938e%22%20d%3D%22M16.2238%200.327817L7.22826%209.25627L8.66372%2010.681L17.6593%201.75257L16.2238%200.327817Z%22%2F%3E%3Cpath%20fill%3D%22%2397938e%22%20d%3D%22M15.1579%2018H1.08271C0.541353%2018%200%2017.597%200%2016.9254V2.95523C0%202.41791%200.406015%201.8806%201.08271%201.8806H8.1203V4.02985H2.03008V15.9851H14.0752V10.0746H16.1053V17.0597C16.1053%2017.597%2015.6992%2018%2015.1579%2018Z%22%2F%3E%3C%2Fsvg%3E');
        -webkit-mask-image: url('data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%20viewBox%3D%220%200%2018%2018%22%3E%3Cpath%20fill%3D%22%2397938e%22%20d%3D%22M18%206.85075H15.9699V2.01493H11.0977V0L17.0526%200C17.3233%200%2017.594%200.134328%2017.7293%200.268657C17.8647%200.402985%2018%200.671642%2018%200.940299V6.85075Z%22%2F%3E%3Cpath%20fill%3D%22%2397938e%22%20d%3D%22M16.2238%200.327817L7.22826%209.25627L8.66372%2010.681L17.6593%201.75257L16.2238%200.327817Z%22%2F%3E%3Cpath%20fill%3D%22%2397938e%22%20d%3D%22M15.1579%2018H1.08271C0.541353%2018%200%2017.597%200%2016.9254V2.95523C0%202.41791%200.406015%201.8806%201.08271%201.8806H8.1203V4.02985H2.03008V15.9851H14.0752V10.0746H16.1053V17.0597C16.1053%2017.597%2015.6992%2018%2015.1579%2018Z%22%2F%3E%3C%2Fsvg%3E');
      }
      &:hover {
        color: color(green-600);
        &::after {
          background-color: color(green-600);
        }
      }
    }
  }
}
// ↑ 230406 add

// .p-researchResult {
//   position: relative;
//   input[type] {
//     position: absolute;
//     opacity: 0;
//   }
//   input[value="conference"]:checked ~ form label[for="conference"],
//   input[value="thesis"]:checked ~ form label[for="thesis"],
//   input[value="p2023"]:checked ~ form label[for="p2023"],
//   input[value="p2022"]:checked ~ form label[for="p2022"],
//   input[value="p2021"]:checked ~ form label[for="p2021"],
//   input[value="p2020"]:checked ~ form label[for="p2020"],
//   input[value="p2019"]:checked ~ form label[for="p2019"],
//   input[value="p2018"]:checked ~ form label[for="p2018"],
//   input[value="taste"]:checked ~ form label[for="taste"],
//   input[value="health"]:checked ~ form label[for="health"],
//   input[value="environment"]:checked ~ form label[for="environment"] {
//     &::before {
//       background-color: #00a040;
//     }
//     &::after {
//       opacity: 1;
//     }
//   }
//   input[value="conference"]:checked ~ .p-researchResult__output .p-researchResult__item:not([data-result~="conference"]),
//   input[value="thesis"]:checked ~ .p-researchResult__output .p-researchResult__item:not([data-result~="thesis"]),
//   input[value="p2023"]:checked ~ .p-researchResult__output .p-researchResult__item:not([data-result~="p2023"]),
//   input[value="p2022"]:checked ~ .p-researchResult__output .p-researchResult__item:not([data-result~="p2022"]),
//   input[value="p2021"]:checked ~ .p-researchResult__output .p-researchResult__item:not([data-result~="p2021"]),
//   input[value="p2020"]:checked ~ .p-researchResult__output .p-researchResult__item:not([data-result~="p2020"]),
//   input[value="p2019"]:checked ~ .p-researchResult__output .p-researchResult__item:not([data-result~="p2019"]),
//   input[value="p2018"]:checked ~ .p-researchResult__output .p-researchResult__item:not([data-result~="p2018"]),
//   input[value="taste"]:checked ~ .p-researchResult__output .p-researchResult__item:not([data-result~="taste"]),
//   input[value="health"]:checked ~ .p-researchResult__output .p-researchResult__item:not([data-result~="health"]),
//   input[value="environment"]:checked ~ .p-researchResult__output .p-researchResult__item:not([data-result~="environment"]) {
//     display: none;
//   }
// }

// .p-researchResult__input {
//   fieldset {
//     display: flex;
//     align-items: center;
//     justify-content: space-between;
//     padding: 1.6rem 0;
//     border-bottom: 1px solid #ddddd5;
//     @include mq(md) {
//       padding: 2rem 0;
//     }
//     legend {
//       display: contents;
//       span {
//         padding: 1.5rem 1rem 1.5rem 0;
//         font-weight: bold;
//         letter-spacing: .2rem;
//       }
//     }
//     .presearchReusult__label {
//       display: flex;
//       flex-wrap: wrap;
//       align-items: center;
//       width: 90%;
//       @include mq(md) {
//         width: 75%;
//       }
//     }
//   }
//   label {
//     position: relative;
//     display: inline-flex;
//     align-items: center;
//     min-width: 14rem;
//     margin-right: 4rem;
//     cursor: pointer;
//     @include mq(md) {
//       min-width: 22rem;
//       margin-right: 4rem;
//       padding: .4em 0;
//     }
//     &::before {
//       content: "";
//       display: block;
//       width: 1em;
//       height: 1em;
//       margin-right: .4em;
//       background-color: #fff;
//       border: 1px solid #aaa;
//     }
//     &::after {
//       opacity: 0;
//       content: "";
//       display: block;
//       position: absolute;
//       top: .77em;
//       left: .32em;
//       width: .35em;
//       height: .7em;
//       margin-top: -0.2em;
//       border-right: 2px solid #fff;
//       border-bottom: 2px solid #fff;
//       transform: rotate(45deg);
//       @include mq(md) {
//         top: 1em;
//         border-right: 3px solid #fff;
//         border-bottom: 3px solid #fff;
//       }
//     }
//   }
// }

// .p-researchResult__button {
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   margin: 5rem 0;
//   @include mq(md) {
//     margin: 7rem 0;
//   }
//   button {
//     position: relative;
//     display: block;
//     align-items: center;
//     justify-content: center;
//     width: 50rem;
//     padding: 1em;
//     border: 2px solid #00a040;
//     background-color: transparent;
//     color: #00a040;
//     font-weight: bold;
//     transition: 300ms;
//     @include mq(md) {
//       padding: 2.6rem;
//     }
//     &::after {
//       content: "";
//       display: block;
//       position: absolute;
//       top: 2.4rem;
//       right: 3rem;
//       width: 1.2rem;
//       height: 1.2rem;
//       border-right: 1px solid #00a040;
//       border-bottom: 1px solid #00a040;
//       transform: rotate(45deg);
//       @include mq(md) {
//         top: 3.4rem;
//         width: 1.6rem;
//         height: 1.6rem;
//       }
//     }
//     &:hover {
//       background-color: #00a040;
//       color: #fff;
//       &::after {
//         border-right: 1px solid #fff;
//         border-bottom: 1px solid #fff;
//       }
//     }
//   }
// }

// .p-researchResult__output {
//   dl {
//     display: flex;
//     flex-wrap: wrap;
//     margin-top: 5rem;
//     padding: 2rem 4rem;
//     background-color: #ededeb;
//     text-align: left;
//     overflow-wrap: break-word;
//     @include mq(md) {
//     }
//     dt, dd {
//       &:not(:last-of-type) {
//         border-bottom: 1px solid #999;
//       }
//     }
//     dt {
//       width: 20%;
//       padding: 2rem;
//       @include mq(md) {
//         display: flex;
//         align-items: center;
//         width: 28%;
//         padding: 3rem 2rem 3rem 0;
//         font-size: 2.8rem;
//         line-height: 1.2;
//       }
//     }
//     dd {
//       width: 72%;
//       padding: 2rem;
//       @include mq(md) {
//         width: 70%;
//         padding: 3rem 0 3rem 2rem;
//       }
//     }
//     a {
//       display: inline-flex;
//       align-items: center;
//       color: #97938e;
//       transition-property: color,text-decoration-color,-webkit-text-decoration-color;
//       transition-duration: .4s;
//       transition-timing-function: cubic-bezier(.215,.61,.355,1);
//       text-decoration: underline;
//       text-underline-position: under;
//       text-underline-offset: 0.4rem;
//       &::after {
//         content: "";
//         width: 1.6rem;
//         height: 1.6rem;
//         margin-left: 0.5em;
//         background-color: #97938e;
//         mask-image: url('data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%20viewBox%3D%220%200%2018%2018%22%3E%3Cpath%20fill%3D%22%2397938e%22%20d%3D%22M18%206.85075H15.9699V2.01493H11.0977V0L17.0526%200C17.3233%200%2017.594%200.134328%2017.7293%200.268657C17.8647%200.402985%2018%200.671642%2018%200.940299V6.85075Z%22%2F%3E%3Cpath%20fill%3D%22%2397938e%22%20d%3D%22M16.2238%200.327817L7.22826%209.25627L8.66372%2010.681L17.6593%201.75257L16.2238%200.327817Z%22%2F%3E%3Cpath%20fill%3D%22%2397938e%22%20d%3D%22M15.1579%2018H1.08271C0.541353%2018%200%2017.597%200%2016.9254V2.95523C0%202.41791%200.406015%201.8806%201.08271%201.8806H8.1203V4.02985H2.03008V15.9851H14.0752V10.0746H16.1053V17.0597C16.1053%2017.597%2015.6992%2018%2015.1579%2018Z%22%2F%3E%3C%2Fsvg%3E');
//         -webkit-mask-image: url('data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%20viewBox%3D%220%200%2018%2018%22%3E%3Cpath%20fill%3D%22%2397938e%22%20d%3D%22M18%206.85075H15.9699V2.01493H11.0977V0L17.0526%200C17.3233%200%2017.594%200.134328%2017.7293%200.268657C17.8647%200.402985%2018%200.671642%2018%200.940299V6.85075Z%22%2F%3E%3Cpath%20fill%3D%22%2397938e%22%20d%3D%22M16.2238%200.327817L7.22826%209.25627L8.66372%2010.681L17.6593%201.75257L16.2238%200.327817Z%22%2F%3E%3Cpath%20fill%3D%22%2397938e%22%20d%3D%22M15.1579%2018H1.08271C0.541353%2018%200%2017.597%200%2016.9254V2.95523C0%202.41791%200.406015%201.8806%201.08271%201.8806H8.1203V4.02985H2.03008V15.9851H14.0752V10.0746H16.1053V17.0597C16.1053%2017.597%2015.6992%2018%2015.1579%2018Z%22%2F%3E%3C%2Fsvg%3E');
//         mask-repeat: no-repeat;
//         mask-position: center;
//         -webkit-mask-repeat: no-repeat;
//         -webkit-mask-position: center;
//       }
//       &:hover {
//         color: #00a040;
//         text-decoration-color: rgba(151,147,142,0);
//         &::after {
//           background-color: #00a040;
//           @include mq(md) {
//             width: 2.6rem;
//             height: 2.6rem;
//           }
//         }
//       }
//     }
//   }
// }

// .p-researchResult__item {
//   > dd:first-of-type {
//     color: #00a040;
//     font-weight: bold;
//   }
// }