@use '../../../foundation/config' as *;

.p-companyBlock1 {
  margin: 13.1rem 0 0;
  @include mq(md, min) {
    margin: 14.5rem 0 0;
  }
}
.p-companyBlock1__header1 {
  @include mq(md, min) {
    display: flex;
  }
}
.p-companyBlock1__header1Title1 {
  @include mq(md, min) {
    min-width: 52.5rem;
    max-width: 52.5rem;
  }
}
.p-companyBlock1__header1Text1 {
  font-size: 6rem;
  line-height: 1.403333;
  @include font-serif;
  @include font-extended;
  margin: 5.1rem 0 0;
  text-align: justify;
  @include mq(md, min) {
    font-size: 4.2rem;
    line-height: 1.52619;
    margin: -0.9rem 0 0 0;
  }
}
.p-companyBlock1__linkGroup1 {
  margin: 8.2rem 0 0;
}
.p-companyBlock1__imagelist1 {
  margin: 18rem 0 0;
  @include mq(md, min) {
    margin: 18.7rem 0 0;
    display: flex;
  }
  @at-root {
    .p-companyBlock1__linklist1 + .p-companyBlock1__imagelist1 {
      margin: 8rem 0 0;
    }
  }
  & > li {
    @include mq(md, min) {
      min-width: 50%;
      max-width: 50%;
    }
  }
}
.p-companyBlock1__imagelist1Unit1 {
  position: relative;
  display: block;
  overflow: hidden;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transition: background-color 400ms easing(easeOutCubic);
    z-index: z(content);
  }

  &:hover {
    &::before {
      background-color: color(green-300);
    }
  }
}
.p-companyBlock1__imagelist1Unit1Title1 {
  position: absolute;
  bottom: 6.2rem;
  left: 3rem;
  font-size: 4rem;
  line-height: 1;
  font-weight: bold;
  color: color(white);
  @include font-extended;
  z-index: z(content);
  @include mq(md, min) {
    font-size: 2.6rem;
    line-height: (40/26);
    left: 3.3rem;
    bottom: 3.2rem;
  }
  &.-type2 {
    left: 3.8rem;
  }
}
.p-companyBlock1__imagelist1Unit1Icon1 {
  position: absolute;
  bottom: 4rem;
  right: 4rem;
  display: block;
  width: 8rem;
  height: 8rem;
  color: rgba(color(white), 0.5);
  border: 1px solid rgba(color(white), 0.5);
  transition: box-shadow 400ms easing(easeOutCubic);
  z-index: z(content);
  @include mq(md, min) {
    width: 6rem;
    height: 6rem;
    bottom: 2.1rem;
    right: 2rem;
  }

  .p-companyBlock1__imagelist1Unit1:hover & {
    box-shadow: 0 0 0 1px;
  }
  & > svg {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    width: 2.4rem;
    height: 1.7rem;
    fill: color(white);
    @include mq(md, min) {
      width: 1.6rem;
      height: 1.1rem;
    }
  }
}
.p-companyBlock1__imagelist1Unit1Image1 {
  img {
    width: 100%;
    height: auto;
    transition: transform 400ms easing(easeOutCubic);
    backface-visibility: hidden;
  }

  .p-companyBlock1__imagelist1Unit1:hover & img {
    transform: scale(1.04);
  }
}
.p-companyBlock1__imagelist2 {
  margin: 8rem 0 0;
}
.p-companyBlock1__imagelist2Unit1 {
  position: relative;
  display: block;
  overflow: hidden;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transition: background-color 400ms easing(easeOutCubic);
    z-index: z(content);
  }

  &:hover {
    &::before {
      background-color: color(green-300);
    }
  }
}
.p-companyBlock1__imagelist2Unit1Title1 {
  position: absolute;
  bottom: 6.2rem;
  left: 3rem;
  font-size: 4rem;
  line-height: 1;
  font-weight: bold;
  color: color(white);
  @include font-extended;
  z-index: z(content);
  @include mq(md, min) {
    font-size: 2.6rem;
    line-height: (40/26);
    left: 3.3rem;
    bottom: 3.2rem;
  }
  &.-type2 {
    left: 3.8rem;
  }
}
.p-companyBlock1__imagelist2Unit1Icon1 {
  position: absolute;
  bottom: 4rem;
  right: 4rem;
  display: block;
  width: 8rem;
  height: 8rem;
  color: rgba(color(white), 0.5);
  border: 1px solid rgba(color(white), 0.5);
  transition: box-shadow 400ms easing(easeOutCubic);
  z-index: z(content);
  @include mq(md, min) {
    width: 6rem;
    height: 6rem;
    bottom: 2.1rem;
    right: 2rem;
  }

  .p-companyBlock1__imagelist2Unit1:hover & {
    box-shadow: 0 0 0 1px;
  }
  & > svg {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    width: 2.4rem;
    height: 1.7rem;
    fill: color(white);
    @include mq(md, min) {
      width: 1.6rem;
      height: 1.1rem;
    }
  }
}
.p-companyBlock1__imagelist2Unit1Image1 {
  img {
    width: 100%;
    height: auto;
    transition: transform 400ms easing(easeOutCubic);
    backface-visibility: hidden;
  }

  .p-companyBlock1__imagelist2Unit1:hover & img {
    transform: scale(1.04);
  }
}
.p-companyBlock1__button1 {
  margin: 8rem 0 0;
  @include mq(md, min) {
    display: flex;
    justify-content: center;
  }
}
.p-companyBlock1__linkGroup {
  margin-top: 8rem;
}
