@use '../../../foundation/config' as *;

.p-sustainabilityMessage {
  padding-bottom: 25rem;

  @include mq(md) {
    padding-bottom: 24rem;
  }
}

.p-sustainabilityMessage__inner {
  padding-top: 0;
  padding-bottom: 1rem;

  @include mq(md) {
    padding-top: 8.4rem;
  }
}

.p-sustainabilityMessage__section {
  & + & {
    margin-top: 20rem;

    @include mq(md) {
      margin-top: 19.6rem;
    }
  }

  &.-hero {
    display: flex;
    flex-direction: column;
  }

  &.-hero + & {
    // margin-top: 25.2rem;

    @include mq(md) {
      margin-top: 24.4rem;
    }
  }
}

.p-sustainabilityMessage__textBlock {
  @include mq(md) {
    padding: 4rem;
    background-color: #ffffff;
  }
}

.p-sustainabilityMessage__mediaBlock {
  position: relative;

  @include mq(md) {
    margin: 0 -4rem 0;

    .-has-gradation {
      position: relative;
      display: block;
      max-width: 100%;
      width: 100%;
      height: 100%;
  
      &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-image: linear-gradient(180deg, transparent 0 80%, #FFF 100%);
      }
    }
  }

  img {
    width: 100%;
    height: auto;
  }
}

.p-sustainabilityMessage__greeting {
  @include font-serif;
  font-size: 2.7rem;
  line-height: 1.56;

  @include mq(md, min) {
    position: absolute;
    top: 7rem;
    right: 3rem;
  }

  @include mq(md) {
    font-size: 5rem;
    line-height: 1.4;
  }
}

.p-sustainabilityMessage__sign {
  @include mq(md, min) {
    display: none;
  }

  @include mq(md) {
    font-size: 3rem;
    font-weight: bold;
    line-height: 1.8;
    text-align: right;
  }

  img {
    height: auto;

    @include mq(md, min) {
      width: 25rem;
      margin-left: 4rem;
    }

    @include mq(md) {
      display: block;
      width: 36rem;
      margin-top: 1.5rem;
      margin-left: auto;
    }
  }
}

.p-sustainabilityMessage__sectionHeading {
  font-size: 3.4rem;
  @include lhCrop(44 / 34);

  @include mq(md) {
    font-size: 6rem;
    @include lhCrop(80 / 60);
  }
}

.p-sustainabilityMessage__smallSection {
  .p-sustainabilityMessage__sectionHeading + & {
    margin-top: 6.4rem;
  }

  & + & {
    margin-top: 6.6rem;
  }
}

.p-sustainabilityMessage__text {
  font-size: 1.6rem;
  line-height: (36 / 16);
  @include lhCrop(36 / 16);

  @include mq(md) {
    font-size: 3rem;
    line-height: 2;
    @include lhCrop(2);
  }

  .p-sustainabilityMessage__sectionHeading + & {
    margin-top: 7.4rem;
  }

  .c-heading.-level4 + & {
    margin-top: 2.5rem;

    @include mq(md) {
      margin-top: 3.5rem;
    }
  }
}
