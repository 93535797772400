@use "../../../foundation/config" as *;

.p-headerChildMenu {
  height: 0;
  overflow: hidden;

  * {
    // アニメーション時の文字のかくつき対策
    backface-visibility: hidden;
  }

  @include mq(md, min) {
    position: absolute;
    top: calc(100% + 1px);
    left: 0;
    right: 0;
  }

  &.-active {
    @include mq(md, min) {
      z-index: z(content);
    }
  }

  &.-search {
    left: auto;
    height: 0;

    @include mq(md) {
      position: absolute;
      top: calc(100% + 1px);
      left: 0;
      right: 0;
      overflow: hidden;
    }
  }
}

.p-headerChildMenu__contents {
  @include mq(md, min) {
    display: flex;
    background-color: color(white);
  }

  @include mq(md) {
    padding-bottom: 6rem;
    border-bottom: 1px solid color(gray-300);
    background-color: color(gray-100);
  }
}

.p-headerChildMenu__picture {
  flex: 0 0 62.5rem;
  height: 50rem;
  overflow: hidden;

  @include mq(md) {
    display: none;
  }
}

.p-headerChildMenu__media {
  width: 100%;
  height: 100%;
  transition: transform 800ms easing(easeOutSine);

  .p-header__primaryNavButton[aria-expanded='true']
    + .p-headerChildMenu:not([aria-hidden='true'])
    & {
    transform: scale(1.1);
  }
}

.p-headerChildMenu__body {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;
}

.p-headerChildMenu__lead {
  padding: 4.4rem 6.2rem;
  font-size: 1.7rem;
  line-height: 2;

  @include mq(md) {
    padding: 4.4rem 4rem 0;
    font-size: 3rem;
  }
}

.p-headerChildMenu__nav {
  @include mq(md, min) {
    display: flex;
    height: 100%;
    .p-headerChildMenu__primaryNav {
      padding: 0 4rem 4.4rem;
    }
    .p-headerChildMenu__secondaryNav {
      padding: 0.4rem 8rem 4.8rem 3rem;
    }
  }
}

.p-headerChildMenu__primaryNav {
  flex: 0 0 52.5rem;
  padding: 4.4rem 4rem;

  @include mq(md) {
    padding: 3.6rem 0 1.6rem;
  }
}

.p-headerChildMenu__primaryNavItem {
  font-size: 2rem;
  font-weight: bold;

  &.has-nest {
    padding: 0.5em 0 0 2.2rem;
    ul {
      padding-left: 2.2rem;
      .p-headerChildMenu__primaryNavLink {
        padding: 0.5em 2.2rem 0;
      }
      .p-headerChildMenu__primaryNavItem {
        &:last-child {
          .p-headerChildMenu__primaryNavLink {
            padding: 0.5em 2.2rem;
          }
        }
      }
    }
  }

  @include mq(md) {
    font-size: 3.6rem;

    &.has-nest {
      padding: 0.5em 4rem;
    }
  }
}

.p-headerChildMenu__primaryNavLink {
  display: inline-block;
  padding: 0.5em 2.2rem;
  transition: color 400ms easing(easeOutCubic);

  @include mq(md) {
    display: block;
    padding: 0.5em 4rem;
  }

  &:hover {
    color: color(green-600);
  }
}

.p-headerChildMenu__secondaryNav {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 4.8rem 8rem 4.8rem 3rem;
  border-left: 1px solid color(gray-300);

  @include mq(md) {
    padding: 0 4rem;
  }
}

.p-headerChildMenu__secondaryNavList {
  @include mq(md) {
    display: flex;
    flex-wrap: wrap;

    &.-displayNoneSP {
      display: none;
    }
  }
}

.p-headerChildMenu__secondaryNavItem {
  font-size: 1.4rem;

  @include mq(md) {
    width: 50%;
    font-size: 2.2rem;
  }
}

.p-headerChildMenu__secondaryNavLink {
  display: inline-flex;
  align-items: center;
  padding: 0.7em 1.8rem;
  transition: color 400ms easing(easeOutCubic);

  @include mq(md) {
    padding: 0.7em 0;
  }

  &:hover {
    color: color(green-600);
  }
}

.p-headerChildMenu__secondaryNavExternalIcon {
  width: 1.1rem;
  height: 1.1rem;
  margin-left: 1rem;
}

.p-headerChildMenu__categoryTop {
  margin-top: auto;
  font-size: 1.6rem;
  font-weight: bold;
  text-align: right;

  @include mq(md) {
    margin-top: 5.4rem;
    font-size: 3rem;
  }
}

.p-headerChildMenu__categoryTopLink {
  display: inline-flex;
  align-items: center;
  padding: 0.5em 2rem;
  color: color(gray-600);
  transition: color 400ms easing(easeOutCubic);

  @include mq(md) {
    padding: 0.5em 0;
  }

  &:hover {
    color: color(green-600);
  }
}

.p-headerChildMenu__categoryTopIcon {
  width: 1.6rem;
  height: 1.4rem;
  margin-left: 1.2rem;

  @include mq(md) {
    width: 2.4rem;
    height: 2.1rem;
    margin-left: 1.9rem;
  }
}

.p-headerChildMenu__searchGroup {
  display: flex;
  width: 75rem;

  @include mq(md) {
    width: 100%;
  }

  .p-header__searchButton[aria-expanded='true'] + .p-headerChildMenu & {
    transform: translateY(0);
    visibility: visible;
    transition: transform 400ms easing(easeOutQuart);
  }
}

.p-headerChildMenu__input {
  flex: 1 1 65rem;
  padding: 3.8rem 4rem;
  border-radius: 0;
  border: 1px solid transparent;
  background-color: color(gray-250);
  font-size: 1.6rem;
  line-height: 1.5;
  appearance: none;

  @include placeholder {
    color: color(black);
  }

  @include mq(md) {
    width: calc(100% - 15rem);
    padding: 4.882rem 4rem;
    font-size: 3.2rem;
  }

  &:focus {
    outline: none;
  }

  &::-webkit-search-cancel-button {
    -webkit-appearance: none;
  }
}

.p-headerChildMenu__submit {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 10rem;
  background-color: color(black);
  color: color(white);
  font-size: 1.6rem;
  font-weight: bold;

  @include mq(md) {
    flex-shrink: 0;
    width: 15rem;
    font-size: 3.2rem;
  }
}
