@use '../../../foundation/config' as *;

.p-irGrowthPerformanceConsolidateIndicators__section {
  display: flex;
  margin: 0 auto 15rem;
}

.p-irGrowthPerformanceConsolidateIndicators__tabContent {
  margin-top: 9.2rem;
}

.p-irGrowthPerformanceConsolidateIndicators__content {
  margin-top: 7.1rem;
}

.p-irGrowthPerformanceConsolidateIndicators__irPerformanceNav {
  margin: 20rem 0 24.8rem;
}

.p-irGrowthPerformanceConsolidateIndicators__table {
  @include mq(md) {
    margin-right: -4rem;
    overflow: auto;
  }

  table {
    min-width: 100%;
    border-collapse: collapse;
    border-top: 1px solid color(gray-300);

    small {
      font-size: 1.4rem;

      @include mq(md) {
        font-size: 2rem;
      }
    }
  }

  th,
  td {
    border-bottom: 1px solid color(gray-300);
  }

  th[scope='col'] {
    background-color: color(gray-200);
  }

  th[scope='row'] {
    background-color: color(gray-80);
    text-align: left;
    font-weight: normal;
  }

  th:not(:last-child),
  td:not(:last-child) {
    border-right: 1px solid color(gray-300);
  }

  td {
    text-align: right;
  }

  caption {
    text-align: right;

    @include mq(md) {
      font-size: 3rem;
      line-height: 2;
    }
  }
}

.c-table.-irTable {
  .p-irTableConsolidate__titleCell {
    width: 26.6rem;
    @include mq(md) {
      width: 42.6rem;
    }
  }
  + .-irTable {
    margin-top: 8rem;
  }
}

.p-irTable + .p-irTable {
  margin-top: 3rem;
}

.p-irTable__titleRow > th {
  padding: 1rem 0.5rem;
}

.c-table .p-irTable__titleRow th[scope='col'] {
  background-color: color(gray-300);
}

.c-table .p-irTable__titleRow th:not(:last-child) {
  border-right: 1px solid color(gray-100);
}

.c-table .p-irTable__rowBold {
  th,
  td {
    font-weight: 700;
  }
}

@include mq(md) {
  .c-table__spSize.-irConsolidate {
    width: 167rem;
  }
}
