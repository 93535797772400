@use '../../../foundation/config' as *;

.p-irStockRating__smallSection {
  margin-top: 6rem;
  .p-irStockRating__smallSection + & {
    margin-top: 8rem;
    @include mq(md, min) {
      margin-top: 9.4rem;
    }
  }
}

.p-irStockRating__table1,
.p-irStockRating__table2 {
  margin-top: 7rem;

  @include mq(md, min) {
    margin-top: 5rem;
  }

  th[scope='row'] {
    width: 33%;
    @include mq(md) {
      width: 25%;
    }
  }
}

.p-irStockRating__table1ColWidth {
  width: calc(66.6667% / 3);
  @include mq(md) {
    width: 25%;
  }
}

.c-table.p-irStockRating__table2 {
  th[scope='col'] {
    padding: 1.5rem 0.5rem;
    @include mq(md) {
      padding: 2.8rem 1rem;
    }
  }
}

.p-irStockRating__table2ColWidth {
  width: calc(66.6667% / 4);
  @include mq(md) {
    width: calc(75% / 4);
  }
}

.p-irStockRating__smallText {
  font-size: 2.4rem;
  line-height: 1.7;
  margin-top: 3rem;
  @include mq(md, min) {
    font-size: 1.4rem;
    margin-top: 3.8rem;
    line-height: 1.8;
  }
}

.p-irStockRating__list {
  margin-top: 1rem;
  font-size: 1.6rem;
  line-height: (36 / 16);
  @include lhCrop(36 / 16);

  @include mq(md) {
    margin-top: 2rem;
    font-size: 3rem;
    line-height: 2;
    @include lhCrop(2);
  }
}

.p-irStockRating__listItem {
  //
}

.p-irStockRating__listLink {
  display: inline-block;
  color: color(gray-600);
  transition-property: color, text-decoration-color;
  transition-duration: 400ms;
  transition-timing-function: easing(easeOutCubic);
  text-decoration: underline;
  text-underline-offset: 0.4rem;

  &:hover {
    color: color(green-600);
    text-decoration-color: rgba(color(gray-600), 0);
  }
}

.p-irStockRating__externalIcon {
  width: 1.4rem;
  height: 1.4rem;
  vertical-align: baseline;

  @include mq(md) {
    width: 2.1rem;
    height: 2.1rem;
  }
}
