@use '../../../foundation/config' as *;

.p-researchCollaborative {
  padding-bottom: 25.2rem;

  @include mq(md) {
    padding-bottom: 24rem;
  }
}

.p-researchCollaborative__inner {
  margin-top: 14.5rem;

  @include mq(md) {
    margin-top: 14.5rem;
  }
}

.p-researchCollaborative__section {
  & + & {
    margin-top: 10rem;
  }
}

.p-researchCollaborative__title {
  margin: 0 0 5rem;
}

.p-researchCollaborative__subTitle {
  margin: 5rem 0 1.5rem;
}

.p-researchCollaborative__button {
  text-align: center;
  margin: 5rem 0 0;
}

.p-researchCollaborative__body {
  font-size: 1.6rem;
  line-height: (36/16);

  @include mq(md) {
    font-size: 3rem;
    line-height: 2;
  }
}

.p-researchCollaborative__list {
  li {
    position: relative;

    @include mq(md, min) {
      padding: 0 0 0 2.6rem;
    }

    @include mq(md) {
      padding: 0 0 0 4rem;
    }

    &::before {
      content: '';
      display: inline-block;
      position: absolute;
      top: 1.8rem;
      left: 0;
      width: 0.8rem;
      height: 0.8rem;
      margin-right: 2rem - 0.8rem;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='8px' height='8px'%3E%3Cpath fill-rule='evenodd' fill='rgb(0, 160, 64)' d='M4.000,-0.000 C6.209,-0.000 8.000,1.791 8.000,4.000 C8.000,6.209 6.209,8.000 4.000,8.000 C1.791,8.000 -0.000,6.209 -0.000,4.000 C-0.000,1.791 1.791,-0.000 4.000,-0.000 Z'/%3E%3C/svg%3E");
      background-repeat: no-repeat;
      background-size: contain;
      transform: translateY(-50%);

      @include mq(md) {
        top: 3rem;
        width: 1.6rem;
        height: 1.6rem;
        margin-right: 4rem - 1.6rem;
      }
    }
  }
}

.p-researchCollaborative__ol {
  @include mq(md, min) {
    padding: 0 0 0 1.6rem;
  }

  @include mq(md) {
    padding: 0 0 0 4.2rem;
  }

  li {
    list-style-type: decimal;
  }
}
