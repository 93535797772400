@use '../../../foundation/config' as *;

.p-newsDetail {
  padding-bottom: 25rem;
  background-color: color(white);

  @include mq(md, min) {
    .c-breadcrumb__inner {
      justify-content: flex-start;
    }
  }
  .c-pageHeader__inner {
    max-width: 125rem;

    @include mq(md, min) {
      padding: 4rem 0;
    }
  }

  .c-pageHeader__text {
    @include font-default;
    font-size: 2.8rem;
    line-height: 1.8;
    @include mq(md) {
      font-size: 4.2rem;
    }
    &::after {
      @include mq(md, min) {
        left: 0;
      }
    }
  }
}

.p-newsDetail__inner {
  position: relative;
  max-width: 125rem;
  padding: 4rem 0 0;
  @include mq(md) {
    padding: 5.37rem 4rem 0;
  }
  .l-printButton {
    position: absolute;
    top: 4rem;
    right: 0;
    .-print {
      margin: 0;
    }
    @include mq(md) {
      position: absolute;
      top: 5.37rem;
      right: 4rem;
      padding: 0;
    }
  }
}

.p-newsDetail__footer {
  max-width: 105rem;
  margin-right: auto;
  margin-left: auto;
  padding-top: 5rem;
  border-top: 1px solid color(gray-500);
  @include mq(md, min) {
    .c-linkButton {
      margin-right: auto;
      margin-left: auto;
    }
  }
}

.p-newsDetail__backLink {
  text-align: center;
}
