@use '../../foundation/config' as *;

.c-pageTop {
  width: 7rem;
  height: 7rem;
  background-color: color(black);
  color: color(white);

  @include mq(md) {
    width: 11rem;
    height: 11rem;
  }
}

.c-pageTop__link {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.c-pageTop__icon {
  width: 1.6rem;
  height: 1.1rem;
  transform: rotate(-90deg);

  @include mq(md) {
    width: 2.4rem;
    height: 1.7rem;
  }
}
/* ページ内トップへ：固定用スタイル
-----------------------------*/
.l-footer__pageTop{
	position:absolute;
	z-index: 1;
	text-indent: -9999px;
	opacity: 0;
	pointer-events: none;
	transition-property: opacity;
	transition-duration: 0.3s;

	top:0;
	right:0;
  width: 7rem;
	height: 7rem;
  @include mq(md) {
		top:0;
		right:0;
		width: 11rem;
		height: 11rem;
  }

	&.show{
		opacity: 1;
		pointer-events: auto;
	}

	&.fixed{
		position: fixed;
		top: auto;
    bottom: -7rem;
    @include mq(md){
      bottom: -11rem;
		}
	}
} // #tpl-pagetop
