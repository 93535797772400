@use '../../../foundation/config' as *;

.p-irTop__newestData {
  margin: 14rem 0 0;
  display: flex;
  @include mq(md) {
    margin: 13.7rem 0 0;
    display: block;
  }
}

.p-irTop__newestDataTitleArea {
  width: 25%;
  @include mq(md) {
    width: 100%;
  }
}

.p-irTop__newestDataListGroup {
  width: 75%;
  margin: 1rem 0 0;

  @include mq(md) {
    width: 100%;
    margin: 7rem 0 0;
  }
}

.p-irTop__newestDataList {
  & + & {
    border-top: 1px solid color(gray-300);
  }
}

.p-irTop__newestDataButton {
  display: inline-flex;
  position: relative;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 100%;
  padding: 2.7rem 3rem 2.7rem 4rem;
  font-size: 1.6rem;
  line-height: 1;
  border: 0;
  background-color: color(gray-200);
  font-weight: bold;
  text-align: left;
  transition: color 400ms easing(easeOutCubic);

  @include mq(md) {
    padding: 2.3rem 4rem;
    font-size: 2.6rem;
    line-height: 2;
  }

  &:hover {
    color: color(green-600);

    &::before {
      left: 0;
      width: 100%;
      transition: width 600ms easing(easeOutCubic);
    }
  }
  &::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 0;
    transition: width 800ms easing(easeOutCubic);
    border-bottom: 2px solid color(green-600);
  }
}

.p-irTop__newestDataButtonText {
  margin-right: 2rem;
}

.p-irTop__newestDataButtonArrow {
  position: relative;
  flex-shrink: 0;
  width: 1.6rem;
  height: 1.1rem;
  color: color(gray-450);
  transition: color 400ms easing(easeOutCubic);
  @include mq(md) {
    width: 2.5rem;
    height: 1.6rem;
    vertical-align: middle;
  }

  .p-irTop__newestDataButton:hover & {
    color: color(green-600);
  }
}

.p-irTop__imgLink {
  margin: 19.9rem 0 0;
  @include mq(md) {
    margin: 20.4rem 0 0;
  }
}

.p-irTop__imgLinkListGroup {
  display: flex;
  @include mq(md) {
    display: block;
  }
}

.p-irTop__imgLinkList {
  width: 50%;
  font-size: 2.6rem;
  font-weight: bold;
  overflow: hidden;
  @include mq(md) {
    width: 100%;
  }
}

.p-irTop__imgLinkButton {
  position: relative;
  display: block;
  color: color(white);

  &::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transition: background-color 400ms easing(easeOutCubic);
    z-index: z(content);
  }

  &:hover {
    &::before {
      background-color: color(green-300);
    }
  }
}

.p-irTop__imgLinkImage {
  width: 100%;
  height: auto;
  transition: transform 400ms easing(easeOutCubic);
  backface-visibility: hidden;

  .p-irTop__imgLinkButton:hover & {
    transform: scale(1.04);
  }
}

.p-irTop__imgLinkText {
  position: absolute;
  left: 4rem;
  bottom: 3.2rem;
  font-size: 2.6rem;
  z-index: z(content);

  @include mq(md) {
    font-size: 4rem;
    left: 2.9rem;
    bottom: 5rem;
  }
}

.p-irTop__imgLinkSeparator {
  display: flex;
  position: absolute;
  justify-content: center;
  align-items: center;
  right: 2rem;
  bottom: 2rem;
  width: 6rem;
  height: 6rem;
  border: 1px solid;
  transition: box-shadow 400ms easing(easeOutCubic);
  z-index: z(content);

  @include mq(md) {
    right: 4rem;
    bottom: 4rem;
    width: 8rem;
    height: 8rem;
  }

  .p-irTop__imgLinkButton:hover & {
    box-shadow: 0 0 0 1px;
  }
}

.p-irTop__newestDataButtonLabel {
  display: inline-block;
  padding: 0.35rem 2.707rem;
  border-radius: 12.5rem;
  border: 1px solid;
  color: color(gray-600);
  @include font-barlow;
  font-size: 1.4rem;
  line-height: 1.15;
  @include mq(md) {
    font-size: 2.4rem;
    line-height: 1.2;
    padding: 0px 2.652rem 0.2rem;
  }
}

.p-irTop__imgLinkArrow {
  width: 1.6rem;
  height: 1.1rem;

  @include mq(md) {
    width: 2.5rem;
    height: 1.6rem;
    vertical-align: middle;
  }
}

.p-irTop__irInfo {
  display: flex;
  margin-top: 19.2rem;
  .p-sustainabilityTop__sectionHeading {
    position: relative;
  }
  @include mq(md) {
    display: block;
    .p-sustainabilityTop__article {
      margin-top: 8.3rem;
    }
  }
}

.p-irTop__irInfoHeading {
  position: relative;
  font-size: 3.4rem;
  overflow: hidden;
}

.p-irTop__irInfoRss {
  position: absolute;
  top: 6.6rem;
  left: 0;
  @include font-barlow;
  font-size: 1.6rem;
  color: color(gray-600);
  transition: color 400ms easing(easeOutCubic);
  &:hover {
    color: color(green-600);
  }
  @include mq(md) {
    top: 3.2rem;
    left: auto;
    right: 0;
    font-size: 3.2rem;
  }
}

.p-irTop__irInfoRssSvg {
  position: relative;
  flex-shrink: 0;
  width: 1.4rem;
  height: 1.4rem;
  margin-right: 0.8rem;
  vertical-align: middle;
  @include mq(md) {
    width: 2.4rem;
    height: 3.4rem;
    align-items: center;
  }
}

.p-irTop__irInfoArticle {
  flex: 0 0 75%;
  margin-left: auto;
  @include mq(md) {
    flex: none;
    margin-top: 7.2rem;
  }
}

.p-irTop__detailLinkArea {
  margin-top: 20rem;

  @include mq(md, min) {
    display: flex;
    flex-wrap: wrap;
    margin: 10.25rem -5rem 0;
  }
}

@include mq(md, min) {
  .p-irTop__detailLinkArea {
    > .p-irTop__detailLink {
      padding: 0 5rem;
      min-width: 50%;
      max-width: 50%;
    }
  }
  .p-irTop__detailLinkGroup {
    &.c-linkGroup.-column2.-gutter {
      margin-top: 5rem;
      > ul {
        margin: 0 -5rem;
      }
      .c-linkGroup__item:nth-child(odd) {
        padding: 0 3.5rem 0 5rem;
      }
      .c-linkGroup__item:nth-child(even) {
        padding: 0 5rem 0 3.5rem;
      }
      .c-linkGroup__item > a {
        min-height: 7.02rem;
      }
    }
  }
}

.p-irTop__detailLink {
  margin-top: 9.75rem;
  @include mq(md) {
    margin-top: 8rem;
  }

  .c-button__external {
    width: 3rem;
    height: 3rem;
  }

  .c-linkGroup__item a svg.c-button__external {
    width: 2.4rem;
    height: 2.4rem;
    @media (min-width: 751px) {
      width: 1.45rem;
      height: 1.45rem;
    }
  }
}

.p-irTop__detailLinkTitleArea {
  position: relative;
  overflow: hidden;
}

.p-irTop__detailLinkImg {
  width: 100%;
  height: auto;
}

.p-irTop__detailLinkTitle {
  position: absolute;
  bottom: 0;
  font-size: 2.6rem;
  color: color(white);
  padding: 2.5rem 3rem;
  z-index: z(content);
  @include mq(md) {
    font-size: 4rem;
    padding: 1.9rem 3rem;
  }
}

.p-irTop__detailLinkGroup {
  &.c-linkGroup.-column2.-gutter {
    margin-top: 5.2rem;
  }
}

.p-irTop__trade {
  display: flex;
  flex-wrap: wrap;
  margin-top: 19.4rem;
  @include mq(md) {
    display: block;
    margin-top: 18rem;
  }
  .c-button__external {
    width: 2.4rem;
    height: 2.4rem;
    @media (min-width: 751px) {
      width: 1.45rem;
      height: 1.45rem;
    }
  }
}

.p-irTop__tradeTitleArea {
  width: 25%;
  @include mq(md) {
    width: 100%;
  }
}

.p-irTop__tradeListGroup {
  width: 75%;
  margin: 0.5rem 0 0;
  @include mq(md) {
    width: 100%;
    margin: 6.8rem 0 0;
  }
}

.p-irTop__tradeList {
  & + & {
    margin-top: 2.9rem;

    @include mq(md) {
      margin-top: 5rem;
    }
  }
}

.p-irTop__tradeNote {
  width: 100%;
  font-size: 1.4rem;
  text-align: right;
  margin-top: 2.4rem;
  @include mq(md) {
    font-size: 2.4rem;
    text-align: left;
    margin-top: 4.1rem;
  }
}

.p-irTop__other {
  margin: 19.5rem 0 24.7rem;
  .c-linkGroup__item a {
    padding: 1.8rem 3rem;
    @include mq(md) {
      padding: 2.4rem 3rem;
    }
  }
}
