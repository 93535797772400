@use '../../../foundation/config' as *;

.p-irFaq__pcTab {
  display: block;
  @include mq(md) {
    display: none;
  }
}

.p-irFaq__spSelect {
  display: none;
  @include mq(md) {
    display: block;
  }
}

.p-irFaq__tabContent {
  margin-top: 9.8rem;
  @include mq(md) {
    margin-top: 7.6rem;
  }
}

.p-irFaq__faqAccordion {
  border-top: 1px solid color(gray-300);
}

.p-irFaq__faqAccordionHead {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  border-bottom: 1px solid color(gray-300);
  .ui-accordion-header-icon.ui-icon {
    display: none;
  }
  &.ui-state-default {
    .p-irFaq__navMinus {
      display: none;
    }
    .p-irFaq__navPlus {
      display: inline-block;
    }
  }
  &.ui-state-active {
    .p-irFaq__navMinus {
      display: inline-block;
    }
    .p-irFaq__navPlus {
      display: none;
    }
  }
}

.p-irFaq__faqQuestion {
  flex: 1;
  display: flex;
  align-items: center;
  padding: 3.5rem 2.5rem 3.5rem 3.5rem;
  @include mq(md) {
    padding: 2rem;
  }
}

.p-irFaq__navQ {
  width: 5rem;
  height: 5rem;
  @include mq(md) {
    width: 7.4rem;
    height: 7.4rem;
  }
}

.p-irFaq__faqQuestionText {
  flex: 1;
  font-size: 2rem;
  font-weight: 700;
  line-height: 1.8;
  text-align: left;
  margin-left: 3rem;
  @include mq(md) {
    font-size: 3rem;
    margin-left: 2rem;
  }
}

.p-irFaq__navIcon {
  display: flex;
  align-items: center;
  margin-right: 3.5rem;
  @include mq(md) {
    margin-right: 2.4rem;
  }
}

.p-irFaq__navMinus {
  width: 1.4rem;
  height: 0.4rem;
  margin-left: auto;
  @include mq(md) {
    width: 2.8rem;
    height: 0.8rem;
  }
}

.p-irFaq__navPlus {
  width: 1.4rem;
  height: 1.4rem;
  margin-left: auto;
  @include mq(md) {
    width: 2.8rem;
    height: 2.8rem;
  }
}

.p-irFaq__faqAnswer {
  display: flex;
  width: 100%;
  background-color: color(gray-200);
  align-items: center;
  padding: 3.5rem 3.5rem 3.5rem 4.1rem;
  border-bottom: 1px solid color(gray-300);
  @include mq(md) {
    padding: 2rem 2rem 2rem 3.6rem;
  }
}

.p-irFaq__navA {
  flex-shrink: 0;
  width: 5rem;
  height: 5rem;
  display: table;
  @include mq(md) {
    width: 7rem;
    height: 7rem;
  }
}

.p-irFaq__faqAnswerText {
  font-size: 1.6rem;
  line-height: 2.2;
  text-align: left;
  margin-left: 3rem;
  @include mq(md) {
    font-size: 3rem;
    margin-left: 2rem;
  }
}

.p-irFaq__faqAnswerTextLink {
  color: color(gray-600);

  &:hover {
    color: color(green-600);
  }
}

.p-irFaq__faqAnswerLinkText {
  transition-property: color, text-decoration-color;
  transition-duration: 400ms;
  transition-timing-function: easing(easeOutCubic);
  text-decoration: underline;
  text-underline-offset: 0.4rem;

  .p-irFaq__faqAnswerTextLink:hover & {
    text-decoration-color: rgba(color(gray-600), 0);
  }
}

.p-irFaq__svgInnerTab {
  width: 1.4rem;
  height: 1.4rem;
  vertical-align: middle;
  @include mq(md) {
    width: 2.8rem;
    height: 2.8rem;
  }
}

.p-irFaq__content {
  margin-top: 7.1rem;
}

.p-irFaq__bottom {
  margin-top: 19rem;
}

.p-irFaq__bottomContent {
  font-size: 1.6rem;
  line-height: 2.2;
  margin-top: 6rem;
  @include mq(md) {
    font-size: 3rem;
    line-height: 2;
    margin-top: 4rem;
  }
}

.p-irFaq__box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 2.4rem 3.4rem;
  margin-top: 6.4rem;
  .c-box__heading {
    line-height: 2.2;
    &::before,
    &::after {
      margin-top: 0;
    }
  }
  .c-box__body {
    display: flex;
  }
  .c-box__heading + .c-box__body {
    line-height: 2.2;
    margin-top: 0;
  }
  .c-box__text {
    display: flex;
    align-items: center;
  }
  @include mq(md) {
    display: block;
    padding: 2rem 4rem 3rem;
    .c-box__body {
      display: block;
    }
    .c-box__heading + .c-box__body {
      line-height: 2.5;
      margin-top: 2rem;
    }
  }
}

.p-irFaq__boxLink {
  font-weight: 700;
  color: color(gray-600);
  transition-property: color, text-decoration-color;
  transition-duration: 400ms;
  transition-timing-function: easing(easeOutCubic);
  text-decoration: underline;
  text-underline-offset: 0.4rem;

  @include mq(md) {
    font-weight: 400;
  }

  &:hover {
    color: color(green-600);
    text-decoration-color: rgba(color(gray-600), 0);
  }
}

.p-irFaq__externalIcon {
  width: 1.4rem;
  height: 1.4rem;
  margin-left: 0.5em;
  vertical-align: -0.03em;

  @include mq(md) {
    width: 2.1rem;
    height: 2.1rem;
  }
}

.p-irFaq__boxAddress {
  margin-left: 3rem;
  @include mq(md) {
    margin-left: 0;
  }
}

.p-irFaq__linkGroup {
  margin-top: 7.7rem;
}
