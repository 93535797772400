@use '../../foundation/config' as *;

.p-localNav {
  margin: 18.3rem 0 0;
  @include mq(md, min) {
    margin: 19rem 0 0 0;
    padding: 0 6.25%;
    overflow: hidden;
  }
  @include mq(1921px, min) {
    padding: 0;
  }
}
.p-localNav__inner1 {
  background: #fcfcfc;
  position: relative;
  padding: 0 0 13rem;
  @include mq(md, min) {
    display: flex;
    padding: 0;
  }
  @include mq(1921px, min) {
    max-width: calc(1920px - 12.5%);
    margin: auto;
  }
  &:before {
    content: '';
    display: none;
    position: absolute;
    top: 0;
    left: 100%;
    width: 100%;
    height: 100%;
    z-index: -1;
    background: #fcfcfc;
    @include mq(md, min) {
      display: block;
    }
  }
}
.p-localNav__header1 {
  padding: 13.8rem 0 0 4rem;
  @include mq(md, min) {
    min-width: 35rem;
    max-width: 35rem;
    padding: 6.8rem 0 0 8rem;
    .-communication & {
      display: flex;
      flex-direction: column;
      justify-content: center;
      min-width: 38rem;
      max-width: 38rem;
      padding-top: 0;
    }
  }
  @include mq(1921px, min) {
    min-width: 35%;
    max-width: 35%;
    .-communication & {
      min-width: 38%;
      max-width: 38%;
    }
  }
}
.p-localNav__header1Title1 {
  font-size: 4rem;
  line-height: (50/40);
  @include font-serif;
  position: relative;
  padding: 0 0 2.9rem;
  @include mq(md, min) {
    font-size: 2.2rem;
    line-height: (40/22);
    padding: 0 0 1.4rem 0;
  }
  &:before {
    content: '';
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 4rem;
    height: 0.6rem;
    background: color(green-600);
    @include mq(md, min) {
      width: 1.6rem;
      height: 0.3rem;
    }
  }
}
.p-localNav__header1LinkTop1 {
  position: absolute;
  bottom: 3.8rem;
  right: 4rem;
  @include mq(md, min) {
    bottom: 4.9rem;
    right: inherit;
    left: 7.9rem;
  }
  .-communication & {
    @include mq(md, min) {
      position: static;
      margin-top: 2.9rem;
    }
  }
  & > a {
    display: flex;
    align-items: center;
    position: relative;
    font-size: 3rem;
    line-height: (60/30);
    color: color(gray-600);
    font-weight: bold;
    transition: color 400ms easing(easeOutCubic);
    @include mq(md, min) {
      font-size: 1.6rem;
      line-height: (36/16);
    }
    &:hover {
      color: color(green-600);
    }
    & > i {
      font-size: 0;
      margin: 0.3rem 0 0 1.6rem;
      @include mq(md, min) {
        margin-left: 1rem;
      }
    }
    svg {
      width: 2.4rem;
      height: 2.1rem;
      @include mq(md, min) {
        width: 1.6rem;
        height: 1.4rem;
      }
    }
  }
}
.p-localNav__main1 {
  margin: 6rem 0 0;
  @include mq(md, min) {
    margin: 0;
    width: 100%;
  }
}
.p-localNav__list1 {
  @include mq(md, min) {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    overflow: hidden;
    margin-bottom: -1px;
  }
  & > li {
    margin: -1px 0 0;
    border-top: 1px solid color(gray-300);
    border-bottom: 1px solid color(gray-300);
    transition: all 0.7s easing(easeOutQuint);
    @include mq(md, min) {
      min-width: 50%;
      max-width: 50%;
      border-top: none;
      border-right: 1px solid color(gray-300);
      margin: 0 0 -1px;
    }
    &:hover {
      @include mq(md, min) {
        border-bottom-color: color(green-600);
      }
    }
    &:nth-of-type(2),
    &:nth-of-type(1) {
      @include mq(md, min) {
        border-top: none;
      }
    }
    &:nth-of-type(2n + 2) {
      @include mq(md, min) {
        border-right: none;
      }
    }
    & > a {
      position: relative;
      display: block;
      font-size: 3.4rem;
      line-height: (51/34);
      font-weight: bold;
      padding: 3.5rem 6rem 3.3rem 4rem;
      transition: all 0.7s easing(easeOutQuint);
      @include mq(md, min) {
        font-size: 2rem;
        line-height: (40/20);
        padding: 3.1rem 0 2.9rem 3rem;
      }
      @at-root {
        .p-localNav__list1 > li.-active > a,
        .p-localNav__list1 > li:hover > a {
          color: color(green-600);
        }
      }
      &:before {
        position: absolute;
        bottom: 0;
        left: 0;
        content: '';
        display: block;
        width: 100%;
        height: 1px;
        background: color(green-600);
        transition: all 0.7s easing(easeOutQuint);
        opacity: 0;
        z-index: 1;
        @at-root {
          .p-localNav__list1 > li:hover > a:before {
            opacity: 1;
          }
          .p-localNav__list1
            > li:nth-of-type(even):nth-last-of-type(2)
            > a:before {
            @include mq(md, min) {
              height: 1px;
              bottom: 0;
            }
          }
          .p-localNav__list1
            > li:nth-of-type(odd):nth-last-of-type(1)
            > a:before {
            @include mq(md, min) {
              height: 2px;
              bottom: 1px;
            }
          }
          .p-localNav__list1 > li:nth-last-of-type(2) > a:before,
          .p-localNav__list1 > li:nth-last-of-type(1) > a:before {
            @include mq(md, min) {
              height: 2px;
              bottom: 1px;
            }
          }
        }
      }
      & > i {
        position: absolute;
        top: 0.5rem;
        bottom: 0;
        display: flex;
        height: 100%;
        align-items: center;
        right: 3.9rem;
        @include mq(md, min) {
          top: 0;
          right: 2.9rem;
        }
      }
    }
    svg {
      width: 2.4rem;
      height: 1.7rem;
      @include mq(md, min) {
        width: 1.6rem;
        height: 1.1rem;
      }
    }
  }
}
