@use '../../foundation/config' as *;

.c-pageHeader {
  border-bottom: 1px solid color(gray-300);
}

.c-pageHeader__inner {
  position: relative;
  max-width: 1920px;
  margin-right: auto;
  margin-left: auto;
  padding: 4rem 10rem;
  // アニメーションさせるために初期表示は非表示
  opacity: 0;

  @include mq(md) {
    padding: 5.37rem 4rem;
  }
}

.c-pageHeader__text {
  @include font-serif;
  font-size: 4rem;
  line-height: (60 / 50);

  @include mq(md) {
    font-size: 6rem;
    line-height: (80 / 70);
  }

  &::after {
    content: '';
    display: block;
    position: absolute;
    bottom: 0;
    left: 10rem;
    // widthをアニメーションさせる
    // width: 8rem;
    width: 0;
    height: 0.6rem;
    background-color: color(green-600);
    transition: width 0.5s easing(easeOutCubic);

    @include mq(md) {
      left: 4rem;
    }
  }
}

.c-pageHeader__subtitle {
  padding: 3rem 0 0;
  line-height: 1.8;
  @include mq(md,min) {
    font-size: 2rem;
  }
  @include mq(md) {
    font-size: 3.4rem;
  }
}

// アニメーション実行時
.c-pageHeader__inner.-active .c-pageHeader__text {
  &::after {
    width: 8rem;
  }
}
