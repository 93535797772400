@use '../../../foundation/config' as *;

.p-companyBlock11 {
  margin: 13.5rem 0 20rem;
  @include mq(md, min) {
    margin-top: 14.1rem;
  }
}
.p-companyBlock11__header1 {
  @include mq(md, min) {
    display: flex;
  }
}
.p-companyBlock11__header1Title1 {

}
.p-companyBlock11__linkGroup {
  margin-top: 8rem;
}

.p-companyBlock11__unit1 {
  margin: 13.6rem 0 0;
  @include mq(md, min) {
    display: flex;
    width: 100%;
    margin: 7rem 0 0;
  }
  &:first-child {
    margin: 0;
  }
}
.p-companyBlock11__unit1Title1 {
  font-size: 4rem;
  line-height: (60/40);
  font-weight: bold;
  @include mq(md, min) {
    min-width: 52.2rem;
    max-width: 52.2rem;
    font-size: 2.6rem;
    line-height: (44/26);
  }
}
.p-companyBlock11__unit1LinkGroup1 {
  margin: 5.1rem 0 0;
  @include mq(md, min) {
    margin: 1.3rem 0 0;
    width: 100%;
    overflow: hidden;
  }
  @at-root {
    .p-companyBlock11__unit1:first-child .p-companyBlock11__unit1LinkGroup1 {
      margin: 5.9rem 0 0;
      @include mq(md, min) {
        margin: 1.3rem 0 0;
      }
    }
  }
}
.p-companyBlock11__unit1LinkGroup1Inner1 {
  @include mq(md, min) {
    display: flex;
    margin: 1px -4rem 0;
  }
  & > div {
    @include mq(md, min) {
      min-width: 50%;
      max-width: 50%;
      padding: 0 4rem;
    }
  }
}

.p-companyBlock11__list {
  @include lhCrop(36 / 16);
  font-size: 1.6rem;
  font-weight: normal;
  line-height: (36 / 16);
  margin-top: 2.3rem;

  @include mq(md) {
    @include lhCrop(2);
    font-size: 3rem;
    line-height: 2;
    margin-top: 4.5rem;
  }

  &.-normal {
    &::before,
    &::after {
      display: none;
    }
  }

  & > li {
    position: relative;
    padding-left: 2rem;
    font-size: inherit;
    line-height: inherit;

    @include mq(md) {
      padding-left: 4rem;
    }
    small{
      font-size: 1.4rem;
      @include mq(md) {
        font-size: 2.4rem;
      }
    }

    &::before {
      content: '';
      display: inline-block;
      position: absolute;
      top: 1.8rem;
      left: 0;
      width: 0.8rem;
      height: 0.8rem;
      margin-right: 2rem - 0.8rem;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='8px' height='8px'%3E%3Cpath fill-rule='evenodd' fill='rgb(0, 160, 64)' d='M4.000,-0.000 C6.209,-0.000 8.000,1.791 8.000,4.000 C8.000,6.209 6.209,8.000 4.000,8.000 C1.791,8.000 -0.000,6.209 -0.000,4.000 C-0.000,1.791 1.791,-0.000 4.000,-0.000 Z'/%3E%3C/svg%3E");
      background-repeat: no-repeat;
      background-size: 100%;
      transform: translateY(-50%);

      @include mq(md) {
        top: 3rem;
        width: 1.6rem;
        height: 1.6rem;
        margin-right: 4rem - 1.6rem;
      }
    }
  }

  & > li + li {
    margin-top: 2em;
  }
}
