@use '../../foundation/config' as *;

.c-select {
  display: inline-flex;
  position: relative;
  align-items: center;
  border-radius: 0.4rem;
  border: 1px solid color(gray-300);

  @include mq(md) {
    border-radius: 1rem;
  }
}

.c-select__form {
  width: inherit;
  padding: 1em 9rem 1em 3rem;
  border: 0;
  border-radius: inherit;
  background-color: color(white);
  font-size: 1.6rem;
  appearance: none;

  @include mq(md) {
    padding: 1.362em 16rem 1.362em 4rem;
    font-size: 3rem;
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 0 1px color(black);
  }
}

.c-select__appearance {
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
  justify-content: center;
  align-items: center;
  width: 6rem;
  height: 100%;
  border-top-right-radius: inherit;
  border-bottom-right-radius: inherit;
  border-left: 1px solid color(gray-300);
  background-color: color(gray-200);
  pointer-events: none;

  @include mq(md) {
    width: 12rem;
  }
}

.c-select__icon {
  width: 0.9rem;
  height: 1.5rem;
  transform: rotate(90deg);

  @include mq(md) {
    width: 1.2rem;
    height: 2.3rem;
  }
}
