@use '../../../foundation/config' as *;

.p-sustainabilityPerformance {
  padding-bottom: 25.2rem;

  @include mq(md) {
    padding-bottom: 24rem;
  }
}

.p-sustainabilityPerformance__inner {
  margin-top: 0;
  padding-bottom: 1rem;

  @include mq(md) {
    margin-top: 13.6rem;
    padding-bottom: 0.9rem;
  }
}

.p-sustainabilityPerformance__section {
  & + & {
    margin-top: 19.4rem;

    @include mq(md) {
      margin-top: 18.4rem;
    }
  }

  .c-heading.-level4 {
    margin-top: 6.5rem;

    @include mq(md) {
      margin-top: 4.5rem;
    }
  }

  .c-table + .c-heading.-level5 {
    margin-top: 8rem;
  }

  .c-heading.-level5 + .c-table {
    margin-top: 1rem;

    @include mq(md) {
      margin-top: 1.6rem;
    }
  }
}

.p-sustainabilityPerformance__body {
  .c-heading.-level4 + & {
    margin-top: 2.5rem;

    @include mq(md) {
      margin-top: 3.6rem;
    }
  }

  .c-heading.-level2 + & {
    margin-top: 7.1rem;
  }
}

.p-sustainabilityPerformance__smallSection {
  &:not(:first-child) {
    margin-top: 9.4rem;
  }
}

.p-sustainabilityPerformance__text {
  @include lhCrop(2.25);
  font-size: 1.6rem;
  font-weight: normal;
  line-height: 2.25;

  @include mq(md) {
    @include lhCrop(2);
    font-size: 3rem;
    line-height: 2;
  }

  .c-heading.-level3 + & {
    margin-top: 9.2rem;

    @include mq(md) {
      margin-top: 8.2rem;
    }
  }

  .c-heading.-level4 + & {
    margin-top: 2.5rem;

    @include mq(md) {
      margin-top: 3.6rem;
    }
  }

  & + .c-heading.-level4 {
    margin-top: 5rem;
  }

  .p-sustainabilityPerformance__button + & {
    margin-top: 8rem;
  }
}

.p-sustainabilityPerformance__content {
  position: relative;
  margin-top: 7.5rem;

  .c-heading.-level5 {
    + table {
      margin-top: 1rem;

      @include mq(md) {
        margin-top: 2rem;
      }
    }
  }

  table {
    + table {
      margin-top: 4rem;

      @include mq(md) {
        margin-top: 5rem;
      }
    }
  }

  th > span {
    font-weight: normal;
  }

  &.-type1,
  &.-type2 {
    caption {
      position: absolute;
      top: 0.5rem;
      right: 0;
    }
  }

  &.-type1 {
    caption {
      @include mq(md) {
        top: 0;
      }
    }
  }

  &.-type2 {
    caption {
      @include mq(md) {
        top: 1.7em;
      }
    }
  }

  &.-type3 {
    caption {
      position: absolute;
      top: -5rem;
      right: 0;
    }
  }
}

.p-sustainabilityPerformance__table1 {
  th:nth-child(1) {
    width: 31.2%;
  }

  .c-table__spSize {
    @include mq(md) {
      width: 140rem;
    }
  }
}

.p-sustainabilityPerformance__table2 {
  th:nth-child(1) {
    width: 37.5%;

    @include mq(md) {
      width: 35.9%;
    }
  }

  td {
    width: 20.8%;

    @include mq(md) {
      width: 21.3%;
    }
  }

  .c-table__spSize {
    @include mq(md) {
      width: 128rem;
    }
  }
}

.p-sustainabilityPerformance__table3 {
  th:nth-child(1) {
    width: 37.5%;

    @include mq(md) {
      width: 30%;
    }
  }

  th {
    line-height: 1.3;
  }

  td {
    width: 20.8%;

    @include mq(md) {
      width: 23.3%;
    }
  }

  .c-table__spSize {
    @include mq(md) {
      width: 140rem;
    }
  }
}

.p-sustainabilityPerformance__table4 {
  th:nth-child(1) {
    width: 50%;
  }

  .c-table__spSize {
    @include mq(md) {
      width: 140rem;
    }
  }
}

.p-sustainabilityPerformance__table5 {
  th:nth-child(1) {
    // width: 37.5%;
    width: 27.5%;

    @include mq(md) {
      // width: 30%;
      width: 20%;
    }
  }

  td {
    width: 20.8%;

    @include mq(md) {
      width: 23.3%;
    }

    &.table5_item{
      width:10%;
      background-color:#f1f1ee;
    }
  }

  .c-table__spSize {
    @include mq(md) {
      width: 140rem;
    }
  }
}

.p-sustainabilityPerformance__table6 {
  table {
    table-layout: fixed;
    width: 100%;
  }
  th:nth-child(1) {
    width: 31.2%;
  }
  th {
    line-height: 1.3;
  }

  .c-table__spSize {
    @include mq(md) {
      width: 140rem;
    }
  }
}

.p-sustainabilityPerformance__note {
  margin-top: 2rem;
  font-size: 1.4rem;
  line-height: 1.9;

  @include mq(md) {
    font-size: 2.2rem;
  }
}

.p-sustainabilityPerformance__figure {
  margin-top: 7.9rem;
  padding-right: 5.5rem;
  padding-left: 5.5rem;
  text-align: center;

  @include mq(md) {
    margin-top: 8.1rem;
    padding-right: 0;
    padding-left: 0;
  }

  img {
    max-width: 100%;
    height: auto;
  }
}

.p-sustainabilityPerformance__button {
  margin-top: 5rem;

  @include mq(md) {
    text-align: center;
  }

  &.-large {
    margin-top: 8rem;
  }
}
