@use '../../../foundation/config' as *;

.p-irIndividual {
  margin-bottom: 24.8rem;
}

.p-irIndividualHeroArea {
  .p-irIndividualHeroImage {
    @include mq(md) {
      margin-left: -4rem;
      margin-right: -4rem;
    }
    img {
      width: 100%;
      height: auto;
    }
  }
  .p-irIndividualHeroText {
    font-size: 2rem;
    font-weight: 700;
    line-height: 2.025;
    margin-top: 6.7rem;
    @include mq(md) {
      font-size: 3.4rem;
      line-height: 1.6;
    }
  }
}

.p-irIndividualKeywords {
  margin-top: 17.88rem;
  .p-irIndividualKeywordsNav {
    background-color: color(gray-200);
    margin-top: 7.15rem;
    ul {
      display: flex;
      @include mq(md) {
        display: block;
      }
    }
    li {
      width: 20%;
      + li {
        border-left: 1px solid color(white);
      }
      @include mq(md) {
        width: 100%;
        height: 12.8rem;
        + li {
          border-top: 1px solid color(white);
          border-left: none;
        }
      }
    }
    a {
      position: relative;
      display: flex;
      flex-flow: column;
      align-items: center;
      height: 100%;
      padding: 4rem 0 6.2rem;
      transition: color 400ms easing(easeOutCubic);
      &:hover {
        color: color(green-600);
      }
      @include mq(md) {
        flex-flow: row;
        padding: 0.5rem 3.5rem;
      }
      i {
        position: absolute;
        bottom: 3rem;
        display: block;
        svg {
          transform: rotate(90deg);
        }
        @include mq(md) {
          bottom: auto;
          right: 2.4rem;
        }
      }
    }
    .p-irIndividualKeywordsIcon {
      svg {
        width: 4.8rem;
        height: 4rem;
      }
      @include mq(md) {
        width: 7.2rem;

        svg {
          width: 7.2rem;
          height: 6rem;
        }
      }
    }
    .p-irIndividualKeywordsText {
      font-size: 2rem;
      font-weight: 700;
      text-align: center;
      padding-top: 3.75rem;
      &.-turn {
        padding-top: 1.85rem;
      }
      @include mq(md) {
        font-size: 3rem;
        line-height: 1;
        padding-top: 0;
        padding-left: 4.2rem;
        &.-turn {
          padding-top: 0;
        }
      }
    }
  }
}

.p-irIndividualSectionText {
  font-size: 1.6rem;
  line-height: 2.4;
  margin-top: 6rem;
  @include mq(md) {
    font-size: 3rem;
    line-height: 2;
    margin-top: 5.5rem;
  }
}

.p-irIndividualDescription {
  margin-top: 18.9em;
  @include mq(md) {
    margin-top: 18.5rem;
  }
  .p-irIndividualEachBusiness {
    margin-top: 6.5rem;
    li {
      display: flex;
      + li {
        margin-top: 8rem;
      }
      @include mq(md) {
        display: block;
        + li {
          margin-top: 7rem;
        }
      }
    }
  }
  .p-irIndividualEachBusinessImage,
  .p-irIndividualEachBusinessText {
    width: 50%;
    @include mq(md) {
      width: 100%;
    }
  }
  .p-irIndividualEachBusinessText {
    display: flex;
    flex-flow: column;
    justify-content: center;
    padding: 0 0 0 8.8rem;
    @include mq(md) {
      padding: 0;
      margin-top: 3.8rem;
    }
    h3 {
      font-size: 2.6rem;
      margin-bottom: 1.95rem;
      @include mq(md) {
        font-size: 4rem;
      }
    }
    p {
      font-size: 1.6rem;
      line-height: 2.25;
      @include mq(md) {
        font-size: 3rem;
        line-height: 2;
      }
    }
  }
  .p-irIndividualEachBusinessImage img {
    width: 100%;
    height: auto;
  }
  .p-irIndividualMainBusinessLink {
    margin-top: 8rem;
    @include mq(md) {
      margin-top: 6.6rem;
    }
  }
  .p-irIndividualMainBusinessText {
    font-size: 1.6rem;
    line-height: 2.2;
    margin-top: 6.8rem;
    @include mq(md) {
      font-size: 3rem;
      line-height: 2;
      margin-top: 6.2rem;
    }
  }
  .p-irIndividualSubBusinessLink {
    margin-top: 7rem;
    @include mq(md) {
      margin-top: 6.4rem;
    }
  }
}

@include mq(md, min) {
  .p-irIndividualSubBusinessLink .c-linkGroup__item a {
    padding: 1.8rem 3rem;
  }
}

.p-irIndividualBrand {
  margin-top: 18.9rem;
  .p-irIndividualBrandItemArea {
    margin-top: 7rem;
    @include mq(md) {
      margin-top: 6.5rem;
    }
    .p-irIndividualBrandItemTitleArea {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      justify-content: center;
      width: 45.714%;
      padding-left: 3.9rem;
      @include mq(md) {
        width: 100%;
        height: 43rem;
      }
      &.-leaf {
        background-color: #b6b69e;
        @include mq(md) {
          height: 50rem;
        }
      }
      &.-greentea {
        background-color: #a1aa9c;
      }
      &.-barleytea {
        background-color: #b79f8c;
      }
      h3 {
        font-size: 2rem;
        color: color(white);
        margin-top: 1.3rem;
        @include mq(md) {
          font-size: 4rem;
        }
      }
      h4 {
        font-size: 4.2rem;
        @include font-serif;
        color: color(white);
        margin-top: 1.8rem;
        @include mq(md) {
          font-size: 6rem;
        }
      }
    }
    .p-irIndividualBrandItemImage {
      width: 54.286%;
      @include mq(md) {
        width: 100%;
      }
      img {
        width: 100%;
        height: auto;
      }
    }
    .p-irIndividualBrandItemText {
      font-size: 1.6rem;
      line-height: 2.2;
      margin-top: 4rem;
      @include mq(md) {
        font-size: 3rem;
        line-height: 2;
        margin-top: 3rem;
      }
    }
    + .p-irIndividualBrandItemArea {
      margin-top: 9.1rem;
      @include mq(md) {
        margin-top: 8.3rem;
      }
    }
  }
  .p-irIndividualBrandItem {
    display: flex;
    @include mq(md) {
      display: block;
    }
  }
  .p-irIndividualBrandItemSubArea {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 9rem;
    @include mq(md) {
      display: block;
      margin-top: 2.6rem;
    }
    .p-irIndividualSubBrandItem {
      display: flex;
      width: calc(50% - 4rem);
      @include mq(md) {
        width: 100%;
        + .p-irIndividualSubBrandItem {
          margin-top: 8.6rem;
        }
      }
    }
    .p-irIndividualBrandItemImage {
      width: 45%;
      img {
        width: 100%;
        height: auto;
      }
      @include mq(md) {
        width: 29.41%;
      }
    }
    .p-irIndividualBrandItemTitleArea {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      justify-content: center;
      padding-left: 4rem;
      @include mq(md) {
        padding-left: 5.7rem;
      }
      h3 {
        font-size: 1.6rem;
        margin-bottom: 1.1rem;
        @include mq(md) {
          font-size: 3rem;
          margin-bottom: 2.6rem;
        }
      }
      h4 {
        font-size: 2.4rem;
        line-height: 1.9;
        @include mq(md) {
          font-size: 4rem;
        }
      }
    }
    + p {
      font-size: 1.6rem;
      line-height: 2.2;
      margin-top: 2rem;
    }
  }
}

.p-irIndividualGlobal {
  margin-top: 19.2rem;
  @include mq(md) {
    margin-top: 18.2rem;
  }
  .p-irIndividualGlobalItem {
    display: flex;
    margin-top: 7.1rem;
    @include mq(md) {
      display: block;
    }
  }
  .p-irIndividualEachBusinessImage,
  .p-irIndividualEachBusinessText {
    width: 50%;
    @include mq(md) {
      width: 100%;
    }
  }
  .p-irIndividualEachBusinessImage img {
    width: 100%;
    height: auto;
  }
  .p-irIndividualEachBusinessText {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    padding-left: 9rem;
    @include mq(md) {
      padding-left: 0;
    }
    p {
      font-size: 1.6rem;
      line-height: 2.2;
      @include mq(md) {
        font-size: 3rem;
        line-height: 2;
        margin-top: 5.6rem;
      }
    }
    h3 {
      font-size: 2.4rem;
      line-height: 1.9;
      margin-top: 2.6rem;
      @include mq(md) {
        font-size: 4rem;
        line-height: 1.5;
        margin-top: 2rem;
      }
    }
    .c-button {
      margin-top: 3.7rem;
    }
  }
}

.p-irIndividualDirection {
  margin-top: 19.2rem;
}

.p-irIndividualDirectionArea {
  position: relative;

  &::after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transition: background-color 400ms easing(easeOutCubic);
    pointer-events: none;
  }

  &:hover::after {
    background-color: color(green-300);
  }
}

.p-irIndividualDirectionImage {
  margin-top: 7.2rem;
  overflow: hidden;

  @include mq(md) {
    margin-top: 6.8rem;
    margin-left: -4rem;
    margin-right: -4rem;
  }

  img {
    width: 100%;
    height: auto;
    transition: transform 400ms easing(easeOutCubic);
    backface-visibility: hidden;

    .p-irIndividualDirectionArea:hover & {
      transform: scale(1.04);
    }
  }
}
.p-irIndividualDirectionItem {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  color: color(white);
  padding-left: 7rem;
  @include mq(md) {
    padding-left: 0;
  }
  p {
    font-size: 2rem;
    font-weight: 700;
    line-height: 2;
    z-index: z(content);
    pointer-events: none;
    @include mq(md) {
      font-size: 3.4rem;
      line-height: 1.6;
    }
  }
  h3 {
    font-size: 4.2rem;
    @include font-serif;
    margin-top: 1.4rem;
    z-index: z(content);
    pointer-events: none;
    @include mq(md) {
      font-size: 6rem;
      margin-top: 1.6rem;
    }
  }
  .c-button {
    margin-top: 3.9rem;
    z-index: z(content);
    @include mq(md) {
      margin-top: 3.6rem;
    }

    &:hover {
      color: color(white);
      letter-spacing: 0;

      .c-button__separator,
      .c-button__arrow {
        color: inherit;
      }
    }

    &::after {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
    }
  }
}

.p-irIndividualShareholder {
  margin-top: 19.2rem;
  @include mq(md) {
    margin-top: 23.6rem;
  }
  .p-irIndividualShareholderList {
    display: flex;
    justify-content: space-between;
    margin-top: 7.3rem;
    @include mq(md) {
      display: block;
    }
    li {
      width: calc((100% / 3) - 4.1rem);
      @include mq(md) {
        width: 100%;
        + li {
          margin-top: 7.3rem;
        }
      }
    }
    .p-irIndividualEachBusinessImage img {
      width: 100%;
      height: auto;
    }
    h3 {
      font-size: 2rem;
      margin-top: 2.2rem;
      @include mq(md) {
        font-size: 3.4rem;
        margin-top: 3.2rem;
      }
    }
    p {
      font-size: 1.6rem;
      line-height: 2.2;
      margin-top: 1.5rem;
      @include mq(md) {
        font-size: 3rem;
        margin-top: 1.9rem;
      }
    }
    .c-button {
      margin-top: 0.9rem;
      @include mq(md) {
        margin-top: 1.2rem;
      }
    }
  }
}

.p-irIndividualShareholderAdvanced {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  padding: 3.3rem 3rem 2.7rem 4rem;
  border: 1px solid color(gray-300);
  margin-top: 7rem;
  @include mq(md) {
    margin-top: 6.8rem;
    padding: 2.8rem 3rem 4rem 4rem;
  }
  h3 {
    font-size: 1.9rem;
    @include mq(md) {
      font-size: 3.4rem;
    }
  }
  p {
    font-size: 1.6rem;
    line-height: 2.1;
    margin-top: 0.5rem;
    @include mq(md) {
      font-size: 3rem;
      line-height: 2;
    }
  }
  .c-button {
    margin-top: 1.3rem;
  }
}
