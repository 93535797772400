@use '../../../foundation/config' as *;

.p-topCompany {
  margin-top: 10rem;

  @include mq(md) {
    // margin-top: 23.4rem;
  }
}

.p-topCompany__description {
  margin-top: 6.2rem;
  font-size: 1.6rem;
  line-height: (36 / 16);

  @include mq(md) {
    // margin-top: 5.2rem;
    font-size: 3rem;
    line-height: (60 / 30);
  }
}

.p-topCompany__mediaGroup {
  margin-top: 4.3rem;

  @include mq(md) {
    margin-top: 6.2rem;
  }
}

.p-topCompany__mediaList {
  @include mq(md, min) {
    display: flex;
  }
}

.p-topCompany__mediaItem {
  position: relative;
  flex: 1 1 50%;
  background-color: gray;
  font-size: 2.6rem;
  font-weight: bold;
  overflow: hidden;

  @include mq(md) {
    font-size: 4rem;
  }
}

.p-topCompany__mediaLink {
  display: block;
  color: color(white);

  &::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transition: background-color 400ms easing(easeOutCubic);
    z-index: z(content);
  }

  &:hover {
    &::before {
      background-color: color(green-300);
    }
  }
}

.p-topCompany__mediaImage {
  width: 100%;
  height: auto;
  transition: transform 400ms easing(easeOutCubic);
  backface-visibility: hidden;

  .p-topCompany__mediaLink:hover & {
    transform: scale(1.04);
  }
}

.p-topCompany__mediaText {
  position: absolute;
  bottom: 3.4rem;
  left: 4rem;
  z-index: z(content);

  @include mq(md) {
    bottom: 5rem;
    left: 3.4rem;
  }
}

.p-topCompany__mediaSeparator {
  display: flex;
  position: absolute;
  justify-content: center;
  align-items: center;
  right: 2rem;
  bottom: 2rem;
  width: 6rem;
  height: 6rem;
  border: 1px solid;
  color: rgba(color(white), 0.5);
  transition: box-shadow 400ms easing(easeOutCubic);
  // z-index: z(content);

  @include mq(md) {
    right: 4rem;
    bottom: 4rem;
    width: 8rem;
    height: 8rem;
  }

  .p-topCompany__mediaLink:hover & {
    box-shadow: 0 0 0 1px;
  }
}

.p-topCompany__mediaArrow {
  width: 1.6rem;
  height: 1.1rem;
  color: color(white);

  @include mq(md) {
    width: 2.4rem;
    height: 1.7rem;
  }
}

.p-topCompany__linkGroup {
  margin-top: 8rem;
}
