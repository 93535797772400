@use '../../../foundation/config' as *;

.p-irStockPreferredStocks__smallSection {
  margin-top: 9rem;
  @include mq(md, min) {
    margin-top: 11rem;
  }
}

.p-irStockPreferredStocks__text {
  margin-top: 6rem;
  font-size: 1.6rem;
  line-height: (36 / 16);
  @include lhCrop(36 / 16);

  @include mq(md) {
    margin-top: 6rem;
    font-size: 3rem;
    line-height: 2;
    @include lhCrop(2);
  }
}

.p-irStockPreferredStocks__table1 {
  margin-top: 7rem;
  th,
  td {
    width: 33.3333%;
  }
  @include mq(md, min) {
    margin-top: 4rem;
  }
}

.c-table.-style1.p-irStockPreferredStocks__table1 {
  th[scope='col'] {
    background-color: color(gray-240);
  }
  th[scope='col']:not(:last-child) {
    border-right: 1px solid color(white);
  }
}

.c-table.-style1 td.p-irStockPreferredStocks__td {
  text-align: left;
}

.c-table.-style1 .p-irStockPreferredStocks__highlight {
  background-color: color(gray-80);
}

.p-irStockPreferredStocks__smallText {
  font-size: 2.4rem;
  line-height: 1.7;
  margin-top: 4rem;
  @include mq(md, min) {
    font-size: 1.4rem;
    margin-top: 3.8rem;
    line-height: 1.8;
  }
  &.marT-line1{
    margin-top: 2.4rem;
    @include mq(md, min) {
      margin-top: 1.4rem;
    }
  }
}

.p-irStockPreferredStocks__list {
  margin-top: 7rem;
  font-size: 3rem;
  line-height: 2;
  counter-reset: item;
  @include mq(md, min) {
    margin-top: 3rem;
    font-size: 1.6rem;
  }
}

.p-irStockPreferredStocks__listItem {
  padding-left: 10.7rem;
  padding-top: 2rem;
  margin-bottom: 2rem;
  position: relative;
  @include mq(md, min) {
    padding-left: 5.7rem;
    padding-top: 1.3rem;
    margin-bottom: 1rem;
  }
}

.p-irStockPreferredStocks__listItem:before {
  color: color(green-600);
  counter-increment: item;
  content: counter(item);
  position: absolute;
  top: 1rem;
  left: 0;
  width: 8rem;
  height: 8rem;
  text-align: center;
  border-radius: 50%;
  border: 1px solid color(green-600);
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  @include mq(md, min) {
    width: 4rem;
    height: 4rem;
  }
}

.p-irStockPreferredStocks__box {
  margin-top: 4rem;
  .c-box__heading {
    line-height: 2.2;
  }
  @include mq(md, min) {
    margin-top: 8rem;
  }
}

.p-irStockPreferredStocks__button {
  margin-top: 4rem;
  @include mq(md, min) {
    margin-top: 7rem;
  }
}
