@use '../../../foundation/config' as *;

.p-researchCatechin {
  padding-bottom: 25.2rem;

  @include mq(md) {
    padding-bottom: 24rem;
  }
}

.p-researchCatechin__inner {
  margin-top: 14.5rem;

  @include mq(md) {
    margin-top: 14.5rem;
  }
}

.p-researchCatechin__section {
  & + & {
    margin-top: 20rem;

    @include mq(md) {
      margin-top: 18.4rem;
    }
  }

  @include mq(md, min) {
    &.-safety,
    &.-product {
      margin-top: 18.5rem;
    }
  }
}

.p-researchCatechin__title {
  @include mq(md, min) {
    margin: 0 0 7rem;
    font-size: 3.4rem;
    @include lhCrop(44 / 34);
  }

  @include mq(md) {
    margin: 0 0 6rem;
    font-size: 4rem;
    @include lhCrop(60 / 40);
  }
}

.p-researchCatechin__antibacterialImage {
  text-align: center;

  @include mq(md, min) {
    margin: 7rem 26.6rem 0;
  }

  @include mq(md) {
    margin: 7.4rem 0 0;
  }

  img {
    width: 100%;
    height: auto;
  }
}

.p-researchCatechin__button {
  text-align: center;
  margin: 5rem 0 0;
}

.p-researchCatechin__body {
  font-size: 1.6rem;
  line-height: (36/16);

  @include mq(md) {
    font-size: 3rem;
    line-height: 2;
  }
}

.p-researchCatechin__deodorizeTable {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  table-layout: fixed;

  @include mq(md, min) {
    font-size: 1.6rem;
    line-height: (36/16);
    margin: 1rem 0 3.8rem;
  }

  @include mq(md) {
    font-size: 2.6rem;
    line-height: (40/26);
    margin: 3.4rem 0 6rem;
  }

  th,
  td {
    border: 1px solid color(gray-300);

    &:first-child {
      border-left: 0;
    }

    &:last-child {
      border-right: 0;
    }

    @include mq(md, min) {
      padding: 1.7rem 2.4rem;
    }

    @include mq(md) {
      padding: 2.3rem 2.5rem;
    }
  }

  thead {
    background-color: color(gray-200);
    th {
      text-align: center;
      font-weight: bold;
    }
  }

  tbody {
    th {
      text-align: left;
      font-weight: 400;
      background-color: #f1f1ee;
    }

    td {
      text-align: center;
    }
  }
}

.p-researchCatechin__productLink {
  margin: 8rem 0 0;
}

.p-researchCatechin__contact {
  border: 1px solid color(gray-300);
  margin: 10rem 0 0;

  @include mq(md, min) {
    display: flex;
    align-items: center;
  }

  @include mq(md) {
    padding: 2.5rem 3.9rem 2.7rem;
  }
}

.p-researchCatechin__contactTitle {
  font-weight: bold;

  @include mq(md, min) {
    font-size: 2rem;
    line-height: (40/20);
    width: 46rem;
    padding: 3rem 0 3rem 4rem;
    flex-shrink: 0;
  }

  @include mq(md) {
    font-size: 3.4rem;
    line-height: (54/34);
    margin: 0 0 2rem;
  }
}

.p-researchCatechin__contactData {
  @include mq(md, min) {
    font-size: 1.6rem;
    line-height: (36/16);
    display: flex;
    align-items: center;
  }

  @include mq(md) {
    font-size: 3rem;
    line-height: 2;
  }
}

.p-researchCatechin__contactName {
  font-weight: bold;

  @include mq(md, min) {
    margin-right: 3.4rem;
  }
}

.p-researchCatechin__contactAddress {
  @include mq(md, min) {
    margin-right: 1.6rem;
  }
}
