@use 'config' as *;

*,
::before,
::after {
  box-sizing: border-box;
}

html {
  height: 100%;
  font-family: 'Noto Sans JP', sans-serif;
  font-size: 0.625vw;
  line-height: 1.5;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;

  @include mq(md) {
    font-size: 1.94 * 0.625vw;
  }

  @include mq(lg, min) {
    font-size: 62.5% * 1920 / 1600;
  }

  @include print {
    font-size: 4px;
  }
}

body {
  background-color: color(gray-100);
}

body,
#container {
  height: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
hr,
blockquote,
ol,
ul,
dl,
dd,
figure,
pre {
  margin: 0;
}

a {
  color: currentColor;
  text-decoration: none;
  text-underline-position: under;
}

img,
svg,
video,
canvas,
audio,
iframe,
embed,
object {
  max-width: 100%;
  vertical-align: bottom;
}

button {
  border: 0;
  background-color: transparent;
  color: inherit;
}

button:not(:disabled):not([aria-disabled='true']) {
  cursor: pointer;
}

ol,
ul {
  padding: 0;
}

// 1. リスト項目のマーカーのリセットと復元
//    https://standard.shiftbrain.com/blog/reset-and-restore-list-item-marker

li {
  list-style-type: none; // 1
}

textarea {
  border-radius: 0;
  resize: vertical;
}

fieldset {
  border: 0;
}

small {
  font-size: inherit;
}

em,
i,
address {
  font-style: normal;
}

sub {
  bottom: 0;
}

svg {
  fill: currentColor;
  overflow: visible;
}

[data-whatintent='mouse'] :focus,
[data-whatintent='touch'] :focus {
  outline: 0;
}

[data-whatintent='keyboard'] :not(input):focus {
  outline: 3px solid color(green-600);
}

::-ms-expand {
  display: none;
}

// WordPress管理画面の投稿追加ボタン
#wp-admin-bar-new-post {
  display: none;
}
