@use '../../../foundation/config' as *;

.p-researchTop {
  padding-bottom: 25.2rem;

  @include mq(md) {
    padding-bottom: 24rem;
  }
}

.p-researchTop__inner {
  margin-top: 14.5rem;

  @include mq(md) {
    margin-top: 14.5rem;
  }
}

.p-researchTop__section {
  & + & {
    margin-top: 20rem;

    @include mq(md) {
      margin-top: 19.4rem;
    }
  }

  @include mq(md, min) {
    &.-flex {
      display: flex;
    }
  }
}

.p-researchTop__title {
  @include mq(md, min) {
    margin: 0 0 7rem;
    font-size: 3.4rem;
    @include lhCrop(44 / 34);
  }

  @include mq(md) {
    margin: 0 0 6rem;
    font-size: 4rem;
    @include lhCrop(60 / 40);
  }
}

.p-researchTop__text {
  @include mq(md, min) {
    font-size: 1.6rem;
    line-height: (36/16);
  }

  @include mq(md) {
    font-size: 3rem;
    line-height: 2;
  }
}

.p-researchTop__article {
  flex: 0 0 75%;
  margin: 0 0 0 auto;
}

.p-researchTop__statement {
  @include mq(md, min) {
    display: flex;
    flex-wrap: wrap;
  }
}

.p-researchTop__statementTitle {
  @include mq(md, min) {
    font-size: 3.4rem;
    @include lhCrop(44 / 34);
  }

  @include mq(md) {
    margin: 0 0 6rem;
    font-size: 6rem;
    @include lhCrop(80 / 60);
  }
}

.p-researchTop__statementSubTitle {
  @include font-serif;

  @include mq(md, min) {
    font-size: 4.2rem;
    @include lhCrop(62 / 42);
    flex: 0 0 62.5%;
    margin: 0 0 0 auto;
  }

  @include mq(md) {
    font-size: 6rem;
    @include lhCrop(80 / 60);
  }
}

.p-researchTop__statementImage {
  img {
    width: 100%;
  }

  @include mq(md, min) {
    margin: 7.5rem 0 0;
    flex: 0 0 100%;
  }

  @include mq(md) {
    margin: 7rem -4rem 0;
  }
}

.p-researchTop__linkList {
  margin-top: 20rem;

  @include mq(md, min) {
    display: flex;
  }
}

.p-researchTop__linkItem {
  position: relative;
  background-color: gray;
  font-size: 2.6rem;
  font-weight: bold;
  overflow: hidden;

  @include mq(md, min) {
    width: 50%;
  }

  @include mq(md) {
    font-size: 4rem;
  }
}

.p-researchTop__linkInner {
  display: block;
  color: color(white);
  position: relative;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transition: background-color 400ms easing(easeOutCubic);
    z-index: z(content);
  }

  &:hover {
    &::before {
      background-color: color(green-300);
    }
  }
}

.p-researchTop__linkImage {
  img {
    width: 100%;
    height: auto;
    transition: transform 400ms easing(easeOutCubic);
    backface-visibility: hidden;

    .p-researchTop__linkInner:hover & {
      transform: scale(1.04);
    }
  }
}

.p-researchTop__linkText {
  position: absolute;
  bottom: 3.4rem;
  left: 4rem;
  z-index: z(content);

  @include mq(md) {
    bottom: 5rem;
    left: 3.4rem;
  }
}

.p-researchTop__linkup {
  margin-top: 8rem;
}

.p-researchTop__linkSeparator {
  display: flex;
  position: absolute;
  justify-content: center;
  align-items: center;
  right: 2rem;
  bottom: 2rem;
  width: 6rem;
  height: 6rem;
  border: 1px solid;
  transition: box-shadow 400ms easing(easeOutCubic);
  z-index: z(content);

  @include mq(md) {
    right: 4rem;
    bottom: 4rem;
    width: 8rem;
    height: 8rem;
  }

  .p-researchTop__linkInner:hover & {
    box-shadow: 0 0 0 1px;
  }
}

.p-researchTop__linkArrow {
  width: 1.6rem;
  height: 1.1rem;

  @include mq(md) {
    width: 2.4rem;
    height: 1.7rem;
  }
}

.p-researchTop__buttonLarge {
  @include mq(md, min) {
    margin: 8rem 17.5rem;
  }

  @include mq(md) {
    margin: 8rem 0;
  }
}
