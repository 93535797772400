@use '../../../foundation/config' as *;

.p-companyModal1__unit1 {
  margin: 4rem;
  padding: 3.6rem 2.8rem;
  @include mq(md, min) {
    position: relative;
    display: flex;
    margin: 10rem auto;
    padding: 16.2rem 0;
    width: 140rem;
  }
}
.p-companyModal1__unit1Header1 {
  @include mq(md, min) {
    min-width: 52.5rem;
    max-width: 52.5rem;
    padding: 0 0 0 17.5rem;
  }
}
.p-companyModal1__unit1Header1Job {
  font-size: 3rem;
  font-weight: bold;
  line-height: (54/30);
  @include mq(md, min) {
    font-size: 2rem;
    line-height: (40/20);
  }
}
.p-companyModal1__unit1Header1Name1 {
  font-size: 6rem;
  line-height: (80/60);
  @include font-serif;
  margin: 0.4rem 0 0;
  @include mq(md, min) {
    font-size: 4rem;
    line-height: (44/40);
    margin: 1.5rem 0 0;
  }
}
.p-companyModal1__unit1Header1Date {
  font-size: 3rem;
  line-height: (60/30);
  margin: 2.1rem 0 0;
  @include mq(md, min) {
    font-size: 1.6rem;
    line-height: (36/16);
    margin: 3.4rem 0 0;
  }
}
.p-companyModal1__unit1List1 {
  margin: 6rem 0 0;
  @include mq(md, min) {
    width: 100%;
    margin: 0rem 0 0;
  }
  & > dl {
    font-size: 3rem;
    line-height: (60/30);
    text-align: justify;
    @include mq(md, min) {
      font-size: 1.6rem;
      line-height: (36/16);
    }
    & > dt {
      font-weight: bold;
      @include mq(md, min) {
        // width: 8.9rem;
        min-width: 12rem;
        float: left;
      }
    }
    & > dd {
      @include mq(md, min) {
        overflow: hidden;
        padding: 0 2.9rem 0 2.9rem;
      }
    }
  }
}
.p-companyModal1__unit1Button1 {
  border-top: 1px solid color(gray-300);
  padding: 3.4rem 0 0;
  margin: 4.4rem 0 0;
  @include mq(md, min) {
    position: absolute;
    top: 3.6rem;
    right: 5.1rem;
    margin: 0;
    padding: 0;
    border: none;
  }
  & > .mfp-close {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    position: relative;
    width: 100%;
    height: auto;
    font-size: 3rem;
    line-height: (60/30);
    font-weight: bold;
    color: color(black);
    opacity: 1;
    font-family: 'Noto Sans JP', sans-serif;
    @include mq(md, min) {
      font-size: 1.6rem;
      line-height: (36/16);
    }
    i {
      width: 2.6em;
      height: 2.6rem;
      display: block;
      font-size: 1rem;
      transform: rotate(45deg);
      margin: 0 0 0 1.8rem;
      @include mq(md, min) {
        width: 1.4rem;
        height: 1.4rem;
        right: 2rem;
        margin: 0 0 0 0.7rem;
      }
      &:after,
      &:before {
        content: '';
        display: block;
        width: 3.4rem;
        height: 2px;
        background: #000;
        position: absolute;
        top: 0;
        bottom: 0;
        margin: auto;
        @include mq(md, min) {
          width: 1.9rem;
        }
      }
      &:after {
        transform: rotate(-90deg);
      }
    }
  }
}
.mfp-bg {
  background: rgba(color(gray-200), 0.9);
}

.mfp-close {
  position: static;
  padding: 0;
  opacity: 1;
}
