@use '../../../foundation/config' as *;

.l-irMain {
  display: flex;
  justify-content: space-between;
  flex-flow: row-reverse;
  margin-top: 15rem;
  .l-irContent {
    width: 75%;
  }
  .l-irSidebar {
    width: calc(25% - 9rem);
    margin-bottom: 15rem;
    .irNav {
      background-color: color(white);
    }
  }
  @include mq(md) {
    display: block;
    margin-top: 4rem;
    .l-irContent {
      width: 100%;
    }
    .l-irSidebar {
      width: 100%;
    }
  }
}

.p-irSelectbox {
  width: 100%;
  border-radius: 0;
  border: 1px solid color(gray-200);
  .c-select__form {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    box-sizing: border-box;
    width: 100%;
    padding: 1.362em 16rem 1.362em 4rem;
    background-color: color(gray-200);
    font-size: 3rem;
    font-weight: 700;
  }
  .c-select__appearance {
    background-color: color(black);
  }
  .c-select__icon {
    width: 1.8rem;
    height: 3.45rem;
    fill: color(white);
  }
}

.p-irCommon__textLink {
  display: inline-flex;
  align-items: center;
  color: color(gray-600);
  transition-property: color, text-decoration-color;
  transition-duration: 400ms;
  transition-timing-function: easing(easeOutCubic);
  text-decoration: underline;
  text-underline-offset: 0.4rem;
  font-size: 1.6rem;
  line-height: 2.4;

  @include mq(md) {
    font-size: 3rem;
    line-height: 2;
  }

  &:hover {
    color: color(green-600);
    text-decoration-color: rgba(color(gray-600), 0);
  }

  > span {
    @include mq(md, min) {
      display: inline-flex;
      align-items: center;
    }
  }
}

.p-irCommon__externalIcon {
  flex-shrink: 0;
  width: 1.4rem;
  height: 1.4rem;
  margin-left: 0.5em;
  vertical-align: baseline;

  @include mq(md) {
    width: 2.1rem;
    height: 2.1rem;
  }
}
