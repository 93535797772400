@use '../../../foundation/config' as *;

.p-companyBlock14 {
  margin: 13.6rem 0 0;
  border-bottom: 1px solid color(gray-300);
  @include mq(md, min) {
    margin: 14.2rem 0 0;
  }
  & + .p-companyBlock14 {
    margin-top: 18.8rem;
    @include mq(md, min) {
      margin: 19.3rem 0 0;
    }
  }
  &:last-child {
    border: none;
    margin-bottom: -2.9rem;
    @include mq(md, min) {
      margin-bottom: -2.1rem;
    }
  }
}
.p-companyBlock14__unit1 {
  padding: 3.6rem 0 5.1rem;
  border-top: 1px solid color(gray-300);
  @include mq(md, min) {
    display: flex;
    padding: 3rem 0;
  }
  @at-root {
    .p-companyBlock14__title1 + .p-companyBlock14__unit1 {
      margin-top: 6.8rem;
      @include mq(md, min) {
        margin-top: 7.4rem;
      }
    }
  }
}
.p-companyBlock14__unit1Title1 {
  font-size: 3.4rem;
  line-height: (54/34);
  font-weight: bold;
  @include mq(md, min) {
    min-width: 17.5rem;
    max-width: 17.5rem;
    font-size: 2rem;
    line-height: (36/20);
    margin: -1rem 0 -0.9rem;
  }
}
.p-companyBlock14__unit1Text1 {
  font-size: 3rem;
  line-height: (60/30);
  text-align: justify;
  margin: 0.8rem 0 0;
  @include mq(md, min) {
    width: 100%;
    font-size: 1.6rem;
    line-height: (36/16);
    margin: -1rem 0 -0.9rem;
  }
  .small{
    display: block;
    @include mq(md) {
      font-size: 2.25rem;
      margin-top: -0.8rem;
      line-height: 1.6;
    }
    @include mq(md, min) {
      font-size: 1.2rem;
      margin-top: -0.8rem;
    }
    + br{
      display: none;
    }
  }
  &:last-child {
    margin-bottom: -1.7rem;
    @include mq(md, min) {
      margin-bottom: -0.9rem;
    }
  }
  a:not(.c-button) {
    color: color(gray-600);
    display: inline-flex;
    align-items: center;
    text-align: left;
    letter-spacing: -0.05em;
    transition-property: color, text-decoration-color;
    transition-duration: 400ms;
    transition-timing-function: easing(easeOutCubic);
    text-decoration: underline;
    text-underline-offset: 0.4rem;

    i {
      display: block;
      width: 2.4rem;
      height: 1.7rem;
      font-size: 0;
      margin: 0 0 0 1.8rem;
      @include mq(md, min) {
        width: 1.6rem;
        height: 1.1rem;
        margin: 0 0 0 1rem;
      }
    }

    svg {
      width: 2.4rem;
      height: 1.7rem;

      @include mq(md, min) {
        width: 1.6rem;
        height: 1.1rem;
      }
    }

    &.-external {
      i {
        display: block;
        width: 2.1rem;
        height: 2.1rem;
        font-size: 0;
        margin: 0 0 0 1.8rem;
        @include mq(md, min) {
          width: 1.4rem;
          height: 1.4rem;
          margin: 0 0 0 1rem;
        }
      }
      svg {
        width: 2.1rem;
        height: 2.1rem;
        @include mq(md, min) {
          width: 1.4rem;
          height: 1.4rem;
        }
      }
    }
    &:hover {
      color: color(green-600);
      text-decoration-color: rgba(color(gray-600), 0);
    }
  }
}
.p-companyBlock14__unit1Button1 {
  margin: 3.8rem 0 4.9rem;
  @include mq(md, min) {
    margin: 2rem 0 0.9rem;
  }
}
.p-companyBlock14__unit1Image1 {
  margin: 3.4rem 0 0 0;
  @include mq(md, min) {
    flex: 0 0 12rem;
    margin: 0;
  }
  picture {
    img {
      margin: 0 0 0 3.8rem;
      width: 20rem;
      height: 20rem;
      @include mq(md, min) {
        margin: 1rem 0 0;
        width: 100%;
        height: auto;
      }
    }
    &:first-child {
      img {
        margin: 0;
      }
    }
  }
}
