@use '../../../foundation/config' as *;

.p-researchTheme {
  padding-bottom: 25.2rem;

  @include mq(md) {
    padding-bottom: 24rem;
  }
}

.p-researchTheme__inner {
  margin-top: 15rem;

  @include mq(md) {
    margin-top: 14.7rem;
  }
}

.p-researchTheme__body {
  @include mq(md, min) {
    min-width: 50%;
    max-width: 50%;
    padding: 0 9.2rem 0 0;
  }
}

.p-researchTheme__text {
  font-size: 3rem;
  line-height: (60/30);
  text-align: justify;
  margin: 2.1rem 0 0;
  @include mq(md, min) {
    font-size: 1.6rem;
    line-height: (36/16);
    margin: 1.4rem 0 0;
  }
}

.p-researchTheme__image {
  display: block;
  margin: 8rem 0 0;
  @include mq(md, min) {
    flex: 0 0 50%;
    margin: 1.2rem 0 0;
  }

  img {
    width: 100%;
    height: auto;
  }
}

.p-researchTheme__section {
  & + & {
    margin-top: 20rem;

    @include mq(md) {
      margin-top: 19.4rem;
    }
  }

  @include mq(md, min) {
    &.-flex {
      display: flex;
    }
  }
}

.p-researchTheme__title {
  @include mq(md, min) {
    font-size: 3.4rem;
    @include lhCrop(44 / 34);
  }

  @include mq(md) {
    font-size: 6rem;
    @include lhCrop(80 / 60);
    margin: 0 0 8rem;
  }
}

.p-researchTheme__article {
  flex: 0 0 75%;
  margin: 0 0 0 auto;
}
