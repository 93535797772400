@use '../../../foundation/config' as *;

.p-irScheduleMeeting__title {
  .c-heading + .c-heading {
    margin-top: 6.8rem;
  }
}

.p-irScheduleMeeting__table1 .c-table.-style1 {
  margin-top: 7rem;
  th[scope='row'] {
    width: 35%;
    font-weight: 700;
  }
  td {
    text-align: left;
  }
  @include mq(md, min) {
    th[scope='row'] {
      width: 28%;
    }
  }
}

.p-irScheduleMeeting__section {
  margin-top: 9rem;
}

.p-irScheduleMeeting__box {
  margin-top: 8rem;
  padding: 3rem 4rem 2.6rem;
  @include mq(md) {
    padding: 3rem 4rem 2.6rem;
    .c-box__heading {
      line-height: 2;
    }
  }
}

.p-irScheduleMeeting__list {
  font-size: 3rem;
  padding-bottom: 1rem;
  @include mq(md, min) {
    font-size: 1.6rem;
    padding-top: 1rem;
  }
}

.p-irScheduleMeeting__listItem {
  line-height: 2.5;
  @include mq(md, min) {
    line-height: 2.2;
  }
}

.p-irScheduleMeeting__listItem a {
  color: color(gray-600);
  display: inline-block;
  vertical-align: middle;
  transition: color 400ms easing(easeOutCubic);

  &:hover {
    color: color(green-600);
  }
}

.p-irScheduleMeeting__linkText {
  display: inline;
  vertical-align: middle;
  margin-right: 0.5em;
  transition-property: color, text-decoration-color;
  transition-duration: 400ms;
  transition-timing-function: easing(easeOutCubic);
  text-decoration: underline;
  text-underline-offset: 0.4rem;

  .p-irScheduleMeeting__listItem a:hover & {
    text-decoration-color: rgba(color(gray-600), 0);
  }
}

.p-irScheduleMeeting__pdf {
  display: inline-block;
  padding: 0 2.7066rem;
  border-radius: 12.5rem;
  border: 1px solid;
  @include font-barlow;
  font-size: 1.4rem;
  line-height: 1.7;
  vertical-align: middle;
  @include mq(md) {
    font-size: 2.4rem;
    line-height: 1.2;
    padding: 0 2.652rem 0.2rem;
    position: relative;
    bottom: -0.2rem;
  }
}

.p-irScheduleMeeting__button {
  margin-top: 2.6rem;
  @include mq(md) {
    margin-top: 6.6rem;
  }
}

.p-irScheduleMeeting__archiveData {
  margin-top: 6.6rem;
}

.p-irScheduleMeeting .c-linkButton {
  min-width: unset;
}

.p-irScheduleMeeting_wysiwyg.p-post{
  margin-top: 1rem;
  @include mq(md, min) {
    margin-top: 3rem;
  }
  #wp-post .wysiwyg{

  }
}
