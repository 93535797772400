// Tab Large
// ============================================================================

@use '../../foundation/config' as *;

.c-tabLarge {
  //
}

.c-tabLarge__list {
  display: flex;
  border-bottom: 1px solid color(gray-300);
  background-color: color(white);
}

.c-tabLarge__item {
  position: relative;
  flex: 1;
  font-size: 2.6rem;
  font-weight: bold;
  text-align: center;
  line-height: (46 / 26);

  @include mq(md) {
    font-size: 4rem;
    line-height: (60 / 40);
  }

  &:nth-child(n + 2) {
    border-left: 1px solid color(gray-300);
  }

  &.-active,
  &[aria-selected='true'] {
    color: color(green-600);

    &::after {
      content: '';
      display: block;
      position: absolute;
      bottom: -1px;
      left: 50%;
      width: 7rem;
      height: 4px;
      background-color: currentColor;
      transform: translateX(-50%);

      @include mq(md) {
        height: 2px;
      }
    }
  }
}

.c-tabLarge__button {
  display: block;
  padding: 6.15rem 10rem;
  transition: color 400ms easing(easeOutCubic);

  @include mq(md) {
    padding: 5.38rem 4rem;
    min-height: 23rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &:hover {
    color: color(green-600);
  }
}

.c-tabLarge__panel {
  max-width: 1920px;
  margin-right: auto;
  margin-left: auto;
  padding-right: 100 / 1600 * 100%;
  padding-left: 100 / 1600 * 100%;

  @include mq(md) {
    padding-right: 4rem;
    padding-left: 4rem;
  }
}
