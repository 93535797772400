@use '../../../foundation/config' as *;

.p-sustainabilityLabourKenkousengen__box {
  border: 2px solid color(gray-300);
  padding: 8rem;

  @include mq(md, min) {
    width: 95%;
    border: 1px solid color(gray-300);
    margin: 0 auto;
    padding: 8rem;
  }
}

.p-sustainabilityLabourKenkousengen__message {
  display: inline-block;
  @include font-serif;
  font-size: 3rem;
  font-weight: 600;
  line-height: 1.5;
  @include mq(md) {
    font-size: 4rem;
  }
  p {
    text-indent: 1em;
  }
  p + p {
   margin-top: 1em;
  }
}

.p-sustainabilityLabourKenkousengen__sign {
  font-size: 2rem;
  font-weight: 700;
  margin-top: 3.6rem;

  @include mq(md) {
    margin-top: 4.5rem;
  }

  img {
    height: auto;
    margin-top: 2rem;

    @include mq(md, min) {
      width: 22rem;
    }

    @include mq(md) {
      width: 24rem;
    }
  }
}

.p-sustainabilityLabourKenkousengen__section {
  & + & {
    margin-top: 9.7rem!important;

    @include mq(md) {
      margin-top: 8rem!important;
    }
  }
}
