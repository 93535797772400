@use '../../../foundation/config' as *;

.p-researchBlock2 {
  @include mq(md) {
    margin-top: 7rem;
  }
}

.p-researchBlock2__body {
  display: flex;

  @include mq(md) {
    flex-direction: column-reverse;
  }
}

.p-researchBlock2__text {
  @include mq(md, min) {
    width: 50%;
    padding: 0 9.2rem 0 0;
  }

  @include mq(md) {
    width: 100%;
  }
}

.p-researchBlock2__description {
  @include mq(md, min) {
    font-size: 1.6rem;
    line-height: (36/16);
    margin: 2rem 0 0;
  }

  @include mq(md) {
    font-size: 3rem;
    line-height: (30/15);
    margin: 1.4rem 0 0;
  }

  p + p {
    margin-top: 4rem;
  }
}

.p-researchBlock2__position {
  font-weight: bold;
}

.p-researchBlock2__image {
  @include mq(md, min) {
    width: 50%;
    text-align: right;
    margin: 1.2rem 0 0;
  }

  @include mq(md) {
    width: 100%;
    margin: 0 0 6.4rem;
  }

  img {
    width: 100%;
    height: auto;
  }
}
