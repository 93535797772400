@use '../../../foundation/config' as *;

.p-researchContainer {
  padding-bottom: 25.2rem;

  @include mq(md) {
    padding-bottom: 24rem;
  }
}

.p-researchContainer__inner {
  margin-top: 14.5rem;

  @include mq(md) {
    margin-top: 13.6rem;
  }
}

.p-researchContainer__section {
  & + & {
    margin-top: 19.5rem;

    @include mq(md) {
      margin-top: 18.4rem;
    }
  }
}

.p-researchContainer__title {
  margin: 0 0 6rem;
}

.p-researchContainer__body {
  @include mq(md, min) {
    margin: 0 0 9rem;
  }

  @include mq(md) {
    margin: 0 0 8rem;
  }
}

.p-researchContainer__text {
  font-size: 1.6rem;
  line-height: 2.25;

  @include mq(md) {
    font-size: 3rem;
    line-height: 2;
  }
}

.p-researchContainer__image {
  @include mq(md, min) {
    margin: 20rem 0;
  }

  @include mq(md) {
    margin: 20rem -4rem;
  }

  img {
    width: 100%;
    height: auto;
  }
}
