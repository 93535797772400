@use '../../../foundation/config' as *;

.p-researchBlock1__body {
  @include mq(md, min) {
    display: flex;
  }
}

.p-researchBlock1__header {
  @include mq(md, min) {
    width: 50%;
    padding: 0 9.2rem 0 0;
  }
}

.p-researchBlock1__title {
  @include font-serif;
  @include mq(md, min) {
    font-size: 4.2rem;
    line-height: (62/42);
  }

  @include mq(md) {
    font-size: 6rem;
    line-height: (80/60);
  }
}

.p-researchBlock1__text {
  @include mq(md, min) {
    width: 50%;
    font-size: 1.6rem;
    line-height: (36/16);
    margin: 0.5rem 0 0;
  }

  @include mq(md) {
    font-size: 3rem;
    line-height: (30/15);
    margin: 2rem 0 0;
  }

  p + p {
    margin-top: 4rem;
  }
}
