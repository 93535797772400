@use '../foundation/config' as *;

.l-header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: z(header);

  @include print {
    position: static;
  }

  // WordPressのadmin barが表示された場合
  .customize-support & {
    top: 32px;

    @include mq(782px) {
      top: 46px;
    }
  }
}
