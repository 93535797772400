@use '../../../foundation/config' as *;

.p-irGrowthVision__section {
  margin: 13rem 0 22rem;
  @include mq(md, min) {
    max-width: 1050px;
    margin: 0 auto 15rem;
  }
}

.p-irGrowthVision__text {
  &:first-child {
    margin-top: 0;
  }

  & > p {
    font-size: 3rem;
    line-height: (60/30);
    text-align: justify;
    margin: 1em 0 0;
    @include mq(md, min) {
      font-size: 1.6rem;
      line-height: (36/16);
    }

    &:first-child {
      margin-top: 0;
    }

    &.-alignRight {
      text-align: right;
    }

    & > small {
      font-size: 2.4rem;
      line-height: (40/24);
      @include mq(md, min) {
        font-size: 1.4rem;
        line-height: (26/14);
      }
    }

  }

  .p-irGrowthVisionBlock__titleMainLabel1{
    // font-size: 6rem;
    line-height: (80/60);
    @include font-serif;
    display: block;
    @include mq(md, min) {
      font-size: 4.2rem;
      line-height: (62/42);
      margin: auto;
    }
    @include mq(md) {
      font-size:6rem;
    }
  }

  .p-irGrowthVisionBlock__titleSubLabel1{
    font-size: 3.4rem;
    line-height: (64/34);
    font-weight: bold;
    display: block;
    margin: 3.2rem 0 0;
    @include mq(md, min) {
      font-size:2rem;
      line-height: (40/20);
      margin: 2.7rem auto 0;
      text-align: left;
    }
  }

  .p-irGrowthVisionBlock__titleSubLabel2{
    font-size: 3.4rem;
    line-height: (64/34);
    font-weight: bold;
    display: block;
    @include mq(md, min) {
      font-size:2rem;
      line-height: (40/20);
      text-align: left;
    }
  }
}

.p-irGrowthVision__title {
  margin: 17.5rem 0 5rem;
  @include mq(md, min) {
    margin: 18.2rem 0 6.4rem;
  }

  &:nth-of-type(1) {
    margin: 17.3rem 0 5rem;
    @include mq(md, min) {
      margin: 0 0 6.4rem;
    }
  }

}

.p-irGrowthVision__listItem {
  position: relative;
  padding-left: 2rem;
  font-size: 2rem;
  line-height: 1.8;

  & + & {
    margin-top: 2rem;
  }

  @include mq(md) {
    padding-left: 4rem;
    font-size: 3.4rem;
  }

  &::before {
    content: '';
    display: inline-block;
    position: absolute;
    top: 1.8rem;
    left: 0;
    width: 0.8rem;
    height: 0.8rem;
    margin-right: 2rem - 0.8rem;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='8px' height='8px'%3E%3Cpath fill-rule='evenodd' fill='rgb(0, 160, 64)' d='M4.000,-0.000 C6.209,-0.000 8.000,1.791 8.000,4.000 C8.000,6.209 6.209,8.000 4.000,8.000 C1.791,8.000 -0.000,6.209 -0.000,4.000 C-0.000,1.791 1.791,-0.000 4.000,-0.000 Z'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-size: contain;
    transform: translateY(-50%);

    @include mq(md) {
      top: 3rem;
      width: 1.6rem;
      height: 1.6rem;
      margin-right: 4rem - 1.6rem;
    }
  }
}

.p-irGrowthVision__table {
  margin: 7.5rem 0 0;
  @include mq(md) {
    margin: 6.9rem 0 0;
  }
  + .p-irGrowthVision__text {
    margin: 7.5rem 0 0;
    @include mq(md) {
      margin: 6.9rem 0 0;
    }
  }
}

.p-irGrowth__table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;

  th {
    background: color(gray-200);
    line-height: 1.54;
    font-size: 1.6rem;
    border-top: 1px solid color(gray-300);
    border-bottom: 1px solid color(gray-300);
    font-weight: bold;
    min-width: 29rem;
    max-width: 29rem;
    padding: 0 3rem;
    text-align: left;

    @include mq(md) {
      font-size: 2.6rem;
      line-height: 2.25;
      padding: 0 2.1rem;
      min-width: 44rem;
      max-width: 44rem;
    }
  }

  td {
    padding: 1.7rem 0 1.9rem 1.9rem;
    font-size: 1.6rem;
    line-height: 2.25;
    border-left: 1px solid color(gray-300);
    width: 100%;
    border-top: 1px solid color(gray-300);
    border-bottom: 1px solid color(gray-300);

    @include mq(md) {
      padding: 2.3rem 0 2.4rem 3rem;
      font-size: 2.6rem;
      line-height: 1.5384615385;
    }
  }
}
