@use '../../../foundation/config' as *;

.p-sustainabilityEvaluate {
  padding-bottom: 25.2rem;

  @include mq(md) {
    padding-bottom: 24rem;
  }
}

.p-sustainabilityEvaluate__inner {
  margin-top: 0;
  padding-bottom: 1rem;

  @include mq(md) {
    margin-top: 13rem;
    padding-bottom: 1.5rem;
  }
}

.p-sustainabilityEvaluate__text {
  @include mq(md, min) {
    font-size: 1.6rem;
    line-height: (36/16);
  }

  @include mq(md) {
    font-size: 3rem;
    line-height: (60/30);
  }
}

.p-sustainabilityEvaluate__notes {
  text-align: right;

  @include mq(md, min) {
    margin: 6.3rem 0 1rem;
  }

  @include mq(md) {
    margin: 5rem 0 1rem;
  }
}

.p-sustainabilityEvaluateBlock {
  border-bottom: 1px solid color(gray-300);
  @include mq(md, min) {
    font-size: 1.6rem;
    line-height: (36/16);
  }

  @include mq(md) {
    font-size: 3rem;
    line-height: (60/30);
  }
}

.p-sustainabilityEvaluateBlock__row {
  border-top: 1px solid color(gray-300);

  @include mq(md, min) {
    display: flex;
    align-items: center;
  }
}

.p-sustainabilityEvaluateBlock__title {
  font-weight: bold;

  @include mq(md, min) {
    padding: 1.7rem 0 1.7rem 0.6rem;
    width: 35.3rem;
    flex-shrink: 0;
  }

  @include mq(md) {
    margin: 3.3rem 0 0;
    font-size: 3.4rem;
    line-height: (54/34);
  }
}

.p-sustainabilityEvaluateBlock__text {
  em {
    font-weight: bold;
  }

  a {
    color: color(gray-600);
    transition: color 400ms easing(easeOutCubic);

    &:hover {
      color: color(green-600);
    }
  }

  svg {
    vertical-align: top;

    @include mq(md, min) {
      width: 1.6rem;
      height: 1.1rem;
      margin: 1.3rem 0 0 0;
    }

    @include mq(md) {
      width: 2.4rem;
      height: 1.7rem;
      margin: 2.3rem 0 0 0;
    }
  }

  @include mq(md, min) {
    width: 100%;
    padding: 1.9rem 0;
  }

  @include mq(md) {
    margin: 1rem 0 3.4rem;
  }
}
