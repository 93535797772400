@use '../../../foundation/config' as *;

.p-sustainabilityGri {
  padding-bottom: 25rem;

  .p-sustainabilityFormat__section:nth-of-type(1) {
    margin: 0 0 4rem;
  }
  .c-button__label {
    margin-right: 1em;
  }

  @include mq(md) {
    padding-bottom: 24rem;

    .p-sustainabilityFormat__section:nth-of-type(1) {
      margin: 0 0 6rem;
    }
  }
}

.p-sustainabilityGri__inner {
  padding-top: 0;
  padding-bottom: 1rem;

  @include mq(md) {
    padding-top: 8.4rem;
  }
}

.p-sustainabilityGri__description {
  font-size: 1.6rem;
  line-height: (36 / 16);

  @include mq(md) {
    font-size: 3rem;
    line-height: 2;
  }
}

.p-sustainabilityGri__scrollArea {
  margin-top: 6.2rem;

  @include mq(md) {
    margin-right: -4rem;
    overflow: auto;
  }
}

.p-sustainabilityGri__tableArea {
  border-top: 1px solid color(gray-300);

  @include mq(md) {
    position: relative;
    width: 140rem;
    padding-right: 4rem;
  }

  &::after {
    @include mq(md) {
      content: '';
      display: block;
      position: absolute;
      top: -1px;
      right: 0;
      width: 4rem;
      height: 1px;
      border: 1px solid color(gray-100);
    }
  }
}

.p-sustainabilityGri__table {
  border-collapse: collapse;
  font-size: 1.6rem;
  line-height: (36 / 16);

  @include mq(md) {
    font-size: 2.6rem;
    line-height: (40 / 26);
  }

  th {
    padding: 1.13em 2.4rem;
  }

  td {
    padding: 1.14em 2.4rem;
  }

  th,
  td {
    border-bottom: 1px solid color(gray-300);
  }

  td:not(:last-child) {
    border-right: 1px solid color(gray-300);
  }

  td:nth-child(1) {
    width: 175 / 1400 * 100%;
  }

  td:nth-child(2) {
    width: 265 / 1400 * 100%;
  }

  td:nth-child(3) {
    width: 521 / 1400 * 100%;
  }

  td:nth-child(4) {
    width: 438 / 1400 * 100%;
  }

  a {
    color: color(gray-600);
  }
}
.p-sustainabilityGri__table2 {
  .c-table2 {
    font-size: 1.4rem;
    line-height: 1.5;
    table {
      @include mq(md) {
        min-width: 164rem;
        font-size: 2.2rem;
      }
    }
    .-item__id {
      width: 9rem;
      @include mq(md) {
        width: 12rem;
      }
    }
    .-item__name {
      width: 28rem;
      @include mq(md) {
        width: 41rem;
      }
    }
    .-item__page {
      width: 37rem;
      @include mq(md) {
        width: 53rem;
      }
    }
    .-item__web {
      @include mq(md, min) {
        width: 30rem;
      }
    }
    caption {
      margin: 2rem 0 1rem;
      font-weight: bold;
      text-align: left;
    }
    thead {
      th, td {
        background-color: color(gray-240);
        text-align: center;
      }
      th {
        border-right: 1px solid #fff;
      }
    }
    tbody {
      th, td {
        text-align: left;
      }
      td {
        padding: 1.4rem;
      }
    }
    .rowgroup1 {
      background-color: color(gray-200);
      font-weight: bold;
      text-align: left;
    }
    .rowgroup2 {
      background-color: color(gray-80);
      font-weight: bold;
      text-align: left;
    }
  }
  dl {
    dt {
      float: left;
    }
    dd {
      margin-left: 6.7rem;
      @include mq(md) {
        margin-left: 10rem;
      }
    }
  }
  .p-sustainabilityGri__arrow,
  .p-sustainabilityGri__external {
    width: 1.2rem;
    height: 1.2rem;
  }
  .p-sustainabilityGri__pdf {
    padding: 0.1rem 1.2rem;
    font-size: 1.2rem;
    line-height: 1.2;
  }
  .p-sustainabilityGri__link {
    display: block;
    text-underline-position: initial;
  }
  .p-sustainabilityGri__link span:not([class]) {
    margin-right: .2em;
  }
  .p-sustainabilityGri__link + .p-sustainabilityGri__link {
    margin-top: .8rem;
    @include mq(md) {
      margin-top: 2rem;
    }
  }
}

.p-sustainabilityGri__header {
  &.-level1 {
    background-color: color(gray-240);

    th:not(:last-child) {
      border-right: 1px solid color(gray-100);
    }
  }

  &.-level2 {
    background-color: color(gray-200);
    text-align: left;
  }

  &.-level3 {
    background-color: color(gray-80);
    text-align: left;
  }
}

.p-sustainabilityGri__pages {
  display: inline-block;
  width: 7.8rem;
  vertical-align: top;

  @include mq(md) {
    width: 12rem;
  }
}

.p-sustainabilityGri__value {
  display: inline-block;
  width: calc(100% - 7.8rem);

  @include mq(md) {
    width: calc(100% - 12rem);
  }
}

.p-sustainabilityGri__link {
  &:hover {
    color: color(green-600);
  }

  span:not([class]) {
    margin-right: 0.5em;
    transition-property: color, text-decoration-color;
    transition-duration: 400ms;
    transition-timing-function: easing(easeOutCubic);
    text-decoration: underline;
    text-underline-offset: 0.4rem;
  }

  &:hover span:not([class]) {
    text-decoration-color: rgba(color(gray-600), 0);
  }
}

.p-sustainabilityGri__arrow {
  width: 1.6rem;
  height: 1.1rem;
  vertical-align: baseline;

  @include mq(md) {
    width: 2.4rem;
    height: 1.7rem;
  }
}

.p-sustainabilityGri__external {
  width: 1.4rem;
  height: 1.4rem;
  vertical-align: baseline;

  @include mq(md) {
    width: 2.1rem;
    height: 2.1rem;
  }
}

.p-sustainabilityGri__pdf {
  display: inline-flex;
  padding: 0.1rem 2.707rem;
  border-radius: 13px;
  border: 1px solid;
  @include font-barlow;
  font-size: 1.4rem;
  line-height: 1.5;

  @include mq(md) {
    padding: 0 2.7rem;
    font-size: 2.4rem;
    line-height: 1.3;
  }
}

/*
.p-sustainabilityGri__table,
.p-sustainabilityGri__table2 {
  tr:not([class]) > td:last-child {
    display: none;
  }
}
*/
