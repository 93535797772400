@use '../../../foundation/config' as *;

.p-irSchedulePresentation__table1 {
  margin-top: 7rem;
  th[scope='row'] {
    width: 35%;
  }
  @include mq(md, min) {
    th[scope='row'] {
      width: 28%;
    }
  }
}

.c-table.-style1.p-irSchedulePresentation__table1 {
  th[scope='row'] {
    font-weight: 700;
  }
  td {
    text-align: left;
  }
}

.p-irSchedulePresentation__section {
  margin-top: 9rem;
}

.p-irSchedulePresentation__box {
  margin-top: 8rem;
  padding: 3rem 4rem 2.6rem;
  @include mq(md) {
    padding: 3rem 1rem 2.6rem 3.8rem;
    .c-box__heading {
      line-height: 2;
    }
  }
}

.p-irSchedulePresentation__list {
  font-size: 3rem;
  padding-bottom: 1rem;
  @include mq(md, min) {
    font-size: 1.6rem;
    padding-top: 1rem;
  }
}

.p-irSchedulePresentation__listItem {
  line-height: 2;
  @include mq(md, min) {
    line-height: 2.2;
  }
}

.p-irSchedulePresentation__listItem a {
  color: color(gray-600);
  display: inline-block;
  vertical-align: middle;
  transition: color 400ms easing(easeOutCubic);

  &:hover {
    color: color(green-600);
  }
}

.p-irSchedulePresentation__linkText {
  display: inline;
  vertical-align: middle;
  margin-right: 0.5em;
  transition-property: color, text-decoration-color;
  transition-duration: 400ms;
  transition-timing-function: easing(easeOutCubic);
  text-decoration: underline;
  text-underline-offset: 0.4rem;

  .p-irSchedulePresentation__listItem a:hover & {
    color: color(green-600);
    text-decoration-color: rgba(color(gray-600), 0);
  }
}

.p-irSchedulePresentation__pdf {
  display: inline-block;
  padding: 0 2.7066rem;
  border-radius: 12.5rem;
  border: 1px solid;
  @include font-barlow;
  font-size: 1.4rem;
  line-height: 1.7;
  vertical-align: middle;
  @include mq(md) {
    font-size: 2.4rem;
    line-height: 1.2;
    padding: 0 2.652rem 0.2rem;
    position: relative;
    bottom: -0.2rem;
  }
}

.p-irSchedulePresentation__image{
  margin: 2.5rem auto 0;
  width: 50%;
  img{
    width: 100%;
    min-width: 100%;
    height: auto;
  }
}
