@use '../../../foundation/config' as *;

.p-sustainabilityFormat {
  padding-bottom: 25.2rem;

  @include mq(md) {
    padding-bottom: 24rem;
  }
}

.p-sustainabilityFormat__inner {
  margin-top: 0;
  padding-bottom: 1rem;

  @include mq(md) {
    margin-top: 13.6rem;
    padding-bottom: 0.9rem;
  }
}

.p-sustainabilityFormat__section {
  & + & {
    margin-top: 19.4rem;

    @include mq(md) {
      margin-top: 18.4rem;
    }
  }

  .c-table,
  .c-heading + .c-heading.-level4 {
    margin-top: 5rem;
  }

  .c-heading.-level5 {
    margin-top: 5rem;
  }

  .c-table + .c-heading.-level5 {
    margin-top: 8rem;
  }

  .c-heading.-level5 + .c-table {
    margin-top: 1rem;

    @include mq(md) {
      margin-top: 1.6rem;
    }
  }
}

.p-sustainabilityFormat__body {
  .c-heading.-level4 + & {
    margin-top: 2.5rem;

    @include mq(md) {
      margin-top: 3.6rem;
    }
  }

  .c-heading.-level2 + & {
    margin-top: 7.1rem;
  }

  .c-heading.-level3 + .c-box {
    margin-top: 5rem;
  }
}

.p-sustainabilityFormat__smallSection {
  &:not(:first-child) {
    margin-top: 9.4rem;
  }
}

.p-sustainabilityFormat__layout {
  margin-top: 5rem;

  @include mq(md, min) {
    display: flex;
  }

  > :first-child {
    @include mq(md, min) {
      margin-right: 9rem;
    }
  }
}

.p-sustainabilityFormat__layoutImage {
  @include mq(md, min) {
    flex-shrink: 0;
    max-width: 50%;
  }

  @include mq(md) {
    margin-top: 5rem;
  }

  img {
    height: auto;
  }

  &.-kurumin {
    img {
      @include mq(md) {
        width: 45rem;
      }

      @include mq(md, min) {
        width: 30.4rem;
      }
    }
  }

  &.-photo {
    text-align: center;

    img {
      width: 43.5rem;

      @include mq(md) {
        width: 60rem;
      }
    }
  }

  &.-photo.-center {
    picture {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 43.5rem;

      @include mq(md) {
        width: auto;
      }
    }

    img {
      width: 50%;
    }
  }

  &.-adjusted {
    margin: 5rem 0 auto;

    @include mq(md) {
      margin: 7rem 0 5rem;
    }
  }

  &.-photo.-widened {
    img {
      width: 60%;
    }
  }
}

.p-sustainabilityFormat__text {
  @include lhCrop(2.25);
  font-size: 1.6rem;
  font-weight: normal;
  line-height: 2.25;

  @include mq(md) {
    @include lhCrop(2);
    font-size: 3rem;
    line-height: 2;
  }

  &.-margin1row {
    margin-top: 5.6rem;

    @include mq(md) {
      margin-top: 9rem;
    }
  }

  &.-marginhalfrow {
    margin-top: 2.8rem;

    @include mq(md) {
      margin-top: 4.5rem;
    }
  }

  &.-normal {
    &::before,
    &::after {
      display: none;
    }
  }

  .c-heading.-level3 + & {
    margin-top: 5rem;
  }

  .c-heading.-level4 + & {
    margin-top: 2.5rem;

    @include mq(md) {
      margin-top: 3.6rem;
    }
  }

  & + .c-heading.-level4 {
    margin-top: 5rem;
  }

  & + .c-box {
    margin-top: 8rem;
  }

  .p-sustainabilityFormat__button + &,
  .p-sustainabilityFormat__image + & {
    margin-top: 8rem;
  }

  .text-box{
    display: inline-block;
    border: 1px solid #ddddd5;
    padding: 1rem;
  }
  .color-gr{
    color: color(green-600);
  }
}

.p-sustainabilityFormat__list {
  @include lhCrop(36 / 16);
  font-size: 1.6rem;
  font-weight: normal;
  line-height: (36 / 16);

  @include mq(md) {
    @include lhCrop(2);
    font-size: 3rem;
    line-height: 2;
  }

  &.-normal {
    &::before,
    &::after {
      display: none;
    }
  }

  &.-margin1row {
    margin-top: 5.6rem;

    @include mq(md) {
      margin-top: 9rem;
    }
  }

  li {
    position: relative;
    padding-left: 2rem;
    font-size: inherit;
    line-height: inherit;

    @include mq(md) {
      padding-left: 4rem;
    }

    &::before {
      content: '';
      display: inline-block;
      position: absolute;
      top: 1.8rem;
      left: 0;
      width: 0.8rem;
      height: 0.8rem;
      margin-right: 2rem - 0.8rem;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='8px' height='8px'%3E%3Cpath fill-rule='evenodd' fill='rgb(0, 160, 64)' d='M4.000,-0.000 C6.209,-0.000 8.000,1.791 8.000,4.000 C8.000,6.209 6.209,8.000 4.000,8.000 C1.791,8.000 -0.000,6.209 -0.000,4.000 C-0.000,1.791 1.791,-0.000 4.000,-0.000 Z'/%3E%3C/svg%3E");
      background-repeat: no-repeat;
      background-size: 100%;
      transform: translateY(-50%);

      @include mq(md) {
        top: 3rem;
        width: 1.6rem;
        height: 1.6rem;
        margin-right: 4rem - 1.6rem;
      }
    }
  }

  .c-heading.-level3 + & {
    margin-top: 5rem;
  }

  .c-heading.-level4 + & {
    margin-top: 2.5rem;

    @include mq(md) {
      margin-top: 3.6rem;
    }
  }

  & + .c-heading.-level4 {
    margin-top: 5rem;
  }

  & + .c-box {
    margin-top: 8rem;
  }
}
.p-sustainabilityFormat__list2 {
  margin: 1rem 0 0 0;
  & > li {
    font-size: 3rem;
    line-height: (60/30);
    text-align: justify;
    margin: 1.5rem 0 0;
    padding: 0 0 0 4rem;
    position: relative;
    @include mq(md, min) {
      font-size: 1.6rem;
      line-height: (36/16);
      padding-left: 2rem;
    }
    &:before {
      content: '・';
      display: block;
      font-size: 5.8rem;
      color: color(green-600);
      position: absolute;
      top: 0;
      left: -8px;
      line-height: 1;
      @include mq(md, min) {
        font-size: 3rem;
        top: 0.3rem;
      }
      @media screen and (min-width: 1900px) {
        top: 0.1rem;
      }
    }
    @at-root {
      .p-sustainabilityFormat__list2.-type1 {
        margin-left: 4rem;
        @include mq(md, min) {
          margin-left: 2rem;
        }
      }
      .p-sustainabilityFormat__list2.-type1 > li {
        list-style-type: decimal;
        margin: 1.5rem 0 0;
        padding: 0;
        &:before {
          display: none;
        }
      }
    }
    span.icon_square{
      display: flex;
      flex-direction: row;
      &::before {
        content: '■';
        color: color(green-600);
        margin-right: 1rem;
      }
    }
  }
}


.p-sustainabilityFormat__image {
  margin-top: 7.9rem;
  text-align: center;

  @include mq(md, min) {
    padding-right: 2.6rem;
    padding-left: 2.6rem;
  }

  @include mq(md) {
    margin-top: 8.1rem;
  }

  img {
    max-width: 100%;
    height: auto;
  }

  &.-quality {
    img {
      width: 81.3rem;
    }
    figcaption {
      width: 81.3rem;
      max-width: 100%;
      margin: 0 auto 1em auto;
      font-size: 1.7rem;
      font-weight: bold;
      text-align: left;
    }
  }

  &.-contact1 {
    img {
      width: 84.3rem;
    }
    figcaption {
      width: 84.3rem;
      max-width: 100%;
      margin: 0 auto 1em auto;
      font-size: 1.7rem;
      font-weight: bold;
      text-align: left;
    }
  }

  &.-contact2 {
    img {
      width: 100.3rem;
    }
  }

  &.-chart {
    img {
      width: 89.3rem;
    }
  }
}

.p-sustainabilityFormat__button {
  margin-top: 5rem;

  @include mq(md) {
    text-align: center;
  }

  &.-large {
    margin-top: 8rem;
    text-align: center;
  }

  &.-multiple {
    @include mq(md, min) {
      display: inline-block;
    }
  }

  &.-multiple + &.-multiple {
    @include mq(md, min) {
      margin-left: 5rem;
    }
  }
}

.p-sustainabilityFormat__textLink {
  display: inline-flex;
  align-items: center;
  color: color(gray-600);
  transition-property: color, text-decoration-color;
  transition-duration: 400ms;
  transition-timing-function: easing(easeOutCubic);
  text-decoration: underline;
  text-underline-offset: 0.4rem;

  &:hover {
    color: color(green-600);
    text-decoration-color: rgba(color(gray-600), 0);
  }

  > span {
    @include mq(md, min) {
      display: inline-flex;
      align-items: center;
    }
  }
}

.p-sustainabilityFormat__externalIcon {
  flex-shrink: 0;
  width: 1.4rem;
  height: 1.4rem;
  margin-left: 0.5em;
  vertical-align: baseline;

  @include mq(md) {
    width: 2.1rem;
    height: 2.1rem;
  }
}

.p-sustainabilityFormat__arrowIcon {
  flex-shrink: 0;
  width: 1.6rem;
  height: 1.1rem;
  margin-left: 0.5em;

  @include mq(md) {
    width: 2.4rem;
    height: 6rem;
  }
}

.p-sustainabilityFormat__linkGroup {
  margin-top: 8rem;
}

.p-sustainabilityFormat__table {
  &.c-table.-style1 {
    caption {
      text-align: left;
    }
  }

  th[scope='row'].green1 {
    background-color: #e4eae6;
  }

  th[scope='row'].green2 {
    background-color: #e9efe5;
  }

  th[scope='row'].green3 {
    background-color: #f4f4e5;
  }
}

.p-sustainabilityFormat__smallText {
  margin-top: 3rem;
  font-size: 1.4rem;
  line-height: (26 / 14);

  @include mq(md) {
    font-size: 2.4rem;
    line-height: (40 / 24);
  }
}

.p-sustainabilityFormat_scenarioTable {
  border: none!important;
  .p-sustainabilityFormat_scenarioTableRow {
    th, td {
      border: none!important;
      vertical-align: top;
      padding: 0;
      line-height: 1.6;
    }
    .p-sustainabilityFormat_scenarioTableTitle {
      white-space: nowrap;
      padding-left: 1.6rem;
      @include mq(md) {
        padding-left: 3rem;
      }
    }
  }
  .p-sustainabilityFormat_scenarioTableRow:not(:first-of-type) {
    th, td {
      padding-top: 0.6rem;
    }
  }
}

// utility
// pc: max80%
.p-w80pc {
  img {
    max-width: 80%;
    @include mq(md) {
      max-width: 100%;
    }
  }
}

.p-mt1P {
  margin-top: 2.5rem;
  @include mq(md) {
    margin-top: 1em;
  }
}

.p-layoutColumn {
  @include mq(md) {
    margin-bottom: 1em;
  }
  @include mq(md, min) {
    &.-column2 {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
    }
    &.-is-half {
      & > * {
        flex: 0 0 48%
      }
    }
  }
  .p-layoutColumn__text {
  }
}