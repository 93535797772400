// Tab
// ============================================================================

@use '../../foundation/config' as *;

.c-tab {
  //
}

.c-tab__list {
  display: flex;
  flex-wrap: wrap;

  @include mq(md) {
    margin-right: -4rem;
    margin-left: -4rem;
  }
}

.c-tab__item {
  flex: 1;
  background-color: color(gray-200);
  font-size: 1.6rem;
  font-weight: bold;
  line-height: (36 / 16);

  @include mq(md) {
    font-size: 3rem;
    text-align: center;
    line-height: (60 / 30);
  }

  &:nth-child(n + 2) {
    border-left: 1px solid color(gray-100);
  }

  &[aria-selected='true'] {
    position: relative;
    color: color(green-600);

    &::before {
      content: '';
      display: block;
      position: absolute;
      width: 0.375em;
      height: 0.375em;
      background-color: currentColor;

      @include mq(md, min) {
        top: 50%;
        left: 2rem;
        transform: translateY(-50%);
      }

      @include mq(md) {
        bottom: 0;
        left: 50%;
        width: 0.399em;
        height: 0.399em;
        transform: translateX(-50%);
      }
    }
  }
}

.c-tab__button {
  display: block;
  padding: 1.7rem 4rem;
  transition: color 400ms easing(easeOutCubic);

  @include mq(md) {
    padding: 1.994rem 4rem;
  }

  &:hover {
    color: color(green-600);
  }
}

.c-tab__panel {
  //
}
