@use '../../../foundation/config' as *;

.p-companyBlock13 {
  // position: sticky;
  margin: 16rem 0 20rem;

  // PC
  @include mq(md, min) {
    position: relative;
    margin: 17rem 0 19.9rem;
  }
}
.p-companyBlock13__header1 {
  display: none;

  // PC
  @include mq(md, min) {
    display: block;
    position: sticky;
    z-index: -1;
    top: 15rem;
    margin-bottom: 10rem;
  }
}

.p-companyBlock13__header1__inner {
  position: relative;
}

.p-companyBlock13__header1Title1 {
  font-size: 8rem;
  line-height: 1;
  @include font-serif;
  margin: 0 0 0 7.3rem;
  opacity: 0;
  transition: opacity 0.5s linear;

  &.-active {
    opacity: 1;
  }
}

.p-companyBlock13__unit1Title1__year {
  font-size: 4rem;
}

// インジケーター
.p-companyBlock13__indicator {
  position: absolute;
  top: 2rem;

  opacity: 0;
  transition: opacity 0.5s linear;

  &.-active {
    opacity: 1;
  }

  svg {
    display: block;
    width: 6px;
    height: 6px;
    fill: color(gray-200);
  }
}

.p-companyBlock13__indicator__inner {
  position: relative;
  width: 10px;
}

.p-companyBlock13__indicator__base {
  position: relative;
  left: 2px;

  // バー
  &::before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 2px;
    height: 100%;
    width: 2px;
    background: color(gray-200);
  }
}

.p-companyBlock13__indicator__anim {
  position: absolute;
  top: 0;
  transition: height 0.3s ease;

  svg {
    display: block;
    width: 10px;
    height: 10px;
    fill: color(green-600);
  }

  // バー
  &::before {
    content: '';
    display: block;
    height: 100%;
    width: 2px;
    background: color(green-600);
    margin-left: calc(50% - 1px);
  }
}

// インジケーターのドット
.p-companyBlock13__indicator__dot {
  &.-active svg {
    fill: color(green-600);
  }

  &:nth-of-type(n + 2) {
    &::before {
      content: '';
      display: block;
      height: 40px;
    }
  }
}

.p-companyBlock13__inner1 {
  @include mq(md, min) {
    position: relative;
    z-index: 1;
  }
}
.p-companyBlock13__unit1 {
  margin: 15.9rem 0 0;
  @include mq(md, min) {
    margin: 18.8rem 0 0 35rem;
  }
  &:first-child {
    margin-top: 0;
  }
}
.p-companyBlock13__unit1Title1 {
  font-size: 9.8rem;
  line-height: 1;
  @include font-serif;
  @include mq(md, min) {
    display: none;
  }
  & > span {
    font-size: 6rem;
  }
}
.p-companyBlock13__unit1Main1 {
  margin: 7.8rem 0 0;
  @include mq(md, min) {
    margin: 0rem 0 0;
    display: flex;
  }
}
.p-companyBlock13__unit1Image1 {
  @include mq(md, min) {
    max-width: 35rem;
    min-width: 35rem;
    margin: 1.2rem 0 0;
  }
  img {
    display: block;
    width: 51rem;
    margin: auto;
    @include mq(md, min) {
      width: 100%;
    }
  }
}
.p-companyBlock13__unit1Main1Body1 {
  margin: 6.2rem 0 0;
  @include mq(md, min) {
    width: 100%;
    margin: 0 0 0;
  }

  .p-companyBlock13__unit1Image1 + & {
    @include mq(md, min) {
      padding: 0 0 0 9rem;
    }
  }
}
.p-companyBlock13__unit1Title2 {
  font-size: 4rem;
  line-height: (60/40);
  font-weight: bold;
  @include mq(md, min) {
    font-size: 2.6rem;
    line-height: (46/26);
  }
}
.p-companyBlock13__unit1Text1 {
  font-size: 3rem;
  line-height: (60/30);
  text-align: justify;
  margin: 2.1rem 0 0;
  @include mq(md, min) {
    font-size: 1.6rem;
    line-height: (36/16);
    margin: 1.8rem 0 -1.1rem;
  }
}
.p-companyBlock13__unit1List1 {
  margin: 5.8rem 0 6.1rem 3.1rem;
  @include mq(md, min) {
    margin: 4.5rem 0 3.6rem 1.6rem;
  }
  & > li {
    list-style: decimal;
    font-size: 3rem;
    line-height: (60/30);
    text-align: justify;
    @include mq(md, min) {
      font-size: 1.6rem;
      line-height: (36/16);
    }
  }
}
.p-companyBlock13__button1 {
  border-top: 1px solid #8f9294;
  margin: 18.6rem 0 0;
  padding: 5.1rem 0 0;
  @include mq(md, min) {
    text-align: center;
    margin: 20.1rem 0 0;
    padding: 4.8rem 0 0;
  }
}
