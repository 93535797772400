@use '../../../foundation/config' as *;

.p-companyBlock18 {
  margin: 18.8rem 0 0;
  @include mq(md, min) {
    margin: 19.2rem 0 0;
  }
  &:first-child {
    margin: 14rem 0 0;
  }
}
.p-companyBlock18__text1 {
  font-size: 3rem;
  line-height: (60/30);
  text-align: justify;
  margin: 3.1rem 0 0;
  @include mq(md, min) {
    font-size: 1.6rem;
    line-height: (36/16);
    margin: 3.8rem 0 0;
  }
  &.txt-right{
    text-align: right;
  }
}
.p-companyBlock18__title2 {
  margin: 9rem 0 1.4rem;
  @include mq(md, min) {
    margin: 6.4rem 0 2.1rem;
  }
  .c-heading.-level4{
    &.text-indent{
      @include mq(md, min) {
        text-indent: -2rem;
        padding-left: 4rem;
      }
      @include mq(md) {
        text-indent: -3.4rem;
        padding-left: 6.8rem;
      }
    }
  }
}

.p-companyBlock18__list {
  @include lhCrop(36 / 16);
  font-size: 1.6rem;
  font-weight: normal;
  line-height: (36 / 16);
  margin-top: 2.3rem;

  @include mq(md) {
    @include lhCrop(2);
    font-size: 3rem;
    line-height: 2;
    margin-top: 4.5rem;
  }

  &.-normal {
    &::before,
    &::after {
      display: none;
    }
  }

  li {
    position: relative;
    padding-left: 2rem;
    font-size: inherit;
    line-height: inherit;

    @include mq(md) {
      padding-left: 4rem;
    }
    small{
      font-size: 1.4rem;
      @include mq(md) {
        font-size: 2.4rem;
      }
    }

    &::before {
      content: '';
      display: inline-block;
      position: absolute;
      top: 1.8rem;
      left: 0;
      width: 0.8rem;
      height: 0.8rem;
      margin-right: 2rem - 0.8rem;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='8px' height='8px'%3E%3Cpath fill-rule='evenodd' fill='rgb(0, 160, 64)' d='M4.000,-0.000 C6.209,-0.000 8.000,1.791 8.000,4.000 C8.000,6.209 6.209,8.000 4.000,8.000 C1.791,8.000 -0.000,6.209 -0.000,4.000 C-0.000,1.791 1.791,-0.000 4.000,-0.000 Z'/%3E%3C/svg%3E");
      background-repeat: no-repeat;
      background-size: 100%;
      transform: translateY(-50%);

      @include mq(md) {
        top: 3rem;
        width: 1.6rem;
        height: 1.6rem;
        margin-right: 4rem - 1.6rem;
      }
    }
  }
}
