@use '../../foundation/config' as *;


.c-heading {
  &.-level2 {
    $border-width: 0.6rem;
    position: relative;
    padding-left: 2rem + $border-width;
    @include font-serif;
    font-size: 3.4rem;
    line-height: (44 / 34);

    @include mq(md) {
      font-size: 6rem;
      line-height: (80 / 60);
    }

    &::before {
      content: '';
      position: absolute;
      top: 50%;
      left: 0;
      width: $border-width;
      height: calc(100% - 0.9rem);
      background-color: color(green-600);
      transform: translateY(-50%);

      @include mq(md) {
        width: 0.8rem;
        height: calc(100% - 2.6rem);
      }
    }
  }

  &.-level3 {
    position: relative;
    padding-bottom: 0.8em;
    @include font-serif;
    font-size: 2.8rem;
    line-height: (38 / 28);
    border-bottom: 2px solid color(gray-300);

    @include mq(md) {
      padding-bottom: 0.5em;
      font-size: 4.6rem;
      line-height: (66 / 46);
    }

    &::after {
      content: '';
      position: absolute;
      bottom: -2px;
      left: 0;
      width: 10rem;
      border-bottom: 2px solid color(green-600);
    }
  }

  &.-level4 {
    font-size: 2.6rem;
    font-weight: bold;
    line-height: (46 / 26);

    @include mq(md) {
      font-size: 4rem;
      line-height: (60 / 40);
    }
  }

  &.-level5 {
    font-size: 2rem;
    font-weight: bold;
    line-height: (40 / 20);

    @include mq(md) {
      font-size: 3.4rem;
      line-height: (54 / 34);
    }

    &.-withNoMargin + * {
      margin-top: 2.5rem;
    }
  }
  &.icon_square{
    display: flex;
    flex-direction: row;
    &::before {
      content: '■';
      color: color(green-600);
      @include mq(md) {
        margin-right: 1rem;
      }
    }
  }
  &.-hasIndent {
    padding-left: 1.8em;
    text-indent: -2em;
  }
}

// トップページ用の見出し
// トップページだけアニメーションが必要なため
#page-home .c-heading.-level2 {
  &::before {
    content: '';
    position: absolute;
    top: 0.5rem;
    left: 0;
    width: 0.6rem;
    // heightをアニメーションさせる
    // height: calc(100% - 0.9rem);
    height: 0;
    background-color: color(green-600);
    transform: translateY(0);
    transition: height 0.5s easing(easeOutCubic);
    transform-origin: top left !important;

    @include mq(md) {
      width: 0.8rem;
      height: calc(100% - 2.6rem);
    }
  }

  &.-active {
    &::before {
      height: calc(100% - 0.9rem);
    }
  }
}
